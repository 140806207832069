import {EDIT_PROMOS, FETCH_PROMOS, FETCH_PROMOS_FAILED, FETCH_PROMOS_SUCCESS} from "../store/types";
import {onValue, push, remove, set} from "firebase/database";
import {firebase} from '../config/configureFirebase';

export const fetchPromos = () => (dispatch) => {

    const {
        promoRef
    } = firebase;

    dispatch({
        type: FETCH_PROMOS,
        payload: null
    });
    onValue(promoRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i
                if (data[i].user_avail) {
                    data[i].promo_used_by = data[i].user_avail.count;
                }
                return data[i]
            });
            dispatch({
                type: FETCH_PROMOS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_PROMOS_FAILED,
                payload: "No promos available."
            });
        }
    });
};

export const editPromo = (promos, method) => (dispatch) => {

    const {
        promoRef,
        promoEditRef
    } = firebase;

    dispatch({
        type: EDIT_PROMOS,
        payload: method
    });
    if (method === 'Add') {
        push(promoRef, promos);
    } else if (method === 'Delete') {
        remove(promoEditRef(promos.id));
    } else {
        set(promoEditRef(promos.id), promos);
    }
}
