import React, {useContext, useEffect, useState} from 'react';
import classNames from "classnames";
import {makeStyles} from '@material-ui/core/styles';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import {FormControl, MenuItem, Paper, Select, TextField} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import SectionDownload from "./Sections/SectionDownload.js";
import {useDispatch, useSelector} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertDialog from '../components/AlertDialog';
import {language} from 'config';
import {api, FirebaseContext} from 'common';
import {getRouteDetails} from 'common/src/other/GoogleAPIFunctions';
import {formatNumber} from "react-native-currency-input";
import {congthuc1chieu, congthuc2chieu} from "common/src/other/FareCalculator";
import CircularLoading from "../components/CircularLoading";
import {View, Text} from "react-native-web";
import {
    ConvertSecondToHm,
    ConvertSecondToHms, ConvertTimeStampTime,
    ConvertTimeStampTimeForPa,
    GetDateString,
    ConvertTimeStampToDate,
    GetCurrentMiniTimeStamp, GetCurrentTimeStamp, GetStartCurrentTimeInDay
} from "common/src/other/DateFunctions";
import {Alert, Linking, Platform} from "react-native";
import {useNavigate} from "react-router-dom";
import {formatPriceRound, getDaysInMonth, validPhoneVN} from "common/src/other/CommonFunctions";
import {useCookies} from 'react-cookie';
import Icon from "@material-ui/core/Icon";
import {colors} from "../config/theme";
import {GetDistance} from "common/src/other/GeoFunctions";
import {fetchDriverGroupJsonOnce} from "common/src/actions/drivergroupaction";
import {onValue} from "firebase/database";
import {RequestPushMsg} from "common/src/other/NotificationFunctions";

const geolib = require('geolib');
const getDistance = require('geolib/es/getDistance');

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const {
        caDemConfigRef,
        singleTaskDriverAcceptRef, bookingRef, trackBookingRef, singleBookingRef,
        singleUserRef,
        paCallCenterRef,
        usersRef,
        listAreaRef, refApi
    } = useContext(FirebaseContext);
    const {
        clearEstimate,
        clearBooking,
        pushCallCancelById,
        loadBookingByTripCode,
        stopLoadBookingByTripCode
    } = api;
    const {
        setNoteMapAddressBookingApi,
        fetchDriverGroupJsonOnce
    } = refApi;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {...rest} = props;
    const cartypes = useSelector(state => state.cartypes.cars);
    const estimatedata = useSelector(state => state.estimatedata);
    const bookingdata = useSelector(state => state.bookingdata);
    const settings = useSelector(state => state.settingsdata.settings);
    const [carType, setCarType] = useState(language.select_car);
    const [pickupAddress, setPickupAddress] = useState(null);
    const [dropAddress, setDropAddress] = useState(null);
    const [estimateKmModalStatus, setEstimateKmModalStatus] = useState(false);
    const [bookingModalStatus, setBookingModalStatus] = useState(false);
    const [estimateKm2ModalStatus, setEstimateKm2ModalStatus] = useState(false);
    const [showBookingModalStatus, setShowBookingModalStatus] = useState(false);
    const [selectedCarDetails, setSelectedCarDetails] = useState(null);
    const auth = useSelector(state => state.auth);
    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});
    const [bookingType, setBookingType] = useState('Book Now');
    const [role, setRole] = useState(null);
    const [routeType, setRouteType] = useState("1");
    const [fareKm, setFareKm] = useState(null);
    const [fareKm2, setFareKm2] = useState(null);
    const [loaddingKm, setLoadingKm] = useState(false);
    const [loaddingBooking, setLoaddingBooking] = useState(false);
    const [bookingCode, setBookingCode] = useState(null);
    const [loaddingKm2, setLoadingKm2] = useState(false);
    const [capatchaReady, setCapatchaReady] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [cookieBooking, setCookieBooking] = useCookies(['booking']);
    const [bookingDetail, setBookingDetail] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        let now = GetCurrentTimeStamp();
        /*onValue(usersRef, (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const arr = Object.keys(data)
                    .filter(i => data[i].usertype === "driver"
                    )
                    .map(i => {
                        let user = data[i];

                    });
            }
        }, {onlyOnce: true});*/


        /*listAreaRef().once("value", (snapshot) => {
            console.log("snapshot ", snapshot.val());
        });
        let bookingId = '-NgcprgvZ-HT7bnqxZgG';*/

        /*async function fetchData() {
           usersRef.orderByChild("queue").equalTo(false).get({source: 'server'}).then((snapshotBooking) => {
                console.log("snapshotBooking", snapshotBooking.val())
            }).catch(error => console.log(error));
        }

        fetchData();*/


        /*let uid = 'FTrcCh3Hl9T51HT1ErXvdBzbiEG2';
        let startDate = 1679590811;
        let endDate = 1679899801;
        let currentTime = GetCurrentTimeStamp();

        let dataUpdate = {
            time_active : 0,
            total_success: 0,
            total_call: 0
        };

        singleUserRef(uid).once("value", snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                if (data.loginHistory) {
                    let listLoginHistoryValid = Object.values(data.loginHistory).filter(loginData => {
                        if (loginData.at) {
                            return true;
                        }

                    });
                    let totalTime = 0;
                    for (let i = 0; i < listLoginHistoryValid.length; i++) {
                        if (listLoginHistoryValid[i].at >= startDate && listLoginHistoryValid[i].at <= endDate) {
                            if(listLoginHistoryValid[i-1] && listLoginHistoryValid[i-1].at < startDate){
                                if( listLoginHistoryValid[i-1].type == "login"){
                                    totalTime  = totalTime + (listLoginHistoryValid[i].at - startDate);
                                }
                            }
                            if(listLoginHistoryValid[i-1] && listLoginHistoryValid[i-1].at > startDate){
                                if( listLoginHistoryValid[i-1].type == "login"){
                                    totalTime  = totalTime + (listLoginHistoryValid[i].at - listLoginHistoryValid[i-1].at);
                                }
                            }

                            if(listLoginHistoryValid[i+1] && listLoginHistoryValid[i+1].at > endDate){
                                if( listLoginHistoryValid[i].type == "login"){
                                    totalTime  = totalTime + (endDate - listLoginHistoryValid[i].at);
                                }
                            }else{
                                if(listLoginHistoryValid[i].at < currentTime && listLoginHistoryValid[i].type == "login"){
                                    console.log("Using Current");
                                    totalTime  = totalTime + (currentTime - listLoginHistoryValid[i].at);
                                }
                            }

                        }
                    }

                    dataUpdate.time_active = totalTime;
                }
            }
        });

        paCallCenterRef.orderByChild('time').startAt(startDate).endAt(endDate).once('value', snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                let dataCall = [];
                const arr = Object.keys(data).map(i => {
                    data[i].firebase_id = i;
                    data[i].id = i;
                    if(data[i].msnv && data[i].msnv == uid && data[i].action == "note" && data[i].action == "note"  && (!data[i].customer_note || (data[i].customer_note && data[i].customer_note != 'Khách quen lái xe'))){
                        dataCall.push(data[i]);
                    }
                });
                let totalSuccess = dataCall.filter(c=> (c.status == 'cancel' && c.reason) || (c.driver_code && c.status == "paid"));
                if(totalSuccess && totalSuccess.length>0){
                    dataUpdate.total_success = totalSuccess.length;
                }
                dataUpdate.total_call = dataCall.length;
            }
        });
*/
        /*async function fetchData() {
            trackBookingRef("-NPePNoP7HeySBS1cmlQ").once("value", snapshot => {
                if (snapshot.val()) {
                    const booking = snapshot.val();
                    if (booking.driver_accepted) {
                        var driver_accepted_array = Object.entries(booking.driver_accepted);
                        var sorted = [];
                        if (settings && settings.approve_by_time) {
                            sorted = driver_accepted_array.sort(function (a, b) {
                                if (!b[1].totalBook) {
                                    b[1].totalBook = 0;
                                }set
                                if (!a[1].totalBook) {
                                    a[1].totalBook = 0;
                                }
                                return (a[1].totalBook - b[1].totalBook);
                            });
                        } else {
                            sorted = driver_accepted_array.sort(function (a, b) {
                                if (!b[1].timestamp) {
                                    b[1].timestamp = 0;
                                }
                                if (!a[1].timestamp) {
                                    a[1].timestamp = 0;
                                }
                                return (a[1].timestamp - b[1].timestamp);
                            });
                        }
                        console.log(sorted);
                    }
                }
            });
        }

        fetchData();*/

        /*app.database().ref('bookings').orderByChild('codeDriver').equalTo('45').limitToLast(30).once("value", (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const bookings = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                        return data[i];
                    });

                for (let i = 0; i < bookings.length; i++) {
                    let booking = bookings[i];
                    if (booking.type == "TT" && booking.driver_group && booking.driver_group.value != '2') {
                        let acceptInfo = booking.driver_accepted[booking.driver];
                        let lat = acceptInfo.lat;
                        let lng = acceptInfo.lng;
                        let group = booking.driver_group;
                        if (group.coord && group.areas && group) {
                            let coords = group.group_coords;
                            // nam trong ban kinh 3km
                            var isCoord = false;
                            if (coords) {
                                coords.map(coord => {
                                    if (coord.coord && coord.coord.split(",").length > 0) {
                                        let toado_con = coord.coord.split(",");
                                        let kc_con = GetDistance(lat, lng, toado_con[0], toado_con[1]);
                                        if (kc_con <= parseFloat(coord.radius) / 1000) {
                                            isCoord = true;
                                        }
                                    }
                                });
                            }

                            if (isCoord) {
                                console.log("CK ĐÚNG: " + booking.customer_note + " - Lúc: [" + ConvertTimeStampToDate(booking.bookingDate) + "]  điểm nhận khách có trong BK " + " - Mã: " + booking.id);
                            } else {
                                console.log("CK KO ĐÚNG: " + booking.customer_note + " - Lúc: [" + ConvertTimeStampToDate(booking.bookingDate) + "] điểm nhận khách không trong BK" + " - Mã: " + booking.id);
                            }
                        }
                    }
                }
            }
        });*/
        /*

                singleBookingRef('-NQ3nAWEPf5i218Mnd0V').once("value", (snapshot) => {
                    if (snapshot.val()) {
                        let booking = snapshot.val();
                        if (booking.callId && booking.accept_time_stone && booking.accept_time_stone.lat && booking.driver_group && booking.driver_group.areas) {
                            let callId = booking.callId;
                            console.log(booking.driver_group.areas)
                            let listArea = booking.driver_group.areas;
                            console.log(listArea);
                            let hasInArea = false;
                            for (let i = 0; i < listArea.length; i++) {
                                hasInArea = geolib.isPointInPolygon({
                                    latitude: booking.accept_time_stone.lat,
                                    longitude: booking.accept_time_stone.lng
                                }, listArea[i]);
                            }
                            console.log("hasInArea", hasInArea);
                        }
                    }
                });
        */


    }, []);

    useEffect(() => {
        //dispatch(fetchChatMessages(['-N7Yqydm7KLwMaEBRuJ6']));
        if (cookieBooking && cookieBooking.action_content) {
            var CryptoJS = require("crypto-js");
            let encrytChecksum = CryptoJS.AES.decrypt(cookieBooking.action_content, "etoviet").toString(CryptoJS.enc.Utf8);
            let objectBooking = JSON.parse(encrytChecksum);
            if (objectBooking) {
                if (objectBooking.phone) {
                    setPhone(objectBooking.phone);
                }
                if (objectBooking.note) {
                    setNote(objectBooking.note);
                }
                if (objectBooking.info_booking) {
                    setPickupAddress(objectBooking.info_booking);
                }
            }

            setBookingModalStatus(true);
        }
        if (auth.info && auth.info.profile) {
            setLoggedIn(true);
            //AsyncStorage.removeItem('info_tracuoc');
        } else {
            setLoggedIn(false);
        }

    }, [auth.info]);

    useEffect(() => {
        if (bookingdata.loading) {
            setLoaddingBooking(true);
        } else {
            setLoaddingBooking(false);
        }
        if (bookingdata.fetchBooking) {
            let bookingList = Object.values(bookingdata.fetchBooking);
            if (bookingList[0]) {
                setShowBookingModalStatus(true);
                setBookingDetail(bookingList[0]);
            }
        } else {
            setShowBookingModalStatus(false);
            setBookingDetail(null);
        }
    }, [bookingdata]);

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }
    const [selectedDate, setSelectedDate] = useState(GetDateString());
    const [totalDiff, setTotalDiff] = useState(null);
    const [phone, setPhone] = useState(null);
    const [note, setNote] = useState(null);

    const handleCarSelect = (event) => {
        setCarType(event.target.value);
        let carDetails = null;
        for (let i = 0; i < cartypes.length; i++) {
            if (cartypes[i].name === event.target.value) {
                carDetails = cartypes[i];
            }
        }
        setSelectedCarDetails(carDetails);
    };

    const handShowBookingDialog = (e) => {
        e.preventDefault();
        if (bookingCode) {
            dispatch(loadBookingByTripCode(bookingCode));
        } else {
            setCommonAlert({open: true, msg: "Vui lòng nhập mã cuốc khách"});
        }
    };

    const handleBookTypeSelect = (event) => {
        setBookingType(event.target.value);
        if (bookingType === 'Book Later') {
            setSelectedDate(GetDateString());
        }
    };

    const handleRouteTypeSelect = (event) => {
        setRouteType(event.target.value);
    };

    const onDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const onDiffChange = (event) => {
        setTotalDiff(event.target.value);
    };

    const onCodeChange = (event) => {
        setBookingCode(event.target.value);
    };

    const onPhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const onNoteChange = (event) => {
        setNote(event.target.value);
    };

    useEffect(() => {
        if (fareKm && fareKm.totalCost > 0) {
            setEstimateKmModalStatus(true);
        }
    }, [fareKm]);

    useEffect(() => {
        if (fareKm2 && fareKm2.totalCost > 0) {
            setEstimateKm2ModalStatus(true);
        }
    }, [fareKm2]);


    const handleGetEstimate = (e) => {
        e.preventDefault();
        setCommonAlert({open: true, msg: "Chức năng đang cập nhật"});
        /*if (auth.info) {
            if (pickupAddress && dropAddress && selectedCarDetails) {
                if (bookingType === 'Book Now') {
                    dispatch(getEstimate({
                        platform: 'web',
                        pickup: pickupAddress,
                        drop: dropAddress,
                        carDetails: selectedCarDetails,
                    }));
                } else {
                    if (bookingType === 'Book Later' && selectedDate) {
                        if (MinutesPassed(selectedDate) >= 15) {
                            dispatch(getEstimate({
                                platform: 'web',
                                pickup: pickupAddress,
                                drop: dropAddress,
                                carDetails: selectedCarDetails,
                            }));
                        } else {
                            setCommonAlert({open: true, msg: language.past_booking_error});
                        }
                    } else {
                        setCommonAlert({open: true, msg: language.select_proper});
                    }
                }
            } else {
                setCommonAlert({open: true, msg: language.select_proper})
            }
        } else {
            setCommonAlert({open: true, msg: language.must_login})
        }*/
    };

    const estimateAddress = () => {
        if (pickupAddress && dropAddress && selectedCarDetails) {
            var startLoc = null;
            var destLoc = null;
            if (pickupAddress.coords) {
                startLoc = pickupAddress.coords.lat + ',' + pickupAddress.coords.lng;
            }
            if (dropAddress) {
                destLoc = dropAddress.coords.lat + ',' + dropAddress.coords.lng;
            }
            if (startLoc && destLoc) {
                setLoadingKm(true);
                getRouteDetails(startLoc, destLoc).then((res) => {
                    if (res && res.distance) {
                        var distance = res.distance;
                        if (routeType == "2") {
                            let result2chieu = congthuc2chieu(distance * 2, res.duration, selectedCarDetails, 0);
                            setFareKm({
                                thuebao: result2chieu.thuebao,
                                price: result2chieu.price,
                                time: result2chieu.time,
                                km_phutroi: result2chieu.km_phutroi,
                                time_phutroi: result2chieu.time_phutroi,
                                distance: distance * 2,
                                totalCost: result2chieu.totalCost,
                                grandTotal: result2chieu.grandTotal,
                                convenience_fees: result2chieu.convenience_fees,
                                typePrice: "2"
                            });

                        } else {
                            let result1chieu = congthuc1chieu(distance, res.duration, selectedCarDetails);
                            setFareKm({
                                distance: distance,
                                totalCost: result1chieu.totalCost,
                                grandTotal: result1chieu.grandTotal,
                                convenience_fees: result1chieu.convenience_fees,
                                typePrice: "1"
                            });
                        }

                        if (loggedIn === false) {
                            /*AsyncStorage.getItem('info_tracuoc', (err, result) => {
                                if (result) {
                                    let oldResult = JSON.parse(result);
                                    if (navigator.geolocation) {
                                        navigator.geolocation.getCurrentPosition(function (position) {
                                            var lat = position.coords.latitude;
                                            var lng = position.coords.longitude;
                                            oldResult.push({at: GetCurrentMiniTimeStamp(), lat: lat, lng: lng, info_search: {carType: selectedCarDetails, pickupAddress: pickupAddress, dropAddress: dropAddress, routeType: routeType}});
                                            AsyncStorage.setItem('info_tracuoc', JSON.stringify(oldResult));
                                        }, function (error) {
                                            oldResult.push({at: GetCurrentMiniTimeStamp(), info_search: {carType: selectedCarDetails, pickupAddress: pickupAddress, dropAddress: dropAddress, routeType: routeType}});
                                            AsyncStorage.setItem('info_tracuoc', JSON.stringify(oldResult));
                                        });
                                    } else {
                                        oldResult.push({at: GetCurrentMiniTimeStamp(), info_search: {carType: selectedCarDetails, pickupAddress: pickupAddress, dropAddress: dropAddress, routeType: routeType}});
                                        AsyncStorage.setItem('info_tracuoc', JSON.stringify(oldResult));
                                    }
                                } else {
                                    if (navigator.geolocation) {
                                        navigator.geolocation.getCurrentPosition(function (position) {
                                            var lat = position.coords.latitude;
                                            var lng = position.coords.longitude;
                                            AsyncStorage.setItem('info_tracuoc', JSON.stringify([{at: GetCurrentMiniTimeStamp(), lat: lat, lng: lng, info_search: {carType: selectedCarDetails, totalDiff: totalDiff, routeType: routeType}}]));
                                        }, function (error) {
                                            AsyncStorage.setItem('info_tracuoc', JSON.stringify([{at: GetCurrentMiniTimeStamp(), info_search: {carType: selectedCarDetails, totalDiff: totalDiff, routeType: routeType}}]));
                                        });
                                    } else {
                                        AsyncStorage.setItem('info_tracuoc', JSON.stringify([{at: GetCurrentMiniTimeStamp(), info_search: {carType: selectedCarDetails, totalDiff: totalDiff, routeType: routeType}}]));
                                    }
                                }
                            });*/
                        }
                    } else {
                        setCommonAlert({
                            open: true,
                            msg: "Không thể xác định lộ trình, vui lòng nhập địa chỉ khác hoặc ước tính theo KM"
                        });
                    }
                    setLoadingKm(false);
                }).catch((error) => {
                    setLoadingKm(false);
                    setCommonAlert({
                        open: true,
                        msg: "Không thể xác định vị trí, vui lòng nhập địa chỉ khác hoặc ước tính theo KM"
                    });
                });
            }

        } else {
            setCommonAlert({open: true, msg: language.select_proper})
        }
    }

    const handleGetEstimateAddress = (e) => {
        e.preventDefault();
        setFareKm(null);
        if (loggedIn === false) {
            /*AsyncStorage.getItem('info_tracuoc', (err, result) => {
                if (result) {
                    let oldResult = JSON.parse(result);
                    const startDate = moment(oldResult[0].at);
                    const timeEnd = moment(GetCurrentMiniTimeStamp());
                    const diff = timeEnd.diff(startDate);
                    const diffDuration = moment.duration(diff);
                    if (diffDuration.days() >= 1) {
                        AsyncStorage.removeItem("info_tracuoc");
                        estimateAddress();
                    } else {
                        let listSearchAddress = oldResult.filter((info) => info.info_search && info.info_search.pickupAddress);
                        if (listSearchAddress.length >= 5) {
                            setCommonAlert({
                                open: true, msg: 'Số lần tra cước hôm nay đã hết, vui lòng đăng nhập để được sử dụng tiếp!', onConfirm: () => {
                                     navigate("/login");
                                }
                            });
                        } else {
                            estimateAddress();
                        }
                    }
                } else {
                    estimateAddress();
                }
            });*/
        } else {
            estimateAddress();
        }
    };

    const handleGetEstimateKm = (e) => {
        setFareKm2(null);
        setLoadingKm2(true);
        if (totalDiff && selectedCarDetails) {
            var distance = totalDiff;
            if (routeType == "2") {
                let result2chieu = congthuc2chieu(distance, 0, selectedCarDetails, 0);
                setFareKm2({
                    thuebao: result2chieu.thuebao,
                    price: result2chieu.price,
                    time: result2chieu.time,
                    km_phutroi: result2chieu.km_phutroi,
                    time_phutroi: result2chieu.time_phutroi,
                    distance: distance,
                    totalCost: result2chieu.totalCost,
                    grandTotal: result2chieu.grandTotal,
                    convenience_fees: result2chieu.convenience_fees,
                    typePrice: "2"
                });

            } else {
                let result1chieu = congthuc1chieu(distance, 0, selectedCarDetails);
                setFareKm2({
                    distance: distance,
                    totalCost: result1chieu.totalCost,
                    grandTotal: result1chieu.grandTotal,
                    convenience_fees: result1chieu.convenience_fees,
                    typePrice: "1"
                });
            }
            setLoadingKm2(false);
        } else {
            setCommonAlert({open: true, msg: language.select_proper});
            setLoadingKm2(false);
        }
    };


    const handLoadData2 = async () => {
        let month = 1;
        let year = 2023;
        let totalCarIn = 16;
        var totalCty = 5;
        var totalCpDam = 7;
        let totalDD = totalCarIn - (totalCty + totalCpDam);
        var listDriver = null;
        var listCarD = {};
        var listCarCpD = {};
        var listCarCty = {};

        var listCarDUpdate = {};
        var listCarCpDUpdate = {};
        var listCarCtyUpdate = {};
        var listCarGhepUpdate = {};

        var listCar = [];
        var resultDate = {};
        var resultDateResult = {};
        await onValue(caDemConfigRef(), (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const listDriverKey = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                    });
                listDriver = data;
            }
        }, {onlyOnce: true});
        if (listDriver) {
            let listDriverArray = Object.values(listDriver);
            for (let i = 0; i < listDriverArray.length; i++) {
                let driver = listDriverArray[i];
                if (driver.type == 'Công Ty') {
                    if (listCarCty && listCarCty[driver.bs] && Array.isArray(listCarCty[driver.bs])) {
                        listCarCty[driver.bs].push(driver);
                    } else {
                        listCarCty[driver.bs] = [driver]
                    }
                } else if (driver.type == 'CP Đóng đàm') {
                    if (listCarCpD && listCarCpD[driver.bs] && Array.isArray(listCarCpD[driver.bs])) {
                        listCarCpD[driver.bs].push(driver);
                    } else {
                        listCarCpD[driver.bs] = [driver]
                    }
                } else {
                    if (listCarD && listCarD[driver.bs] && Array.isArray(listCarD[driver.bs])) {
                        listCarD[driver.bs].push(driver);
                    } else {
                        listCarD[driver.bs] = [driver]
                    }
                }
            }
        }

        let listCarCtyArray = Object.values(listCarCty);

        for (let i = 0; i < listCarCtyArray.length; i++) {
            let carDetail = listCarCtyArray[i];
            if (carDetail.length > 1) {
                let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;
                totalCty = totalCty - 1;
            } else {
                listCarCtyUpdate[carDetail[0].bs] = carDetail;
            }
        }

        let listCarDArray = Object.values(listCarD);
        for (let i = 0; i < listCarDArray.length; i++) {
            let carDetail = listCarDArray[i];
            if (carDetail.length > 1) {
                let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;
                totalDD = totalDD - 1;
            } else {
                listCarDUpdate[carDetail[0].bs] = carDetail;
            }
        }

        let listCarCpDArray = Object.values(listCarCpD);
        for (let i = 0; i < listCarCpDArray.length; i++) {
            let carDetail = listCarCpDArray[i];
            if (carDetail.length > 1) {
                let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;
                totalCpDam = totalCpDam - 1;
            } else {
                listCarCpDUpdate[carDetail[0].bs] = carDetail
            }
        }


        var listCarCpDX30 = [];
        var listCarCtyX30 = [];
        var listCarDX30 = [];
        var listCarGhep30 = Object.values(listCarGhepUpdate);
        let listDate = getDaysInMonth(month, year);
        for (let i = 0; i < listDate.length; i++) {
            listCarCpDX30 = listCarCpDX30.concat(Object.values(listCarCpDUpdate));
            listCarDX30 = listCarDX30.concat(Object.values(listCarDUpdate));
            listCarCtyX30 = listCarCtyX30.concat(Object.values(listCarCtyUpdate));
        }

        var listCtyWorkingIndex = 0;
        var listCpDWorkingIndex = 0;
        var listDWorkingIndex = 0;

        let totalCarInWithoutGhep = totalCarIn - listCarGhep30.length;
        let totalCarCty = totalCty;
        let totalCarCpDam = totalCpDam;
        let totalCarDD = totalDD;

        for (let j = 0; j < listDate.length; j++) {
            let date = listDate[j];
            let dateKey = date.toLocaleDateString("vi-VN").toString().replaceAll('/', '-');

            let listCtyWorking = listCarCtyX30.slice(listCtyWorkingIndex, listCtyWorkingIndex + totalCarCty);
            listCtyWorkingIndex = listCtyWorkingIndex + totalCarCty;
            let listCtyWorkingReUpdate = [];
            for (let k = 0; k < listCtyWorking.length; k++) {
                listCtyWorkingReUpdate.push(listCtyWorking[k][0].msd.toString());
            }

            let listCpDWorking = listCarCpDX30.slice(listCpDWorkingIndex, listCpDWorkingIndex + totalCarCpDam);
            listCpDWorkingIndex = listCpDWorkingIndex + totalCarCpDam;
            let listCpDWorkingReUpdate = [];
            for (let k = 0; k < listCpDWorking.length; k++) {
                listCpDWorkingReUpdate.push(listCpDWorking[k][0].msd.toString());
            }

            let listDWorking = listCarDX30.slice(listDWorkingIndex, listDWorkingIndex + totalCarDD);
            listDWorkingIndex = listDWorkingIndex + totalCarDD;
            let listDWorkingReUpdate = [];
            for (let k = 0; k < listDWorking.length; k++) {
                listDWorkingReUpdate.push(listDWorking[k][0].msd.toString());
            }

            let listXeGhepReUpdate = [];
            for (let k = 0; k < listCarGhep30.length; k++) {
                listXeGhepReUpdate.push(listCarGhep30[k][j].msd.toString());
            }

            resultDate[dateKey] = {
                cty: listCtyWorkingReUpdate,
                cpd: listCpDWorkingReUpdate,
                d: listDWorkingReUpdate
            };
            resultDateResult[dateKey] = listCtyWorkingReUpdate.concat(listCpDWorkingReUpdate).concat(listDWorkingReUpdate).concat(listXeGhepReUpdate);

        }

        //console.log(getDaysInMonth(12, 2022)[0].toLocaleDateString("vi-VN"));
    };


    const handLoadData = async () => {
        let month = 1;
        let year = 2023;
        let totalCarIn = 16;
        var totalCty = 2;
        let listReject = [];


        var listDriver = null;
        var listCarD = {};
        var listCarCty = {};

        var listCarDUpdate = {};
        var listCarCtyUpdate = {};
        var listCarGhepUpdate = {};

        var listCar = [];
        var resultDate = {};
        var resultDateResult = {};
        await onValue(caDemConfigRef(), (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const listDriverKey = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                    });
                listDriver = data;
            }
        }, {onlyOnce: true});
        if (listDriver) {
            let listDriverArray = Object.values(listDriver);
            for (let i = 0; i < listDriverArray.length; i++) {
                let driver = listDriverArray[i];

                if (listReject.includes(driver.msd.toString())) {
                    continue;
                }

                if (driver.type == 'Công Ty' || driver.type == 'CP Đóng đàm') {
                    if (listCarCty && listCarCty[driver.bs] && Array.isArray(listCarCty[driver.bs])) {
                        listCarCty[driver.bs].push(driver);
                    } else {
                        listCarCty[driver.bs] = [driver]
                    }
                } else {
                    if (listCarD && listCarD[driver.bs] && Array.isArray(listCarD[driver.bs])) {
                        listCarD[driver.bs].push(driver);
                    } else {
                        listCarD[driver.bs] = [driver]
                    }
                }
            }
        }

        let listCarCtyArray = Object.values(listCarCty);

        for (let i = 0; i < listCarCtyArray.length; i++) {
            let carDetail = listCarCtyArray[i];
            if (carDetail.length > 1) {
                let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;
            } else {
                listCarCtyUpdate[carDetail[0].bs] = carDetail;
            }
        }

        let listCarDArray = Object.values(listCarD);
        for (let i = 0; i < listCarDArray.length; i++) {
            let carDetail = listCarDArray[i];
            if (carDetail.length > 1) {
                let totalMem = carDetail.length;
                do {
                    carDetail = carDetail.concat(carDetail);
                    totalMem = carDetail.length;
                } while (totalMem <= 31);
                listCarGhepUpdate[carDetail[0].bs] = carDetail;
            } else {
                listCarDUpdate[carDetail[0].bs] = carDetail;
            }
        }

        var listCarCtyX30 = [];
        var listCarDX30 = [];
        var listCarGhep30 = Object.values(listCarGhepUpdate);
        let listDate = getDaysInMonth(month, year);
        for (let i = 0; i < listDate.length; i++) {
            listCarDX30 = listCarDX30.concat(Object.values(listCarDUpdate));
            listCarCtyX30 = listCarCtyX30.concat(Object.values(listCarCtyUpdate));
        }

        var listCtyWorkingIndex = 0;
        var listDWorkingIndex = 0;

        let totalCarInWithoutGhep = totalCarIn - listCarGhep30.length;
        let totalCarCty = totalCty;
        let totalCarDD = totalCarInWithoutGhep - totalCty;


        for (let j = 0; j < listDate.length; j++) {
            let date = listDate[j];
            let dateKey = date.toLocaleDateString("vi-VN").toString().replaceAll('/', '-');

            let listCtyWorking = listCarCtyX30.slice(listCtyWorkingIndex, listCtyWorkingIndex + totalCarCty);
            listCtyWorkingIndex = listCtyWorkingIndex + totalCarCty;
            let listCtyWorkingReUpdate = [];
            for (let k = 0; k < listCtyWorking.length; k++) {
                listCtyWorkingReUpdate.push(listCtyWorking[k][0].msd.toString());
            }

            let listDWorking = listCarDX30.slice(listDWorkingIndex, listDWorkingIndex + totalCarDD);
            listDWorkingIndex = listDWorkingIndex + totalCarDD;
            let listDWorkingReUpdate = [];
            for (let k = 0; k < listDWorking.length; k++) {
                listDWorkingReUpdate.push(listDWorking[k][0].msd.toString());
            }

            let listXeGhepReUpdate = [];
            for (let k = 0; k < listCarGhep30.length; k++) {
                listXeGhepReUpdate.push(listCarGhep30[k][j].msd.toString());
            }

            resultDate[dateKey] = {
                cty: listCtyWorkingReUpdate.length,
                d: listDWorkingReUpdate.length
            };
            resultDateResult[dateKey] = listCtyWorkingReUpdate.concat(listDWorkingReUpdate).concat(listXeGhepReUpdate);

        }
    };


    const confirmBooking = (e) => {
        e.preventDefault();
        setBookingModalStatus(true);
    };

    const notifyCenter = (phoneNumber) => {
        let call_link = Platform.OS == 'android' ? `tel:${phoneNumber}` : `telprompt:${phoneNumber}`;
        return Linking.openURL(call_link);
    }

    const bookingNow = (e) => {
        e.preventDefault();
        if (loggedIn === false) {
            let dataAction = {
                phone: phone,
                note: note,
                type: "KH",
                info_booking: pickupAddress,
                booking_type_web: true
            };
            var CryptoJS = require("crypto-js");
            setCommonAlert({
                open: true, msg: 'Vui lòng đăng nhập để thực hiện đặt cuốc khách!', onConfirm: () => {
                    let encryptDataJson = JSON.stringify(dataAction);
                    setCookieBooking('action_content', CryptoJS.AES.encrypt(encryptDataJson, "etoviet_password").toString(), {path: '/'});
                    navigate("/login");
                }
            });
        } else {
            if (!phone || !note) {
                setCommonAlert({open: true, msg: 'Vui lòng nhập đầy đủ thông tin!'});
            } else {
                let isPhoneValid = validPhoneVN(phone);
                if (isPhoneValid === false) {
                    setCommonAlert({open: true, msg: 'Số điện thoại không hợp lệ!'});
                } else {
                    if (note.length <= 3) {
                        setCommonAlert({open: true, msg: 'Ghi chú chưa rõ thông tin, vui lòng cung cấp thêm!'});
                    } else {
                        let data = {
                            phone: phone,
                            note: note,
                            type: "KH",
                            info_booking: pickupAddress,
                            carType: carType,
                            booking_type_web: true
                        };
                        setCookieBooking('action_content', '', {path: '/'});
                        dispatch(pushCallCancelById(data));
                        setTimeout(() => {
                            setPhone(null);
                            setNote(null);
                            setEstimateKmModalStatus(false);
                            setFareKm(null);
                            setEstimateKm2ModalStatus(false);
                            setBookingModalStatus(false);
                            setFareKm2(null);
                            setCommonAlert({
                                open: true,
                                msg: 'ETO xin cảm ơn bạn đã đặt xe, Vui lòng đợi Lái xe liên hệ đón hoặc gọi HOTLINE: 1800 64 68 93 hoặc 02253 675 675 để được hỗ trợ!',
                                onConfirm: () => {
                                    notifyCenter(1800646893);
                                },
                                titleConfirm: "Gọi Hỗ Trợ"
                            });
                        }, 1000);
                    }
                }
            }
        }
    };

    const handleEstimateModalClose = (e) => {
        e.preventDefault();
        if (bookingCode) {
            dispatch(stopLoadBookingByTripCode(bookingCode));
        }
        setEstimateKmModalStatus(false);
        setFareKm(null);
        setEstimateKm2ModalStatus(false);
        setFareKm2(null);
        setBookingCode(null);
        setShowBookingModalStatus(false);
        setBookingDetail(null);
    };

    const handleBookingModalClose = (e) => {
        e.preventDefault();
        setEstimateKmModalStatus(false);
        setFareKm(null);
        setEstimateKm2ModalStatus(false);
        setFareKm2(null);
    };

    const handleEstimateErrorClose = (e) => {
        e.preventDefault();
        dispatch(clearEstimate());
    };

    const handleBookingAlertClose = (e) => {
        e.preventDefault();
        dispatch(clearBooking());
        dispatch(clearEstimate());
        navigate("/login");
    };

    const handleBookingErrorClose = (e) => {
        e.preventDefault();
        dispatch(clearBooking());
    };

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''})
    };

    return (
        <div>
            <div id="booking-button"/>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                absolute
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("../assets/img/background.jpg").default} className={classes.parallax}>
                {settings && !settings.maintenance && (cartypes && !role) || (cartypes && (role === 'rider' || role === 'admin')) ?
                    <div className={classes.container}>
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <br/>
                                    <Text style={{
                                        fontSize: 25,
                                        color: '#fff'
                                    }}>{language.landing_slogan}</Text>
                                    {/*<h2 className={classes.title}>{"Đặt nhanh chóng - Đón khẩn trương - Đi chuẩn giá"}</h2>*/}
                                    <br/>
                                </GridItem>

                                {/*<Button
                                    color="success"
                                    size="lg"
                                    rel="noopener noreferrer"
                                    className={classes.items}
                                    onClick={handLoadData}
                                    style={{height: 52}}
                                >
                                    Load data
                                </Button>*/}

                            </GridItem>
                        </GridContainer>

                        {/*<GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <br/>
                                <br/>
                                <br/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
                                <GridContainer spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <br/>
                                        <h1 className={classes.title}>{"Tra cước theo địa chỉ"}
                                            <Icon style={{color: "white", marginLeft: 20}}>
                                                arrow_downward
                                            </Icon>
                                        </h1>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Paper>
                                            <GoogleMapsAutoComplete
                                                placeholder={language.pickup_location}
                                                variant={"filled"}
                                                value={pickupAddress}
                                                onChange={
                                                    (value) => {
                                                        setPickupAddress(value);
                                                    }
                                                }
                                            />
                                        </Paper>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Paper>
                                            <GoogleMapsAutoComplete
                                                placeholder={language.drop_location}
                                                variant={"filled"}
                                                value={dropAddress}
                                                onChange={
                                                    (value) => {
                                                        setDropAddress(value);
                                                    }
                                                }
                                            />
                                        </Paper>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                        <FormControl style={{width: '100%'}}>
                                            <Select
                                                id="car-type-native"
                                                value={carType}
                                                onChange={handleCarSelect}
                                                className={carType === language.select_car ? classes.inputdimmed : classes.input}
                                            >
                                                <MenuItem value={language.select_car} key={language.select_car}>
                                                    {language.select_car}
                                                </MenuItem>
                                                {
                                                    cartypes.filter((carDetail) => carDetail.code != 'test' && carDetail.type != 'hop_dong').map((car) =>
                                                        <MenuItem key={car.name} value={car.name}>
                                                            <img src={car.image} className={classes.carphoto}
                                                                 alt="car types"/>{car.name} {"(" + car.extra_info + ")"}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={3} lg={3}>
                                <FormControl style={{width: '100%'}}>
                                    <Select
                                        id="booking-type-native"
                                        value={bookingType}
                                        onChange={handleBookTypeSelect}
                                        className={classes.input}
                                        inputProps={{'aria-label': 'Without label'}}
                                    >
                                        <MenuItem key={"Book Now"} value={"Book Now"}>
                                            {language.book_now}
                                        </MenuItem>
                                        <MenuItem key={"Book Later"} value={"Book Later"}>
                                            {language.book_later}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                        <FormControl style={{width: '100%'}}>
                                            <Select
                                                id="booking-type-native"
                                                value={routeType}
                                                onChange={handleRouteTypeSelect}
                                                className={classes.input}
                                                inputProps={{'aria-label': 'Without label'}}
                                            >
                                                <MenuItem key={"1"} value={"1"}>
                                                    {"1 Chiều"}
                                                </MenuItem>
                                                <MenuItem key={"2"} value={"2"}>
                                                    {"2 Chiều"}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    {bookingType === 'Book Later' ?
                                        <GridItem xs={6} sm={6} md={4} lg={4}>
                                            <TextField
                                                id="datetime-local"
                                                label={language.booking_date_time}
                                                type="datetime-local"
                                                variant="filled"
                                                fullWidth
                                                className={classes.commonInputStyle}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                value={selectedDate}
                                                onChange={onDateChange}
                                            />
                                        </GridItem>
                                        : null}
                                    <GridItem xs={12} sm={12} md={12}
                                              lg={12}>
                                        <Button
                                            color="success"
                                            size="lg"
                                            rel="noopener noreferrer"
                                            className={classes.items}
                                            onClick={handleGetEstimateAddress}
                                            style={{height: 52}}
                                        >
                                            <i className="fas fa-dollar-sign"/>
                                            {loaddingKm ? <CircularLoading/> : "Tra cước theo địa chỉ"}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <GridContainer spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <br/>
                                        <h1 className={classes.title}>{"Tra cước theo tổng km"} <Icon style={{color: "white", marginLeft: 20}}>
                                            arrow_downward
                                        </Icon></h1>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="total_diff"
                                            label={"Nhập số km (Nếu 2 chiều nhập tổng km dự tính cả đi và về)"}
                                            type="number"
                                            variant="filled"
                                            fullWidth
                                            className={classes.commonInputStyle}
                                            InputProps={{
                                                className: classes.input
                                            }}
                                            value={totalDiff}
                                            onChange={onDiffChange}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                        <FormControl style={{width: '100%'}}>
                                            <Select
                                                id="car-type-native"
                                                value={carType}
                                                onChange={handleCarSelect}
                                                className={carType === language.select_car ? classes.inputdimmed : classes.input}
                                            >
                                                <MenuItem value={language.select_car} key={language.select_car}>
                                                    {language.select_car}
                                                </MenuItem>
                                                {
                                                    cartypes.filter((carDetail) => carDetail.code != 'test' && carDetail.type != 'hop_dong').map((car) =>
                                                        <MenuItem key={car.name} value={car.name}>
                                                            <img src={car.image} className={classes.carphoto}
                                                                 alt="car types"/>{car.name} {"(" + car.extra_info + ")"}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={3} lg={3}>
                                <FormControl style={{width: '100%'}}>
                                    <Select
                                        id="booking-type-native"
                                        value={bookingType}
                                        onChange={handleBookTypeSelect}
                                        className={classes.input}
                                        inputProps={{'aria-label': 'Without label'}}
                                    >
                                        <MenuItem key={"Book Now"} value={"Book Now"}>
                                            {language.book_now}
                                        </MenuItem>
                                        <MenuItem key={"Book Later"} value={"Book Later"}>
                                            {language.book_later}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                        <FormControl style={{width: '100%'}}>
                                            <Select
                                                id="booking-type-native"
                                                value={routeType}
                                                onChange={handleRouteTypeSelect}
                                                className={classes.input}
                                                inputProps={{'aria-label': 'Without label'}}
                                            >
                                                <MenuItem key={"1"} value={"1"}>
                                                    {"1 Chiều"}
                                                </MenuItem>
                                                <MenuItem key={"2"} value={"2"}>
                                                    {"2 Chiều"}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer spacing={2}>
                                    {bookingType === 'Book Later' ?
                                        <GridItem xs={6} sm={6} md={4} lg={4}>
                                            <TextField
                                                id="datetime-local"
                                                label={language.booking_date_time}
                                                type="datetime-local"
                                                variant="filled"
                                                fullWidth
                                                className={classes.commonInputStyle}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                value={selectedDate}
                                                onChange={onDateChange}
                                            />
                                        </GridItem>
                                        : null}
                                    <GridItem xs={12} sm={12} md={12}
                                              lg={12}>
                                        <Button
                                            color="success"
                                            size="lg"
                                            rel="noopener noreferrer"
                                            className={classes.items}
                                            onClick={handleGetEstimateKm}
                                            style={{height: 52}}
                                        >
                                            <i className="fas fa-dollar-sign"/>
                                            {loaddingKm2 ? <CircularLoading/> : "Tra cước theo KM"}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        <br/>
                        <br/>
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <br/>
                                <h1 className={classes.title}>{"Tra cứu cuốc khách"} <Icon style={{color: "white", marginLeft: 20}}>
                                    arrow_downward
                                </Icon></h1>
                            </GridItem>
                        </GridContainer>
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="booking_code"
                                    label={"Nhập mã cuốc khách để kiểm tra"}
                                    variant="filled"
                                    fullWidth
                                    className={classes.commonInputStyle}
                                    InputProps={{
                                        className: classes.input
                                    }}
                                    value={bookingCode}
                                    onChange={onCodeChange}
                                />
                                <Button
                                    color="success"
                                    size="lg"
                                    rel="noopener noreferrer"
                                    className={classes.items}
                                    onClick={handShowBookingDialog}
                                    style={{height: 52, marginTop: 5}}
                                >
                                    <i className="fas fa-dollar-sign"/>
                                    {loaddingBooking ? <CircularLoading/> : "Xem thông tin cuốc khách"}
                                </Button>
                            </GridItem>
                        </GridContainer>*/}

                    </div>
                    : null}
                {settings && settings.maintenance ?
                    <div className={classes.container}>
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <br/>
                                <h1 className={classes.title}>{language.landing_slogan}</h1>
                                <br/>
                                <a style={{fontSize: 15, fontWeight: 'bold', color: '#00c77e'}}
                                   href={settings.maintenance_url ? settings.maintenance_url : '#'}>{settings.maintenance_msg ? settings.maintenance_msg : ''}</a>
                                <br/>
                                <br/>
                                <br/>
                                <b>{settings.maintenance_date ? 'Thời gian bảo trì đến: ' + ConvertTimeStampToDate(Date.parse(settings.maintenance_date)) : ''}</b>

                            </GridItem>
                        </GridContainer>
                    </div> : null}
            </Parallax>
            <div className={classNames(classes.main2, classes.mainRaised2)}>
                <div className={classes.container}>
                    <SectionDownload/>
                </div>
            </div>
            <Footer/>
            <Dialog
                open={estimateKmModalStatus}
                onClose={handleEstimateModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Ước tính chi phí cuốc khách"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <GridContainer spacing={2}>
                            {fareKm && fareKm.typePrice == '2' ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        color: 'black'
                                    }}>{"Tổng số km quay đầu : "}</h4>
                                </GridItem> :
                                null
                            }
                            {fareKm && fareKm.typePrice == '1' ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        color: 'black'
                                    }}>{"Tổng số km : "}</h4>
                                </GridItem> :
                                null
                            }
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{
                                    fontSize: 15,
                                    color: 'black'
                                }}>{fareKm ? Math.round(fareKm.distance * 100) / 100 : "0"} {"(km)"}</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 15, fontWeight: 'bold', color: 'black'}}>{"Thuê bao : "}</h4>
                            </GridItem>
                            {fareKm && fareKm.typePrice == '2' && fareKm.thuebao ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        color: 'black'
                                    }}> {fareKm.thuebao + "km/" + ConvertSecondToHm(fareKm.time * 60) + "/ " + formatPrice(fareKm.price) + " (2 chiều)"}</h4>
                                </GridItem> : null}
                            {fareKm && fareKm.typePrice == '2' && !fareKm.thuebao ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        color: 'black'
                                    }}> {"Không hỗ trợ thuê bao 2 chiều, áp dụng thuê bao 1 chiều"}</h4>
                                </GridItem> : null}

                            {fareKm && fareKm.typePrice == '1' ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 15, color: 'black'}}> {"1 Chiều"}</h4>
                                </GridItem> : null}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 15, fontWeight: 'bold', color: 'black'}}>{"Số tiền: "}</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{
                                    fontSize: 20,
                                    color: 'green',
                                    fontWeight: 'bold'
                                }}>{fareKm ? formatPrice(fareKm.totalCost) : 0}</h4>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <h4 style={{
                                    fontSize: 15,
                                    color: 'red'
                                }}>{" Đây là dự tính chi phí số tiền thanh toán phụ thuộc vào số km di chuyển thực tế."}</h4>
                            </GridItem>

                        </GridContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEstimateModalClose} style={{backgroundColor: 'red'}}>
                        {language.cancel}
                    </Button>
                    <Button onClick={confirmBooking} autoFocus style={{backgroundColor: 'green'}}>
                        {"Đặt xe ngay"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={estimateKm2ModalStatus}
                onClose={handleEstimateModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Ước tính chi phí cuốc khách"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <GridContainer spacing={2}>
                            {fareKm2 && fareKm2.typePrice == '2' ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        color: 'black'
                                    }}>{"Tổng số km quay đầu : "}</h4>
                                </GridItem> :
                                null
                            }
                            {fareKm2 && fareKm2.typePrice == '1' ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        color: 'black'
                                    }}>{"Tổng số km : "}</h4>
                                </GridItem> :
                                null
                            }
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{
                                    fontSize: 15,
                                    color: 'black'
                                }}>{fareKm2 ? Math.round(fareKm2.distance * 100) / 100 : "0"} {"(km)"}</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 15, fontWeight: 'bold', color: 'black'}}>{"Thuê bao : "}</h4>
                            </GridItem>
                            {fareKm2 && fareKm2.typePrice == '2' && fareKm2.thuebao ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        color: 'black'
                                    }}> {fareKm2.thuebao + "km/" + ConvertSecondToHm(fareKm2.time * 60) + "/ " + formatPrice(fareKm2.price) + " (2 chiều)"}</h4>
                                </GridItem> : null}
                            {fareKm2 && fareKm2.typePrice == '2' && !fareKm2.thuebao ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        color: 'black'
                                    }}> {"Không hỗ trợ thuê bao 2 chiều, áp dụng thuê bao 1 chiều"}</h4>
                                </GridItem> : null}


                            {fareKm2 && fareKm2.typePrice == '1' ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 15, color: 'black'}}> {"1 Chiều"}</h4>
                                </GridItem> : null}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 15, fontWeight: 'bold', color: 'black'}}>{"Số tiền: "}</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{
                                    fontSize: 20,
                                    color: 'green',
                                    fontWeight: 'bold'
                                }}>{fareKm2 ? formatPrice(fareKm2.totalCost) : 0}</h4>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <h4 style={{
                                    fontSize: 15,
                                    color: 'red'
                                }}>{" Đây là dự tính chi phí số tiền thanh toán phụ thuộc vào số km di chuyển thực tế."}</h4>
                            </GridItem>

                        </GridContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEstimateModalClose} style={{backgroundColor: 'red'}}>
                        {language.cancel}
                    </Button>
                    <Button onClick={confirmBooking} autoFocus style={{backgroundColor: 'green'}}>
                        {"Đặt xe ngay"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={bookingModalStatus}
                onClose={handleBookingModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="alert-dialog-title">{"Xác nhận thông tin đặt xe"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="phone"
                                    label={"Số điện thoại khách hàng"}
                                    type="number"
                                    variant="filled"
                                    fullWidth
                                    className={classes.commonInputStyle}
                                    InputProps={{
                                        className: classes.input
                                    }}
                                    value={phone}
                                    onChange={onPhoneChange}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer spacing={2}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="note"
                                    label={"Gửi lời nhắn (Mô tả vị trí đón) tới tài xế"}
                                    type="text"
                                    variant="filled"
                                    fullWidth
                                    className={classes.commonInputStyle}
                                    InputProps={{
                                        className: classes.input
                                    }}
                                    value={note}
                                    onChange={onNoteChange}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setBookingModalStatus(false);
                        setCookieBooking('action_content', '', {path: '/'});
                    }} style={{backgroundColor: 'red'}}>
                        {"Đóng"}
                    </Button>
                    <Button onClick={bookingNow} autoFocus style={{backgroundColor: 'green'}}>
                        {"Xác nhận đặt xe"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showBookingModalStatus}
                onClose={handleEstimateModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Chi tiết cuốc khách #" + bookingCode}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <GridContainer spacing={2}>
                            <GridItem xs={3} sm={3} md={3} lg={3} style={{textAlign: 'left'}}>
                                <img src={require("../assets/img/logo192x192.png").default} alt="Logo ETO"
                                     style={{borderRadius: 50 / 2, width: 50, height: 50}}/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{
                                    fontSize: 14,
                                    color: 'green',
                                    textAlign: 'center'
                                }}>{"ETO được vận hành bởi CÔNG TY CỔ PHẦN DỊCH VỤ VẬN TẢI MY SƠN. "}</h4>
                            </GridItem>
                            <GridItem xs={3} sm={3} md={3} lg={3} style={{textAlign: 'right'}}>
                                <img src={require("../assets/img/taximysonlogo.png").default} alt="Logo MY SƠN"
                                     style={{width: 55, height: 55}}/>
                            </GridItem>

                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{
                                    fontSize: 14,
                                    color: 'black'
                                }}>{"Đã đăng ký với sở giao thông vận tải được cấp phép sử dụng ETO thanh toán."}</h4>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                <img src={require("../assets/img/dau.png").default} alt="Con Dấu MY SƠN"
                                     style={{width: 70, height: 70}}/>
                            </GridItem>

                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}>{"Thông tin lái xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driver_image ?
                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                    <img src={bookingDetail.driver_image} alt="Avatar lái xe"
                                         style={{width: 40, height: 40}}/>
                                </GridItem> : <GridItem xs={4} sm={4} md={4} lg={4}>
                                    <img src={require("../assets/img/taximysonlogo.png").default} alt="Avatar lái xe"
                                         style={{width: 40, height: 40}}/>
                                </GridItem>}
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Họ và tên Lái Xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driver_name ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.driver_name}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Số điện thoại"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driver_contact ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 14,
                                        color: 'black'
                                    }}> {bookingDetail.driver_contact.replace("+084", "")}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}>{"Thông tin xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.vehicle_number ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.vehicle_number}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}


                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Loại xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.carType ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.carType}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}


                            <GridItem xs={5} sm={5} md={5} lg={5}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Vị trí hiện tại"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driverCurrentAddress ?
                                <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <Button onClick={handleEstimateModalClose} style={{backgroundColor: 'green'}}>
                                        {"Ấn để xem vị trí hiện tại"}
                                    </Button>
                                </GridItem> : <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}


                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{
                                    fontSize: 14,
                                    color: 'black',
                                    fontWeight: 'bold'
                                }}>{"Thông tin cuốc khách"}</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Trạng thái đón khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.status ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}> {
                                        bookingDetail.status === "STARTED" ? "Đang di chuyển" :
                                            bookingDetail.status === "PAID" ? "Đã thanh toán" :
                                                bookingDetail.status === "CANCELLED" ? "Đã hủy" : "Đang xử lý"
                                    }</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Thời gian đón khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.pickupTime ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 14,
                                        color: 'black'
                                    }}> {ConvertTimeStampToDate(bookingDetail.pickupTime)}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Số km đã đi"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.estimateDistance ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 14,
                                        color: 'black'
                                    }}> {Math.round(bookingDetail.estimateDistance * 100) / 100} (km)</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Thời gian di chuyển"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.estimateTime ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 14,
                                        color: 'black'
                                    }}> {ConvertSecondToHms(bookingDetail.estimateTime)}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={5} sm={5} md={5} lg={5}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Điểm đón khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.pickupAddress ?
                                <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <a href={"http://maps.google.com?q=" + bookingDetail.pickup.lat + "," + bookingDetail.pickup.lng}
                                       title={"Ấn để xem"} target={"_blank"}><h4
                                        style={{fontSize: 14, color: 'green'}}> {bookingDetail.pickupAddress}</h4></a>
                                </GridItem> : <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={5} sm={5} md={5} lg={5}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Điểm trả khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.dropAddress ?
                                <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <a href={"http://maps.google.com?q=" + bookingDetail.drop.lat + "," + bookingDetail.drop.lng}
                                       title={"Ấn để xem"} target={"_blank"}><h4
                                        style={{fontSize: 14, color: 'green'}}> {bookingDetail.dropAddress}</h4></a>
                                </GridItem> : <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{
                                    fontSize: 14,
                                    color: 'black',
                                    fontWeight: 'bold'
                                }}>{"Thông tin thanh toán"}</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Thuê bao tuyến"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.price1 && bookingDetail.price2 ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 14,
                                        color: 'black'
                                    }}> {bookingDetail && bookingDetail.price1 && bookingDetail.price2 && bookingDetail.price1.totalCost > bookingDetail.price2.totalCost ? " Giá 2 chiều" : bookingDetail && bookingDetail.price1 && bookingDetail.price2 && bookingDetail.price1.totalCost <= bookingDetail.price2.totalCost ? " Giá 1 chiều" : null} </h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Giá 1 chiều"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Tổng số tiền thanh toán"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.estimate ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 15,
                                        color: 'green',
                                        fontWeight: 'bold'
                                    }}> {formatPrice(bookingDetail.estimate)}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <h4 style={{
                                    fontSize: 15,
                                    color: 'red'
                                }}>{" Cuốc khách được cập nhật thời gian thực."}</h4>
                            </GridItem>

                        </GridContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEstimateModalClose} style={{backgroundColor: 'green'}}>
                        {"Đóng"}
                    </Button>
                </DialogActions>
            </Dialog>

            <AlertDialog open={bookingdata.booking ? true : false}
                         onClose={handleBookingAlertClose}>{bookingdata.booking ? language.booking_success + bookingdata.booking.booking_id : null}</AlertDialog>
            <AlertDialog open={bookingdata.error.flag}
                         onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
            <AlertDialog open={estimatedata.error.flag}
                         onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
            <AlertDialog titleConfirm={commonAlert.titleConfirm ? commonAlert.titleConfirm : null}
                         open={commonAlert.open}
                         onClose={commonAlert.onClose ? commonAlert.onClose : handleCommonAlertClose}
                         onConfirm={commonAlert.onConfirm ? commonAlert.onConfirm : null}>{commonAlert.msg}</AlertDialog>
        </div>
    );
}
