import {FETCH_DRIVER_FAVIOURITE, FETCH_DRIVER_FAVIOURITE_FAILED, FETCH_DRIVER_FAVIOURITE_SUCCESS, FETCH_ONE_DRIVER_FAVIOURITE, FETCH_ONE_DRIVER_FAVIOURITE_FAILED, FETCH_ONE_DRIVER_FAVIOURITE_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    drivers: null,
    driver: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const driverfaviouritereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DRIVER_FAVIOURITE:
            return {
                ...state,
                loading: true
            };
        case FETCH_DRIVER_FAVIOURITE_SUCCESS:
            return {
                ...state,
                drivers: action.payload,
                loading: false
            };
        case FETCH_DRIVER_FAVIOURITE_FAILED:
            return {
                ...state,
                drivers: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_ONE_DRIVER_FAVIOURITE:
            return {
                ...state,
                loading: true
            };
        case FETCH_ONE_DRIVER_FAVIOURITE_SUCCESS:
            return {
                ...state,
                driver: action.payload,
                loading: false
            };
        case FETCH_ONE_DRIVER_FAVIOURITE_FAILED:
            return {
                ...state,
                driver: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        default:
            return state;
    }
};
