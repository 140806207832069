import {CLEAR_TRIP_POINTS, UPDATE_BOOKING_ESTIMATE, UPDATE_BOOKING_TIME, UPDATE_DRIVER_ACCEPT, UPDATE_SELECTED_POINT_TYPE, UPDATE_TRIP_CALLCENTER, UPDATE_TRIP_CAR, UPDATE_TRIP_DROP, UPDATE_TRIP_GROUP, UPDATE_TRIP_NOTE, UPDATE_TRIP_PHONE, UPDATE_TRIP_PICKUP, UPDATE_TRIP_PRICE, UPDATE_TWO_WAY} from "../store/types";

export const updateTripPickup = (pickupAddress) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_PICKUP,
        payload: pickupAddress
    });
};

export const updateTripDrop = (dropAddress) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_DROP,
        payload: dropAddress
    });
};

export const updateDriverAccept = (driver) => (dispatch) => {
    dispatch({
        type: UPDATE_DRIVER_ACCEPT,
        payload: driver
    });
};

export const updateTripCar = (selectedCar) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_CAR,
        payload: selectedCar
    });
};

export const updatSelPointType = (selection = 'pickup') => (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_POINT_TYPE,
        payload: selection
    });
};

export const updateTime = (time) => (dispatch) => {
    dispatch({
        type: UPDATE_BOOKING_TIME,
        payload: time
    });
};

export const updateEstimate = (estimate) => (dispatch) => {
    dispatch({
        type: UPDATE_BOOKING_ESTIMATE,
        payload: estimate
    });
};
export const updateTwoWay = (isTwoWay) => (dispatch) => {
    dispatch({
        type: UPDATE_TWO_WAY,
        payload: isTwoWay
    });
};

export const clearTripPoints = () => (dispatch) => {
    dispatch({
        type: CLEAR_TRIP_POINTS,
        payload: null
    });
};

export const updateTripNote = (note) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_NOTE,
        payload: note
    });
};

export const updateTripPhone = (phone) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_PHONE,
        payload: phone
    });
};


export const updateTripCallCenter = (callCenterObj) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_CALLCENTER,
        payload: callCenterObj
    });
};


export const updateTripGroup = (group) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_GROUP,
        payload: group
    });
};

export const updateTripPrice = (price) => (dispatch) => {
    dispatch({
        type: UPDATE_TRIP_PRICE,
        payload: price
    });
};