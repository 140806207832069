import {
    CANCEL_CALL_BY_ID,
    CANCEL_CALL_BY_ID_FAILED,
    CANCEL_CALL_BY_ID_SUCCESS,
    FETCH_CALL_ACTIVE,
    FETCH_CALL_ACTIVE_FAILED,
    FETCH_CALL_ACTIVE_SUCCESS,
    FETCH_CALLS_DETAIL,
    FETCH_CALLS_DETAIL_FAILED,
    FETCH_CALLS_DETAIL_SUCCESS,
    FETCH_CALLS_PENDING,
    FETCH_CALLS_PENDING_FAILED,
    FETCH_CALLS_PENDING_FILTER,
    FETCH_CALLS_PENDING_FILTER_FAILED,
    FETCH_CALLS_PENDING_FILTER_SUCCESS,
    FETCH_CALLS_PENDING_SUCCESS,
    PUSH_CALL_BY_ID,
    PUSH_CALL_BY_ID_FAILED,
    PUSH_CALL_BY_ID_SUCCESS,
    REPORT_FLEET_ADMIN,
    REPORT_FLEET_ADMIN_SUCCESS,
    STOP_FETCH_CALLS_DETAIL,
    STOP_FETCH_CALLS_PENDING,
    UPDATE_TRACKING
} from "../store/types";
import {ConvertTimeStampToDate, ConvertTimeStampToDateRp, generateTripCode, GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "../other/DateFunctions";
import store from "../store/store";
import {getDaysInMonth} from "../other/CommonFunctions";
import {endAt, equalTo, limitToLast, off, onValue, orderByChild, push, query, set, startAt, update} from "firebase/database";
import {firebase} from '../config/configureFirebase';
import {addBookingV2} from "./bookingactions";

export const fetchCallsPending = () => (dispatch) => {
    const {
        paCallCenterRef
    } = firebase;

    dispatch({
        type: FETCH_CALLS_PENDING, payload: null,
    });
    let profile = store.getState().auth.info.profile;
    onValue(query(paCallCenterRef, ...[orderByChild('action'), equalTo('note_call'), limitToLast(1000)]), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].firebase_id = i;
                data[i].id = i;
                return data[i];
            });
            let noteSorted = arr.filter(item => item.status !== '');
            noteSorted = noteSorted.sort((a, b) => (parseInt(a.time) < parseInt(b.time)) ? 1 : -1);
            /*noteSorted = noteSorted.filter(item => !item.customer_note || (item.customer_note && item.customer_note != 'Khách quen lái xe' && item.customer_note != 'Taxi Metter'));*/

            let noteSortedAPP = noteSorted.filter(item => !item.msnv);
            let noteSortedTT = noteSorted.filter(item => item.msnv != '');

            if (store.getState().auth && store.getState().auth.info && store.getState().auth.info.uid) {
                if (profile.usertype == 'fleetadmin') {
                    noteSortedTT = noteSortedTT.filter(item => item.msnv == store.getState().auth.info.uid);
                    noteSortedAPP = noteSortedAPP.filter(item => item.msnv == store.getState().auth.info.uid);
                }
            }


            dispatch({
                type: FETCH_CALLS_PENDING_SUCCESS, payload: {
                    app: noteSortedAPP,
                    tt: noteSortedTT
                },
            });

        } else {
            dispatch({
                type: FETCH_CALLS_PENDING_FAILED, payload: "Không có cuộc gọi đến",
            });
        }
    })
};


export const fetchCallsPendingFilter = (startDate, endDate, limit = 10) => (dispatch) => {
    const {
        paCallCenterRef
    } = firebase;

    dispatch({
        type: FETCH_CALLS_PENDING_FILTER, payload: null,
    });
    let profile = store.getState().auth.info.profile;
    onValue(query(paCallCenterRef, ...[orderByChild('time'), startAt(parseInt(startDate)), endAt(parseInt(endDate)), limitToLast(limit + 5)]), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].firebase_id = i;
                data[i].id = i;
                return data[i];
            });
            let noteSorted = arr.filter(item => item.status !== '' && item.action === 'note_call');
            noteSorted = noteSorted.sort((a, b) => (parseInt(a.time) < parseInt(b.time)) ? 1 : -1);
            noteSorted = noteSorted.filter(item => !item.customer_note || (item.customer_note && item.customer_note != 'Khách quen lái xe' && item.customer_note != 'Taxi Metter'));
            if (store.getState().auth.info.uid) {
                if (profile.usertype == 'fleetadmin') {
                    noteSorted = noteSorted.filter(item => item.msnv == store.getState().auth.info.uid);
                }
            }

            dispatch({
                type: FETCH_CALLS_PENDING_FILTER_SUCCESS, payload: noteSorted,
            });
        } else {
            dispatch({
                type: FETCH_CALLS_PENDING_FILTER_FAILED, payload: "Không có cuộc gọi đến",
            });
        }
    })
};

export const reportCallCenter = (uid, startDate = null, endDate = null) => async (dispatch) => {
    const {
        paCallCenterRef, singleUserRef
    } = firebase;

    dispatch({
        type: REPORT_FLEET_ADMIN, payload: null,
    });

    const pustResult = async (dataUpdate, totalTime, totalTimeArray, totalSuccessArray, totalCallArray, totalSuccess, totalCall, dataUpdateArray) => {
        dataUpdate.time_active = totalTime;
        dataUpdate.time_active_array = totalTimeArray;
        dataUpdate.total_success_array = totalSuccessArray;
        dataUpdate.total_call_array = totalCallArray;
        dataUpdate.total_success = totalSuccess;
        dataUpdate.total_call = totalCall;
        await dataUpdateArray.push(dataUpdate);
        return dataUpdateArray;
    }


    let currentTime = GetCurrentTimeStamp();
    if (uid && !Array.isArray(uid)) {
        let dataUpdate = {
            time_active: 0, total_success: 0, total_call: 0
        };
        await onValue(singleUserRef(uid), snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                if (data.loginHistory) {
                    let listLoginHistoryValid = Object.values(data.loginHistory).filter(loginData => {
                        if (loginData.at) {
                            return true;
                        }

                    });
                    let totalTime = 0;

                    if (listLoginHistoryValid[listLoginHistoryValid.length - 1] && listLoginHistoryValid[listLoginHistoryValid.length - 1].at < startDate && listLoginHistoryValid[listLoginHistoryValid.length - 1].type == 'login') {
                        if (endDate < currentTime) {
                            totalTime = endDate - startDate;
                        } else {
                            if (currentTime > startDate) {
                                totalTime = currentTime - startDate;
                            }
                        }

                    } else {
                        for (let i = 0; i < listLoginHistoryValid.length; i++) {
                            if (listLoginHistoryValid[i].at >= startDate && listLoginHistoryValid[i].at <= endDate) {
                                if (listLoginHistoryValid[i - 1] && listLoginHistoryValid[i - 1].at < startDate) {
                                    if (listLoginHistoryValid[i - 1].type == "login" && listLoginHistoryValid[i].at >= startDate) {
                                        totalTime = totalTime + (listLoginHistoryValid[i].at - startDate);
                                    }
                                }
                                if (listLoginHistoryValid[i - 1] && listLoginHistoryValid[i - 1].at > startDate) {
                                    if (listLoginHistoryValid[i - 1].type == "login") {
                                        totalTime = totalTime + (listLoginHistoryValid[i].at - listLoginHistoryValid[i - 1].at);
                                    }
                                }
                                if (i == listLoginHistoryValid.length - 1) {
                                    if (listLoginHistoryValid[i + 1] && listLoginHistoryValid[i + 1].at > endDate) {
                                        if (listLoginHistoryValid[i].type == "login") {
                                            totalTime = totalTime + (endDate - listLoginHistoryValid[i].at);
                                        }
                                    } else {
                                        if (listLoginHistoryValid[i].at < currentTime && listLoginHistoryValid[i].type == "login") {
                                            totalTime = totalTime + (currentTime - listLoginHistoryValid[i].at);
                                        }
                                    }
                                }

                            } else {
                                continue;
                            }
                        }
                    }
                    dataUpdate.time_active = totalTime;
                }
            }
        }, {onlyOnce: true});

        await onValue(query(paCallCenterRef, ...[orderByChild('time'), startAt(startDate), endAt(endDate)]), snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                let dataCall = [];
                const arr = Object.keys(data).map(i => {
                    data[i].firebase_id = i;
                    data[i].id = i;
                    if (data[i].msnv && data[i].msnv == uid && (!data[i].customer_note || (data[i].customer_note && data[i].customer_note != 'Khách quen lái xe' && data[i].customer_note != 'Taxi Metter'))) {
                        dataCall.push(data[i]);
                    }
                });
                let totalSuccess = dataCall.filter(c => (c.status == 'cancel' && c.reason) || (c.driver_code && c.status == "paid"));
                if (totalSuccess && totalSuccess.length > 0) {
                    dataUpdate.total_success = totalSuccess.length;
                }
                dataUpdate.total_call = dataCall.length;
            }
        }, {onlyOnce: true});
        dispatch({
            type: REPORT_FLEET_ADMIN_SUCCESS, payload: dataUpdate,
        });
    } else if (uid && Array.isArray(uid)) {
        var dataUpdateArray = [];
        let listMonth = [];
        var monthStart = 0;
        var yearStart = 0;
        var monthEnd = 0;
        var yearEnd = 0;
        if (startDate) {
            monthStart = parseInt(ConvertTimeStampToDateRp(startDate * 1000).split("/")[1]);
            yearStart = parseInt(ConvertTimeStampToDateRp(startDate * 1000).split("/")[2]);
        }
        if (endDate) {
            monthEnd = parseInt(ConvertTimeStampToDateRp(endDate * 1000).split("/")[1]);
            yearEnd = parseInt(ConvertTimeStampToDateRp(endDate * 1000).split("/")[2]);
        }

        await uid.map(async id => {
            let dataUpdate = {
                name: '', time_active: 0, total_success: 0, total_call: 0, time_active_array: {}, total_success_array: {}, total_call_array: {}

            };
            var totalTime = 0;
            var totalSuccess = 0;
            var totalCall = 0;
            var totalTimeArray = {};
            var totalSuccessArray = {};
            var totalCallArray = {};
            await onValue(singleUserRef(id), async snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    dataUpdate.name = data.firstName + " " + data.lastName;
                    if (data.loginHistory) {
                        let listLoginHistoryValid = Object.values(data.loginHistory).filter(loginData => {
                            if (loginData.at) {
                                return true;
                            }
                        });
                        if (monthStart && monthEnd) {
                            for (let i = monthStart; i <= monthEnd; i++) {
                                totalCallArray[i.toString()] = 0;
                                totalSuccessArray[i.toString()] = 0;
                                let listDate = getDaysInMonth(i, yearEnd);
                                var startDateR = 0;
                                var endDateR = 0;
                                let month = i < 10 ? "0" + i.toString() : i.toString();
                                let dateLast = listDate[listDate.length - 1] && listDate[listDate.length - 1].getDate() < 10 ? "0" + listDate[listDate.length - 1].getDate().toString() : listDate[listDate.length - 1].getDate().toString();
                                let tempDates = new Date(yearStart + "-" + month + "-01T00:01").getTime() / 1000;
                                let tempDateE = new Date(yearEnd + "-" + month + "-" + dateLast + "T12:59").getTime() / 1000;
                                if (i == monthStart) {
                                    startDateR = startDate;
                                    if (endDate > tempDateE) {
                                        endDateR = tempDateE;
                                    } else {
                                        endDateR = endDate;
                                    }
                                } else {
                                    startDateR = tempDates;
                                    if (endDate > tempDateE) {
                                        endDateR = tempDateE;
                                    } else {
                                        endDateR = endDate;
                                    }
                                }

                                await onValue(query(paCallCenterRef, ...[orderByChild('time'), startAt(startDateR), endAt(endDateR)]), snapshotc => {
                                    if (snapshotc.val()) {
                                        const datac = snapshotc.val();
                                        let dataCall = [];
                                        const arr = Object.keys(datac).map(c => {
                                            datac[c].firebase_id = c;
                                            datac[c].id = c;
                                            if (datac[c].msnv && datac[c].msnv == id && (!datac[c].customer_note || (datac[c].customer_note && datac[c].customer_note != 'Khách quen lái xe' && data[c].customer_note != 'Taxi Metter'))) {
                                                dataCall.push(datac[c]);
                                            }
                                        });
                                        let totalSuccessA = dataCall.filter(d => (d.status == 'cancel' && d.reason) || (d.driver_code && d.status == "paid"));
                                        if (totalSuccessA && totalSuccessA.length > 0) {
                                            totalSuccess = totalSuccess + totalSuccessA.length;
                                            totalSuccessArray[i.toString()] = totalSuccessA.length;
                                        }
                                        totalCall = totalCall + dataCall.length;
                                        totalCallArray[i.toString()] = dataCall.length;
                                    }
                                }, {onlyOnce: true});
                            }
                        }

                        if (monthStart && monthEnd) {
                            for (let i = monthStart; i <= monthEnd; i++) {
                                totalTimeArray[i.toString()] = 0;
                                let listDate = getDaysInMonth(i, yearEnd);
                                let total_time_item = 0;
                                var startDateR = 0;
                                var endDateR = 0;
                                let month = i < 10 ? "0" + i.toString() : i.toString();
                                let dateLast = listDate[listDate.length - 1] && listDate[listDate.length - 1].getDate() < 10 ? "0" + listDate[listDate.length - 1].getDate().toString() : listDate[listDate.length - 1].getDate().toString();
                                let tempDates = new Date(yearStart + "-" + month + "-01T00:01").getTime() / 1000;
                                let tempDateE = new Date(yearEnd + "-" + month + "-" + dateLast + "T12:59").getTime() / 1000;
                                if (i == monthStart) {
                                    startDateR = startDate;
                                    if (endDate > tempDateE) {
                                        endDateR = tempDateE;
                                    } else {
                                        endDateR = endDate;
                                    }
                                } else {
                                    startDateR = tempDates;
                                    if (endDate > tempDateE) {
                                        endDateR = tempDateE;
                                    } else {
                                        endDateR = endDate;
                                    }
                                }

                                if (listLoginHistoryValid[listLoginHistoryValid.length - 1] && listLoginHistoryValid[listLoginHistoryValid.length - 1].at < startDateR && listLoginHistoryValid[listLoginHistoryValid.length - 1].type == 'login') {
                                    if (endDateR < currentTime) {
                                        total_time_item = endDateR - startDateR;
                                        totalTime = endDateR - startDateR;
                                    } else {
                                        if (currentTime > startDateR) {
                                            total_time_item = currentTime - startDateR;
                                            totalTime = currentTime - startDateR;
                                        }
                                    }

                                } else {
                                    for (let j = 0; j < listLoginHistoryValid.length; j++) {
                                        if (listLoginHistoryValid[j].at >= startDateR && listLoginHistoryValid[j].at <= endDateR) {
                                            if (listLoginHistoryValid[j - 1] && listLoginHistoryValid[j - 1].at < startDateR) {
                                                if (listLoginHistoryValid[j - 1].type == "login") {
                                                    totalTime = totalTime + (listLoginHistoryValid[j].at - startDateR);
                                                    total_time_item = total_time_item + (listLoginHistoryValid[j].at - startDateR);
                                                }
                                            }
                                            if (listLoginHistoryValid[j - 1] && listLoginHistoryValid[j - 1].at > startDateR) {
                                                if (listLoginHistoryValid[j - 1].type == "login") {
                                                    totalTime = totalTime + (listLoginHistoryValid[j].at - listLoginHistoryValid[j - 1].at);
                                                    total_time_item = total_time_item + (listLoginHistoryValid[j].at - listLoginHistoryValid[j - 1].at);
                                                }
                                            }

                                            if (j == listLoginHistoryValid.length - 1) {
                                                if (listLoginHistoryValid[j + 1] && listLoginHistoryValid[j + 1].at > endDateR) {
                                                    if (listLoginHistoryValid[j].type == "login") {
                                                        totalTime = totalTime + (endDateR - listLoginHistoryValid[j].at);
                                                        total_time_item = total_time_item + (endDateR - listLoginHistoryValid[j].at);
                                                    }
                                                } else {
                                                    if (listLoginHistoryValid[j].at < currentTime && listLoginHistoryValid[j].type == "login") {
                                                        totalTime = totalTime + (currentTime - listLoginHistoryValid[j].at);
                                                        total_time_item = total_time_item + (currentTime - listLoginHistoryValid[j].at);
                                                    }
                                                }
                                            }
                                        } else {
                                            continue;
                                        }
                                    }
                                }

                                totalTimeArray[i.toString()] = total_time_item;
                            }
                        }
                    }
                }
            }, {onlyOnce: true});
            dataUpdateArray = await pustResult(dataUpdate, totalTime, totalTimeArray, totalSuccessArray, totalCallArray, totalSuccess, totalCall, dataUpdateArray);
        });

        setTimeout(() => {
            dispatch({
                type: REPORT_FLEET_ADMIN_SUCCESS, payload: dataUpdateArray,
            });
        }, 3000);
    }


};

export const stopFetchCallsPending = () => (dispatch) => {
    const {
        paCallCenterRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_CALLS_PENDING, payload: null,
    });
    off(query(paCallCenterRef, orderByChild('action'), equalTo('note_call')));
};

export const fetchCallDetailAll = (key = null) => (dispatch) => {
    const {
        paCallCenterDetailAllRef
    } = firebase;

    dispatch({
        type: FETCH_CALLS_DETAIL, payload: null,
    });
    onValue(paCallCenterDetailAllRef(), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            dispatch({
                type: FETCH_CALLS_DETAIL_SUCCESS, payload: data,
            });
        } else {
            dispatch({
                type: FETCH_CALLS_DETAIL_FAILED, payload: "Không có chi tiết",
            });
        }
    })
};

export const stopFetchCallDetailAll = () => (dispatch) => {
    const {
        paCallCenterDetailAllRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_CALLS_DETAIL, payload: null,
    });
    off(paCallCenterDetailAllRef());
};


export const setCallDetail = (data) => {
    const {
        paCallCenterDetailRef
    } = firebase;
    set(paCallCenterDetailRef(data.phone), data);
};

export const fetchCallDetailApi = (phone) => {
    const {
        paCallCenterDetailRef
    } = firebase;

    onValue(paCallCenterDetailRef(phone), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            return data;
        } else {
            return null;
        }
    })
};

export const fetchCallActive = (id) => (dispatch) => {
    const {
        paCallByIdRef
    } = firebase;

    dispatch({
        type: FETCH_CALL_ACTIVE, payload: null,
    });
    onValue(paCallByIdRef(id), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            dispatch({
                type: FETCH_CALL_ACTIVE_SUCCESS, payload: data,
            });
        } else {
            dispatch({
                type: FETCH_CALL_ACTIVE_FAILED, payload: "Không có chi tiết cuộc gọi",
            });
        }
    })
};

export const updateCallCancelById = (id, data) => (dispatch) => {
    const {
        paCallByIdRef
    } = firebase;

    dispatch({
        type: CANCEL_CALL_BY_ID, payload: null,
    });

    if (id && id.length > 10) {
        onValue(paCallByIdRef(id), (snapshot) => {
            if (snapshot.val()) {
                update(paCallByIdRef(id), data);
                dispatch({
                    type: CANCEL_CALL_BY_ID_SUCCESS, payload: snapshot.val(),
                });
            } else {
                dispatch({
                    type: CANCEL_CALL_BY_ID_FAILED, payload: "Không tìm thấy cuộc gọi này",
                });
            }
        });
    } else {
        dispatch({
            type: CANCEL_CALL_BY_ID_FAILED, payload: "Mã cuộc gọi không hợp lệ",
        });
    }
};

export const pushCallCancelById = (data) => (dispatch) => {
    const {
        auth, paCallCenterRef, singleUserRef, bookingRef, reportdriverRef, paCallByIdRef, trackingRef
    } = firebase;

    dispatch({
        type: PUSH_CALL_BY_ID, payload: null,
    });
    let cars = store.getState().cartypes.cars;
    let carType = data.carType;
    let carSelect = cars.filter((car) => car.name === carType)[0];
    if (data && data.phone && data.note) {
        let dataPa = {};
        dataPa.status = (data.type && data.type === "khachquen") ? "accept" : "pending";
        dataPa.checksum = data.uid ? data.uid + GetCurrentMiniTimeStamp() : GetCurrentMiniTimeStamp();
        dataPa.action = 'note_call';
        dataPa.destination = data.destination ? data.destination : "101";
        dataPa.time = GetCurrentTimeStamp();
        dataPa.uniqueid = GetCurrentMiniTimeStamp();
        dataPa.source = data.phone ? data.phone : '';
        dataPa.teamId = data.teamId ? data.teamId : null;
        dataPa.note = data.note;
        dataPa.codeDriver = data.codeDriver ? data.codeDriver : null;
        dataPa.bookLater = data.bookLater ? data.bookLater : false;
        dataPa.pickup = data.pickup ? data.pickup : null;
        dataPa.drop = data.drop ? data.drop : null;
        dataPa.dropAddress = data.drop ? data.drop.add : null;
        dataPa.pickupAddress = data.pickup ? data.pickup.add : null;
        dataPa.driverBook = data.driverBook ? data.driverBook : null;
        dataPa.carType = data.carType ? data.carType : null;
        dataPa.carTypeCode = carSelect && carSelect.code ? carSelect.code : (data.carTypeCode ? data.carTypeCode : null);
        dataPa.is_two_way = data.is_two_way && data.is_two_way === true ? true : false;
        dataPa.firstName = data.firstName ? data.firstName : null;
        dataPa.lastName = data.lastName ? data.lastName : null;
        dataPa.settings = data.settings ? data.settings : null;
        dataPa.customer_token = data.customer_token ? data.customer_token : null;
        dataPa.hasInPointGroup = data.hasInPointGroup ? data.hasInPointGroup : null;
        dataPa.bookingDate = data.bookingDate ? data.bookingDate : GetCurrentMiniTimeStamp();
        dataPa.customer = auth.currentUser.uid ? auth.currentUser.uid : '';
        dataPa.customer_token = data.customer_token ? data.customer_token : '';
        dataPa.customer_image = data.customer_image ? data.customer_image : null;
        dataPa.customer_note = data.note ? data.note : '';
        dataPa.perCustomer = data.perCustomer ? data.perCustomer : 0;
        dataPa.perUseCustomer = data.perCustomer ? data.perCustomer : 0;
        dataPa.customer_contact = data.phone ? data.phone : '';
        dataPa.areaId = data.areaId ? data.areaId : '';
        dataPa.uid = data.uid ? data.uid : null;
        if (data.type && data.type === "khachquen") {
            dataPa.accept_time = GetCurrentMiniTimeStamp();
            dataPa.type = "LX";
        } else {
            dataPa.type = data.type ? data.type : "TT";
        }
        console.log("OK2");
        push(paCallCenterRef, dataPa).then((res) => {
            let firebase_pa_id = res.key;
            dataPa.callId = firebase_pa_id;
            data.callId = firebase_pa_id;
            if (auth && auth.currentUser && auth.currentUser.uid && data.type && data.type === "khachquen") {
                onValue(singleUserRef(auth.currentUser.uid), res => {
                    let userDetail = res.val();
                    let carType = userDetail.carType;
                    let carSelect = cars.filter((car) => car.name === carType)[0];
                    if (userDetail) {
                        var dataBooking = {
                            callId: firebase_pa_id,
                            drop: dataPa.drop ? dataPa.drop : null,
                            dropAddress: dataPa.drop && dataPa.drop.add ? dataPa.drop.add : null,
                            pickupAddress: dataPa.pickup && dataPa.pickup.add ? dataPa.pickup.add : null,
                            carType: carSelect.name,
                            carImage: carSelect.image,
                            pickup: dataPa.pickup ? dataPa.pickup : null,
                            status: "ACCEPTED",
                            is_auto: 'YES',
                            otp: false,
                            tripdate: GetCurrentMiniTimeStamp(),
                            bookingDate: dataPa.bookingDate ? dataPa.bookingDate : GetCurrentMiniTimeStamp(),
                            coords: (dataPa.pickup && dataPa.pickup.add && dataPa.pickup.lat) ? [{
                                latitude: dataPa.pickup.lat, longitude: dataPa.pickup.lng
                            }] : null,
                            driver_group: (userDetail.group_info && userDetail.group_info.group) ? userDetail.group_info.group : null,
                            customer: dataPa.uid,
                            customer_contact: dataPa.source,
                            customer_note: dataPa.note,
                            teamId: dataPa.teamId ? dataPa.teamId : null,
                            hasInPointGroup: dataPa.hasInPointGroup ? dataPa.hasInPointGroup : null,
                            driver: auth.currentUser.uid,
                            driverRating: userDetail.ratings ? userDetail.ratings.userrating : "0",
                            driver_name: userDetail.firstName ? userDetail.firstName : null,
                            driver_contact: userDetail.mobile ? userDetail.mobile : null,
                            codeDriver: userDetail.codeDriver ? userDetail.codeDriver.toString().trim() : null,
                            driverBook: dataPa.driverBook ? dataPa.driverBook : null,
                            driverBooked: dataPa.driverBook ? dataPa.driverBook.code : null,
                            bookLater: dataPa.bookLater ? dataPa.bookLater : false,
                            driver_token: userDetail.pushToken ? userDetail.pushToken : null,
                            driver_image: userDetail.profile_image ? userDetail.profile_image : null,
                            vehicle_number: userDetail.vehicleNumber ? userDetail.vehicleNumber : null,
                            driver_bankCode: userDetail.bankCode ? userDetail.bankCode : null,
                            metterTime: 0,
                            metterDistance: 0,
                            metterPrice: 0,
                            driver_bankAccount: userDetail.bankAccount ? userDetail.bankAccount : null,
                            customer_wish: false,
                            accept_time: GetCurrentMiniTimeStamp(),
                            perCustomer: dataPa.perCustomer ? dataPa.perCustomer : 0,
                            perUseCustomer: dataPa.perCustomer ? dataPa.perCustomer : 0,
                            source: "KQ",
                            type: dataPa.type ? dataPa.type : "TT",
                            driverInGroup: null,
                            driverOutGroup: null,
                            driverInArea: null,
                            trip_code_status: 'active',
                            trip_code: generateTripCode(6),
                            carTypeCode: dataPa.carTypeCode ? dataPa.carTypeCode : null,
                            is_two_way: dataPa.is_two_way,
                            areaId: dataPa.areaId ? dataPa.areaId : '',
                            prices: data.prices ? data.prices : null,
                            driverCurrentAddress: dataPa.pickupAddress,
                            vipStatus: false,
                            vipPlus: false,
                            isMetter: true
                        }

                        push(bookingRef, dataBooking).then((res) => {
                            let bookingKey = res.key;
                            dataBooking.id = bookingKey;
                            /*let dataNotify = {
                                date_add: GetCurrentTimeStamp(), status: 'pending', from: dataBooking.customer, type: 'booking_driver_accepted', title: "Cuốc khách đang vận hành", booking_id: bookingKey, content: "Cuốc khách đang vận hành, đến xem thông tin!", to: dataBooking.driver, params: [dataBooking]
                            };
                            dispatch(api.pushNotifyImportance(dataNotify));*/

                            if (dataBooking.callId && bookingKey) {
                                let dataPaV = {
                                    status: "accept", accept_driver: auth.currentUser.uid, driver_code: data.codeDriver, accept_time: GetCurrentMiniTimeStamp(), booking_id: bookingKey, booking_time: GetCurrentMiniTimeStamp()
                                };
                                update(paCallByIdRef(dataBooking.callId), dataPaV);
                            }

                            push(trackingRef(bookingKey), {
                                at: GetCurrentMiniTimeStamp(), status: 'STARTED', lat: userDetail.location ? userDetail.location.lat : null, lng: userDetail.location ? userDetail.location.lng : null, is_counter: false
                            });

                            dispatch({
                                type: UPDATE_TRACKING, payload: {
                                    tracking: {
                                        at: GetCurrentMiniTimeStamp(), status: 'STARTED', lat: userDetail.location ? userDetail.location.lat : null, lng: userDetail.location ? userDetail.location.lng : null, is_counter: false
                                    }
                                }
                            });

                            update(singleUserRef(auth.currentUser.uid), {
                                queue: true, cr_bookingid: bookingKey, groupDriver: 9, currentGroup: userDetail.groupDriver,
                                group: {
                                    at: 1704105490,
                                    id: 9,
                                    label: "Đang có khách",
                                    limit: "9999999",
                                    lock_status: false,
                                    totalDriver: 9,
                                    value: "9"
                                }
                            });
                            push(reportdriverRef, {content: ConvertTimeStampToDate(GetCurrentMiniTimeStamp()) + " [" + data.customer_note + "] Tài xế #" + data.codeDriver + " đã nhận #" + bookingKey});
                        }).catch(error => {
                            dispatch({
                                type: PUSH_CALL_BY_ID_FAILED, payload: error.code + ": " + error.message,
                            });
                        });
                    }
                }, {onlyOnce: true});
            }

            console.log("OK3");

            if (dataPa.type === "KH" && dataPa.settings && dataPa.carType) {
                console.log("OK4");
                let bookingObject = {
                    pickup: data.pickup ? data.pickup : null,
                    pickupAddress: data.pickup ? data.pickup.add : null,
                    drop: data.drop ? data.drop : null,
                    dropAddress: data.drop ? data.drop.add : null,
                    carDetails: dataPa.carType,
                    userDetails: {
                        uid: dataPa.uid, profile: {
                            firstName: dataPa.firstName ? dataPa.firstName : '', lastName: dataPa.lastName, mobile: dataPa.source ? dataPa.source : '', phone: dataPa.source ? dataPa.source : '', note: dataPa.note ? dataPa.note : '', customer_token: dataPa.customer_token ? dataPa.customer_token : '', customer_image: dataPa.customer_image ? dataPa.customer_image : null
                        }
                    },
                    driver_group: data.driver_group ? data.driver_group : null,
                    settings: dataPa.settings,
                    customer: auth.currentUser.uid ? auth.currentUser.uid : '',
                    customer_token: dataPa.customer_token ? dataPa.customer_token : '',
                    customer_image: dataPa.customer_image ? dataPa.customer_image : null,
                    customer_note: dataPa.note ? dataPa.note : '',
                    customer_contact: dataPa.source ? dataPa.source : '',
                    teamId: dataPa.teamId ? dataPa.teamId : null,
                    tripdate: GetCurrentMiniTimeStamp(),
                    bookLater: dataPa.bookLater ? dataPa.bookLater : false,
                    bookingDate: dataPa.bookingDate ? dataPa.bookingDate : GetCurrentMiniTimeStamp(),
                    type: "KH",
                    source: "KH",
                    callId: firebase_pa_id,
                    vipStatus: false,
                    vipPlus: false,
                    booking_type_web: true,
                    is_two_way: data.is_two_way,
                    driverOutGroup: data.driverOutGroup ? data.driverOutGroup : null,
                    driverInGroup: data.driverInGroup ? data.driverInGroup : null,
                    driverInArea: data.driverInArea ? data.driverInArea : null,
                    areaId: data.areaId ? data.areaId : '',
                    carType: carSelect ? carSelect.name : null,
                    carTypeCode: dataPa.carTypeCode ? dataPa.carTypeCode : null,
                    prices: data.prices ? data.prices : null,
                    estimateTemp: data.estimateTemp ? data.estimateTemp : null,
                    priceEstimate: data.priceEstimate ? data.priceEstimate : null,
                    tripEstimate: data.tripEstimate ? data.tripEstimate : null
                };
                dispatch(addBookingV2(bookingObject));
            }
            if (dataPa.type === "LX" && dataPa.settings && dataPa.carType) {
                let bookingObject = {
                    pickup: data.pickup ? data.pickup : null,
                    pickupAddress: data.pickup ? data.pickup.add : null,
                    drop: data.drop ? data.drop : null,
                    dropAddress: data.drop ? data.drop.add : null,
                    carDetails: carSelect,
                    userDetails: {
                        uid: dataPa.uid, profile: {
                            firstName: dataPa.firstName ? dataPa.firstName : '', lastName: dataPa.lastName, mobile: dataPa.source ? dataPa.source : '', phone: dataPa.source ? dataPa.source : '', note: dataPa.note ? dataPa.note : '', customer_token: dataPa.customer_token ? dataPa.customer_token : '', customer_image: dataPa.customer_image ? dataPa.customer_image : null
                        }
                    },
                    driver_group: data.driver_group ? data.driver_group : null,
                    settings: dataPa.settings,
                    teamId: dataPa.teamId ? dataPa.teamId : null,
                    tripdate: GetCurrentMiniTimeStamp(),
                    driverBook: dataPa.driverBook ? dataPa.driverBook : null,
                    driverBooked: dataPa.driverBook ? dataPa.driverBook.code : null,
                    bookLater: dataPa.bookLater ? dataPa.bookLater : false,
                    bookingDate: dataPa.bookingDate ? dataPa.bookingDate : GetCurrentMiniTimeStamp(),
                    type: "LX",
                    source: "LX",
                    callId: firebase_pa_id,
                    vipStatus: false,
                    vipPlus: false,
                    booking_type_web: true,
                    is_two_way: data.is_two_way,
                    driverOutGroup: data.driverOutGroup ? data.driverOutGroup : null,
                    driverInGroup: data.driverInGroup ? data.driverInGroup : null,
                    driverInArea: data.driverInArea ? data.driverInArea : null,
                    areaId: data.areaId ? data.areaId : '',
                    carType: carSelect ? carSelect.name : null,
                    carTypeCode: dataPa.carTypeCode ? dataPa.carTypeCode : null,
                    prices: data.prices ? data.prices : null,
                    estimateTemp: data.estimateTemp ? data.estimateTemp : null,
                    priceEstimate: data.priceEstimate ? data.priceEstimate : null,
                    tripEstimate: data.tripEstimate ? data.tripEstimate : null
                };

                dispatch(addBookingV2(bookingObject));
            }

            dispatch({
                type: PUSH_CALL_BY_ID_SUCCESS, payload: {
                    mainData: dataPa
                }
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: PUSH_CALL_BY_ID_FAILED, payload: error.code + ": " + error.message,
            });
        });

    } else {
        dispatch({
            type: PUSH_CALL_BY_ID_FAILED, payload: "Dữ liệu không hợp lệ",
        });
    }

};

export const addCallCancel = (data) => (dispatch) => {
    const {
        paCallCenterRef, auth
    } = firebase;

    let dataPa = {};
    dataPa.status = (data.type && data.type === "khachquen") ? "accept" : "pending";
    dataPa.checksum = data.uid ? data.uid + GetCurrentMiniTimeStamp() : GetCurrentMiniTimeStamp();
    dataPa.action = 'note_call';
    dataPa.destination = data.destination ? data.destination : "101";
    dataPa.time = GetCurrentTimeStamp();
    dataPa.uniqueid = GetCurrentMiniTimeStamp();
    dataPa.source = data.phone;
    dataPa.note = data.note;
    dataPa.codeDriver = data.codeDriver ? data.codeDriver : null;
    dataPa.pickupAddress = data.pickupAddress ? data.pickupAddress : null;
    dataPa.msnv = auth && auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null;
    dataPa.uid = data.uid ? data.uid : null;
    if (data.type && data.type === "khachquen") {
        dataPa.accept_time = GetCurrentMiniTimeStamp();
        dataPa.type = "LX";
    } else {
        dataPa.type = data.type ? data.type : "TT";
    }

    push(paCallCenterRef, dataPa);
};

