import React from "react";
import {InfoWindow} from "react-google-maps";
import {Text, View} from "react-native-web";
import {language} from "config";
import {useSelector} from "react-redux";
import AddBookings3 from "../../views/AddBookings3";


export default function InfoWindowDriver(props) {
    const bookingdata = useSelector(state => state.bookingdata.booking_uid);
    return (
        props.carInfo && props.carInfo.location.lng ? (
            <InfoWindow
                position={{lat: props.carInfo.location.lat, lng: props.carInfo.location.lng}}
                zIndex={20}
                onCloseClick={() => {
                    props.loadCarInfo(null);
                }}
            >
                <View>
                    <h4>
                        {language.driver + ": " + props.carInfo.lastName + ' ' + props.carInfo.firstName}
                    </h4>
                    <table border={1}>
                        <tr>
                            <th>Thông tin</th>
                            <th>Thông Số</th>
                        </tr>
                        <tr>
                            <td>{language.code_driver}</td>
                            <td>{props.carInfo.codeDriver}</td>
                        </tr>
                        <tr>
                            <td>{language.vehicle_model_name}</td>
                            <td>{props.carInfo.vehicleMake ? props.carInfo.vehicleMake : ''}</td>
                        </tr>
                        <tr>
                            <td>{language.vehicle_model_no}</td>
                            <td>{props.carInfo.vehicleModel ? props.carInfo.vehicleModel : ""}</td>
                        </tr>
                        <tr>
                            <td>{language.vehicle_reg_no}</td>
                            <td>{props.carInfo.vehicleNumber ? props.carInfo.vehicleNumber : ""}</td>
                        </tr>
                        <tr>
                            <td>{language.phone}</td>
                            <td>{props.carInfo.phone ? props.carInfo.phone : ""}</td>
                        </tr>
                        <tr>
                            <td>{language.car_type}</td>
                            <td>{props.carInfo.carType ? props.carInfo.carType : ""}</td>
                        </tr>
                        {props.carInfo.address ?
                            <tr>
                                <td>{language.address}</td>
                                <td>{JSON.stringify(props.carInfo.address)}</td>
                            </tr> : null
                        }

                    </table>

                    {bookingdata && bookingdata.status === "DA_DEN_CHO_DON_KHACH" ?
                        <AddBookings3 bookingDriver={props.carInfo} bookingApply={bookingdata}
                                      address={bookingdata}/> : bookingdata ?
                        <View>
                            <Text>Điểm đón: {bookingdata.pickupAddress ? bookingdata.pickupAddress : "Chưa cập nhật"}</Text>
                            <Text>Điểm trả: {bookingdata.dropAddress ? bookingdata.dropAddress : "Chưa cập nhật"}</Text>
                        </View> : null
                    }
                </View>
            </InfoWindow>
        ) : null
    );
}
