import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../CircularLoading";
import {api, FirebaseContext} from "common";
import * as XLSX from 'xlsx';
import {features, language} from "config";

export default function Price2(props) {
    const [dataPrice, setDataPrice] = useState([]);
    const cartypes = useSelector(state => state.cartypes);
    const dispatch = useDispatch();
    const [carTypeSelect, setCarTypeSelect] = useState(null);
    const {
        editCartypePrice2
    } = api;
    useEffect(() => {
        if (cartypes.cars) {
            let cartypeSelectFilter = cartypes.cars.filter(carType =>
                carType.code === props.code
            );
            if (cartypeSelectFilter && cartypeSelectFilter[0]) {
                setCarTypeSelect(cartypeSelectFilter[0]);
                if (cartypeSelectFilter[0].price2) {
                    setDataPrice(cartypeSelectFilter[0].price2);
                } else {
                    setDataPrice([]);
                }
            }
        } else {
            setDataPrice([]);
        }
    }, [cartypes.cars]);

    const columns = [
        {title: 'KM Thuê Bao', field: 'km'},
        {title: 'Thời gian', field: 'time', type: 'numeric'},
        {title: 'Giá / 1 (Km)', field: 'unitPrice', type: 'numeric'}
    ];


    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        /*const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);*/
        const headers = ['km', 'time', 'unitPrice', 'emptyPrice'];
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        dispatch(editCartypePrice2(carTypeSelect.tableData.id, list), "Add");
    }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, {header: 1});

            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    return (
        cartypes.loading ? <CircularLoading/> :

            <MaterialTable
                title={<input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    placeholder={"Import Bảng Giá"}
                    onChange={handleFileUpload}
                />}
                style={{flex: 1}}
                columns={columns}
                data={dataPrice}
                options={{
                    exportButton: true,
                    sorting: true,
                    maxBodyHeight: 500,
                    pageSize: 10,
                    pageSizeOptions: [20, 40, 60],
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = dataPrice;
                                    tblData.push(newData);

                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    //dispatch(editCarType(newData, "Update", data.indexOf(oldData)), "Update");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = dataPrice;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    //dispatch(editCarType(tblData), "Delete");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                }}
            />
    );
}
