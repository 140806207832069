import {
    EDIT_GROUP_DRIVER,
    FETCH_GROUP_AREA,
    FETCH_GROUP_AREA_BY_ID,
    FETCH_GROUP_AREA_BY_ID_FAILED,
    FETCH_GROUP_AREA_BY_ID_SUCCESS,
    FETCH_GROUP_AREA_BY_TEAM,
    FETCH_GROUP_AREA_BY_TEAM_FAILED,
    FETCH_GROUP_AREA_BY_TEAM_PENDING,
    FETCH_GROUP_AREA_BY_TEAM_PENDING_FAILED,
    FETCH_GROUP_AREA_BY_TEAM_PENDING_SUCCESS,
    FETCH_GROUP_AREA_BY_TEAM_SUCCESS,
    FETCH_GROUP_AREA_FAILED,
    FETCH_GROUP_AREA_SUCCESS,
    FETCH_GROUP_DRIVERS,
    FETCH_GROUP_DRIVERS_FAILED,
    FETCH_GROUP_DRIVERS_SUCCESS
} from "../store/types";

import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {endAt, onValue, orderByKey, query, set, startAt} from "firebase/database";

export const fetchGroupDrivers = () => (dispatch) => {
    const {
        groupdriverRef
    } = firebase;

    dispatch({
        type: FETCH_GROUP_DRIVERS,
        payload: null,
    });
    onValue(groupdriverRef, (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            let arr = [];
            let arrGroupKd = [];
            let listF = [];
            let listPolygon = [];
            let coords = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i] && data[i].value && data[i].value != "-1") {
                    arr.push(data[i].label);
                    if (data[i].value != '0' && data[i].value != '1' && data[i].value != '33' && data[i].value != '9' && data[i].value != '12' && data[i].lock_status == false) {
                        arrGroupKd.push(data[i]);
                        let g = data[i];
                        /*if (g.areas && g.areas.length > 0) {
                            for (let j = 0; j < g.areas.length; j++) {
                                let area = g.areas[j];
                                let feature = {
                                    "type": "Feature",
                                    "properties": {
                                        "letter": g.label + ' ' + j,
                                        "color": 'red'
                                    },
                                    "geometry": {
                                        "type": "Polygon",
                                        "coordinates": [area]
                                    }
                                };
                                listF.push(feature);
                                if (area.length > 0) {
                                    let childItem = []
                                    for (let k = 0; k < area.length; k++) {
                                        let areaItem = area[k];
                                        childItem.push({latitude: parseFloat(areaItem[1]), longitude: parseFloat(areaItem[0])});
                                    }
                                    listPolygon.push(childItem);
                                }
                            }
                        }*/
                        if (g.group_coords && g.group_coords.length > 0) {
                            for (let n = 0; n < g.group_coords.length; n++) {
                                let c = g.group_coords[n];
                                coords.push({latitude: parseFloat(c.coord.split(',')[0].trim()), longitude: parseFloat(c.coord.split(',')[1].trim()), radius: parseInt(c.radius), name: c.name, limit: c.limit ? parseInt(c.limit) : 0, totalDriver: c.totalDriver ? parseInt(c.totalDriver) : 0});
                            }
                        }
                    }
                }
            }
            /*const geojson = {
                "type": "FeatureCollection",
                "features": listF
            };*/
            /*
                        store.dispatch({
                            type: 'UPDATE_POLYGON',
                            payload: {
                                polygons: listPolygon,
                                geojson: geojson,
                                coords: coords
                            }
                        });*/
            dispatch({
                type: FETCH_GROUP_DRIVERS_SUCCESS,
                payload: {
                    simple: arr,
                    complex: snapshot.val(),
                    group_kd: arrGroupKd,
                    polygons: {
                        /* polygons: listPolygon,
                         geojson: geojson,*/
                        coords: coords
                    }
                }
            });
        } else {
            dispatch({
                type: FETCH_GROUP_DRIVERS_FAILED,
                payload: language.group_driver_not_found,
            });
        }
    });
};

export const fetchGroupArea = (totalRecord = null, startAtI = null, search = null) => (dispatch) => {
    const {
        listAreaRef,
        searchAreaByDisplayRef
    } = firebase;

    dispatch({
        type: FETCH_GROUP_AREA,
        payload: null,
    });
    if (totalRecord != null && startAt != null) {
        if (search != null) {
            onValue(searchAreaByDisplayRef(search), (snapshot) => {
                if (snapshot.val()) {
                    let data = snapshot.val();
                    const arr = Object.keys(data)
                        .map(i => {
                            data[i].id = i;
                            return data[i];
                        });
                    dispatch({
                        type: FETCH_GROUP_AREA_SUCCESS,
                        payload: {
                            data: arr
                        }
                    });
                } else {
                    dispatch({
                        type: FETCH_GROUP_AREA_FAILED,
                        payload: language.group_driver_not_found,
                    });
                }
            }, {onlyOnce: true});
        } else {
            onValue(query(listAreaRef(), ...[orderByKey(), startAt(startAtI), endAt(totalRecord)]), (snapshot) => {
                    if (snapshot.val()) {
                        let data = snapshot.val();
                        const arr = Object.keys(data)
                            .map(i => {
                                data[i].id = i;
                                return data[i];
                            });
                        dispatch({
                            type: FETCH_GROUP_AREA_SUCCESS,
                            payload: {
                                data: arr
                            }
                        });
                    } else {
                        dispatch({
                            type: FETCH_GROUP_AREA_FAILED,
                            payload: language.group_driver_not_found,
                        });
                    }
                }, {onlyOnce: true}
            );
        }

    } else {
        if (search != null) {
            onValue(searchAreaByDisplayRef(search), (snapshot) => {
                if (snapshot.val()) {
                    let data = snapshot.val();
                    const arr = Object.keys(data)
                        .map(i => {
                            data[i].id = i;
                            return data[i];
                        });
                    dispatch({
                        type: FETCH_GROUP_AREA_SUCCESS,
                        payload: {
                            data: arr
                        }
                    });
                } else {
                    dispatch({
                        type: FETCH_GROUP_AREA_FAILED,
                        payload: language.group_driver_not_found,
                    });
                }
            }, {onlyOnce: true});
        } else {
            onValue(listAreaRef(), (snapshot) => {
                if (snapshot.val()) {
                    let data = snapshot.val();
                    dispatch({
                        type: FETCH_GROUP_AREA_SUCCESS,
                        payload: {
                            data: data
                        }
                    });
                } else {
                    dispatch({
                        type: FETCH_GROUP_AREA_FAILED,
                        payload: language.group_driver_not_found,
                    });
                }
            }, {onlyOnce: true});
        }
    }
};

export const fetchGroupByLeader = (leaderId) => (dispatch) => {
    const {
        searchAreaByLeaderRef
    } = firebase;

    dispatch({
        type: FETCH_GROUP_AREA_BY_TEAM,
        payload: null,
    });
    onValue(searchAreaByLeaderRef(leaderId), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_GROUP_AREA_BY_TEAM_SUCCESS,
                payload: {
                    listAreaByLead: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_GROUP_AREA_BY_TEAM_FAILED,
                payload: language.group_driver_not_found,
            });
        }
    });
};

export const fetchGroupByLeaderPending = (uid) => (dispatch) => {
    const {
        searchAreaByLeaderRef
    } = firebase;

    dispatch({
        type: FETCH_GROUP_AREA_BY_TEAM_PENDING,
        payload: null,
    });
    onValue(searchAreaByLeaderRef(uid), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_GROUP_AREA_BY_TEAM_PENDING_SUCCESS,
                payload: {
                    listAreaByMember: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_GROUP_AREA_BY_TEAM_PENDING_FAILED,
                payload: language.group_driver_not_found,
            });
        }
    });
};

export const editGroupDriver = (groups, method) => (dispatch) => {
    const {
        groupdriverRef,
    } = firebase;

    dispatch({
        type: EDIT_GROUP_DRIVER,
        payload: method
    });
    set(groupdriverRef, groups);
}

export const fetchAreaById = (areaId) => (dispatch) => {
    const {
        driverGroupByIdRef
    } = firebase;

    dispatch({
        type: FETCH_GROUP_AREA_BY_ID,
        payload: null,
    });
    if (areaId) {
        onValue(driverGroupByIdRef(areaId), (snapshot) => {
            if (snapshot.val()) {
                let data = snapshot.val();
                dispatch({
                    type: FETCH_GROUP_AREA_BY_ID_SUCCESS,
                    payload: {
                        areaDetail: data
                    }
                });
            } else {
                dispatch({
                    type: FETCH_GROUP_AREA_BY_ID_FAILED,
                    payload: "Không tồn tại khu vực",
                });
            }
        }, {onlyOnce: true});
    } else {
        dispatch({
            type: FETCH_GROUP_AREA_BY_ID_FAILED,
            payload: "Yêu cầu cung cấp mã khu vực",
        });
    }

};
