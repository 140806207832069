import {
    FETCH_GROUP_DRIVERS,
    FETCH_GROUP_DRIVERS_SUCCESS,
    FETCH_GROUP_DRIVERS_FAILED,
    EDIT_GROUP_DRIVER, FETCH_GROUP_AREA, FETCH_GROUP_AREA_SUCCESS, FETCH_GROUP_AREA_FAILED, FETCH_GROUP_AREA_BY_TEAM, FETCH_GROUP_AREA_BY_TEAM_SUCCESS, FETCH_GROUP_AREA_BY_TEAM_FAILED, FETCH_GROUP_AREA_BY_TEAM_PENDING, FETCH_GROUP_AREA_BY_TEAM_PENDING_SUCCESS, FETCH_GROUP_AREA_BY_TEAM_PENDING_FAILED, FETCH_AREA_BY_ID, FETCH_AREA_BY_ID_FAILED, FETCH_AREA_BY_ID_SUCCESS, FETCH_GROUP_AREA_BY_ID, FETCH_GROUP_AREA_BY_ID_SUCCESS, FETCH_GROUP_AREA_BY_ID_FAILED
} from "../store/types";

const INITIAL_STATE = {
    simple: [],
    complex: [],
    group_kd: [],
    polygon: null,
    areaDetail: null,
    data: [],
    listAreaByLead: [],
    area: [],
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const groupdriverreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_GROUP_DRIVERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_GROUP_DRIVERS_SUCCESS:
            return {
                ...state,
                simple: action.payload.simple,
                complex: action.payload.complex,
                group_kd: action.payload.group_kd,
                polygon: action.payload.polygons,
                loading: false
            };
        case FETCH_GROUP_DRIVERS_FAILED:
            return {
                ...state,
                simple: [],
                complex: [],
                group_kd: [],
                polygon: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_GROUP_AREA:
            return {
                ...state,
                loading: true
            };
        case FETCH_GROUP_AREA_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        case FETCH_GROUP_AREA_FAILED:
            return {
                ...state,
                data: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_GROUP_AREA_BY_TEAM:
            return {
                ...state,
                loading: true
            };
        case FETCH_GROUP_AREA_BY_TEAM_SUCCESS:
            return {
                ...state,
                listAreaByLead: action.payload.listAreaByLead,
                loading: false
            };
        case FETCH_GROUP_AREA_BY_TEAM_FAILED:
            return {
                ...state,
                listAreaByLead: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_GROUP_AREA_BY_TEAM_PENDING:
            return {
                ...state,
                loading: true
            };
        case FETCH_GROUP_AREA_BY_TEAM_PENDING_SUCCESS:
            return {
                ...state,
                listAreaByMember: action.payload.listAreaByMember,
                loading: false
            };
        case FETCH_GROUP_AREA_BY_TEAM_PENDING_FAILED:
            return {
                ...state,
                listAreaByMember: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_GROUP_AREA_BY_ID:
            return {
                ...state,
                loading: true
            };
        case FETCH_GROUP_AREA_BY_ID_SUCCESS:
            return {
                ...state,
                areaDetail: action.payload.areaDetail,
                loading: false
            };
        case FETCH_GROUP_AREA_BY_ID_FAILED:
            return {
                ...state,
                areaDetail: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case EDIT_GROUP_DRIVER:
            return state;
        default:
            return state;
    }
};
