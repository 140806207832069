import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {features, language} from 'config';
import {api, FirebaseContext} from 'common';
import {View} from "react-native-web";
import Popup from "reactjs-popup";
import {makeStyles} from "@material-ui/core/styles";
import {ConvertTimeStampToDate} from "common/src/other/DateFunctions";

const useStyles = makeStyles(theme => ({
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }

}));

export default function GiaoXe() {
    const classes = useStyles();
    const {
        fetchGiaoXe,
        stopFectchGiaoXe
    } = api;
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const giaoxe = useSelector(state => state.giaoxedata);

    const columns = [
        {title: "Ngày Tháng", field: 'date_add', editable: 'never'},
        {title: "Nội dung bàn giao xe", field: 'content', render: rowData => <ModalLich dataGiaoXe={rowData}/>, editable: 'never'},
        {title: "Biển số", field: 'bienso', editable: 'never'},
        {title: "Lái Xe Giao", field: 'lx_giao', editable: 'never'},
        {title: "Lái Xe Nhận", field: 'lx_nhan', editable: 'never'},
        {title: "Trạng thái bàn giao", field: 'status', lookup: {pending: "Chưa xác nhận", approved: "Đồng ý", reject: "Từ chối"}, initialEditValue: "pending"},
        {title: "Ghi chú giao", field: 'message_giao', initialEditValue: ''},
        {title: "Ghi chú nhận", field: 'message_nhan', initialEditValue: ''},
    ];

    useEffect(() => {
        dispatch(fetchGiaoXe())
        return () => {
            dispatch(stopFectchGiaoXe())
        }
    }, []);

    useEffect(() => {
        if (giaoxe.giaoxe) {
            let listValues = Object.values(giaoxe.giaoxe);
            let resultCall = [];
            for (let i = 0; i < listValues.length; i++) {
                listValues[i].date_add = ConvertTimeStampToDate(listValues[i].date_add);
                resultCall.push(listValues[i]);
            }
            setData(resultCall);
        } else {
            setData([]);
        }
    }, [giaoxe.giaoxe]);

    const ModalLich = (props) => {
        var dataContent = null;
        if (props.dataGiaoXe) {
            dataContent = Object.values(props.dataGiaoXe.content);
        }
        return (
            <Popup
                trigger={<button className='button'> Nội dung bàn giao xe</button>}
                modal
                nested
                position="top center"
            >
                {close => (
                    <div className={classes.modal}>
                        <button className={classes.modalClose} onClick={close}>
                            X
                        </button>
                        <div className={classes.modalHeader}> Thông tin bàn giao xe #{props.dataGiaoXe.bienso} ngày {props.dataGiaoXe.date_add}</div>
                        <div className={classes.modalContent}>
                            {dataContent ?
                                <MaterialTable
                                    title={""}
                                    columns={[
                                        {title: "Tên", field: 'name', editable: 'never'},
                                        {title: "Nội dung", field: 'content', editable: 'never'},
                                        {title: "LX Giao Xác Nhận", field: 'lx_giao_status', type: 'boolean', initialEditValue: false, editable: 'never'}
                                    ]}
                                    data={dataContent}
                                /> : null}
                        </div>
                        <div className={classes.modalActions}>
                            <button
                                className='button'
                                onClick={() => {
                                    console.log('modal closed ');
                                    close();
                                }}
                            >
                                Đóng
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        )
    };

    return (
        <View>
            <MaterialTable
                title={"Lịch sử giao xe"}
                columns={columns}
                data={data}
                options={{
                    exportButton: true,
                    pageSize: 25,
                    pageSizeOptions: [10, 25, 50, 100]
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    //dispatch(editMistakes(newData, "Add"));
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        })
                }}
            />
        </View>

    );
}
