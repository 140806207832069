import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function RiderPrivacyPolicy(props) {
    const classes = useStyles();
    const {...rest} = props;
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/header-back.png").default}/>
            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className={classes.container}>
                    <br/>
                    Chào mừng Quý Khách hàng đến với trang thông tin điện tử https://eto.net.vn và ứng dụng ETO của Công ty Cổ phần DV Vận Tải My Sơn.
                    Quý Khách hàng vui lòng đọc kỹ Điều khoản và Điều kiện sử dụng ứng dụng ETO (“Điều khoản sử dụng”) trước khi cài đặt và sử dụng ứng dụng ETO để đặt mua bất kỳ dịch vụ nào thông qua Sàn TMĐT ETO (sau đây gọi là “Dịch vụ”).
                    Điều khoản sử dụng này là thỏa thuận pháp lý giữa Quý Khách hàng và Công ty khi Quý Khách hàng đã lựa chọn sử dụng ứng dụng ETO và sử dụng Dịch vụ trên Sàn TMĐT ETO.
                    Bằng việc sử dụng Dịch vụ trên ứng dụng ETO, Quý Khách hàng đồng ý rằng mình đã đọc, hiểu rõ, chấp nhận và đồng ý với Điều khoản sử dụng này và với bất kỳ nội dung sửa đổi, bổ sung nào của Điều khoản sử dụng này được công bố bởi ETO Group tại từng thời điểm trên trang thông tin điện tử https://eto.net.vn và/hoặc trên ứng dụng ETO.
                    Điều khoản sử dụng này và bất kỳ sửa đổi, bổ sung nào của Điều khoản sử dụng này được công bố bởi ETO Group tại từng thời điểm tại trang thông tin điện tử https://eto.net.vn và/hoặc trên Ứng dụng ETO.
                    ETO Group bảo lưu quyền được điều chỉnh, sửa đổi, bổ sung hoặc hủy bỏ bất kỳ điều khoản nào của Điều khoản sử dụng hoặc các chính sách liên quan đến Dịch vụ tại bất cứ thời điểm nào mà ETO Group cho là phù hợp.
                    Những điều chỉnh, sửa đổi, bổ sung hoặc hủy bỏ Điều khoản sử dụng hoặc các chính sách có liên quan đến Dịch vụ sẽ có hiệu lực ngay khi nội dung được đăng tải trên trang thông tin điện tử https://eto.net.vn và/hoặc thông qua ứng dụng ETO.
                    Quý Khách hàng có trách nhiệm kiểm tra thường xuyên Điều khoản sử dụng này khi sử dụng ứng dụng ETO và sử dụng Dịch vụ thông qua ứng dụng ETO.
                    Việc tiếp tục sử dụng ứng dụng ETO, sử dụng Dịch vụ trên Sàn TMĐT ETO sau khi có bất kỳ thay đổi nào về Điều khoản sử dụng, bất kể là Quý Khách hàng có xem xét sự thay đổi của Điều khoản sử dụng hay chưa, sẽ mặc nhiên được coi là quý khách đã chấp thuận và đồng ý đối với những nội dung thay đổi đó.
                    ETO Group là chủ quản lý, vận hành ứng dụng ETO thực hiện hợp tác kinh doanh với Đối tác đủ điều kiện để cung cấp dịch vụ vận tải cho Khách hàng theo quy định của pháp luật hiện hành có liên quan của Việt Nam (sau đây gọi là: “Đối tác” hoặc “Nhà cung cấp”).
                    Thời điểm giao dịch giữa Nhà cung cấp với Quý Khách hàng được xác lập thông qua ứng dụng ETO cũng là thời điểm quyền và nghĩa vụ giữa Quý Khách hàng và Nhà cung cấp và/hoặc ETO Group phát sinh hiệu lực theo quy định pháp luật Việt Nam hoặc theo Điều ước quốc tế mà nước Cộng hòa Xã hội Chủ nghĩa Việt Nam là thành viên.
                    Dịch vụ mà ETO Group cung cấp là ứng dụng công nghệ kết nối Quý Khách hàng với các Đối tác của ETO Group nhằm tạo sự thuận lợi của các bên trong quá trình giao dịch.
                    ETO Group cam kết hỗ trợ Quý Khách hàng hoặc thực hiện cung cấp thông tin liên quan cho cơ quan có thẩm quyền để giải quyết những tranh chấp phát sinh trong quá trình Quý Khách hàng sử dụng Dịch vụ của Nhà cung cấp kết nối thông qua ứng dụng ETO.
                    Quy định này sẽ giúp Quý Khách hàng hiểu rõ những thông tin cá nhân nào mà ETO Group sẽ thu thập, cũng như cách ETO Group sử dụng những thông tin này sau đó
                    <br/>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
