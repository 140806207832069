import {
    FETCH_MISTAKES,
    FETCH_MISTAKES_SUCCESS,
    FETCH_MISTAKES_FAILED,
    FETCH_DRIVER_MISTAKES,
    FETCH_DRIVER_MISTAKES_SUCCESS,
    FETCH_DRIVER_MISTAKES_FAILED, STOP_FETCH_MISTAKES,
} from "../store/types";

export const INITIAL_STATE = {
    mistakes: null,
    driver_mistakes: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const mistakereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MISTAKES:
            return {
                ...state,
                loading: true
            };
        case FETCH_MISTAKES_SUCCESS:
            return {
                ...state,
                mistakes: action.payload,
                loading: false
            };
        case FETCH_MISTAKES_FAILED:
            return {
                ...state,
                mistakes: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case STOP_FETCH_MISTAKES:
            return {
                ...state,
                mistakes: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_DRIVER_MISTAKES:
            return {
                ...state,
                loading: true
            };
        case FETCH_DRIVER_MISTAKES_SUCCESS:
            return {
                ...state,
                driver_mistakes: action.payload,
                loading: false
            };
        case FETCH_DRIVER_MISTAKES_FAILED:
            return {
                ...state,
                driver_mistakes: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        default:
            return state;
    }
};
