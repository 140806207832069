import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import {api, FirebaseContext} from "common";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {FormControl} from "@material-ui/core";
import MaterialTable from "material-table";
import {ConvertTimeStampToDate, GetCurrentTimeStamp} from "common/src/other/DateFunctions";
import {formatDisplayDateSmart, HiddenPhone} from "common/src/other/CommonFunctions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import AlertDialog from "../AlertDialog";
import CircularLoading from "../CircularLoading";
import BookingCallCenter from "./BookingCallCenter";
import {colors} from "../../config/theme";

const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        backgroundColor: colors.MAIN
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#E8E8E8",
        fontSize: 16,
        padding: '10px 2px 10px 10px',
        color: "#000"
    },
    commonInputStyle: {
        borderRadius: 4,
        backgroundColor: "#E8E8E8",
    },
    title: {color: "#000"}
}));

export default function ButtonCallCenterDetailDialog(props) {
    const {
        fetchCallDetailApi,
        setCallDetail,
        loadBookingByTripCode,
        stopLoadBookingByTripCode,
        fetchBookings,
        stopFetchBookings,
        fetchOnceAllBookingsByCus
    } = api;
    const dispatch = useDispatch();
    const styles = useStyles();
    const [callDetailData, setCallDetailData] = useState(null);
    const auth = useSelector(state => state.auth);
    const settings = useSelector(state => state.settingsdata.settings);
    const bookinglistdata = useSelector(state => state.bookinglistdata.bookings);
    const [modalVisible, setModalVisible] = useState(props.showModal);
    const [alias, setAlias] = useState(null);
    const tableRpRef = useRef(null);
    const [bookingDetail, setBookingDetail] = useState(null);
    const [showBookingModalStatus, setShowBookingModalStatus] = useState(false);
    const [bookingCode, setBookingCode] = useState(null);
    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});
    const bookingByCusPhone = useSelector(state => state.bookinglistdata.listBookingByCusPhone);
    const loadingBk = useSelector(state => state.bookinglistdata.loadingBk);
    const [loaddingBooking, setLoaddingBooking] = useState(false);
    const [searchInit, setSearchInit] = useState(null);
    const [data, setData] = useState([]);


    /*useEffect(() => {
        if (bookinglistdata) {
            if (searchInit) {
                setData(bookinglistdata.filter((b) => b.customer_contact.replace("+840", "0") === props.callDetail.source && b.count_time && b.count_time != 'BAO_TIN_HIEU_LAN_2_REQUEST' && b.customer_note.toUpperCase().includes(searchInit.toUpperCase())));
            } else {
                setData(bookinglistdata.filter((b) => b.customer_contact.replace("+840", "0") === props.callDetail.source && b.count_time && b.count_time != 'BAO_TIN_HIEU_LAN_2_REQUEST'));
            }
        } else {
            setData([]);
        }
    }, [bookinglistdata, searchInit]);*/

    useEffect(() => {
        if (props.callDetail && props.callDetail.source) {
            dispatch(fetchOnceAllBookingsByCus(props.callDetail.source));
            setCallDetailData(fetchCallDetailApi(props.callDetail.source))
        }
    }, [props]);

    /*
        useEffect(() => {
            if (bookingdata.loading) {
                setLoaddingBooking(true);
            } else {
                setLoaddingBooking(false);
            }
            if (bookingdata.fetchBooking) {
                let bookingList = Object.values(bookingdata.fetchBooking);
                if (bookingList[0]) {
                    setShowBookingModalStatus(true);
                    //setBookingDetail(bookingList[0]);
                }
            } else {
                setShowBookingModalStatus(false);
                //setBookingDetail(null);
            }
        }, [bookingdata]);
    */

    /*  const handShowBookingDialog = (e, tripCode) => {
          e.preventDefault();
          if (tripCode) {
              setBookingCode(tripCode);
              dispatch(loadBookingByTripCode(tripCode));
          } else {
              setCommonAlert({open: true, msg: "Vui lòng nhập mã cuốc khách"});
          }
      };*/

    const closeCallback = useCallback(
        () => {
            setModalVisible(false);
            setAlias(null);
            props.hideModal();
        },
        [],
    );
    /*const handleEstimateModalClose = (e) => {
        e.preventDefault();
        if (bookingCode) {
            dispatch(stopLoadBookingByTripCode(bookingCode));
        }
        setBookingCode(null);
        setShowBookingModalStatus(false);
        setBookingDetail(null);
    };

    const formatPrice = (value) => {
        return formatNumber(value, {
            separator: ',',
            precision: 0,
            delimiter: '.',
            ignoreNegative: false,
        }) + ' ' + settings.symbol;
    }*/
    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''})
    };

    const columns_rp = [
        {
            title: "Ngày Giờ",
            field: 'tripdate',
            render: rowData => rowData.tripdate ? <span>{formatDisplayDateSmart(ConvertTimeStampToDate(rowData.tripdate))}</span> : null
        },
        /*{
            title: "Mã CK", field: 'trip_code', editable: 'never', render: rowData => {
                return (
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        id={"button_view_detail_" + rowData.tripdate.toString()}
                        className={styles.submit}
                        onClick={
                            (e) => {
                                setBookingCode(rowData.trip_code);
                                handShowBookingDialog(e, rowData.trip_code);
                                setShowBookingModalStatus(true);
                            }
                        }
                    >
                        {loaddingBooking ? <CircularLoading/> : rowData.trip_code}
                    </Button>
                )
            }
        },*/
        {title: "Ghi Chú", field: 'customer_note', editable: 'never'},
        {title: "Loại xe", field: 'carType', editable: 'never'},
        /*{
            title: "Trạng Thái", field: 'status', editable: 'never',
            render: rowData => rowData.tripdate ? <span>{rowData.status === 'NEWV2' ? "MỚI" : rowData.status === 'CANCELLED' ? "HỦY" : rowData.status === 'COMPLETE' ? "HOÀN THÀNH" : rowData.status === 'PAID' ? "THANH TOÁN" : "ĐANG THỰC HIỆN"}</span> : null
        },*/
        {
            title: "Hành động", field: 'trip_code', editable: 'never', render: rowData => {
                if (bookingDetail && bookingDetail.trip_code == rowData.trip_code) {
                    return (
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            size="small"
                            id={"button_view_detail_" + rowData.tripdate.toString()}
                            className={styles.submit}
                            onClick={
                                (e) => {
                                    setBookingDetail(null);
                                }
                            }
                        >
                            {loaddingBooking ? <CircularLoading/> : "Bỏ chọn"}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            id={"button_view_detail_" + rowData.tripdate.toString()}
                            className={styles.submit}
                            onClick={
                                (e) => {
                                    setBookingDetail(rowData);
                                }
                            }
                        >
                            {loaddingBooking ? <CircularLoading/> : "Chọn"}
                        </Button>
                    )
                }
            }
        }
    ];

    /*const viewDetailBooking = () => {
        return (
            <Dialog
                open={showBookingModalStatus}
                onClose={handleEstimateModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">{"Chi tiết cuốc khách #" + (bookingDetail ? bookingDetail.trip_code : '')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <GridContainer spacing={2}>
                            <GridItem xs={3} sm={3} md={3} lg={3} style={{textAlign: 'left'}}>
                                <img src={require("../../assets/img/logo192x192.png").default} alt="Logo ETO" style={{borderRadius: 50 / 2, width: 50, height: 50}}/>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'green', textAlign: 'center'}}>{"ETO được vận hành bởi CÔNG TY CỔ PHẦN DỊCH VỤ VẬN TẢI MY SƠN. "}</h4>
                            </GridItem>
                            <GridItem xs={3} sm={3} md={3} lg={3} style={{textAlign: 'right'}}>
                                <img src={require("../../assets/img/taximysonlogo.png").default} alt="Logo MY SƠN" style={{width: 55, height: 55}}/>
                            </GridItem>

                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Đã đăng ký với sở giao thông vận tải được cấp phép sử dụng ETO thanh toán."}</h4>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                <img src={require("../../assets/img/dau.png").default} alt="Con Dấu MY SƠN" style={{width: 70, height: 70}}/>
                            </GridItem>

                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}>{"Thông tin lái xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driver_image ?
                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                    <img src={bookingDetail.driver_image} alt="Avatar lái xe" style={{width: 40, height: 40}}/>
                                </GridItem> : <GridItem xs={4} sm={4} md={4} lg={4}>
                                    <img src={require("../../assets/img/taximysonlogo.png").default} alt="Avatar lái xe" style={{width: 40, height: 40}}/>
                                </GridItem>}
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Họ và tên Lái Xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driver_name ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.driver_name}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Số điện thoại"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driver_contact ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.driver_contact.replace("+084", "")}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}>{"Thông tin xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.vehicle_number ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.vehicle_number}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}


                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Loại xe"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.carType ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {bookingDetail.carType.label}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}


                            <GridItem xs={5} sm={5} md={5} lg={5}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Vị trí hiện tại"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.driverCurrentAddress ?
                                <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <a href={"http://maps.google.com?q=" + bookingDetail.driverCurrentAddress.lat + "," + bookingDetail.driverCurrentAddress.lng} title={"Ấn để xem vị trí hiện tại"} target={"_blank"}>
                                        <h4 style={{fontSize: 14, color: 'green'}}> {bookingDetail.pickupAddress}</h4>
                                    </a>
                                </GridItem> : <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}


                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}>{"Thông tin cuốc khách"}</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Trạng thái đón khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.status ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}> {
                                        bookingDetail.status === "STARTED" ? "Đang di chuyển" :
                                            bookingDetail.status === "PAID" ? "Đã thanh toán" :
                                                bookingDetail.status === "CANCELLED" ? "Đã hủy" : "Đang xử lý"
                                    }</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Thời gian đón khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.pickupTime ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {ConvertTimeStampToDate(bookingDetail.pickupTime)}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Số km đã đi"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.estimateDistance ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {Math.round(bookingDetail.estimateDistance * 100) / 100} (km)</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Thời gian di chuyển"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.estimateTime ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {ConvertSecondToHms(bookingDetail.estimateTime)}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={5} sm={5} md={5} lg={5}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Điểm đón khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.pickupAddress ?
                                <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <a href={"http://maps.google.com?q=" + bookingDetail.pickup.lat + "," + bookingDetail.pickup.lng} title={"Ấn để xem"} target={"_blank"}><h4 style={{fontSize: 14, color: 'green'}}> {bookingDetail.pickupAddress}</h4></a>
                                </GridItem> : <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={5} sm={5} md={5} lg={5}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Điểm trả khách"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.dropAddress ?
                                <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <a href={"http://maps.google.com?q=" + bookingDetail.drop.lat + "," + bookingDetail.drop.lng} title={"Ấn để xem"} target={"_blank"}><h4 style={{fontSize: 14, color: 'green'}}> {bookingDetail.dropAddress}</h4></a>
                                </GridItem> : <GridItem xs={7} sm={7} md={7} lg={7}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <h4 style={{fontSize: 14, color: 'black', fontWeight: 'bold'}}>{"Thông tin thanh toán"}</h4>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Thuê bao tuyến"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.price1 && bookingDetail.price2 ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{
                                        fontSize: 14,
                                        color: 'black'
                                    }}> {bookingDetail && bookingDetail.price1 && bookingDetail.price2 && bookingDetail.price1.totalCost > bookingDetail.price2.totalCost ? " Giá 2 chiều" : bookingDetail && bookingDetail.price1 && bookingDetail.price2 && bookingDetail.price1.totalCost <= bookingDetail.price2.totalCost ? " Giá 1 chiều" : null} </h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Giá 1 chiều"}</h4>
                                </GridItem>}

                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <h4 style={{fontSize: 14, color: 'black'}}>{"Tổng số tiền thanh toán"}</h4>
                            </GridItem>
                            {bookingDetail && bookingDetail.estimate ?
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 15, color: 'green', fontWeight: 'bold'}}> {formatPrice(bookingDetail.estimate)}</h4>
                                </GridItem> : <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <h4 style={{fontSize: 14, color: 'black'}}> {"Chưa cập nhật"}</h4>
                                </GridItem>}

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <h4 style={{fontSize: 15, color: 'red'}}>{" Cuốc khách được cập nhật thời gian thực."}</h4>
                            </GridItem>

                        </GridContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEstimateModalClose} style={{backgroundColor: 'green'}}>
                        {"Đóng"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }*/

    const listLyDo = [
        'Số bị trượt máy',
        'Lái xe gọi',
        'Khách hỏi thông tin',
        'Không có phương tiện hỗ trợ',
        'Vấn đề khác'
    ];

    return (
        <Dialog
            open={props.showModal}
            onClose={closeCallback}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{"Thông Tin Khách - "}{HiddenPhone(props.callDetail.source)}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {/*{viewDetailBooking()}*/}
                    <Grid container spacing={1} style={{height: 650}}>
                        <Grid style={{paddingRight: 30}} item xs={4} sm={12} md={12} lg={4}>
                            <FormControl>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={12} lg={12}>
                                        <TextField
                                            id="text_alias"
                                            label={"Tên Bí Danh " + (props.callObj && props.callObj.alias ? " - " + props.callObj.alias : '')}
                                            variant="filled"
                                            fullWidth
                                            className={styles.commonInputStyle}
                                            InputProps={{
                                                className: styles.input,
                                                style: {textAlignLast: 'start'}
                                            }}
                                            value={alias}
                                            onChange={(event) => {
                                                setAlias(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            id={"bookingbuttonmap_detail_" + (props.callDetail && props.callDetail.source ? props.callDetail.source : "")}
                                            className={styles.submit}
                                            onClick={
                                                () => {
                                                    setCallDetail({phone: props.callDetail.source, alias: alias});
                                                    alert("Thay đổi bí danh khách hàng thành công!");
                                                }
                                            }
                                        >
                                            {"Lưu"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="red"
                                            size="small"
                                            id={"cancelbutton_detail_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                            className={styles.submit}
                                            onClick={closeCallback}
                                        >
                                            {"Đóng"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <MaterialTable
                                            tableRef={tableRpRef}
                                            title={"Cuốc khách đã đặt"}
                                            columns={columns_rp}
                                            data={bookingByCusPhone && bookingByCusPhone.length > 0 ? bookingByCusPhone.filter(b => b.customer != b.driver) : []}
                                            options={{
                                                exportButton: false,
                                                columnsButton: false,
                                                pageSize: 5,
                                                pageSizeOptions: [5],
                                                searchText: searchInit ? searchInit : null
                                            }}
                                            style={{
                                                flexGrow: 0
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <AlertDialog titleConfirm={commonAlert.titleConfirm ? commonAlert.titleConfirm : null} open={commonAlert.open} onClose={commonAlert.onClose ? commonAlert.onClose : handleCommonAlertClose}
                                             onConfirm={commonAlert.onConfirm ? commonAlert.onConfirm : null}>{commonAlert.msg}</AlertDialog>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sm={12} md={12} lg={8}>
                            <BookingCallCenter {...props} time={ConvertTimeStampToDate(GetCurrentTimeStamp())} callDetail={props.callDetail}
                                               notes={props.notes}
                                //noteTemp={props.noteTemp}
                                               setSearchInit={setSearchInit}
                                               listGroup={props.listGroup} listLyDo={listLyDo}
                                               cartypes={props.cartypes} closeCallback={closeCallback}
                                               booking={bookingDetail} bookings={bookinglistdata ? bookinglistdata.filter((b) => b.customer_contact.replace("+840", "0") === props.callDetail.source && b.count_time && b.count_time != 'BAO_TIN_HIEU_LAN_2_REQUEST') : []}/>

                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
