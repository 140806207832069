import {CLEAR_ESTIMATE, FETCH_ESTIMATE, FETCH_ESTIMATE_FAILED, FETCH_ESTIMATE_SUCCESS} from "../store/types";
import Polyline from '@mapbox/polyline';

import {FareCalculator} from '../other/FareCalculator';
import {getRouteDetails} from '../other/GoogleAPIFunctions';
import store from "../store/store";
import {GetDistance} from "../other/GeoFunctions";

export const getEstimate = (bookingData) => (dispatch) => {
    dispatch({
        type: FETCH_ESTIMATE,
        payload: bookingData,
    });

    var driverPrices = bookingData.prices ? bookingData.prices : null;

    var startLoc = "";
    var distanceBak = 1;
    var pickupLat = '';
    var pickupLng = '';
    var dropLat = '';
    var dropLng = '';

    if (bookingData.pickup && bookingData.pickup.coords) {
        pickupLat = bookingData.pickup.coords.lat;
        pickupLng = bookingData.pickup.coords.lng;
    } else {
        pickupLat = bookingData.pickup.lat;
        pickupLng = bookingData.pickup.lng;
    }

    if (bookingData.drop && bookingData.drop.coords) {
        dropLat = bookingData.drop.coords.lat;
        dropLng = bookingData.drop.coords.lng;
    } else {
        dropLat = bookingData.drop.lat;
        dropLng = bookingData.drop.lng;
    }


    startLoc = pickupLat + ',' + pickupLng;
    distanceBak = GetDistance(pickupLat, pickupLng, dropLat, dropLng);

    let destLoc = dropLat + ',' + dropLng;
    var durationBak = distanceBak / 40;
    if (bookingData.is_two_way === true) {
        durationBak = durationBak * 2;
    }
    let settings = store.getState().settingsdata.settings;
    getRouteDetails(startLoc, destLoc).then((res) => {
        if (res) {
            let points = Polyline.decode(res.polylinePoints);
            let waypoints = points.map((point) => {
                return {
                    latitude: point[0],
                    longitude: point[1]
                }
            })
            var distance = settings.convert_to_mile ? (res.distance / 1.609344) : res.distance;
            if (bookingData.is_two_way === true) {
                distance = distance * 2;
            }
            let fareCalculation = FareCalculator(distance, parseFloat(res.duration), bookingData.carDetails, 0, driverPrices);
            dispatch({
                type: FETCH_ESTIMATE_SUCCESS,
                payload: {
                    pickup: bookingData.pickup,
                    drop: bookingData.drop,
                    bookLater: bookingData.bookLater,
                    bookingDate: bookingData.bookingDate,
                    carDetails: bookingData.carDetails,
                    driverPrices: driverPrices,
                    estimateDistance: parseFloat(distance).toFixed(2),
                    fareCost: fareCalculation ? parseFloat(fareCalculation.totalCost).toFixed(0) : 0,
                    estimateFare: fareCalculation ? parseFloat(fareCalculation.grandTotal).toFixed(0) : 0,
                    estimateTime: res.duration,
                    convenience_fees: fareCalculation ? parseFloat(fareCalculation.convenience_fees).toFixed(0) : 0,
                    waypoints: waypoints
                },
            });
        } else {
            var fareCalculation = FareCalculator(distanceBak, durationBak, bookingData.carDetails, 0, driverPrices);
            dispatch({
                type: FETCH_ESTIMATE_SUCCESS,
                payload: {
                    pickup: bookingData.pickup,
                    drop: bookingData.drop,
                    bookLater: bookingData.bookLater,
                    bookingDate: bookingData.bookingDate,
                    carDetails: bookingData.carDetails,
                    driverPrices: driverPrices,
                    estimateDistance: parseFloat(distanceBak).toFixed(2),
                    fareCost: fareCalculation ? parseFloat(fareCalculation.totalCost).toFixed(0) : 0,
                    estimateFare: fareCalculation ? parseFloat(fareCalculation.grandTotal).toFixed(0) : 0,
                    estimateTime: durationBak,
                    convenience_fees: fareCalculation ? parseFloat(fareCalculation.convenience_fees).toFixed(0) : 0,
                    waypoints: [{
                        latitude: dropLat,
                        longitude: dropLng
                    }]
                },
            });

            dispatch({
                type: FETCH_ESTIMATE_FAILED,
                payload: "No Route Found",
            });
        }
    });
}


export const clearEstimate = () => (dispatch) => {
    dispatch({
        type: CLEAR_ESTIMATE,
        payload: null,
    });
}

export const getEstimateV2 = (bookingData) => (dispatch) => {

    dispatch({
        type: FETCH_ESTIMATE,
        payload: bookingData,
    });

    var pickupLat = '';
    var pickupLng = '';
    if (bookingData.pickup && bookingData.pickup.coords) {
        pickupLat = bookingData.pickup.coords.lat;
        pickupLng = bookingData.pickup.coords.lng;
    } else {
        pickupLat = bookingData.pickup.lat;
        pickupLng = bookingData.pickup.lng;
    }


    let waypoints = {
        latitude: pickupLat,
        longitude: pickupLng
    };


    dispatch({
        type: FETCH_ESTIMATE_SUCCESS,
        payload: {
            pickup: bookingData.pickup,
            groupDriver: bookingData.groupDriver,
            carDetails: bookingData.carDetails,
            waypoints: waypoints
        },
    });

}

