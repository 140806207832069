import {GetDistance} from "./GeoFunctions";
import store from "../store/store";
import {ConvertSecondToHms} from "./DateFunctions";

export function congthuc1chieu(distance, time, rateDetails = null, driverRateBase = null) {
    if (distance && distance > 0) {
    } else {
        distance = 0;
    }
    let settings = store.getState().settingsdata.settings;
    var driverPrices = null;
    if (driverRateBase !== null) {
        driverPrices = driverRateBase;
    }
    if (store.getState().pricesdata && driverPrices === null) {
        driverPrices = store.getState().pricesdata.prices;
    }

    let gia_mo_cua = driverPrices && driverPrices.open ? driverPrices.open : (rateDetails && rateDetails.min_fare ? rateDetails.min_fare : 0);
    var price_estimate = gia_mo_cua;
    var basePrice = 0;
    let dis_cal = settings && settings.convert_to_mile ? 1000 : 1;
    let pricePerTime = driverPrices && driverPrices.add ? driverPrices.add : (rateDetails && rateDetails.rate_per_hour ? rateDetails.rate_per_hour : 0);
    let khoangcach = parseFloat(distance);
    if (driverPrices && driverPrices.price1) {
        let price1 = Object.values(driverPrices.price1);
        let firstKm = parseFloat(price1[0].price);
        /*if (khoangcach >= 1) {
            price_estimate = parseFloat(price_estimate) + firstKm;
        }*/
        for (let i = 0; i < price1.length; i++) {
            let from = parseFloat(price1[i].from);
            let to = price1[i].to ? parseFloat(price1[i].to) : null;
            let price = parseFloat(price1[i].price);
            if (khoangcach >= (from * dis_cal)) {
                var distanceCal = khoangcach - (from * dis_cal);
                if (to) {
                    if (distanceCal >= (to * dis_cal - from * dis_cal)) {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * (to * dis_cal - from * dis_cal) / (1 * dis_cal));
                    } else {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * distanceCal / (1 * dis_cal));
                    }
                } else {
                    price_estimate = parseFloat(price_estimate) + parseFloat(price * distanceCal / (1 * dis_cal));
                }

                basePrice = price;
            }
        }

        if (basePrice === 0) {
            basePrice = firstKm;
        }
        /*if (price1 && price1.length > 0 && khoangcach > (price1[price1.length - 1].to * dis_cal)) {
            var distance = khoangcach - (price1[price1.length - 1].to * dis_cal);
            price_estimate = parseFloat(price_estimate) + parseFloat(price1[price1.length - 1].price * distance / (1 * dis_cal));
        }*/
    } else {
        let gia_1km_10 = rateDetails ? parseFloat(rateDetails.rate_per_kilometer) : 0;
        let gia_1km_10_15 = rateDetails ? parseFloat(rateDetails.rate_per_kilometer_10_15) : 0;
        let gia_1km_15_30 = rateDetails ? parseFloat(rateDetails.rate_per_kilometer_15_30) : 0;
        let gia_1km_30_ = rateDetails ? parseFloat(rateDetails.rate_per_kilometer_30) : 0;

        if (khoangcach >= (1 * dis_cal)) {
            var distance_1 = khoangcach - (1 * dis_cal);
            if (distance_1 <= (9 * dis_cal)) {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10 * distance_1 / (1 * dis_cal));
            } else {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10 * (9 * dis_cal) / (1 * dis_cal));
            }
        }

        if (khoangcach > (10 * dis_cal)) {
            var distance_2 = khoangcach - (10 * dis_cal);
            if (distance_2 <= (5 * dis_cal)) {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10_15 * distance_2 / (1 * dis_cal));
            } else {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_10_15 * (5 * dis_cal) / (1 * dis_cal));
            }
            basePrice = gia_1km_10_15;
        }
        if (khoangcach > (15 * dis_cal)) {
            var distance_3 = khoangcach - (15 * dis_cal);
            if (distance_3 <= (15 * dis_cal)) {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_15_30 * distance_3 / (1 * dis_cal));
            } else {
                price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_15_30 * (15 * dis_cal) / (1 * dis_cal));
            }
            basePrice = gia_1km_15_30;
        }
        if (khoangcach > (30 * dis_cal)) {
            var distance_4 = khoangcach - (30 * dis_cal);
            price_estimate = parseFloat(price_estimate) + parseFloat(gia_1km_30_ * distance_4 / (1 * dis_cal));
            basePrice = gia_1km_30_;
        }
    }

    // let baseCalculated = ((parseFloat(rateDetails.rate_per_kilometer) * (parseFloat(distance) / 1000))) + ((parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600)));
    var priceTimeAdditional = 0;
    if (time) {
        let timeArray = ConvertSecondToHms(time).split(":");
        if (timeArray && timeArray.length === 3) {
            let totalMin = parseFloat(timeArray[0]) * 60 + parseFloat(timeArray[1]);
            priceTimeAdditional = totalMin * (parseFloat(pricePerTime) / 60);
        }
    }

    let baseCalculated = parseFloat(price_estimate) + parseFloat(priceTimeAdditional);
    let total = baseCalculated;
    let convenienceFee = 0;
    /*if (rateDetails !== null && rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat') {
        convenienceFee = rateDetails.convenience_fees;
    } else {
        if (rateDetails !== null && rateDetails.convenience_fees) {
            convenienceFee = (total * parseFloat(rateDetails.convenience_fees ? rateDetails.convenience_fees : 0) / 100);
        }
    }*/

    let grand = total;

    return {
        totalCost: total ? parseFloat(total).toFixed(0) : 0,
        grandTotal: grand ? parseFloat(grand).toFixed(0) : 0,
        convenience_fees: convenienceFee ? parseFloat(convenienceFee).toFixed(0) : 0,
        distance: distance,
        basePrice: basePrice
    }
}


/**
 *
 * @param distance : km
 * @param time: second
 * @param rateDetails
 * @param kmquaydau
 * @param driverRateBase
 * @param timeWaiting
 * @returns {{kmQuayDau: number, convenience_fees: (number|number), distance, addTime: (*|number), grandTotal: number, thuebao: (boolean|string|*), price: (*|number), emptyPrice: (*|number), time_phutroi: number, time, totalCost: number, basePrice: number}|null|{convenience_fees: (number|number), distance, grandTotal: number, totalCost: number, basePrice: number}}
 */
export function congthuc2chieu(distance, time, rateDetails = null, driverRateBase = null) {
    if (distance && distance > 0) {
    } else {
        distance = 0;
    }
    let settings = store.getState().settingsdata.settings;
    var driverPrices = null;
    if (driverRateBase !== null) {
        driverPrices = driverRateBase;
    }
    if (store.getState().pricesdata && driverPrices === null) {
        driverPrices = store.getState().pricesdata.prices;
    }

    let gia_mo_cua = 0;
    var price_estimate = gia_mo_cua;
    var basePrice = 0;
    let dis_cal = settings && settings.convert_to_mile ? 1000 : 1;
    let pricePerTime = driverPrices && driverPrices.add ? driverPrices.add : (rateDetails && rateDetails.rate_per_hour ? rateDetails.rate_per_hour : 0);
    let khoangcach = parseFloat(distance);
    if (driverPrices && driverPrices.price2) {
        let price1 = Object.values(driverPrices.price2);

        for (let i = 0; i < price1.length; i++) {
            let km = price1[i].km ? parseFloat(price1[i].km) : null;
            let price = parseFloat(price1[i].unitPrice);
            var distanceCal = khoangcach - (km * dis_cal);
            if (km) {
                if (distanceCal <= 0) {
                    if (price1[i - 1] && price1[i - 1].km) {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * ((km - parseFloat(price1[i - 1].km)) * dis_cal) / (1 * dis_cal));
                    } else {
                        if (price1[i + 1] && price1[i + 1].km) {
                            price_estimate = parseFloat(price_estimate) + parseFloat(price * (km * dis_cal) / (1 * dis_cal));
                        }
                    }
                } else {
                    if (price1[i - 1] && price1[i - 1].km) {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * ((km - parseFloat(price1[i - 1].km)) * dis_cal) / (1 * dis_cal));
                    }
                    if (price1[i - 1] && price1[i - 1].km && ((khoangcach - parseFloat(price1[i - 1].km)) * dis_cal) > 0) {
                        price_estimate = parseFloat(price_estimate) + parseFloat(price * ((khoangcach - parseFloat(price1[i - 1].km)) * dis_cal) / (1 * dis_cal));
                    }
                }
            }
            basePrice = price;
        }
    } else {
        return congthuc1chieu(distance, time, null, driverRateBase);
    }

    var priceTimeAdditional = 0;
    if (time) {
        let timeArray = ConvertSecondToHms(time).split(":");
        if (timeArray && timeArray.length === 3) {
            let totalMin = parseFloat(timeArray[0]) * 60 + parseFloat(timeArray[1]);

            if (totalMin > (khoangcach * 2.5)) {
                priceTimeAdditional = (totalMin - (khoangcach * 2.5)) * (parseFloat(pricePerTime) / 60);
            }
        }
    }

    let baseCalculated = parseFloat(price_estimate) + parseFloat(priceTimeAdditional);
    let total = baseCalculated;
    let convenienceFee = 0;

    let grand = total;

    return {
        totalCost: total ? parseFloat(total).toFixed(0) : 0,
        grandTotal: grand ? parseFloat(grand).toFixed(0) : 0,
        convenience_fees: convenienceFee ? parseFloat(convenienceFee).toFixed(0) : 0,
        distance: distance,
        basePrice: basePrice
    }
}

export function FareCalculator(distance, time, rateDetails, kmquaydau = 0, driverPrices) {
    let result1chieu = congthuc1chieu(distance, time, rateDetails, driverPrices);
    let result2chieu = congthuc2chieu(distance, time, rateDetails, driverPrices);
    if (result2chieu && result2chieu.totalCost) {
        // thue bao 2 chieu
        return {
            totalCost: result2chieu.totalCost,
            grandTotal: result2chieu.grandTotal,
            convenience_fees: result2chieu.convenience_fees,
            typePrice: "2"
        }
    } else {
        return {
            totalCost: result1chieu.totalCost,
            grandTotal: result1chieu.grandTotal,
            convenience_fees: result1chieu.convenience_fees,
            typePrice: "1"
        }
    }
}

export function FareCalculator2(distance, time, rateDetails = null, kmquaydau = 0, timeWaiting = 0, driverRateBase = null) {
    let result1chieu = congthuc1chieu(distance, time, rateDetails, driverRateBase);
    let result2chieu = congthuc2chieu(distance, time, rateDetails, driverRateBase);

    return [{
        totalCost: result1chieu.totalCost,
        grandTotal: result1chieu.grandTotal,
        convenience_fees: result1chieu.convenience_fees,
        typePrice: "1"
    }, {
        totalCost: result2chieu.totalCost,
        grandTotal: result2chieu.grandTotal,
        convenience_fees: result2chieu.convenience_fees,
        typePrice: "2"
    }];
}

export function fastEstimate(pickup, lastLocation, carType, total_diff, total_time, customerState = false, driver_group = null, timeWaiting = 0) {
    var kmQuayDau = 0;

    if (pickup && pickup.lat) {
        kmQuayDau = GetDistance(lastLocation.lat, lastLocation.lng, pickup.lat, pickup.lng);
    } else {
        if (driver_group) {
            kmQuayDau = GetDistance(lastLocation.lat, lastLocation.lng, driver_group.coord.split(",")[0], driver_group.coord.split(",")[1].trim());
        }
    }

    if (customerState) {
        let fareCalculation = FareCalculator2(total_diff, total_time, carType, kmQuayDau, timeWaiting);
        return fareCalculation;
    } else {
        let fareCalculation = FareCalculator2(total_diff, total_time, carType, kmQuayDau, timeWaiting);
        return fareCalculation;
    }
}
