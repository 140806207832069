import React, {useContext} from 'react';
import {api, FirebaseContext} from "common";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function NoteCallCenterComponent(props) {
    const {refApi} = useContext(FirebaseContext);
    const {setNoteCallCenterApi} = refApi;
    return (
        <Autocomplete
            freeSolo
            onChange={(event, value) => {
                if (props.rowData && props.rowData.id && props.rowData.status != 'cancel' && props.rowData.status != 'paid' && props.rowData.status != 'accept') {
                    setNoteCallCenterApi(props.rowData.id, value);
                }
            }
            }
            id={"note_autocompleted" + props.rowData.uniqueid}
            options={props.arrayOptions}
            value={props.rowData.booking_note ? props.rowData.booking_note : (props.rowData.note ? props.rowData.note : "")}
            renderInput={(params) =>
                <TextField
                    {...params}
                    id={"text_" + props.rowData.uniqueid}
                    variant="filled"
                    fullWidth
                    disabled={props.isApp}
                    placeholder={"Chưa có"}
                    onBlur={(e) => {
                        if (props.rowData && props.rowData.id && props.rowData.status != 'cancel' && props.rowData.status != 'paid' && props.rowData.status != 'accept') {
                            setNoteCallCenterApi(props.rowData.id, e.target.value);
                        }
                    }
                    }
                    onEndEditing={(event) => {
                        let result = event.nativeEvent.text;
                        if (props.rowData && props.rowData.id && props.rowData.status != 'cancel' && props.rowData.status != 'paid' && props.rowData.status != 'accept') {
                            setNoteCallCenterApi(props.rowData.id, result);
                        }
                    }}
                />
            }
        />
    )
}
