export const FirebaseConfig = {
    locationId: "us-central",
    apiKey: "AIzaSyA_0wK28YfcjFCOL46tzLKYBV7tu8mSOMU",
    authDomain: "eto-viet.firebaseapp.com",
    databaseURL: "https://eto-viet-default-rtdb.firebaseio.com",
    projectId: "eto-viet",
    storageBucket: "eto-viet.appspot.com",
    messagingSenderId: "807990849271",
    appId: "1:807990849271:web:ec9cb54126406bd17eadf2",
    measurementId: "G-MJTP1HBQNT",
    WebPushCertificate: "BFoVUZ2klp0gfGEyk1UXKmaAfBSos7xi8u2az_a0u_PIvSVHSBcv2x6Vy9XONjgZv3dsn4uETDburKBpMXVcZFw"
};