import {FETCH_TRADE, FETCH_TRADE_FAILED, FETCH_TRADE_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    trade: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const tradesreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TRADE:
            return {
                ...state,
                loading: true
            };
        case FETCH_TRADE_SUCCESS:
            return {
                ...state,
                trade: action.payload.trade,
                loading: false
            };
        case FETCH_TRADE_FAILED:
            return {
                ...state,
                trade: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        default:
            return state;
    }
};
