import momentTZ from 'moment-timezone';
import moment from 'moment';
import 'moment/locale/vi';
import {timeZone} from "config";

moment.locale("vi");
momentTZ.tz.setDefault(timeZone);
momentTZ.defineLocale("vi", moment.localeData()._config); // copy locale to moment-timezone
momentTZ.locale("vi"); // apply it to moment-timezone

export const MinutesPassed = (date) => {
    const date1 = new Date();
    const date2 = new Date(date);
    const diffTime = date2 - date1;
    return diffTime / (1000 * 60);
}

export const GetDateString = (date) => {
    let d = null;
    d = date ? new Date(date) : new Date();

    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        mins = d.getMinutes();
    if (month >= 1 & month <= 9)
        month = '0' + month.toString();
    if (day >= 0 & day <= 9)
        day = '0' + day.toString();
    if (hours >= 0 & hours <= 9)
        hours = '0' + hours.toString();
    if (mins >= 0 & mins <= 9)
        mins = '0' + mins.toString();

    return [year, month, day].join('-') + 'T' + [hours, mins].join(':');
}


export const GetDateString00 = (date) => {
    let d = null;
    d = date ? new Date(date) : new Date();

    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        mins = d.getMinutes();
    if (month >= 1 & month <= 9)
        month = '0' + month.toString();
    if (day >= 0 & day <= 9)
        day = '0' + day.toString();
    if (hours >= 0 & hours <= 9)
        hours = '0' + hours.toString();
    if (mins >= 0 & mins <= 9)
        mins = '0' + mins.toString();

    return [year, month, day].join('-') + 'T' + ['00', '00'].join(':');
}


export const ConvertSecondToHms = (d, removeSecon = false) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    if (removeSecon == false) {
        var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "00:";
        var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "00:";
        var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "00";
        return hDisplay + mDisplay + sDisplay;
    } else {
        var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "00:";
        var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : "00";
        return hDisplay + mDisplay;
    }


}
export const ConvertSecondToHm2 = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "00:";
    var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "00:";
    var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "00";
    return hDisplay + mDisplay;
}

export const ConvertSecondToHm = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "(giờ)" : "(giờ)") : "00(giờ)";
    var mDisplay = m > 0 ? m + (m == 1 ? "(phút)" : "(phút)") : "00(phút)";
    return hDisplay + mDisplay;
}

export const ConvertSecondToHmShort = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "(giờ)" : "(giờ)") : "00(giờ)";
    var mDisplay = m > 0 ? m + (m == 1 ? "(phút)" : "(phút)") : "00(phút)";
    return hDisplay + mDisplay;
}

export const ConvertSecondToHmToBooking = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " giờ " : " giờ ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " phút " : " phút ") : "";
    return hDisplay + mDisplay;
}

export const ConvertHmsToSeconds = (hmi) => {
    let hmiarray = hmi.split(":");
    return (hmiarray[0] * 3600 + hmiarray[1] * 60 + hmi[2]);
}

export const generateTripCode = (length) => {
    if (length > 2) {
        moment.locale("vi");
        let currentTime = parseInt(moment().format("X")).toString();
        var result = '';
        var characters = '0123456789abcdefghijklmnopqzxtr';
        var charactersLength = characters.length;
        for (var i = 0; i < length - 2; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result + currentTime.substr(currentTime.length - 2);
    } else {
        moment.locale("vi");
        let currentTime = parseInt(moment().format("X")).toString();
        var result = '';
        var characters = '0123456789abcdefghijklmnopqzxtr';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

}

export const ConvertTimeStampToDate = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format('LLL');
}


export const ConvertTimeStampToDateRp = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format('DD/MM/YYYY');
}

export const ConvertTimeStampToDateM = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format("dddd, MMMM Do YYYY, h:mm:ss");
}

export const ConvertTimeStampPaUnique = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format("YYYYMMDD-hhmmss");
}

export const ConvertTimeStampToDateMinisecond = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format("dddd, MMMM Do YYYY, h:mm:ss SSS");
}

export const GetCurrentMiniTimeStamp = () => {
    moment.locale("vi");
    return parseInt(moment().format("x"));
}

export const GetCurrentTimeStamp = () => {
    moment.locale("vi");
    return parseInt(moment().format("X"));
}

export const GetCurrentDateCaTrucDem = () => {
    var beginningTime = moment('6:30am', 'h:mma');
    var endTime = moment(moment().hours() + ':' + moment().minutes() + 'am', 'h:mma');

    if (endTime.isBefore(beginningTime)) {
        return moment().subtract(1, 'days').format("DD_M_YYYY")
    }
    return moment().format("DD_M_YYYY");
}

export const GetStartCurrentTimeInDay = () => {
    let formatedDate = moment().format("YYYY-MM-DD 00:00:01");
    const d2 = new Date(formatedDate);
    return new Date(d2.toLocaleString('vn-VN', {
        hour12: false,
    })).getTime();
}

export const GetCurrentDateReport = () => {
    var beginningTime = moment('00:01am', 'h:mma');
    var endTime = moment(moment().hours() + ':' + moment().minutes() + 'am', 'h:mma');

    if (endTime.isBefore(beginningTime)) {
        return moment().subtract(1, 'days').format("DD_M_YYYY")
    }
    return moment().format("DD_M_YYYY");
}


export const GetCurrentDateCaTrucDem2 = () => {
    return moment().format("DD/M/YYYY");
}

export const GetCurrentDate = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format('LL');
}
export const ConvertTimeStampTime = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format("h:mm:ss");
}
export const ConvertTimeStampTimeForPa = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format("YYYY-MM-DD HH:mm:ss");
}

export const ConvertTimeStampTimeForPaShort = (timestamp) => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    var t = new Date(timestamp);
    return moment(t).format("HH:mm DD/MM/YYYY");
}

export const GetYesterdayDatePicker = (formater = 'X', subday = 1) => {
    moment.locale("vi");
    return moment(moment(moment().subtract(subday, 'day').unix() * 1000).startOf('day').unix() * 1000).format(formater);
}

export const GetCurrentDatePicker = (formater = 'X') => {
    moment.locale("vi");
    return moment().format(formater);
}

export const GetEndDatePicker = (formater = 'X', timestamp = null) => {
    moment.locale("vi");
    return moment(moment(moment().unix(timestamp) * 1000).endOf('day').unix() * 1000).format(formater);
}

export const GetStartDatePicker = (formater = 'X', timestamp = null) => {
    moment.locale("vi");
    return moment(moment(moment().unix(timestamp) * 1000).startOf('day').unix() * 1000).format(formater);
}
export const ConvertStringToDate = (string, formater = 'X') => {
    moment.locale("vi");
    return moment(string).format(formater);
}

export const ConvertTimeStampToDateWidthFormat = (timestamp, formater = 'X') => {
    moment.locale("vi");
    if (typeof timestamp == 'string') {
        timestamp = parseInt(timestamp);
    }
    return moment.unix(timestamp).format(formater);
}