import {
    FETCH_REPORT_DRIVERS,
    FETCH_REPORT_DRIVERS_SUCCESS,
    FETCH_REPORT_DRIVERS_FAILED,
    EDIT_REPORT_DRIVER, STOP_FETCH_REPORT_DRIVERS
} from "../store/types";

const INITIAL_STATE = {
    reports: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const reportdriverreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_REPORT_DRIVERS:
            return {
                ...state,
                loading: true
            };
        case FETCH_REPORT_DRIVERS_SUCCESS:
            return {
                ...state,
                reports: action.payload,
                loading: false
            };
        case FETCH_REPORT_DRIVERS_FAILED:
            return {
                ...state,
                reports: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case STOP_FETCH_REPORT_DRIVERS:
            return {
                ...state,
                loading: false,
            };
        case EDIT_REPORT_DRIVER:
            return state;
        default:
            return state;
    }
};
