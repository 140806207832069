import React, {useEffect, useMemo, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {geocodeByPlaceId} from 'react-places-autocomplete';
import {Google_Map_Key, language} from 'config';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    googleListAddress: {
        position: 'relative',
        zIndex: 9,
        elevation: 9
    }
}));

export default function GoogleMapsAutoComplete(props) {
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    if (typeof window !== 'undefined' && !loaded.current && !window.google) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=' + Google_Map_Key + '&libraries=geometry,drawing,places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        let active = true;
        if (value && value.label) {

        } else {
            if (!autocompleteService.current && window.google) {
                autocompleteService.current = new window.google.maps.places.AutocompleteService();
            }
            if (!autocompleteService.current) {
                return undefined;
            }

            if (inputValue === '') {
                setOptions(value ? [value] : []);
                return undefined;
            }

            fetch({input: inputValue}, (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                }
            });
        }
        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    useEffect(() => {
        if (props.loadOptions && options) {
            props.loadOptions(options);
        }
    }, [options]);

    const renderOption = (option) => {
        if (props.hasNotRenderOption) {
            return null
        } else {
            if (!inputValue || (value && value.label)) {
                if (props.listGroup) {
                    return (
                        <Grid container alignItems="center" className={classes.googleListAddress}>
                            <Grid item>
                                <LocationOnIcon className={classes.icon}/>
                            </Grid>
                            <Grid item xs className={classes.googleListAddress}>
                               <span style={{fontWeight: 700}}>
                              {option.label}
                            </span>

                                <Typography variant="body2" color="textSecondary">
                                    {""}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
            } else {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );
                return (
                    <Grid container alignItems="center" className={classes.googleListAddress}>
                        <Grid item>
                            <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs className={classes.googleListAddress}>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                  {part.text}
                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }

        }
    }

    return (
        <Autocomplete
            style={props.style}
            getOptionLabel={(option) => (props.value && !option.label ? (typeof option === 'string' ? option : option.description) : (option.label ? option.label : ''))}
            getOptionSelected={(option, value) => option.label && value.label ? option.label === value.label : option.place_id === value.place_id}
            filterOptions={(x) => x}
            options={props.hasNotRenderOption ? [] : (((!inputValue) || (value && value.label) || (props.value && props.value.label)) ? props.listGroup : options)}
            autoComplete
            freeSolo={props.loadOptions ? true : false}
            includeInputInList
            filterSelectedOptions
            value={props.value}
            onChange={(event, newValue) => {
                if (newValue && newValue.label) {
                    setValue(newValue);
                    props.onChange(newValue);
                } else {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    if (newValue && newValue.place_id) {
                        geocodeByPlaceId(newValue.place_id)
                            .then(results => {
                                if (results.length > 0) {
                                    newValue.coords = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                    newValue.placeDetails = results[0];
                                }
                                props.onChange(newValue);
                            })
                            .catch(error => alert(language.google_places_error));
                    } else {
                        props.onChange(newValue);
                    }
                }

            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.placeholder}
                    variant={props.variant}
                    fullWidth
                />
            )}
            renderOption={props.hasNotRenderOption ? null : renderOption}
        />
    );
}