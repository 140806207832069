import {EDIT_GROUP_COORD, FETCH_GROUP_COORDS, FETCH_GROUP_COORDS_FAILED, FETCH_GROUP_COORDS_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {onValue, set} from "firebase/database";
import {getDownloadURL} from "firebase/storage";
import {uploadBytesResumable} from "@firebase/storage";

export const fetchGroupCoords = (coord_id) => (dispatch) => {
    const {
        groupCoordRef
    } = firebase;

    dispatch({
        type: FETCH_GROUP_COORDS,
        payload: null,
    });
    onValue(groupCoordRef(coord_id), snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_GROUP_COORDS_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_GROUP_COORDS_FAILED,
                payload: "Không tìm thấy tọa độ điểm",
            });
        }
    });
};

export const editGroupCoord = (coords, groupId, imageCoord, id, method) => (dispatch) => {
    const {
        groupCoordRef,
        coordImageRef
    } = firebase;

    if (method == "Delete" && groupId) {
        set(groupCoordRef(groupId), coords);
        dispatch({
            type: EDIT_GROUP_COORD,
            progress: null,
            payload: method
        });
    } else {
        if (groupId) {
            if (imageCoord) {
                let createDate = new Date();
                let timestamp = createDate.getTime();
                var uploadTask = uploadBytesResumable(coordImageRef(timestamp, groupId), imageCoord);
                uploadTask.on("state_changed",
                    (snapshot) => {
                        var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100;
                    }, (error) => {
                        throw error
                    }, () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            if (method === "Add") {
                                coords[coords.length - 1].image = url;
                            }
                            if (method === "Update") {
                                coords[id].image = url;
                            }
                            set(groupCoordRef(groupId), coords);
                            dispatch({
                                type: EDIT_GROUP_COORD,
                                payload: method
                            });
                        });
                    }
                )

            } else {
                set(groupCoordRef(groupId), coords);
                dispatch({
                    type: EDIT_GROUP_COORD,
                    payload: method
                });
            }
        } else {
            dispatch({
                type: FETCH_GROUP_COORDS_FAILED,
                payload: "Không tìm thấy tọa độ điểm",
            });
        }
    }
}


