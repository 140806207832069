import {EDIT_NEWS, FETCH_NEWS, FETCH_NEWS_FAILED, FETCH_NEWS_SUCCESS, PUSH_NEWS} from "../store/types";
import {language} from 'config';
import {GetCurrentMiniTimeStamp} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {limitToLast, onValue, push, query, update} from "firebase/database";

export const fetchNews = () => (dispatch) => {
    const {
        auth,
        newsByIdAddByRef
    } = firebase;

    dispatch({
        type: FETCH_NEWS,
        payload: null
    });
    if (auth && auth.currentUser && auth.currentUser.uid) {
        onValue(query(newsByIdAddByRef(auth.currentUser.uid), limitToLast(500)), snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const arr = Object.keys(data).map(i => {
                    data[i].id = i
                    return data[i]
                });
                const newData = arr.sort((a, b) => (a.date_add < b.date_add) ? 1 : -1);
                dispatch({
                    type: FETCH_NEWS_SUCCESS,
                    payload: newData
                });
            } else {
                dispatch({
                    type: FETCH_NEWS_FAILED,
                    payload: language.no_force_notify
                });
            }
        }, {onlyOnce: true});
    } else {
        dispatch({
            type: FETCH_NEWS_FAILED,
            payload: language.no_force_notify
        });
    }

};

export const pushNews = (data) => (dispatch) => {
    const {
        auth,
        newsAllRef
    } = firebase;

    dispatch({
        type: PUSH_NEWS,
        payload: null
    });
    var user = auth.currentUser;
    let dataPush = {
        date_add: GetCurrentMiniTimeStamp(),
        title: data.title ? data.title : null,
        content: data.content ? data.content : null,
        type: data.type ? data.type : 'warning',
        add_by: data.add_by ? data.add_by : user.uid,
        status: data.status ? data.status : 'pending'
    }
    push(newsAllRef, dataPush).then((res) => {
        let key = res.key;
    });
};

export const editNews = (id, data) => (dispatch) => {
    const {
        newsByIdRef
    } = firebase;

    dispatch({
        type: EDIT_NEWS,
        payload: null
    });
    update(newsByIdRef(id), data);
};

