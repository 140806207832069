import {
    FETCH_PROMO_LOCATIONS,
    FETCH_PROMO_LOCATIONS_SUCCESS,
    FETCH_PROMO_LOCATIONS_FAILED,
    EDIT_PROMO_LOCATION,
} from "../store/types";

const INITIAL_STATE = {
    locations: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const promolocationreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PROMO_LOCATIONS:
            return {
                ...state,
                loading: true
            };
        case FETCH_PROMO_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.payload,
                loading: false
            };
        case FETCH_PROMO_LOCATIONS_FAILED:
            return {
                ...state,
                locations: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case EDIT_PROMO_LOCATION:
            return state;
        default:
            return state;
    }
};