import {FETCH_AREA_WALLET, FETCH_AREA_WALLET_FAILED, FETCH_AREA_WALLET_SUCCESS, FETCH_ETO_WALLET, FETCH_ETO_WALLET_FAILED, FETCH_ETO_WALLET_SUCCESS, INIT_AREA_WALLET, INIT_AREA_WALLET_FAILED, INIT_AREA_WALLET_SUCCESS, INIT_ETO_WALLET, INIT_ETO_WALLET_FAILED, INIT_ETO_WALLET_SUCCESS, PUSH_AREA_WALLET, PUSH_AREA_WALLET_FAILED, PUSH_AREA_WALLET_SUCCESS, PUSH_ETO_WALLET, PUSH_ETO_WALLET_FAILED, PUSH_ETO_WALLET_SUCCESS, PUSH_POINT, PUSH_POINT_FAILED, PUSH_POINT_SUCCESS, STOP_FETCH_ETO_WALLET} from "../store/types";

const INITIAL_STATE = {
    walletArea: null,
    etoWallet: null,
    loading: false,
    transaction: null,
    error: {
        flag: false,
        msg: null
    }
}

export const walletsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_AREA_WALLET:
            return {
                ...state,
                loading: true
            };
        case FETCH_AREA_WALLET_SUCCESS:
            return {
                ...state,
                walletArea: action.payload.areaWallet,
                loading: false
            };
        case FETCH_AREA_WALLET_FAILED:
            return {
                ...state,
                walletArea: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case STOP_FETCH_ETO_WALLET:
            return {
                ...state,
                walletArea: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case INIT_AREA_WALLET:
            return {
                ...state,
                loading: true
            };

        case INIT_AREA_WALLET_SUCCESS:
            return {
                ...state,
                walletArea: action.payload.walletArea,
                loading: false
            };

        case INIT_AREA_WALLET_FAILED:
            return {
                ...state,
                walletArea: action.payload,
                loading: false
            };

        case PUSH_AREA_WALLET:
            return {
                ...state,
                loading: true
            };
        case PUSH_AREA_WALLET_SUCCESS:
            return {
                ...state,
                transaction: action.payload.transaction,
                loading: false
            };
        case PUSH_AREA_WALLET_FAILED:
            return {
                ...state,
                transaction: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };


        case FETCH_ETO_WALLET:
            return {
                ...state,
                loading: true
            };
        case FETCH_ETO_WALLET_SUCCESS:
            return {
                ...state,
                etoWallet: action.payload.etoWallet,
                loading: false
            };
        case FETCH_ETO_WALLET_FAILED:
            return {
                ...state,
                etoWallet: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case INIT_ETO_WALLET:
            return {
                ...state,
                loading: true
            };

        case INIT_ETO_WALLET_SUCCESS:
            return {
                ...state,
                etoWallet: action.payload.etoWallet,
                loading: false
            };

        case INIT_ETO_WALLET_FAILED:
            return {
                ...state,
                etoWallet: action.payload,
                loading: false
            };

        case PUSH_ETO_WALLET:
            return {
                ...state,
                loading: true
            };
        case PUSH_ETO_WALLET_SUCCESS:
            return {
                ...state,
                transaction: action.payload.transaction,
                loading: false
            };
        case PUSH_ETO_WALLET_FAILED:
            return {
                ...state,
                transaction: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };


        default:
            return state;
    }
};
