import {EDIT_GIAOXE, FETCH_GIAOXE, FETCH_GIAOXE_BIENSO, FETCH_GIAOXE_BIENSO_FAILED, FETCH_GIAOXE_BIENSO_SUCCESS, FETCH_GIAOXE_FAILED, FETCH_GIAOXE_SUCCESS, PUSH_GIAOXE, STOP_FETCH_GIAOXE} from "../store/types";

import {language} from 'config';
import {GetCurrentMiniTimeStamp} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {limitToLast, off, onValue, push, query, update} from "firebase/database";

export const fetchGiaoXe = () => (dispatch) => {
    const {
        giaoxeAllRef
    } = firebase;

    dispatch({
        type: FETCH_GIAOXE,
        payload: null
    });

    onValue(giaoxeAllRef, snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_GIAOXE_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_GIAOXE_FAILED,
                payload: language.no_giao_xe
            });
        }
    });
};

export const stopFectchGiaoXe = () => (dispatch) => {
    const {
        giaoxeAllRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_GIAOXE,
        payload: null
    });
    off(giaoxeAllRef);
}

export const fetchGiaoXeByBienSo = (bienso) => (dispatch) => {
    const {
        giaoxeByBienSoRef
    } = firebase;

    dispatch({
        type: FETCH_GIAOXE_BIENSO,
        payload: null
    });

    onValue(query(giaoxeByBienSoRef(bienso), limitToLast(1)), snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_GIAOXE_BIENSO_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_GIAOXE_BIENSO_FAILED,
                payload: language.no_giao_xe
            });
        }
    });
};

export const stopFetchGiaoXeByBienSo = (bienso) => (dispatch) => {
    const {
        giaoxeByBienSoRef
    } = firebase;

    dispatch({
        type: FETCH_GIAOXE_BIENSO,
        payload: null
    });

    off(query(giaoxeByBienSoRef(bienso), limitToLast(1)));
};

export const pushGiaoXe = (data) => (dispatch) => {
    const {
        giaoxeAllRef
    } = firebase;

    dispatch({
        type: PUSH_GIAOXE,
        payload: null
    });
    let dataPush = {
        date_add: GetCurrentMiniTimeStamp(),
        content: data.content ? data.content : null,
        bienso: data.bienso ? data.bienso : null,
        lx_giao: data.lx_giao ? data.lx_giao : null,
        message_giao: data.message_giao ? data.message_giao : null,
        status: 'pending'
    }
    push(giaoxeAllRef, dataPush).then((res) => {
        let key = res.key;
    });
};
export const editGiaoXe = (id, data) => (dispatch) => {
    const {
        giaoxeByIdRef
    } = firebase;

    dispatch({
        type: EDIT_GIAOXE,
        payload: null
    });
    update(giaoxeByIdRef(id), data);
};

