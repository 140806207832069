import React, {useContext, useEffect, useState} from 'react';
import {Alert, SafeAreaView, StyleSheet, Text, TouchableOpacity, View} from "react-native-web";
import {colors} from '../../config/theme';
import {useDispatch, useSelector} from 'react-redux';
import {api, FirebaseContext} from 'common';
import {formatNumber} from "react-native-currency-input";
import {formatPriceRound} from "common/src/other/CommonFunctions";
import {language} from "config";

import {AppBar, Tab, Tabs} from "@material-ui/core";
import {FlatList, ScrollView} from "react-native";
import TextField from "@material-ui/core/TextField";
import {onValue} from "firebase/database";
/*import * as PropTypes from "prop-types";*/
/*function SegmentedControl(props) {
    return null;
}

SegmentedControl.propTypes = {
    onChange: PropTypes.func,
    activeFontStyle: PropTypes.shape({color: PropTypes.string, fontWeight: PropTypes.string}),
    values: PropTypes.any,
    style: PropTypes.shape({backgroundColor: PropTypes.string, color: PropTypes.string, height: PropTypes.number}),
    fontStyle: PropTypes.shape({color: PropTypes.string}),
    selectedIndex: PropTypes.number
};*/

export default function EditPriceDriver(props) {
    const {refApi, setGroupdriverByIdRef} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [selectIndex, setSelectIndex] = useState(0);
    const [open, setOpen] = useState(null);
    const [add1Min, setAdd1Min] = useState(null);
    const [allCarTypes, setAllCarTypes] = useState([]);
    const cars = useSelector(state => state.cartypes.cars);
    const [price1Array, setPrice1Array] = useState([{from: 0.1, to: null}]);
    const [price1String, setPrice1String] = useState(null);
    const [price2Array, setPrice2Array] = useState([{km: 20, time: 20 * 2.5}, {km: "", time: ""}]);
    const [price2String, setPrice2String] = useState(null);
    const settingsdata = useSelector(state => state.settingsdata.settings)
    const [listCarType, setListCarType] = useState(null);
    const [carTypeSelect, setCarTypeSelect] = useState(null);
    const [selectIndexCarType, setSelectIndexCarType] = useState(0);
    const areas = useSelector(state => state.areasdata.areas);
    const {dataGroup} = props;
    const [pricesdata, setPricesdata] = useState(null);
    const [areasInGroup, setAreasInGroup] = useState([]);

    useEffect(() => {
        if (cars) {
            let carWiseArr = [];
            for (let i = 0; i < cars.length; i++) {
                let temp = {...cars[i]};
                if (temp.type === 'taxi') {
                    carWiseArr.push(temp);
                }
            }
            setAllCarTypes(carWiseArr);
        }
    }, []);

    useEffect(() => {
        if (dataGroup && dataGroup.area_ids) {
            let areasId = dataGroup.area_ids;
            if (areas) {
                let listArea = [];
                for (let i = 0; i <= areasId.length; i++) {
                    let areaPrice = areas.filter((a) => a.id == areasId[i])[0];
                    if (areaPrice) {
                        listArea.push(areaPrice);
                    }
                }
                setAreasInGroup(listArea);
            } else {
                setAreasInGroup([]);
            }
        } else {
            setAreasInGroup([]);
        }
        if (dataGroup && dataGroup.prices) {
            setPricesdata(dataGroup.prices);
        }
    }, [dataGroup, areas]);

    useEffect(() => {
        if (cars) {
            let carWiseArr = [];
            let listCar = [];
            for (let i = 0; i < cars.length; i++) {
                let temp = {...cars[i]};
                if (temp.type === 'taxi') {
                    carWiseArr.push(temp);
                    listCar.push(temp.name);
                }
            }
            setListCarType(listCar);
            setAllCarTypes(carWiseArr);
            setCarTypeSelect(carWiseArr[selectIndexCarType]);
        }
    }, [cars]);

    useEffect(() => {
        if (selectIndexCarType >= 0 && allCarTypes) {
            setCarTypeSelect(allCarTypes[selectIndexCarType]);
        }
    }, [selectIndexCarType, allCarTypes]);

    useEffect(() => {
        if (carTypeSelect && pricesdata && pricesdata[carTypeSelect.code] && pricesdata[carTypeSelect.code].price1) {
            let object1Key = Object.keys(pricesdata[carTypeSelect.code].price1);
            setPrice1Array(object1Key.map((i) => {
                pricesdata[carTypeSelect.code].price1[i].id = i;
                return pricesdata[carTypeSelect.code].price1[i];
            }).sort((a, b) => a.from && b.from && a.from > b.from ? 1 : -1));
        } else {
            setPrice1Array([{from: 0.1, to: null}]);
        }
        if (carTypeSelect && pricesdata && pricesdata[carTypeSelect.code] && pricesdata[carTypeSelect.code].price2) {
            let object2Key = Object.keys(pricesdata[carTypeSelect.code].price2);
            let price2ArrayPrepare = object2Key.map((i) => {
                pricesdata[carTypeSelect.code].price2[i].id = i;
                return pricesdata[carTypeSelect.code].price2[i];
            }).filter((item) => item.km && parseInt(item.km) > 0)
                .sort((a, b) => a.km && b.km && parseInt(a.km) > parseInt(b.km) > 0 ? 1 : -1);

            let price2ArrayPrepareEmpty = object2Key.map((i) => {
                pricesdata[carTypeSelect.code].price2[i].id = i;
                return pricesdata[carTypeSelect.code].price2[i];
            }).filter((item) => item.km == '');

            setPrice2Array(price2ArrayPrepare.concat(price2ArrayPrepareEmpty));
        } else {
            setPrice2Array([{km: 20, time: 20 * 2.5}, {km: "", time: ""}]);
        }
        if (carTypeSelect && pricesdata && pricesdata[carTypeSelect.code] && pricesdata[carTypeSelect.code].open) {
            setOpen(pricesdata[carTypeSelect.code].open);
        } else {
            setOpen(null);
        }
        if (carTypeSelect && pricesdata && pricesdata[carTypeSelect.code] && pricesdata[carTypeSelect.code].add) {
            setAdd1Min(pricesdata[carTypeSelect.code].add);
        } else {
            setAdd1Min(null);
        }

    }, [pricesdata, carTypeSelect]);

    const formatPrice = (value) => {
        if (value && value != undefined) {
            return formatPriceRound(value, formatNumber);
        }
    }

    const valid1Chieu = () => {
        let minPriceValid = carTypeSelect && carTypeSelect.code && carTypeSelect.code == 'taxi' ? parseFloat(settingsdata.minPrice1) : parseFloat(settingsdata.min7Price1);
        let maxPriceValid = carTypeSelect && carTypeSelect.code && carTypeSelect.code == 'taxi' ? parseFloat(settingsdata.maxPrice1) : parseFloat(settingsdata.max7Price1);
        let newPrice1Array = price1Array;
        var hasValid = true;
        for (let i = 0; i < newPrice1Array.length; i++) {
            var validPrice = true;
            if (newPrice1Array[i].price) {
                if (settingsdata && settingsdata.minPrice1 && parseFloat(newPrice1Array[i].price) < minPriceValid) {
                    newPrice1Array[i].message = "Giá phải > " + formatPrice(minPriceValid) + ' (đ)';
                    hasValid = false;
                    validPrice = false;
                }
                if (settingsdata && settingsdata.maxPrice1 && parseFloat(newPrice1Array[i].price) > maxPriceValid) {
                    newPrice1Array[i].message = "Giá phải < " + formatPrice(maxPriceValid) + ' (đ)';
                    hasValid = false;
                    validPrice = false;
                }
            } else {
                newPrice1Array[i].message = "Chưa nhập giá";
                hasValid = false;
                validPrice = false;
            }
            if (validPrice) {
                newPrice1Array[i].message = null;
            }
        }
        setPrice1Array(newPrice1Array);
        setPrice1String(JSON.stringify(newPrice1Array));
        return hasValid;
    }


    const valid2Chieu = () => {
        let minPriceValid = carTypeSelect && carTypeSelect.code && carTypeSelect.code == 'taxi' ? parseFloat(settingsdata.minPrice2) : parseFloat(settingsdata.min7Price2);
        let maxPriceValid = carTypeSelect && carTypeSelect.code && carTypeSelect.code == 'taxi' ? parseFloat(settingsdata.maxPrice2) : parseFloat(settingsdata.max7Price2);
        let newPrice2Array = price2Array;
        var hasValid = true;
        var indexRemove = null;
        for (let i = 0; i < newPrice2Array.length; i++) {
            if (newPrice2Array[i].km && parseInt(newPrice2Array[i].km) > 0) {
            } else {
                console.log("newPrice2Array[i].km", newPrice2Array[i].km);
                console.log("index", i);
                indexRemove = i;
            }
            var validPrice = true;
            if (newPrice2Array[i].unitPrice) {
                if (settingsdata && settingsdata.minPrice2 && parseFloat(newPrice2Array[i].unitPrice) < minPriceValid) {
                    newPrice2Array[i].messagePrice = "Giá phải > " + formatPrice(minPriceValid) + ' (đ)';
                    hasValid = false;
                    validPrice = false;
                }
                if (settingsdata && settingsdata.maxPrice1 && parseFloat(newPrice2Array[i].unitPrice) > maxPriceValid) {
                    newPrice2Array[i].messagePrice = "Giá phải < " + formatPrice(maxPriceValid) + ' (đ)';
                    hasValid = false;
                    validPrice = false;
                }
            } else {
                newPrice2Array[i].messagePrice = "Chưa nhập giá";
                hasValid = false;
                validPrice = false;
            }
            if (validPrice) {
                newPrice2Array[i].messagePrice = null;
            }


            let previousItem = newPrice2Array[i - 1];
            let nextItem = newPrice2Array[i + 1];
            if (newPrice2Array[i].km) {
                if (previousItem) {
                    if (newPrice2Array[i].km <= previousItem.km) {
                        newPrice2Array[i].messageKm = "Km phải > " + previousItem.km;
                    } else {
                        newPrice2Array[i].messageKm = "";
                    }
                } else {
                    if (newPrice2Array[i].km < 20) {
                        newPrice2Array[i].messageKm = "Km phải > " + 20;
                    } else {
                        newPrice2Array[i].messageKm = "";
                    }
                }
            } else {
                if (nextItem) {
                    newPrice2Array[i].messageKm = "Chưa nhập Km";
                } else {
                }
            }

            if (newPrice2Array[i].time) {
                if (newPrice2Array[i].km && newPrice2Array[i].time < newPrice2Array[i].km * 2.5) {
                    newPrice2Array[i].messageTime = "Phút phải > " + newPrice2Array[i].km * 2.5;
                } else {
                    newPrice2Array[i].messageTime = "";
                }
            } else {
                if (nextItem) {
                    newPrice2Array[i].messageTime = "Chưa nhập phút";
                } else {
                }

            }

            if (newPrice2Array[i].messageKm && newPrice2Array[i].messageKm.length > 0) {
                hasValid = false;
            }

            if (newPrice2Array[i].messageTime && newPrice2Array[i].messageTime.length > 0) {
                hasValid = false;
            }
        }

        console.log("indexRemove", indexRemove);

        if (indexRemove && indexRemove > 0) {
            newPrice2Array.splice(indexRemove, 1);
        }
        /*setPrice2Array(newPrice2Array);
        setPrice2String(JSON.stringify(newPrice2Array));*/
        return hasValid;
    }


    return (
        <View style={styles.main}>
            {carTypeSelect ?
                <SafeAreaView style={styles.container}>
                    <View style={{flexDirection: 'column', padding: 10, backgroundColor: colors.BACKGROUND_DARK}}>
                        <AppBar position="static">
                            <Tabs value={selectIndexCarType} onChange={(e, value) => {
                                setSelectIndexCarType(value);
                            }}>
                                {listCarType.map((key, value) => {
                                    return (
                                        <Tab value={value} label={key}/>
                                    )
                                })}
                            </Tabs>
                        </AppBar>
                    </View>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', padding: 10, backgroundColor: colors.MAIN}}>
                        <Text style={{color: colors.WHITE, fontSize: 18}}>{'Cập nhật bảng giá xe - ' + (carTypeSelect ? carTypeSelect.name : '')}</Text>
                        <View style={{width: 70, flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <TouchableOpacity
                                style={{backgroundColor: colors.WHITE, padding: 5, borderRadius: 5, marginLeft: 10}}
                                onPress={() => {
                                    if (selectIndex == 0) {
                                        if (valid1Chieu()) {
                                            if (dataGroup && carTypeSelect && carTypeSelect.code) {
                                                if (price1Array && price1Array.length > 0) {
                                                    refApi.setPrice1GroupApi(dataGroup.value, carTypeSelect.code, price1Array);
                                                }
                                                if (open) {
                                                    refApi.setPriceOpenGroupApi(dataGroup.value, carTypeSelect.code, open);
                                                }
                                                if (add1Min) {
                                                    refApi.setPriceAddGroupApi(dataGroup.value, carTypeSelect.code, add1Min);
                                                }
                                                if (areasInGroup && areasInGroup.length > 0) {
                                                    setTimeout(() => {
                                                        onValue(setGroupdriverByIdRef(dataGroup.value), (snapshot) => {
                                                            const data = snapshot.val();
                                                            if (data && data.prices) {
                                                                for (let i = 0; i < areasInGroup.length; i++) {
                                                                    if (areasInGroup[i].id) {
                                                                        refApi.setPriceAreaFromGroupApi(areasInGroup[i].id, data.prices);
                                                                    }
                                                                }
                                                            }
                                                        }, {onlyOnce: true});
                                                    }, 2000);
                                                    Alert.alert(language.alert, "Vui lòng đợi trong giây lát để bảng giá cập nhật");
                                                } else {
                                                    Alert.alert(language.alert, "Cập nhật bảng giá thành công");
                                                }
                                            }
                                        } else {
                                            Alert.alert(language.alert, "Có lỗi vui lòng kiểm tra lại cài đặt");
                                        }
                                    } else {
                                        if (valid2Chieu()) {
                                            if (dataGroup && carTypeSelect && carTypeSelect.code) {
                                                if (price2Array && price2Array.length > 0) {
                                                    refApi.setPrice2GroupApi(dataGroup.value, carTypeSelect.code, price2Array);
                                                }
                                                if (open) {
                                                    refApi.setPriceOpenGroupApi(dataGroup.value, carTypeSelect.code, open);
                                                }
                                                if (add1Min) {
                                                    refApi.setPriceAddGroupApi(dataGroup.value, carTypeSelect.code, add1Min);
                                                }
                                                if (areasInGroup && areasInGroup.length > 0) {
                                                    setTimeout(() => {
                                                        onValue(setGroupdriverByIdRef(dataGroup.value), (snapshot) => {
                                                            const data = snapshot.val();
                                                            if (data && data.prices) {
                                                                for (let i = 0; i < areasInGroup.length; i++) {
                                                                    if (areasInGroup[i].id) {
                                                                        refApi.setPriceAreaFromGroupApi(areasInGroup[i].id, data.prices);
                                                                    }
                                                                }
                                                            }
                                                        }, {onlyOnce: true});
                                                    }, 2000);

                                                    Alert.alert(language.alert, "Vui lòng đợi trong giây lát để bảng giá cập nhật");
                                                } else {
                                                    Alert.alert(language.alert, "Cập nhật bảng giá thành công");
                                                }
                                            }
                                        } else {
                                            Alert.alert(language.alert, "Có lỗi vui lòng kiểm tra lại cài đặt");
                                        }
                                    }
                                }}
                            >
                                <button className='button' onClick={() => {
                                }}>
                                    Lưu
                                </button>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={{justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%'}}>
                        <View style={{
                            flexDirection: 'row', flexWrap: "wrap", alignItems: 'center',
                            justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: 'rgb(0,197,255)', width: '100%'
                        }}>
                            <View style={{justifyContent: 'space-between', flexDirection: 'row', flex: 0.5, alignItems: 'center'}}>
                                <TextField
                                    readOnly={false}
                                    id="standard-textarea-giamocua"
                                    label={"Giá mở cửa"}
                                    placeholder={"Giá mở cửa"}
                                    variant="standard"
                                    value={open ? open.toString() : ''}
                                    onChange={(event) => {
                                        setOpen(event.target.value);
                                    }}
                                    //helperText="Giá mở cửa là giá ..."
                                />
                            </View>
                            <View style={{justifyContent: 'space-between', flexDirection: 'row', flex: 0.5, alignItems: 'center'}}>
                                <TextField
                                    readOnly={false}
                                    id="standard-textarea-giaphutroi"
                                    label={"Phụ trội thời gian"}
                                    placeholder={"Phụ trội thời gian"}
                                    variant="standard"
                                    value={add1Min ? add1Min.toString() : ''}
                                    onChange={(event) => {
                                        setAdd1Min(event.target.value);
                                    }}
                                />
                            </View>
                        </View>
                    </View>

                    <AppBar position="static">
                        <Tabs value={selectIndex} onChange={(e, value) => {
                            setSelectIndex(value);
                        }}>
                            <Tab value={0} label={"1 chiều"}/>
                            <Tab value={1} label={"2 chiều"}/>
                        </Tabs>
                    </AppBar>
                    <ScrollView horizontal={false} style={{height: 300}} showsHorizontalScrollIndicator={true}>
                        {selectIndex === 0 ?
                            <FlatList
                                extraData={price1String}
                                data={price1Array}
                                key={"ftl_price1"}
                                ItemSeparatorComponent={
                                    () => <View style={{height: 4}}/>
                                }
                                renderItem={({item, index}) => {
                                    let currentKey = index;
                                    return (
                                        <View behavior="padding" style={{justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', width: '100%'}}>
                                            <View style={{justifyContent: 'center', flexDirection: 'column', flex: 0.3}}>
                                                <TextField
                                                    readOnly={true}
                                                    id="standard-textarea-tukm"
                                                    label={"Từ kilomet"}
                                                    placeholder={"Từ kilomet"}
                                                    variant="standard"
                                                    value={item.from ? item.from.toString() : null}
                                                    onChange={(event) => {
                                                        //setOpen(parseFloat(event.target.value));
                                                    }}
                                                />
                                            </View>
                                            <View style={{justifyContent: 'center', flexDirection: 'column', flex: 0.3}}>
                                                <TextField
                                                    readOnly={false}
                                                    id="standard-textarea-denkm"
                                                    label={'Đến kilomet:'}
                                                    placeholder={"Kết thúc"}
                                                    variant="standard"
                                                    value={item.to ? item.to.toString() : null}
                                                    onChange={(event) => {
                                                        let text = event.target.value;
                                                        if (text && parseInt(text) > item.from) {
                                                            let newPrice1Array = price1Array;
                                                            newPrice1Array[currentKey].to = parseInt(text);
                                                            if (newPrice1Array[index + 1]) {
                                                                newPrice1Array[index + 1].from = parseInt(text);
                                                                if (newPrice1Array[index + 1].to && newPrice1Array[index + 1].to <= parseInt(text)) {
                                                                    newPrice1Array[index + 1].to = null;

                                                                    if (newPrice1Array.length > index + 1) {
                                                                        for (let i = index + 2; i <= newPrice1Array.length; i++) {
                                                                            newPrice1Array.splice(i);
                                                                        }
                                                                    }
                                                                }
                                                                setPrice1Array(newPrice1Array);
                                                                setPrice1String(JSON.stringify(newPrice1Array));
                                                            } else {
                                                                let itemPrice1 = {};
                                                                let newPrice1Array = price1Array;
                                                                itemPrice1.from = parseInt(text);
                                                                itemPrice1.to = null;
                                                                newPrice1Array.push(itemPrice1);
                                                                setPrice1Array(newPrice1Array);
                                                                setPrice1String(JSON.stringify(newPrice1Array));
                                                            }
                                                        } else {
                                                            let newPrice1Array = price1Array;
                                                            newPrice1Array[currentKey].to = text ? parseInt(text) : null;
                                                            if (newPrice1Array[index + 1]) {
                                                                newPrice1Array.splice(index + 1);
                                                            }
                                                            setPrice1Array(newPrice1Array);
                                                            setPrice1String(JSON.stringify(newPrice1Array));
                                                        }
                                                    }}
                                                />
                                            </View>
                                            <View style={{justifyContent: 'center', flexDirection: 'column', flex: 0.3}}>
                                                <TextField
                                                    readOnly={false}
                                                    id="standard-textarea-giakm"
                                                    label={'Giá(đ/km):'}
                                                    placeholder={"Giá(đ/km)"}
                                                    variant="standard"
                                                    value={item.price ? item.price.toString() : ''}
                                                    onChange={(event) => {
                                                        let text = event.target.value;
                                                        let newPrice1Array = price1Array;
                                                        newPrice1Array[currentKey].price = text ? parseFloat(text) : null;
                                                        setPrice1Array(newPrice1Array);
                                                        setPrice1String(JSON.stringify(newPrice1Array));
                                                    }}
                                                    error={item.messagePrice ? true : false}
                                                    helperText={item.message ? item.message : null}
                                                />
                                            </View>
                                        </View>
                                    )
                                }}
                                keyExtractor={(item, index) => index.toString()}
                            /> : null}
                        {selectIndex === 1 ?
                            <FlatList
                                extraData={price2String}
                                data={price2Array}
                                key={"ftl_price2"}
                                ItemSeparatorComponent={
                                    () => <View style={{height: 4}}/>
                                }
                                renderItem={({item, index}) => {
                                    let currentKey = index;
                                    let nextKey = index + 1;
                                    let previousKey = index - 1;
                                    return (
                                        <View behavior="padding" style={{justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', width: '100%'}}>
                                            <View style={{justifyContent: 'center', flexDirection: 'column', flex: 0.4}}>
                                                <TextField
                                                    readOnly={false}
                                                    id="standard-textarea-tongkm2chieuquaydau"
                                                    label={'Tổng km 2 chiều quay đầu:'}
                                                    placeholder={item.km ? item.km.toString() : 'Kết thúc'}
                                                    variant="standard"
                                                    value={item.km ? item.km.toString() : null}
                                                    onChange={(event) => {
                                                        let text = event.target.value;
                                                        let newPrice2Array = price2Array;
                                                        newPrice2Array[currentKey].km = parseInt(text);
                                                        setPrice2Array(newPrice2Array);
                                                        setPrice2String(JSON.stringify(newPrice2Array));
                                                    }}
                                                    onBlur={(event) => {
                                                        let result = event.target.value;

                                                        let newPrice2Array = price2Array;
                                                        let previousItem = price2Array[previousKey];
                                                        if (previousItem) {
                                                            if (result && parseInt(result) > previousItem.km) {
                                                                if (newPrice2Array[nextKey]) {
                                                                    if (newPrice2Array[nextKey].unitPrice) {
                                                                    } else {
                                                                        if (item.unitPrice) {
                                                                            newPrice2Array[nextKey].unitPrice = item.unitPrice;
                                                                        }
                                                                    }
                                                                } else {
                                                                    let itemPrice2 = {};
                                                                    itemPrice2.km = '';
                                                                    itemPrice2.time = '';
                                                                    if (item.unitPrice) {
                                                                        itemPrice2.unitPrice = item.unitPrice;
                                                                    }
                                                                    //newPrice2Array.push(itemPrice2);
                                                                }
                                                            }
                                                            if (result && parseInt(result) <= previousItem.km) {
                                                                newPrice2Array[currentKey].messageKm = "Tổng Km phải > " + previousItem.km;
                                                            } else {
                                                                newPrice2Array[currentKey].messageKm = "";
                                                            }
                                                        } else {
                                                            if (result && parseInt(result) < 20) {
                                                                newPrice2Array[currentKey].messageKm = "Tổng Km phải >= " + 20;
                                                            } else {

                                                                if (newPrice2Array[nextKey]) {
                                                                    if (newPrice2Array[nextKey].unitPrice) {
                                                                    } else {
                                                                        if (item.unitPrice) {
                                                                            newPrice2Array[nextKey].unitPrice = item.unitPrice;
                                                                        }
                                                                    }
                                                                }
                                                                newPrice2Array[currentKey].messageKm = "";
                                                            }
                                                        }

                                                        if (newPrice2Array[nextKey]) {
                                                            if (newPrice2Array[nextKey].km <= parseInt(result) && newPrice2Array[nextKey].km > 0) {
                                                                newPrice2Array[nextKey].messageKm = "Tổng Km phải > " + parseInt(result);
                                                            } else {
                                                                newPrice2Array[nextKey].messageKm = "";
                                                            }
                                                        }

                                                        newPrice2Array[currentKey].time = parseInt(result) * 2.5;
                                                        setPrice2Array(newPrice2Array);
                                                        setPrice2String(JSON.stringify(newPrice2Array));
                                                    }}
                                                    error={item.messagePrice ? true : false}
                                                    helperText={item.messageKm ? "\n" + item.messageKm : null}
                                                />
                                            </View>
                                            <View style={{justifyContent: 'center', flexDirection: 'column', flex: 0.3}}>
                                                <TextField
                                                    readOnly={false}
                                                    id="standard-textarea-gioihanphut"
                                                    label={'Giới hạn phút:'}
                                                    placeholder={item.km ? item.km.toString() : 'Kết thúc'}
                                                    variant="standard"
                                                    value={item.time ? item.time.toString() : ''}
                                                    onChange={(event) => {
                                                        let text = event.target.value;
                                                        let newPrice2Array = price2Array;
                                                        newPrice2Array[currentKey].time = parseInt(text);
                                                        setPrice2Array(newPrice2Array);
                                                        setPrice2String(JSON.stringify(newPrice2Array));
                                                    }}
                                                    onBlur={(event) => {
                                                        let result = event.target.value;
                                                        let newPrice2Array = price2Array;
                                                        if (result && parseInt(result)) {
                                                            if (newPrice2Array[currentKey].km) {
                                                                if (parseInt(result) < newPrice2Array[currentKey].km * 2.5) {
                                                                    newPrice2Array[currentKey].messageTime = 'Phút phải >= ' + newPrice2Array[currentKey].km * 2.5;
                                                                } else {
                                                                    newPrice2Array[currentKey].messageTime = '';
                                                                }
                                                            } else {
                                                                newPrice2Array[currentKey].messageTime = '';
                                                            }
                                                        } else {
                                                            if (newPrice2Array[nextKey]) {
                                                                newPrice2Array[currentKey].messageTime = 'Phút chưa hợp lệ';
                                                            } else {
                                                                newPrice2Array[currentKey].messageTime = '';
                                                            }
                                                        }
                                                        setPrice2Array(newPrice2Array);
                                                        setPrice2String(JSON.stringify(newPrice2Array));
                                                    }}
                                                    error={item.messagePrice ? true : false}
                                                    helperText={item.messageTime ? "\n" + item.messageTime : null}
                                                />
                                            </View>
                                            <View style={{justifyContent: 'center', flexDirection: 'column', flex: 0.3}}>
                                                <TextField
                                                    readOnly={item.km && item.km > 0 ? false : true}
                                                    id="standard-textarea-gia"
                                                    label={'Giá(đ/km):'}
                                                    placeholder={"Giá"}
                                                    variant="standard"
                                                    value={item.unitPrice ? item.unitPrice.toString() : ''}
                                                    onChange={(event) => {
                                                        let text = event.target.value;
                                                        let newPrice2Array = price2Array;
                                                        newPrice2Array[currentKey].unitPrice = parseInt(text);
                                                        setPrice2Array(newPrice2Array);
                                                        setPrice2String(JSON.stringify(newPrice2Array));
                                                    }}
                                                    onBlur={(event) => {
                                                        let result = event.target.value;
                                                        let newPrice2Array = price2Array;

                                                        /*if (result && parseInt(result) < settingsdata.minPrice2) {
                                                            newPrice2Array[currentKey].messagePrice = 'Giá phải > ' + settingsdata.minPrice2;
                                                        } else {
                                                            if (result && parseInt(result) > settingsdata.maxPrice2) {
                                                                newPrice2Array[currentKey].messagePrice = 'Giá phải < ' + settingsdata.maxPrice2;
                                                            } else {
                                                                if (result) {
                                                                    newPrice2Array[currentKey].messagePrice = '';
                                                                } else {
                                                                    newPrice2Array[currentKey].messagePrice = 'Giá chưa hợp lệ';
                                                                }
                                                            }
                                                        }*/

                                                        if (newPrice2Array[nextKey] && newPrice2Array[nextKey].unitPrice && newPrice2Array[nextKey].km && newPrice2Array[nextKey].km != '') {
                                                            if (newPrice2Array[nextKey].unitPrice) {
                                                            } else {
                                                                if (newPrice2Array[currentKey].messagePrice && newPrice2Array[currentKey].messagePrice.length > 0) {
                                                                } else {
                                                                    newPrice2Array[nextKey].unitPrice = parseInt(result);
                                                                }

                                                            }
                                                        } else {
                                                            let itemPrice2 = {};
                                                            itemPrice2.km = '';
                                                            itemPrice2.time = '';

                                                            if (newPrice2Array[currentKey].messagePrice && newPrice2Array[currentKey].messagePrice.length > 0) {
                                                            } else {
                                                                itemPrice2.unitPrice = parseInt(result);
                                                            }
                                                            if (newPrice2Array[currentKey].messagePrice && newPrice2Array[currentKey].messagePrice.length > 0) {
                                                            } else {
                                                                if (newPrice2Array[currentKey].km && newPrice2Array[currentKey].km != '') {
                                                                    newPrice2Array.push(itemPrice2);
                                                                }
                                                            }

                                                        }
                                                        setPrice2Array(newPrice2Array);
                                                        setPrice2String(JSON.stringify(newPrice2Array));
                                                    }}

                                                    helperText={item.messagePrice ? "\n" + item.messagePrice : null}
                                                    error={item.messagePrice ? true : false}
                                                />
                                            </View>
                                        </View>
                                    )
                                }}
                                keyExtractor={(item, index) => index.toString()}
                            /> : null}
                    </ScrollView>

                </SafeAreaView> : null}
        </View>
    );

}


const styles = StyleSheet.create({
    main: {
        flex: 1
    },
    container: {
        flex: 1,
        backgroundColor: colors.WHITE
    },
    compViewStyle3: {
        paddingTop: 10,
        width: '100%',
        flexDirection: 'row',
        flex: 1,
    },
    adjustViewStyle: {
        flexDirection: 'row',
    },
    segmentcontrol: {
        color: colors.BACKGROUND_DARK,
        fontSize: 18,
        fontFamily: "Roboto-Regular",
        marginTop: 0,
        alignSelf: "center",
        height: 50,
        marginLeft: 35,
        marginRight: 35
    },
});