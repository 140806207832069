import React, {useCallback, useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import {formatPriceRound} from "common/src/other/CommonFunctions";
import {Image, Text, View} from "react-native-web";
import {useDispatch, useSelector} from "react-redux";
import {GetCurrentMiniTimeStamp, GetDateString} from "common/src/other/DateFunctions";
import {api, FirebaseContext} from "common";
import {colors} from "../../config/theme";
import {Google_Map_Key, language} from "config";
import Map from "../Map";
import GoogleMapsAutoComplete from "../GoogleMapsAutoComplete";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {geocodeByPlaceId} from "react-places-autocomplete";
import {Checkbox} from "@material-ui/core";
import {congthuc1chieu, congthuc2chieu} from "common/src/other/FareCalculator";
import {formatNumber} from "react-native-currency-input";
import {getRouteDetails} from "common/src/other/GoogleAPIFunctions";
import {GetDistance} from "common/src/other/GeoFunctions";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    fullHeight: {
        height: '100%',
        width: "100%"
    },
    inputColor: {
        color: colors.LINK
    },
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        backgroundColor: colors.MAIN,
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#E8E8E8",
        fontSize: 16,
        padding: '10px 2px 10px 10px',
        color: "#000"
    },
    commonInputStyle: {
        borderRadius: 4,
        backgroundColor: "#E8E8E8",
    },
}));

export default function BookingCallCenter(props) {
    const {
        bookingDetail,
        fetchDriversOutInGroup,
        updateTripPhone,
        updateTripCar,
        updatSelPointType,
        updateTripPickup,
        updateTripDrop,
        fetchOnceDrivers,
        addBookingV2
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const styles = useStyles();
    const [note, setNote] = useState(null);
    const [notes, setNotes] = useState([]);
    const [group, setGroup] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [carType, setCarType] = useState(null);
    const auth = useSelector(state => state.auth);
    const settings = useSelector(state => state.settingsdata.settings);
    const usersdata = useSelector(state => state.usersdata);
    const [objectBooking, setObjectBooking] = useState(null);
    const [driverInGroup, setDriverInGroup] = useState(null);
    const [driverOutGroup, setDriverOutGroup] = useState(null);
    const [driverInArea, setDriverInArea] = useState(null);
    const [selectedDate, setSelectedDate] = useState(GetDateString());
    const [bookingType, setBookingType] = useState('Book Now');
    const [mylocation, setMylocation] = useState(null);
    const [listGroup, setListGroup] = useState([]);
    const [groupApply, setGroupApply] = useState(null);
    const [polygons, setPolygons] = useState(null);
    const [pickupFromMap, setPickupFromMap] = useState(null);
    const [dropFromMap, setDropFromMap] = useState(null);
    const tripdata = useSelector(state => state.tripdata);
    const [listAddressPickup, setListAddressPickup] = useState(null);
    const [listAddressDrop, setListAddressDrop] = useState(null);
    const [prices, setPrices] = useState(null);
    const [checkPrice1, setCheckPrice1] = useState(null);
    const [resultCheckPrice1, setResultCheckPrice1] = useState(null);
    const [isTwoWay, setIsTwoWay] = useState(0);
    const [estimateTemp, setEstimateTemp] = useState(null);
    const [addressInfo, setAddressInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (mylocation == null) {
            setMylocation({
                lat: 20.942202249303275,
                lng: 106.73757433891298
            })
        }
    }, [mylocation]);

    useEffect(() => {
        if (props.cartypes && props.cartypes.length > 0 && carType == null) {
            if (props.callDetail && props.callDetail.carType) {
                setCarType(props.callDetail.carType);
                dispatch(updateTripCar(props.cartypes.filter((c) => c.name == props.callDetail.carType)[0]));
            } else {
                setCarType(props.cartypes[0].name);
                dispatch(updateTripCar(props.cartypes[0]));
            }
        }
        if (props.callDetail && props.callDetail.booking_id) {
            dispatch(bookingDetail(props.callDetail.booking_id));
        }
        if (props.callDetail && props.callDetail.note) {
            setNote(props.callDetail.note);
        }
        if (props.listGroup && props.listGroup[props.listGroup.length - 1] && props.listGroup[props.listGroup.length - 1].value == '-1') {
            let listG = props.listGroup;
            setListGroup(listG);
        } else {
            let listG = props.listGroup;
            listG.push({
                label: "Vùng tự do",
                value: '-1'
            });
            setListGroup(listG);
        }
        if (props.callDetail && props.callDetail.source) {
            dispatch(updateTripPhone(props.callDetail.source.replace("+84", "")));
        }

        resetBooking();
        return (() => {
            resetBooking();
        });
    }, []);

    useEffect(() => {
        if (note) {
            /*async function fetchData() {
                await getAddressMapFromNoteRef(note.replaceAll(" ", "").replaceAll(".", "").replaceAll("$", "").replaceAll("[", "").replaceAll("]", "")).once("value", snapshot => {
                    if (snapshot.val()) {
                        const noteInfoObj = snapshot.val();
                        setAddressInfo(noteInfoObj);
                        setGroup("Vùng tự do");

                        setMylocation({
                            lat: noteInfoObj.lat,
                            lng: noteInfoObj.lng
                        });
                        dispatch(updateTripPickup({
                            lat: noteInfoObj.lat,
                            lng: noteInfoObj.lng,
                            add: noteInfoObj.add,
                            source: "search"
                        }));
                    } else {
                        setAddressInfo(null);
                    }
                });
            }

            fetchData();*/
        } else {
            setAddressInfo(null);
        }
    }, [note]);

    useEffect(() => {
        if (usersdata && usersdata.driverOutInGroup) {
            if (usersdata.driverOutInGroup.driverInGroup) {
                setDriverInGroup(usersdata.driverOutInGroup.driverInGroup);
            }
            if (usersdata.driverOutInGroup.driverOutGroup) {
                setDriverOutGroup(usersdata.driverOutInGroup.driverOutGroup);
            }

            if (usersdata.driverOutInGroup.driverInArea) {
                setDriverInArea(usersdata.driverOutInGroup.driverInArea);
            }
        }
    }, [usersdata]);

    useEffect(() => {
        if (objectBooking) {
            if (driverInGroup) {
                objectBooking.driverInGroup = driverInGroup;
            }
            if (driverOutGroup) {
                objectBooking.driverOutGroup = driverOutGroup;
            }
            if (driverInArea) {
                objectBooking.driverInArea = driverInArea;
            }
        }
    }, [driverInGroup, driverOutGroup, objectBooking]);

    useEffect(() => {
        if (group) {
            if (group != 'Vùng tự do') {
                let groupApplys = props.listGroup.filter((g) => g.label == group);
                if (groupApplys && groupApplys.length > 0) {
                    let listAreaApply = groupApplys[0].area_ids;
                    let listAreas = groupApplys[0].areas;
                    let polygonList = [];
                    if (listAreas && listAreas.length > 0) {
                        for (let i = 0; i < listAreas.length; i++) {
                            if (listAreas[i]) {
                                let coors = Object.values(listAreas[i]);
                                for (let j = 0; j < coors.length; j++) {
                                    if (coors[j][0] && coors[j][1]) {
                                        polygonList.push({
                                            lat: coors[j][1], lng: coors[j][0]
                                        });
                                    }
                                }
                            }
                        }
                        setPolygons(polygonList);
                    }
                    if (listAreaApply) {
                        /*if (areasdata) {
                            for (let i = 0; i < listAreaApply.length; i++) {
                                let areas = areasdata.filter((a) => a.id == listAreaApply[i]);
                                if (areas && areas.length > 0) {
                                    listArea.push(areas[0]);
                                }
                            }
                            setListAreaSelect(listArea);
                        }*/

                        if (groupApplys[0] && groupApplys[0].coord) {
                            setMylocation({
                                lat: parseFloat(groupApplys[0].coord.split(',')[0]),
                                lng: parseFloat(groupApplys[0].coord.split(',')[1])
                            });
                        }

                        setGroupApply(groupApplys[0]);
                        if (groupApplys[0].prices && tripdata.carType.code) {
                            setPrices(groupApplys[0].prices[tripdata.carType.code]);
                        }
                    } else {
                        //setListAreaSelect([]);
                        setGroupApply(null);
                    }
                } else {
                    setPolygons(null);
                    //setListAreaSelect([]);
                    setGroupApply(null);
                }

                dispatch(updateTripPickup(null));
                dispatch(updateTripDrop(null));
                setDropFromMap(null);
                setPickupFromMap(null);
            } else {
                if (props.booking) {
                } else {
                    dispatch(updateTripPickup(null));
                    dispatch(updateTripDrop(null));
                    setDropFromMap(null);
                    setPickupFromMap(null);
                }
                setPolygons(null);
                /*setPolygons(null);
                let polygonList = [];
                for (let k = 0; k < props.listGroup.length; k++) {
                    let listAreas = props.listGroup[k].areas;
                    if (listAreas && listAreas.length > 0) {
                        for (let i = 0; i < listAreas.length; i++) {
                            let coors = listAreas[i];
                            for (let j = 0; j < coors.length; j++) {
                                if (coors[j][0] && coors[j][1]) {
                                    polygonList.push({
                                        lat: coors[j][1], lng: coors[j][0]
                                    });
                                }
                            }
                        }
                    }
                }
                setPolygons(polygonList);*/
            }
        } else {
            dispatch(updateTripPickup(null));
            dispatch(updateTripDrop(null));
            setPolygons(null);
            //setListAreaSelect([]);
            setGroupApply(null);
        }
    }, [group]);

    useEffect(() => {
        var startLoc = null;
        var destLoc = null;
        if (tripdata.pickup && tripdata.pickup.add) {
            setPickupFromMap(tripdata.pickup.add);
            startLoc = tripdata.pickup.lat + ',' + tripdata.pickup.lng;
            if (props.listGroup && props.listGroup.length > 0) {
                let groups = props.listGroup;
                let bankinhCheck = settings && settings.bankinh ? settings.bankinh : 3;
                var kmcheck = 0;
                var coordApplyCheck = null;
                var groupApplyCheck = null;
                groups.map(group => {
                    // Check toa do tam
                    if (group.coord && group.lock_status === false) {
                        let coords = group.group_coords;
                        let toado_tam = group.coord.split(",");
                        if (toado_tam.length > 0) {
                            let kc_tam = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_tam[0].toString().trim()), parseFloat(toado_tam[1].toString().trim()));
                            if (kc_tam < 10) {
                                if (coords) {
                                    coords.map(coord => {
                                        if (coord.coord && coord.coord.split(",").length > 0) {
                                            let toado_con = coord.coord.split(",");
                                            let kc_con = GetDistance(tripdata.pickup.lat, tripdata.pickup.lng, parseFloat(toado_con[0].toString().trim()), parseFloat(toado_con[1].toString().trim()));
                                            let bkc = coord.radius / 1000;
                                            if (kmcheck === 0) {
                                                kmcheck = kc_con;
                                                if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                    //setCoordApply(coord);
                                                    setGroupApply(group);
                                                    coordApplyCheck = coord;
                                                    groupApplyCheck = group;
                                                }
                                            } else {
                                                if (kc_con <= kmcheck) {
                                                    kmcheck = kc_con;
                                                    if (parseFloat(bkc) + parseFloat(bankinhCheck) >= parseFloat(kmcheck)) {
                                                        //setCoordApply(coord);
                                                        setGroupApply(group);
                                                        coordApplyCheck = coord;
                                                        groupApplyCheck = group;
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });

                /*if (coordApplyCheck && groupApplyCheck && bankinhCheck) {
                    dispatch(fetchDriversOutInGroup(bankinhCheck, props.bookingObj.carDetails.name, {lat: props.address.coords.lat, lng: props.address.coords.lng}, groupApplyCheck.value.toString().trim()));
                } else {
                    if (props.address && props.bookingObj && bankinhCheck && groups && groups.length > 0) {
                        var groupKd3Km = groups.filter(gp => parseInt(gp.value) === 11)[0];
                        if (groupKd3Km) {
                            dispatch(fetchDriversOutInGroup(bankinhCheck, props.bookingObj.carDetails.name, {lat: props.address.coords.lat, lng: props.address.coords.lng}, groupKd3Km));
                        }
                    }
                }*/
            }
            setGroupApply(groupApplyCheck);
        } else {
            if (tripdata.drop && tripdata.drop.add) {
                dispatch(updateTripDrop(null));
            }
            if (tripdata.selected && tripdata.selected != 'pickup') {
                dispatch(updatSelPointType('pickup'));
            }
            if (pickupFromMap) {
                setPickupFromMap(null);
            }
        }

        if (tripdata.drop && tripdata.drop.add) {
            setDropFromMap(tripdata.drop.add);
            destLoc = tripdata.drop.lat + ',' + tripdata.drop.lng;
        } else {
            if (dropFromMap) {
                setDropFromMap(null);
            }
        }

        if (startLoc && destLoc && prices) {
            getRouteDetails(startLoc, destLoc).then((res) => {
                if (res && res.distance) {
                    setCheckPrice1(res.distance);
                    var result = null;
                    let text = res.distance;
                    let time = parseFloat(text) / 40 / 60;
                    if (isTwoWay && prices.price2) {
                        result = congthuc2chieu(parseFloat(text) * 2, time, null, 0, prices);
                    } else {
                        if (prices.price1) {
                            result = congthuc1chieu(parseFloat(text), 0, null, prices);
                        }
                    }
                    if (result && result.totalCost) {
                        setResultCheckPrice1(formatPrice(result.totalCost));
                        setEstimateTemp({
                            distance_in_km: parseFloat(text),
                            time_in_secs: time
                        });
                    } else {
                        setEstimateTemp(null);
                    }

                }
            });
        } else {
            setEstimateTemp(null);
        }

        if (groupApply && groupApply.prices && tripdata.carType && tripdata.carType.code) {
            setPrices(groupApply.prices[tripdata.carType.code]);

            if (tripdata && tripdata.pickup && tripdata.pickup.lat) {
                dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, tripdata.pickup, groupApply.value));
            } else {
                if (groupApply.coord) {
                    let coordArray = groupApply.coord.split(',');
                    let pickup = {
                        lat: coordArray[0].toString().trim(),
                        lng: coordArray[1].toString().trim()
                    }
                    dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, pickup, groupApply.value));
                }
            }
        } else {
            if (group && tripdata.carType.code) {
                if (group.coord) {
                    let coordArray = group.coord.split(',');
                    let pickup = {
                        lat: coordArray[0].toString().trim(),
                        lng: coordArray[1].toString().trim()
                    }
                    dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, pickup, group.value));
                }
            }
        }
    }, [tripdata, groupApply]);

    useEffect(() => {
        if (props.booking) {
            if (props.booking.customer_note) {
                setNote(props.booking.customer_note);
            } else {
                if (props.callDetail && props.callDetail.note) {
                    setNote(props.callDetail.note);
                }
            }
            if (props.booking.carType) {
                setCarType(props.booking.carType);
            } else {
                if (props.cartypes && props.cartypes.length > 0 && carType == null) {
                    setCarType(props.cartypes[0].name);
                    dispatch(updateTripCar(props.cartypes[0]));
                }
            }
            if (props.booking.driver_group) {
                setGroupApply(props.booking.driver_group);
                setGroup(props.booking.driver_group.label);
            } else {
                setGroupApply(null);
                setGroup('');
            }
            if (props.booking.is_two_way == true) {
                setIsTwoWay(1);
            } else {
                setIsTwoWay(0);
            }
            if (props.booking.bookLater == true) {
                setBookingType("Book Later");
            } else {
                setBookingType("Book Now");
            }


            dispatch(updateTripPickup(null));
            dispatch(updateTripDrop(null));
            /*if (props.booking.pickup) {
                props.booking.pickup.from = 'map';
                dispatch(updateTripPickup(props.booking.pickup));
                setMylocation({
                    lat: props.booking.pickup.lat,
                    lng: props.booking.pickup.lng
                });
                setGroup("Vùng tự do");
            } else {
                dispatch(updateTripPickup(null));
            }

            if (props.booking.drop) {
                setGroup("Vùng tự do");
                props.booking.drop.from = 'map';
                dispatch(updateTripDrop(props.booking.drop));
            } else {
                dispatch(updateTripDrop(null));
            }*/

        } else {
            resetBooking();
        }

    }, [props.booking])

    useEffect(() => {
        if (props.bookings) {
            let arr = props.bookings;
            let listResult = [];
            for (let i = 0; i < arr.length; i++) {
                const listData = arr.filter(booking => booking.customer_note === arr[i].customer_note);
                if (listData && listData.length > 0) {
                    let checkList = listResult.filter(item => item.title === arr[i].customer_note);
                    if (checkList.length === 0) {
                        listResult.push({title: arr[i].customer_note, count: listData.length});
                    }
                }
            }
            const noteSorted = listResult.sort((a, b) => (a.count < b.count) ? 1 : -1);
            setNotes(noteSorted);
        }
    }, [props.bookings]);

    const handleChangeNote = (event, value) => {
        setNote(value);
        if (props.setSearchInit) {
            //props.setSearchInit(value);
        }
        //dispatch(updateTripNote(value));
    };

    const handleChangeGroup = (event, value) => {
        setGroup(value);
        setResultCheckPrice1(null);
    };

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }

    const handleCarSelect = (event, value) => {
        let car = props.cartypes.filter(carT => carT.name == value)[0];
        dispatch(updateTripCar(car));
        setCarType(car.name);
        setResultCheckPrice1(null);
    };
    const onDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const loadBookingObj = (bookingNow) => {
        if (group) {
        } else {
            alert("Chọn lại điểm kinh doanh");
            return
        }
        var bookingObject = {
            pickup: null,
            carDetails: null,
            userDetails: {
                uid: auth.info.uid,
                profile: {
                    firstName: "Trung Tâm",
                    lastName: "",
                    mobile: props.callDetail && props.callDetail.source ? props.callDetail.source : settings.panic,
                    note: note ? note : 'Không có ghi chú',
                    pushToken: auth.info.pushToken
                }
            },
            groupDetails: null,
            settings: settings,
            tripdate: GetCurrentMiniTimeStamp(),
            bookLater: bookingType == 'Book Later' && selectedDate ? true : false,
            bookingDate: bookingType == 'Book Later' && selectedDate ? Date.parse(selectedDate) : null,
            booking_type_web: true,
            source: "TT",
            type: "TT",
            callId: props.callDetail && props.callDetail.firebase_id ? props.callDetail.firebase_id : null
        };

        if (props.driverCode) {
            bookingObject.source = "LX";
            bookingObject.driverBook = {code: props.driverCode};
        }

        if (props.callDetail && props.callDetail.booking_id && props.callDetail.driver_code && props.callDetail.status === "booked_cancel" && props.callDetail.booking_id) {
            bookingObject.old_booking_id = props.callDetail.booking_id;
        }

        if (groupApply) {
            bookingObject.groupDetails = groupApply;
            bookingObject.areaId = null;
            bookingObject.carDetails = tripdata.carType;
            bookingObject.carTypeCode = tripdata.carType.code;
            bookingObject.prices = prices ? prices : null;

            /*let groupBook = listGroup.filter(g => g.label == group)[0];
            if (groupBook) {
                bookingObject.groupDetails = groupBook;
                bookingObject.areaId = groupBook.area_ids ? groupBook.area_ids[0] : null;
                if (areasdata && bookingObject.areaId) {
                    let areas = areasdata.filter((a) => a.id == bookingObject.areaId);
                    if (areas && areas.length > 0) {
                        bookingObject.areaId = areas[0].id;
                        if (areas.prices && areas[0].prices[carType]) {
                            bookingObject.prices = areas[0].prices[carType];
                        }
                    }
                }
            } else {
                alert("Dữ liệu điểm kinh doanh không phù hợp!")
            }
            if (carType && carType != null && carType != '' && props.cartypes && props.cartypes.length > 0) {
                let car = props.cartypes.filter(carT => carT.code == carType)[0];
                if (car) {
                    bookingObject.carDetails = car;
                    bookingObject.carTypeCode = car.code;
                } else {
                    alert("Dữ liệu Xe Kinh Doanh không phù hợp!")
                }
            } else {
                alert("Lỗi dữ liệu đầu vào! Vui lòng liên hệ Admin!");
            }*/

        } else {
            // Ko có bảng giá - Vùng Tự Do
            alert("Lỗi dữ liệu đầu vào! Vui lòng liên hệ Admin!");
        }

        if (tripdata.pickup) {
            bookingObject.pickup = tripdata.pickup;
        }

        if (tripdata.drop) {
            bookingObject.drop = tripdata.drop;
        }

        if (objectBooking && objectBooking.driverOutGroup) {
            bookingObject.driverOutGroup = objectBooking.driverOutGroup;
        }

        if (objectBooking && objectBooking.driverInArea) {
            bookingObject.driverInArea = objectBooking.driverInArea;
        }

        if (objectBooking && objectBooking.driverInGroup) {
            bookingObject.driverInGroup = objectBooking.driverInGroup;
        }

        dispatch(fetchDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, tripdata.carType.code, bookingObject.pickup, groupApply.value));
        setButtonDisable(true);
        setTimeout(() => {
            if (bookingNow === true) {
                bookingObject.isBooking = "OK";
                setObjectBooking(bookingObject);
                //setTimeout(props.closeCallback, 400);
            } else {
                navigate('/dashboard', {
                    state: {bookingObject: bookingObject}
                });
            }
        }, 1000);
    }

    useEffect(() => {
        if (objectBooking && objectBooking.isBooking && objectBooking.isBooking == "OK") {
            if (objectBooking.driverOutGroup || objectBooking.driverInGroup) {
                objectBooking.is_two_way = (isTwoWay == 1 ? true : false);
                if (estimateTemp) {
                    objectBooking.estimateTemp = estimateTemp;
                }
                objectBooking.customer_note = note;
                dispatch(addBookingV2(objectBooking));
                setTimeout(() => {
                    resetBooking();
                    props.closeCallback();
                }, 1000);
            } else {
                alert("Không tìm thấy lái xe phù hợp");
            }
        }
    }, [objectBooking]);

    const resetBooking = () => {
        setAddressInfo(null);
        setButtonDisable(false);
        setSelectedDate(GetDateString());
        setBookingType('Book Now');
        setGroupApply(null);
        setGroup('');
        setDropFromMap(null);
        setPickupFromMap(null);
        setListAddressPickup(null);
        setPrices(null);
        setListAddressDrop(null);
        setCheckPrice1(null);
        setResultCheckPrice1(null);
        setIsTwoWay(0);
        setObjectBooking(null);
        dispatch(updateTripPickup(null));
        setEstimateTemp(null);
        dispatch(updateTripDrop(null));
        setPolygons(null);
        /*if (props.cartypes && props.cartypes.length > 0 && carType == null) {
            setCarType(props.cartypes[0].name);
            dispatch(updateTripCar(props.cartypes[0]));
        }*/
    }

    const loadOptionsPickup = useCallback(
        (options) => {
            setListAddressPickup(options);
            setListAddressDrop(null);
        },
        [],
    );

    const loadOptionsDrop = useCallback(
        (options) => {
            setListAddressDrop(options);
            setListAddressPickup(null);
        },
        [],
    );


    return (
        <FormControl>
            <View style={{flexDirection: 'column', justifyContent: 'center'}}>
                <View style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                        <View style={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                            <Autocomplete
                                freeSolo
                                onChange={handleChangeNote}
                                style={{
                                    width: 500,
                                    marginTop: 10
                                }}
                                value={note ? note : ''}
                                defaultValue={note ? note : props.callDetail && props.callDetail.note ? props.callDetail.note : ''}
                                id={"note_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                options={props.notes}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        onChange={(event) => {
                                            let text = event.target.value
                                            setNote(text);
                                            console.log("text 12", text);
                                            //props.setSearchInit(text);
                                        }}
                                        value={note ? note : ''}
                                        label="Lời nhắn tới lái xe: Vị trí đón khách"/>
                                }
                            />

                            {props.cartypes && props.cartypes.length > 0 ?
                                <Autocomplete
                                    id={"xe_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                    autoSelect
                                    style={{
                                        width: 500
                                    }}
                                    defaultValue={props.cartypes[0].name}
                                    value={carType}
                                    options={props.cartypes.filter((c) => c.type == 'taxi').map((option) => option.name)}
                                    onChange={handleCarSelect}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Xe Kinh doanh"/>}
                                /> : null}

                            {listGroup && listGroup.length > 0 ?
                                <Autocomplete
                                    id={"group_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                    autoSelect
                                    style={{
                                        width: 500
                                    }}
                                    value={group}
                                    defaultValue={listGroup[0].label}
                                    options={listGroup.map((option) => option.label)}
                                    onChange={handleChangeGroup}
                                    renderInput={(params) => <TextField {...params} label="Điểm Kinh doanh"/>}
                                /> : null}

                            <View style={{flexDirection: 'row', alignItems: 'center', width: 500}}>
                                {/*{bookingType === 'Book Now' ?
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={buttonDisable}
                                        size="small"
                                        id={"bookingbutton_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                        className={styles.submit}
                                        onClick={() => {
                                            setBookingType("Book Later");
                                        }}
                                    >
                                        {"Chọn ngày đặt trước"}
                                    </Button> : null}*/}

                                {bookingType === 'Book Later' ?
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={buttonDisable}
                                        size="small"
                                        id={"bookingbutton_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                        className={styles.submit}
                                        onClick={() => {
                                            setBookingType("Book Later");
                                            dispatch(fetchOnceDrivers());
                                            loadBookingObj(true);
                                        }}
                                    >
                                        {"Đặt trước"}
                                    </Button> : null}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={buttonDisable}
                                    size="small"
                                    id={"bookingbutton_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                    className={styles.submit}
                                    style={{marginLeft: 50}}
                                    onClick={() => {
                                        loadBookingObj(true);
                                    }}
                                >
                                    {"Đặt ngay"}
                                </Button>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="red"
                                    size="small"
                                    id={"cancelbutton_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                    className={styles.submit}
                                    style={{marginLeft: 50}}
                                    onClick={props.closeCallback}
                                >
                                    {"Đóng"}
                                </Button>
                            </View>

                            {group == 'Vùng tự do' ?
                                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                                    <Image
                                        resizeMode="contain"
                                        style={{
                                            width: 30, height: 30,
                                            backgroundColor: colors.TRANSPARENT,
                                            justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                                        }}
                                        source={require('../../assets/img/pin_pickup.png').default}
                                    />

                                    <GoogleMapsAutoComplete
                                        placeholder={language.pickup_location}
                                        variant={"outlined"}
                                        value={pickupFromMap}
                                        style={{
                                            width: 400,
                                            zIndex: 9,
                                            elevation: 9
                                        }}
                                        renderOption={() => {
                                            return null;
                                        }}
                                        hasNotRenderOption={true}
                                        loadOptions={tripdata.pickup && tripdata.pickup.from && tripdata.pickup.from == 'map' ? null : loadOptionsPickup}
                                        onChange={
                                            (value) => {
                                                setPickupFromMap(value);
                                            }
                                        }
                                    />

                                    <Checkbox
                                        checked={tripdata.selected == 'pickup'}
                                        color={"primary"}
                                        onChange={() => {
                                            if (tripdata.pickup && tripdata.pickup.lat) {
                                                setMylocation({
                                                    lat: tripdata.pickup.lat,
                                                    lng: tripdata.pickup.lng
                                                });
                                            }
                                            if (tripdata.selected == 'pickup') {
                                            } else {
                                                dispatch(updatSelPointType('pickup'));
                                            }
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                    {tripdata.pickup && tripdata.pickup.lat ?
                                        <IconButton
                                            aria-label="close"
                                            color="secondary"
                                            size="medium"
                                            onClick={() => {
                                                dispatch(updateTripPickup(null));
                                            }}
                                        >
                                            <CancelIcon fontSize="inherit" color={'error'}/>
                                        </IconButton> : null}

                                </View> : null}

                            {bookingType === 'Book Later' ?
                                <View style={{flexDirection: 'row', alignItems: 'flex-end', marginTop: 20}}>
                                    <View style={{flexDirection: 'column', alignItems: 'flex-end', width: '60%'}}>
                                        <TextField
                                            id="datetime-local"
                                            label={"Đặt trước"}
                                            type="datetime-local"
                                            variant="filled"
                                            fullWidth
                                            className={styles.commonInputStyle}
                                            InputProps={{
                                                className: styles.input,
                                                style: {textAlignLast: 'start'}
                                            }}
                                            value={selectedDate}
                                            onChange={onDateChange}
                                        />
                                    </View>
                                    <View style={{flexDirection: 'column', paddingTop: 20}}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            disabled={buttonDisable}
                                            size="small"
                                            id={"bookingbutton_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                                            className={styles.submit}
                                            onClick={() => {
                                                setBookingType("Book Now");
                                            }}
                                        >
                                            {"Hủy đặt trước"}
                                        </Button>
                                    </View>
                                </View> : null}

                            {group == 'Vùng tự do' && tripdata.pickup && tripdata.pickup.lat ?
                                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                                    <Image
                                        resizeMode="contain"
                                        style={{
                                            width: 30, height: 30,
                                            backgroundColor: colors.TRANSPARENT,
                                            justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                                        }}
                                        source={require('../../assets/img/pin.png').default}
                                    />
                                    <GoogleMapsAutoComplete
                                        placeholder={language.drop_location}
                                        variant={"outlined"}
                                        value={dropFromMap}
                                        style={{
                                            width: 400,
                                            zIndex: 9,
                                            elevation: 9
                                        }}
                                        renderOption={() => {
                                            return null;
                                        }}
                                        hasNotRenderOption={true}
                                        disableClearable={true}
                                        loadOptions={tripdata.drop && tripdata.drop.from && tripdata.drop.from == 'map' ? null : loadOptionsDrop}
                                        onChange={
                                            (value) => {
                                                setDropFromMap(value);
                                            }
                                        }
                                    />
                                    <Checkbox
                                        checked={tripdata.selected == 'drop'}
                                        color={"primary"}
                                        onChange={() => {
                                            if (tripdata.drop && tripdata.drop.lat) {
                                                setMylocation({
                                                    lat: tripdata.drop.lat,
                                                    lng: tripdata.drop.lng
                                                });
                                            }
                                            if (tripdata.selected == 'drop') {
                                            } else {
                                                dispatch(updatSelPointType('drop'));
                                            }
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />

                                    {tripdata.drop && tripdata.drop.lat ?
                                        <IconButton
                                            aria-label="close"
                                            color="secondary"
                                            size="medium"
                                            onClick={() => {
                                                dispatch(updateTripDrop(null));
                                            }}
                                        >
                                            <CancelIcon fontSize="inherit" color={'error'}/>
                                        </IconButton> : null}

                                </View> : null}
                            {listAddressPickup ?
                                listAddressPickup.map((option, index) => {
                                    const matches = option.structured_formatting.main_text_matched_substrings;
                                    var parts = null;
                                    if (matches) {
                                        parts = parse(
                                            option.structured_formatting.main_text,
                                            matches.map((match) => [match.offset, match.offset + match.length]),
                                        );
                                    }
                                    return (
                                        <div style={{display: "flex", justifyContent: 'flex-start', marginTop: 5}} key={"div_address_search_" + index}>
                                            <Button
                                                key={"button_search_" + index}
                                                fullWidth
                                                color="primary"
                                                size="small"
                                                id={"bookingbutton__address" + index}
                                                onClick={() => {
                                                    if (option && option.place_id) {
                                                        geocodeByPlaceId(option.place_id)
                                                            .then(results => {
                                                                if (results.length > 0) {
                                                                    option.coords = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                                                    option.placeDetails = results[0];
                                                                    setMylocation({
                                                                        lat: results[0].geometry.location.lat(),
                                                                        lng: results[0].geometry.location.lng()
                                                                    });
                                                                    dispatch(updateTripPickup({
                                                                        lat: results[0].geometry.location.lat(),
                                                                        lng: results[0].geometry.location.lng(),
                                                                        add: option.description,
                                                                        source: "search"
                                                                    }));

                                                                }
                                                            })
                                                            .catch(error => alert(language.google_places_error));
                                                    }
                                                    setPickupFromMap(option.description);
                                                    setTimeout(() => {
                                                        setListAddressPickup(null);
                                                    }, 300);

                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start', width: 500
                                                }}>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start', width: 500
                                                    }}>
                                                        <LocationOnIcon className={classes.icon} key={"button_search_" + index}/>
                                                        <View style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                        }}>
                                                            {parts && parts.map((part, index) => (
                                                                <Text style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                    {part.text}
                                                                </Text>
                                                            ))}
                                                        </View>
                                                    </View>
                                                    <View style={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start', width: 500
                                                    }}>
                                                        <Text style={{textAlign: 'left'}}>{option.structured_formatting.secondary_text}</Text>
                                                    </View>

                                                </View>
                                            </Button>
                                        </div>
                                    );
                                })
                                : null}

                            {listAddressDrop && tripdata.pickup && tripdata.pickup.lat ?
                                listAddressDrop.map((option, index) => {
                                    const matches = option.structured_formatting.main_text_matched_substrings;
                                    const parts = parse(
                                        option.structured_formatting.main_text,
                                        matches.map((match) => [match.offset, match.offset + match.length]),
                                    );

                                    return (
                                        <div style={{display: "flex", justifyContent: 'flex-start', marginTop: 5}} key={"div_address_search_" + index}>
                                            <Button
                                                key={"button_search_" + index}
                                                fullWidth
                                                color="primary"
                                                size="small"
                                                id={"bookingbutton__address" + index}
                                                onClick={() => {
                                                    if (option && option.place_id) {
                                                        geocodeByPlaceId(option.place_id)
                                                            .then(results => {
                                                                if (results.length > 0) {
                                                                    option.coords = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                                                    option.placeDetails = results[0];
                                                                    setMylocation({
                                                                        lat: results[0].geometry.location.lat(),
                                                                        lng: results[0].geometry.location.lng()
                                                                    });
                                                                    dispatch(updateTripDrop({
                                                                        lat: results[0].geometry.location.lat(),
                                                                        lng: results[0].geometry.location.lng(),
                                                                        add: option.description,
                                                                        source: "search"
                                                                    }));

                                                                }
                                                            })
                                                            .catch(error => alert(language.google_places_error));
                                                    }
                                                    setDropFromMap(option.description);
                                                    setTimeout(() => {
                                                        setListAddressDrop(null);
                                                    }, 300);

                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start', width: 500
                                                }}>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start', width: 500
                                                    }}>
                                                        <LocationOnIcon className={classes.icon} key={"button_search_" + index}/>
                                                        <View style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                        }}>
                                                            {parts.map((part, index) => (
                                                                <Text style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                    {part.text}
                                                                </Text>
                                                            ))}
                                                        </View>
                                                    </View>
                                                    <View style={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start', width: 500
                                                    }}>
                                                        <Text style={{textAlign: 'left'}}>{option.structured_formatting.secondary_text}</Text>
                                                    </View>

                                                </View>
                                            </Button>
                                        </div>
                                    );
                                })
                                : null}

                            {prices ?
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Text style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 8, fontWeight: 'bold', fontSize: 18, marginLeft: 20}}>
                                        {"Báo giá dự tính"}
                                    </Text>
                                </View>
                                : null
                            }

                            {group && prices ?
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    {group == 'Vùng tự do' && tripdata.pickup ?
                                        <Text style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 8, fontWeight: 'bold', fontSize: 18, marginLeft: 20}}>
                                            {tripdata.drop && checkPrice1 ? (isTwoWay ? checkPrice1 * 2 : checkPrice1) + "(km)" : "Chọn điểm trả để xem giá"}
                                        </Text>
                                        : null}

                                    {group != 'Vùng tự do' ?
                                        <TextField
                                            id="text_check_price"
                                            label={"Nhập số KM để biết giá"}
                                            variant="filled"
                                            fullWidth
                                            /* className={styles.commonInputStyle}
                                             InputProps={{
                                                 className: styles.input,
                                                 style: {textAlignLast: 'start'}
                                             }}*/
                                            value={checkPrice1}
                                            onChange={(event) => {
                                                let text = event.target.value;
                                                setCheckPrice1(text);
                                                var result = null;
                                                if (isTwoWay && prices.price2) {
                                                    let time = parseFloat(text) / 40 / 60;
                                                    result = congthuc2chieu(parseFloat(text), time, null, 0, prices);
                                                } else {
                                                    if (prices.price1) {
                                                        result = congthuc1chieu(parseFloat(text), 0, null, prices);
                                                    }
                                                }

                                                if (result && result.totalCost) {
                                                    setResultCheckPrice1(formatPrice(result.totalCost));
                                                }
                                            }}
                                        /> : null}

                                    <Text style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 8, fontWeight: 'bold', fontSize: 18, marginLeft: 20}}>
                                        {resultCheckPrice1 ? resultCheckPrice1 : '0(đ)'}
                                    </Text>
                                </View> : null}
                            {group && prices ?
                                // Đổi nút (Màu nền mặc định là trắng đục, Màu active là MAIN)
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Switch
                                        checked={isTwoWay === 1}
                                        onChange={(e) => {
                                            let value = e.target.checked ? 1 : 0;
                                            setIsTwoWay(value);
                                            var result = null;
                                            if (value == 1 && prices.price2) {
                                                var priceCheck = checkPrice1;
                                                if (tripdata.drop) {
                                                    priceCheck = checkPrice1 * 2;
                                                }
                                                let time = parseFloat(priceCheck) / 40 / 60;
                                                result = congthuc2chieu(parseFloat(priceCheck), time, null, 0, prices);
                                            } else {
                                                if (prices.price1) {
                                                    result = congthuc1chieu(parseFloat(checkPrice1), 0, null, prices);
                                                }
                                            }
                                            if (result && result.totalCost) {
                                                setResultCheckPrice1(formatPrice(result.totalCost));
                                            }
                                        }}
                                        name="2_chieu"
                                        color="primary"
                                    />
                                    <Text style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 10}}>
                                        {isTwoWay ? "2 chiều(tổng số km quay đầu)" : "1 chiều"}
                                    </Text>
                                </View>
                                : null}
                        </View>

                        <View style={{
                            flexDirection: 'column'
                        }}>
                            <View style={{width: 700, height: 600}}>
                                {mylocation ?
                                    <Map
                                        isPickup={true}
                                        loadingElement={<div className={classes.fullHeight}/>}
                                        containerElement={<div className={classes.fullHeight}/>}
                                        mapElement={<div className={classes.fullHeight}/>}
                                        center={mylocation}
                                        polygons={polygons}
                                        group={group}
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Google_Map_Key + "&v=3.exp&libraries=geometry,drawing,places"}
                                    /> : null}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </FormControl>
    )
        ;
}
