import {ADD_FRIENDS, ADD_FRIENDS_ACCEPTED, ADD_FRIENDS_ACCEPTED_FAILED, ADD_FRIENDS_ACCEPTED_SUCCESS, ADD_FRIENDS_FAILED, ADD_FRIENDS_SUCCESS, FETCH_FRIENDS, FETCH_FRIENDS_FAILED, FETCH_FRIENDS_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    friend: null,
    friends: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const friendsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_FRIENDS:
                return {
                    ...state,
                    loading: true
                };
        case FETCH_FRIENDS_SUCCESS:
                return {
                    ...state,
                    friends: action.payload.friends,
                    loading: false
                };
        case FETCH_FRIENDS_FAILED:
                return {
                    ...state,
                    friends: [],
                    loading: false,
                    error: {
                        flag: true,
                        msg: action.payload
                    }
                };

        case ADD_FRIENDS:
                return {
                    ...state,
                    loading: true
                };
        case ADD_FRIENDS_SUCCESS:
                return {
                    ...state,
                    friend: action.payload.friend,
                    loading: false
                };
        case ADD_FRIENDS_FAILED:
                return {
                    ...state,
                    area: null,
                    loading: false,
                    error: {
                        flag: true,
                        msg: action.payload
                    }
                };

        case ADD_FRIENDS_ACCEPTED:
                return {
                    ...state,
                    loading: true
                };
        case ADD_FRIENDS_ACCEPTED_SUCCESS:
                return {
                    ...state,
                    friend: action.payload.friend,
                    loading: false
                };
        case ADD_FRIENDS_ACCEPTED_FAILED:
                return {
                    ...state,
                    friend: null,
                    loading: false,
                    error: {
                        flag: true,
                        msg: action.payload
                    }
                };

        default:
            return state;
    }
};
