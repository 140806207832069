import {FETCH_MISTAKE_CONFIG, FETCH_MISTAKE_CONFIG_FAILED, FETCH_MISTAKE_CONFIG_SUCCESS,} from "../store/types";

export const INITIAL_STATE = {
    mistake_config: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const mistakeconfigreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MISTAKE_CONFIG:
            return {
                ...state,
                loading: true
            };
        case FETCH_MISTAKE_CONFIG_SUCCESS:
            return {
                ...state,
                mistake_config: action.payload,
                loading: false
            };
        case FETCH_MISTAKE_CONFIG_FAILED:
            return {
                ...state,
                mistake_config: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        default:
            return state;
    }
};
