import {UPDATE_ROUTE_NAME,} from "../store/types";

const INITIAL_STATE = {
    routeName: null
}
export const routenamereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_ROUTE_NAME:
            return {
                routeName: action.payload,
            };
        default:
            return state;
    }
};