import {UPDATE_POLYGON} from "../store/types";

const INITIAL_STATE = {
    polygon: null
}
export const polygonreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_POLYGON:
            return {
                ...state,
                polygon: action.payload,
            };
        default:
            return state;
    }
};