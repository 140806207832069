import {EDIT_BANNDEDUIDS, FETCH_BANNDEDUIDS, FETCH_BANNDEDUIDS_FAILED, FETCH_BANNDEDUIDS_SUCCESS} from "../store/types";

export const INITIAL_STATE = {
    banneduids: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const banneduidsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_BANNDEDUIDS:
            return {
                ...state,
                loading: true
            };
        case FETCH_BANNDEDUIDS_SUCCESS:
            return {
                ...state,
                banneduids: action.payload,
                loading: false
            };
        case FETCH_BANNDEDUIDS_FAILED:
            return {
                ...state,
                banneduids: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case EDIT_BANNDEDUIDS:
            return state;
        default:
            return state;
    }
};