import {Google_Map_Key} from 'config';
import {
    cloud_function_server_url
} from 'config';
import store from "../store/store";

export const fetchCoordsfromPlace = async (place_id) => {
    const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + place_id + '&key=' + Google_Map_Key);
    const json = await response.json();
    if (json.results && json.results.length > 0 && json.results[0].geometry) {
        let coords = json.results[0].geometry.location;
        return coords;
    }
    return null;
}

export const fetchDirections = async (origin, destination) => {
    let response = await fetch(`${cloud_function_server_url}/get_directions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "start": origin,
            "dest": destination,
            "google_map_key": Google_Map_Key
        })
    });
    let json = await response.json();
    return json;
}


export const getAddressDefaultAutoCompleted = async (address) => {
    var new_url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?language=vi&components=country:vn&key=' + Google_Map_Key + '&input=' + address;
    const response = await fetch(new_url);
    const json = await response.json();
    if (json.status && json.status === 'OK') {
        return json.predictions;
    } else {
        return [];
    }
}


export const fetchAddressfromCoords = (latlng) => {
    return fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng + '&key=' + Google_Map_Key, {
        method: 'GET',
    })
        .then(response => response.text())
        .then(result => {
            let data = JSON.parse(result);
            if (data.results && data.results.length > 0 && data.results[0].formatted_address) {
                return data.results[0].formatted_address;
            }
        })
        .catch(error => console.log('error', error));
    return null;
}

export const fetchAddressFullfromCoords = (latlng) => {
    return fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng + '&key=' + Google_Map_Key, {
        method: 'GET',
    })
        .then(response => response.text())
        .then(result => {
            let data = JSON.parse(result);
            if (data.results && data.results.length > 0 && data.results[0].formatted_address) {
                return data.results[0];
            }
        })
        .catch(error => console.log('error', error));
    return null;
}

export const getRouteDetails = async (startLoc, destLoc) => {
    let response = await fetch(`${cloud_function_server_url}/get_route_details`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "start": startLoc,
            "dest": destLoc,
            "google_map_key": Google_Map_Key
        })
    });
    let json = await response.json();
    if (json.distance) {
        return json;
    } else {
        console.log(json.error);
    }
    return null;
}

export const getDriveTime = async (startLoc, destLoc) => {
    let url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=' + startLoc + '&destinations=' + destLoc + '&key=' + Google_Map_Key;
    const response = await fetch(url);
    const json = await response.json();
    const elements = json.rows[0].elements;
    if (elements && elements.length > 0) {
        if (elements[0] && elements[0].status === "OK" && elements[0].distance && elements[0].distance.value) {
            return {
                distance_in_km: elements[0].distance.value / 1000,
                time_in_secs: elements[0].duration.value,
                timein_text: elements[0].duration.text
            }
        } else {
            return null;
        }
    } else {
        return null;
    }
}