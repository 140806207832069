import {FETCH_LOCAL_REPORTS, FETCH_LOCAL_REPORTS_FAILED, FETCH_LOCAL_REPORTS_SUCCESS, INIT_REPORT_BY_DATE, INIT_REPORT_BY_DATE_FAILED, INIT_REPORT_BY_DATE_SUCCESS, SET_REPORT_ITEM, SET_REPORT_ITEM_FAILED, SET_REPORT_ITEM_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    reports: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const localreportsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_LOCAL_REPORTS:
            return {
                ...state,
                loading: true
            };
        case FETCH_LOCAL_REPORTS_SUCCESS:
            return {
                ...state,
                reports: action.payload,
                loading: false
            };
        case FETCH_LOCAL_REPORTS_FAILED:
            return {
                ...state,
                reports: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case INIT_REPORT_BY_DATE:
            return {
                ...state,
                loading: true
            };
        case INIT_REPORT_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case INIT_REPORT_BY_DATE_FAILED:
            return {
                ...state,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case SET_REPORT_ITEM:
            return {
                ...state,
                loading: true
            };
        case SET_REPORT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case SET_REPORT_ITEM_FAILED:
            return {
                ...state,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};