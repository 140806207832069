import {EDIT_ROLE_PERMISSION, EDIT_ROLE_PERMISSION_MEMBER, EDIT_ROLE_PERMISSION_MEMBER_SUCCESS, EDIT_ROLE_PERMISSION_SUCCESS, FETCH_ROLE_PERMISIONS, FETCH_ROLE_PERMISIONS_FAILED, FETCH_ROLE_PERMISIONS_SUCCESS, STOP_FETCH_ROLE_PERMISIONS,} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue, push, remove, set, update} from "firebase/database";

export const fetchRolePermission = () => (dispatch) => {

    const {
        rolePermissionRef
    } = firebase;

    dispatch({
        type: FETCH_ROLE_PERMISIONS,
        payload: null
    });
    onValue(rolePermissionRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i
                return data[i]
            });
            dispatch({
                type: FETCH_ROLE_PERMISIONS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ROLE_PERMISIONS_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const stopFetchRolePermission = () => (dispatch) => {
    const {
        rolePermissionRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_ROLE_PERMISIONS,
        payload: null
    });
    off(rolePermissionRef)
};

export const editRolePermission = (roles, method) => (dispatch) => {

    const {
        rolePermissionItemRef,
        rolePermissionRef
    } = firebase;

    dispatch({
        type: EDIT_ROLE_PERMISSION,
        payload: {method, roles}
    });
    if (method === 'Add') {
        push(rolePermissionRef, roles).then((res) => {
            let key = res.key;
            dispatch({
                type: EDIT_ROLE_PERMISSION_SUCCESS,
                payload: res
            });
        });
    } else if (method === 'Delete') {
        remove(rolePermissionItemRef(roles.id));
    } else if (method === 'Update') {
        update(rolePermissionItemRef(roles.id), roles);
        dispatch({
            type: EDIT_ROLE_PERMISSION_SUCCESS,
            payload: roles
        });
    } else {
        set(rolePermissionItemRef(roles.id), roles);
    }
}


export const editRolePermissionMember = (parentId, member, method) => (dispatch) => {
    const {
        rolePermissionItemMemberRef,
        rolePermissionMemberRef
    } = firebase;

    dispatch({
        type: EDIT_ROLE_PERMISSION_MEMBER,
        payload: {method, member}
    });
    if (method === 'Add') {
        push(rolePermissionMemberRef(parentId), member).then((res) => {
            let key = res.key;
            dispatch({
                type: EDIT_ROLE_PERMISSION_MEMBER_SUCCESS,
                payload: res
            });
        });
    } else if (method === 'Delete') {
        remove(rolePermissionItemMemberRef(parentId, member.id));
    } else if (method === 'Update') {
        update(rolePermissionItemMemberRef(parentId, member.id), member);
        dispatch({
            type: EDIT_ROLE_PERMISSION_MEMBER_SUCCESS,
            payload: member
        });
    } else if (method === "Set") {
        set(rolePermissionMemberRef(parentId), member);
    } else {
        set(rolePermissionItemMemberRef(parentId, member.id), member);
    }
}

