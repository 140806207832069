import {FETCH_VOTE, FETCH_VOTE_FAILED, FETCH_VOTE_SUCCESS, PUSH_VOTE, PUSH_VOTE_FAILED, PUSH_VOTE_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    votes: [],
    vote: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const votesreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_VOTE:
            return {
                ...state,
                loading: true
            };
        case FETCH_VOTE_SUCCESS:
            return {
                ...state,
                votes: action.payload.votes,
                loading: false
            };
        case FETCH_VOTE_FAILED:
            return {
                ...state,
                votes: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case PUSH_VOTE:
            return {
                ...state,
                loading: true
            };
        case PUSH_VOTE_SUCCESS:
            return {
                ...state,
                vote: action.payload.vote,
                loading: false
            };
        case PUSH_VOTE_FAILED:
            return {
                ...state,
                vote: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
