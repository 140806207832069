import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPremium,
    GridToolbar,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import {useDemoData} from '@mui/x-data-grid-generator';
import {useCallback, useContext, useEffect, useState} from "react";
import {formatDisplayDate, groupByArray} from "common/src/other/CommonFunctions";
import {ConvertTimeStampToDate, GetEndDatePicker, GetStartDatePicker} from "common/src/other/DateFunctions";
import {useDispatch} from "react-redux";
import ReactExport from "react-export-excel";
import {api, FirebaseContext} from 'common';
import {Text, View} from "react-native-web";
import {onValue} from "firebase/database";


export default function BackendTrackingCar() {
    const {bookingTrackingByPickupTimeRef} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startDate, setStartDate] = useState(GetStartDatePicker('X'));
    const [endDate, setEndDate] = useState(GetEndDatePicker('X'));
    const [rows, setRows] = useState([]);
    const [loadingS, setLoadingS] = useState(false);
    const [listBs, setListBs] = useState([]);
    const [codes, setCodes] = useState([]);
    const [bksFilter, setBksFilter] = useState([]);
    const [queryOptions, setQueryOptions] = useState({});
    const [filterMode, setFilterMode] = useState('client');

    useEffect(() => {
        if (startDate && endDate && rowsPerPage) {
            setLoadingS(true);
            let prepareData = [];
            onValue(bookingTrackingByPickupTimeRef("pickupTime", 100000, endDate * 1000, 1000000), snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const arr = Object.keys(data).map(i => {
                        data[i].id = i;
                        data[i].pickupTime = new Date(data[i].pickupTime);
                        data[i].dropTime = data[i].dropTime && data[i].status == 'done' ? new Date(data[i].dropTime) : '';
                        data[i].grandTotal = data[i].total;
                        return data[i];
                    });
                    let rowsFilter = arr;

                    if (bksFilter && bksFilter.length > 0) {
                        var listDatasFilted = [];
                        for (let i = 0; i < bksFilter.length; i++) {
                            let bksF = bksFilter[i];
                            if (bksF && bksF.value) {
                                listDatasFilted = listDatasFilted.concat(rowsFilter.filter((d) => d.bsx.toString() == bksF.value.toString()));
                            }
                        }
                        rowsFilter = listDatasFilted
                    }

                    setRows(rowsFilter);

                    /*let sorted = prepareData.filter(item => item.status !== 'NEWV2');*/
                    //sorted = sorted.sort((a, b) => (parseInt(a[orderBy]) < parseInt(b[orderBy])) ? 1 : -1);
                    /*sorted = sorted.filter(item => !item.customer_note || (item.customer_note && item.customer_note != 'Khách quen lái xe'));*/
                    let listBsUnique = Object.keys(groupByArray(Object.values(data), 'bsx'));
                    let listBsx = [];
                    for (let i = 0; i < listBsUnique.length; i++) {
                        listBsx.push({
                            title: listBsUnique[i], value: listBsUnique[i]
                        });
                    }
                    setListBs(listBsx)
                    setLoadingS(false);
                } else {
                    setRows(prepareData);
                    setLoadingS(false);
                }
            }, {onlyOnce: true})
        }
    }, [startDate, endDate, rowsPerPage, codes, bksFilter]);

    const headCells = [
        {
            field: 'bsx',
            headerName: 'Biển số',
        },
        {
            field: 'pickupTime',
            type: 'dateTime',
            headerName: 'Thời gian đón',
            width: 200
        },
        {
            field: 'pickupAddress',
            headerName: 'Vị trí đón',
            resizable: true,
            width: 300

        },
        {
            field: 'dropTime',
            type: 'dateTime',
            headerName: 'Thời gian trả',
            width: 200
        },
        {
            field: 'dropAdd',
            headerName: 'Vị trí trả',
            resizable: true,
            width: 300
        },
        {
            field: 'km',
            type: 'number',
            headerName: 'Km',
        },
        {
            field: 'type',
            headerName: 'Thuê bao',
        },
        {
            field: 'grandTotal',
            type: 'number',
            headerName: 'Tiền đồng hồ',
        },
        {
            field: 'total',
            type: 'number',
            headerName: 'Thực thu',
        },
        {
            field: 'totalCk',
            type: 'number',
            headerName: 'Số CK',
        }
    ];

    let data2 = {};
    data2.columns = headCells;
    data2.experimentalFeatures = {ariaV7: true};
    data2.initialState = {
        columnVisibilityModel: {
            id: true
        }
    }
    data2.rows = rows;

    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            ...data2.initialState,
            rowGrouping: {
                ...data2.initialState?.rowGrouping,
                model: ['bsx']
            },
            aggregation: {
                model: {
                    total: 'sum',
                    km: 'sum',
                    grandTotal: 'sum'
                },
            },
            filter: {
                filterModel: {
                    items: [
                        {field: 'pickupTime', operator: "onOrAfter", value: GetStartDatePicker('YYYY-MM-DDTHH:mm')},
                        {field: 'pickupTime', operator: "onOrBefore", value: GetEndDatePicker('YYYY-MM-DDTHH:mm')},
                    ]
                },
            },
        },
    });

    const onFilterChange = useCallback((filterModel) => {
        setQueryOptions({filterModel: {...filterModel}});
        if (filterModel && filterModel.items) {
            let filterPickupTimeStart = filterModel.items.filter(item => item.field == 'pickup');
        }

        console.log("filterModel.items", typeof filterModel.items[0]);

        console.log("onFilterChange", filterModel.items);
    }, []);


    return (
        <Box sx={{height: 520, width: '100%', marginTop: 10}}>
            <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: 17, padding: 10}}>{"Báo cáo cuốc khách METTER"}</Text>
            </View>
            <DataGridPremium
                {...data2}
                apiRef={apiRef}
                loading={loadingS}
                disableRowSelectionOnClick
                initialState={initialState}
                hideFooterPagination={false}
                groupingColDef={{leafField: 'bsx'}}
                pagination={true}
                slots={{toolbar: GridToolbar}}
            />
        </Box>
    );
}