import {
    UPDATE_SESSION,
    UPDATE_SESSION_BOOKING_ID,
    UPDATE_SESSION_HAS_BOOKING,
    UPDATE_SESSION_HAS_BOOKING_ACTIVE,
    UPDATE_SESSION_HAS_FIX_BG,
    UPDATE_SESSION_HAS_NOTIFY,
    UPDATE_SESSION_HAS_UPDATE,
    UPDATE_SESSION_HAS_UPDATE_CURRENT_AREA,
    UPDATE_SESSION_HAS_UPDATE_GROUP,
    UPDATE_SESSION_HAS_UPDATE_TIME_ACTIVE,
    UPDATE_SESSION_HAS_UPDATE_TIME_OUT,
    UPDATE_SESSION_NOTIFY,
    UPDATE_SESSION_PLAY_BOOKING,
    UPDATE_SESSION_SOUND_BATDONGHO,
    UPDATE_SESSION_SOUND_BOOKING,
    UPDATE_SESSION_SOUND_BOOKING_FIRST,
    UPDATE_SESSION_SOUND_BUTTON_CLICK,
    UPDATE_SESSION_SOUND_CLOSE,
    UPDATE_SESSION_SOUND_LOICHUC,
    UPDATE_SESSION_SOUND_OPEN,
    UPDATE_SESSION_SOUND_WARNING_CUSTOMER,
    UPDATE_SESSION_TEAM_SELECTED,
} from "../store/types";

const INITIAL_STATE = {
    session: null,
    notify_group_empty: null,
    hasPlaySoundBooking: false,
    teamSelected: null,
    soudButtonClick: null,
    soundOpen: null,
    soundClose: null,
    soundBatDongHo: null,
    soundBooking: null,
    soundBookingFirst: null,
    soundLoiChuc: null,
    soundWarningCustomer: null,
    hasUpdate: false,
    hasNotify: false,
    hasBooking: false,
    hasFixBg: false,
    hasUpdateGroup: null,
    hasUpdateTimeOut: null,
    hasUpdateTimeActive: null,
    hasBookingActive: null,
    hasUpdateCurrenArea: null,
    bookingNewId: null
}
export const sessionreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_SESSION:
            return {
                ...state,
                session: action.payload
            };
        case UPDATE_SESSION_BOOKING_ID:
            return {
                ...state,
                bookingNewId: action.payload
            };
        case UPDATE_SESSION_TEAM_SELECTED:
            return {
                ...state,
                teamSelected: action.payload.teamSelected
            };
        case UPDATE_SESSION_SOUND_BUTTON_CLICK:
            return {
                ...state,
                soudButtonClick: action.payload.soudButtonClick
            };
        case UPDATE_SESSION_SOUND_OPEN:
            return {
                ...state,
                soundOpen: action.payload.soundOpen
            };
        case UPDATE_SESSION_SOUND_CLOSE:
            return {
                ...state,
                soundClose: action.payload.soundClose
            };
        case UPDATE_SESSION_SOUND_BATDONGHO:
            return {
                ...state,
                soundBatDongHo: action.payload.soundBatDongHo
            };
        case UPDATE_SESSION_SOUND_BOOKING:
            return {
                ...state,
                soundBooking: action.payload.soundBooking
            };
        case UPDATE_SESSION_SOUND_BOOKING_FIRST:
            return {
                ...state,
                soundBookingFirst: action.payload.soundBookingFirst
            };
        case UPDATE_SESSION_SOUND_LOICHUC:
            return {
                ...state,
                soundLoiChuc: action.payload.soundLoiChuc
            };
        case UPDATE_SESSION_SOUND_WARNING_CUSTOMER:
            return {
                ...state,
                soundWarningCustomer: action.payload.soundWarningCustomer
            };
        case UPDATE_SESSION_NOTIFY:
            return {
                ...state,
                notify_group_empty: action.payload
            };
        case UPDATE_SESSION_PLAY_BOOKING:
            return {
                ...state,
                hasPlaySoundBooking: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE:
            return {
                ...state,
                hasUpdate: action.payload
            };
        case UPDATE_SESSION_HAS_BOOKING:
            return {
                ...state,
                hasBooking: action.payload
            };

        case UPDATE_SESSION_HAS_NOTIFY:
            return {
                ...state,
                hasNotify: action.payload
            };

        case UPDATE_SESSION_HAS_FIX_BG:
            return {
                ...state,
                hasFixBg: action.payload
            };

        case UPDATE_SESSION_HAS_UPDATE_GROUP:
            return {
                ...state,
                hasUpdateGroup: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE_TIME_OUT:
            return {
                ...state,
                hasUpdateTimeOut: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE_TIME_ACTIVE:
            return {
                ...state,
                hasUpdateTimeActive: action.payload
            };
        case UPDATE_SESSION_HAS_UPDATE_CURRENT_AREA:
            return {
                ...state,
                hasUpdateCurrenArea: action.payload
            };
        case UPDATE_SESSION_HAS_BOOKING_ACTIVE:
            return {
                ...state,
                hasBookingActive: action.payload
            };
        default:
            return state;
    }
};