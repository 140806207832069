import React, {useState, useEffect, useContext} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector, useDispatch} from "react-redux";
import AlertDialog from '../components/AlertDialog';
import CircularLoading from "../components/CircularLoading";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {
    features,
    language
} from 'config';
import {api, FirebaseContext} from 'common';
import Popup from "reactjs-popup";
import ImportSlogan from "../components/Settings/ImportSlogan";
import {Text} from 'react-native-web';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: 192,
        height: 192
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }
}));

const Settings = (props) => {
    const {
        editSettings,
        clearSettingsViewError
    } = api;
    const settingsdata = useSelector(state => state.settingsdata);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [data, setData] = useState({
        code: 'VND',
        symbol: 'đ',
        bonus: 0,
        bonusd: 0,
        panic: '',
        otp_secure: false,
        min_wallet: 100000,
        approve_by_time: false,
        approve_by_px: false,
        approve_by_vip: false,
        approve_by_total_book: false,
        approve_by_wallet: false,
        driver_approval: false,
        email_verify: false,
        CarHornRepeat: false,
        CompanyName: '',
        CompanyWebsite: '',
        CompanyTerms: '',
        driverTerms: '',
        riderTerms: '',
        etoTerms: '',
        TwitterHandle: '',
        FacebookHandle: '',
        InstagramHandle: '',
        AppleStoreLink: '',
        PlayStoreLink: '',
        ApkLink: '',
        license: '',
        contact_email: '',
        convert_to_mile: false,
        source_kh: false,
        source_tt: false,
        source_lx: false,
        report_year: 2022,
        loichao: 0,
        bankinh: 3,
        refundValue: 100000,
        minTotalToCount: 150000,
        minKilometToCount: 10,
        velocity: 100,
        minPrice1: 9000,
        maxPrice1: 20000,
        minPrice2: 9000,
        maxPrice2: 20000,
        min7Price1: 9000,
        max7Price1: 20000,
        min7Price2: 9000,
        max7Price2: 20000,
        driverCodeList: '',
        maintenance: false,
        maintenance_date: '',
        maintenance_msg: '',
        maintenance_url: '',
        callback_url_pa: '',
        callback_url_binhanh: '',
        lock_vip: true,
        vip_limit: 2
    });
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (settingsdata.settings) {
            setData(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    const handleSwitchChange = (e) => {
        setData({...data, [e.target.name]: e.target.checked});
    };

    const handleTextChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const handleBonusChange = (e) => {
        setData({...data, bonus: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : ''});
    };

    const handleBonusdChange = (e) => {
        setData({...data, bonusd: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : ''});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (features.AllowCriticalEditsAdmin) {
            if (data.bonus === '') {
                alert(language.proper_bonus)
            } else {
                setClicked(true);
                dispatch(editSettings(data));
            }
        } else {
            alert(language.demo_mode);
        }
    }

    const handleClose = () => {
        setClicked(false);
        dispatch(clearSettingsViewError());
    };

    const ModalSloganPopup = (props) => (
        <Popup
            trigger={<button className='button'> Import Slogan</button>}
            modal
            nested
            position="top center"
        >
            {close => (
                <div className={classes.modal}>
                    <button className={classes.modalClose} onClick={close}>
                        X
                    </button>
                    <div className={classes.modalHeader}> Import Slogan</div>
                    <div className={classes.modalContent}>
                        {' '}
                        <ImportSlogan close={close}/>
                    </div>
                    <div className={classes.modalActions}>
                        <button
                            className='button'
                            onClick={() => {
                                console.log('modal closed ');
                                close();
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );

    return (
        settingsdata.loading ? <CircularLoading/> :
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography component="h1" variant="h5">
                            {language.currency_settings}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="vip_limit"
                                    label={'Vip giới hạn'}
                                    name="vip_limit"
                                    autoComplete="vip_limit"
                                    onChange={handleTextChange}
                                    value={data.vip_limit}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="symbol"
                                    label={language.currency_symbol}
                                    name="symbol"
                                    autoComplete="symbol"
                                    onChange={handleTextChange}
                                    value={data.symbol}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="code"
                                    label={language.currency_code}
                                    name="code"
                                    autoComplete="code"
                                    onChange={handleTextChange}
                                    value={data.code}
                                />
                            </Grid>
                        </Grid>
                        <Typography component="h1" variant="h5">
                            {language.referral_bonus}
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="bonus"
                            label={language.referral_bonus}
                            name="bonus"
                            autoComplete="bonus"
                            onChange={handleBonusChange}
                            value={data.bonus}
                        />
                        <Typography component="h1" variant="h5" style={{marginTop: '30px'}}>
                            {language.referral_bonus_driver}
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="bonusd"
                            label={language.referral_bonus_driver}
                            name="bonusd"
                            autoComplete="bonusd"
                            onChange={handleBonusdChange}
                            value={data.bonusd}
                        />

                        <Typography component="h1" variant="h5" style={{marginTop: '30px'}}>
                            {"Số tiền hoàn"}
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="refundValue"
                            label={"Số tiền hoàn"}
                            name="refundValue"
                            autoComplete="refundValue"
                            onChange={handleBonusdChange}
                            value={data.refundValue}
                        />

                        <Typography component="h1" variant="h5" style={{marginTop: 8}}>
                            {language.security_title}
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="panic"
                            label={language.panic_num}
                            name="panic"
                            autoComplete="panic"
                            onChange={handleTextChange}
                            value={data.panic}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.otp_secure}
                                    onChange={handleSwitchChange}
                                    name="otp_secure"
                                    color="primary"
                                />
                            }
                            label={language.settings_label3}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.lock_vip}
                                    onChange={handleSwitchChange}
                                    name="lock_vip"
                                    color="primary"
                                />
                            }
                            label={"Khoá VIP"}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.approve_by_time}
                                    onChange={handleSwitchChange}
                                    name="approve_by_time"
                                    color="primary"
                                />
                            }
                            label={"Áp dụng thời gian trong điểm"}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.approve_by_px}
                                    onChange={handleSwitchChange}
                                    name="approve_by_px"
                                    color="primary"
                                />
                            }
                            label={"Áp dụng cấp phường xã"}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.approve_by_vip}
                                    onChange={handleSwitchChange}
                                    name="approve_by_vip"
                                    color="primary"
                                />
                            }
                            label={"Áp dụng điểm VIP"}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.approve_by_total_book}
                                    onChange={handleSwitchChange}
                                    name="approve_by_total_book"
                                    color="primary"
                                />
                            }
                            label={"Áp dụng tổng ck"}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.approve_by_wallet}
                                    onChange={handleSwitchChange}
                                    name="approve_by_wallet"
                                    color="primary"
                                />
                            }
                            label={"Áp dụng ví nội bộ"}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.driver_approval}
                                    onChange={handleSwitchChange}
                                    name="driver_approval"
                                    color="primary"
                                />
                            }
                            label={language.settings_label4}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.email_verify}
                                    onChange={handleSwitchChange}
                                    name="email_verify"
                                    color="primary"
                                />
                            }
                            label={language.settings_label5}
                        />
                        <Typography component="h1" variant="h5" style={{marginTop: '10px'}}>
                            {language.advance_settings}
                        </Typography>
                        <FormControlLabel style={{marginTop: '10px'}}
                                          control={
                                              <Switch
                                                  checked={data.CarHornRepeat}
                                                  onChange={handleSwitchChange}
                                                  name="CarHornRepeat"
                                                  color="primary"
                                              />
                                          }
                                          label={language.car_horn_repeat}
                        />
                        <FormControlLabel style={{marginTop: '10px'}}
                                          control={
                                              <Switch
                                                  checked={data.convert_to_mile}
                                                  onChange={handleSwitchChange}
                                                  name="convert_to_mile"
                                                  color="primary"
                                              />
                                          }
                                          label={language.convert_to_mile}
                        />
                        <Typography component="h1" variant="h5">
                            {"Nguồn báo cáo"}
                        </Typography>
                        <FormControlLabel style={{marginTop: '10px'}}
                                          control={
                                              <Switch
                                                  checked={data.source_kh}
                                                  onChange={handleSwitchChange}
                                                  name="source_kh"
                                                  color="primary"
                                              />
                                          }
                                          label={"Khách hàng"}
                        />
                        <FormControlLabel style={{marginTop: '10px'}}
                                          control={
                                              <Switch
                                                  checked={data.source_tt}
                                                  onChange={handleSwitchChange}
                                                  name="source_tt"
                                                  color="primary"
                                              />
                                          }
                                          label={"Trung tâm 675"}
                        />
                        <FormControlLabel style={{marginTop: '10px'}}
                                          control={
                                              <Switch
                                                  checked={data.source_lx}
                                                  onChange={handleSwitchChange}
                                                  name="source_lx"
                                                  color="primary"
                                              />
                                          }
                                          label={"Lái xe"}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="loichao"
                                    label={"Năm báo cáo"}
                                    name="report_year"
                                    autoComplete="report_year"
                                    onChange={handleTextChange}
                                    value={data.report_year}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="loichao"
                                    label={"Số dư ví tối thiểu"}
                                    name="min_wallet"
                                    autoComplete="min_wallet"
                                    onChange={handleTextChange}
                                    value={data.min_wallet}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        {/*<Typography component="h1" variant="h5" style={{marginTop:8}}>
              {language.purchase_settings_title}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="license"
              label={language.purchase_code}
              name="license"
              autoComplete="license"
              onChange={handleTextChange}
              value={data.license}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="contact_email"
              label={language.contact_email}
              name="contact_email"
              autoComplete="contact_email"
              onChange={handleTextChange}
              value={data.contact_email}
            />*/}
                        <Typography component="h1" variant="h5">
                            {"Cài đặt tín hiệu"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="loichao"
                                    label={"Thời gian phát lời chào(Phút)"}
                                    name="loichao"
                                    autoComplete="loichao"
                                    onChange={handleTextChange}
                                    value={data.loichao}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="bankinh"
                                    label={"Bán kính tín hiệu ( km )"}
                                    name="bankinh"
                                    autoComplete="bankinh"
                                    onChange={handleTextChange}
                                    value={data.bankinh}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>


                        <Typography component="h1" variant="h5">
                            {"Điều kiện điểm thưởng points"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="minTotalToCount"
                                    label={"Số tiền tối thiểu để cộng điểm(đ/cuốc khách)"}
                                    name="minTotalToCount"
                                    autoComplete="minTotalToCount"
                                    onChange={handleTextChange}
                                    value={data.minTotalToCount}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="minKilometToCount"
                                    label={"Số Km tối thiểu để cộng điểm"}
                                    name="minKilometToCount"
                                    autoComplete="minKilometToCount"
                                    onChange={handleTextChange}
                                    value={data.minKilometToCount}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="velocity"
                                    label={"Vận tốc để cộng điểm"}
                                    name="velocity"
                                    autoComplete="velocity"
                                    onChange={handleTextChange}
                                    value={data.velocity}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>


                        <Typography component="h3" variant="h5">
                            {"Điều kiện bước giá của bảng giá 1 chiều (4 Ghế)"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="minPrice1"
                                    label={"Đơn giá tối (đ/km)"}
                                    name="minPrice1"
                                    autoComplete="minPrice1"
                                    onChange={handleTextChange}
                                    value={data.minPrice1}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="maxPrice1"
                                    label={"Đơn giá tối đa (đ/km)"}
                                    name="maxPrice1"
                                    autoComplete="maxPrice1"
                                    onChange={handleTextChange}
                                    value={data.maxPrice1}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Typography component="h3" variant="h5">
                            {"Điều kiện bước giá của bảng giá 2 chiều (4 Ghế)"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="minPrice2"
                                    label={"Đơn giá tối (đ/km)"}
                                    name="minPrice2"
                                    autoComplete="minPrice2"
                                    onChange={handleTextChange}
                                    value={data.minPrice2}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="maxPrice2"
                                    label={"Đơn giá tối đa (đ/km)"}
                                    name="maxPrice2"
                                    autoComplete="maxPrice2"
                                    onChange={handleTextChange}
                                    value={data.maxPrice2}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Typography component="h3" variant="h5">
                            {"Điều kiện bước giá của bảng giá 1 chiều (7 Ghế)"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="min7Price1"
                                    label={"Đơn giá tối (đ/km)"}
                                    name="min7Price1"
                                    autoComplete="min7Price1"
                                    onChange={handleTextChange}
                                    value={data.min7Price1}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="max7Price1"
                                    label={"Đơn giá tối đa (đ/km)"}
                                    name="max7Price1"
                                    autoComplete="max7Price1"
                                    onChange={handleTextChange}
                                    value={data.max7Price1}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Typography component="h3" variant="h5">
                            {"Điều kiện bước giá của bảng giá 2 chiều (7 Ghế)"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="min7Price2"
                                    label={"Đơn giá tối (đ/km)"}
                                    name="min7Price2"
                                    autoComplete="min7Price2"
                                    onChange={handleTextChange}
                                    value={data.min7Price2}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="max7Price2"
                                    label={"Đơn giá tối đa (đ/km)"}
                                    name="max7Price2"
                                    autoComplete="max7Price2"
                                    onChange={handleTextChange}
                                    value={data.max7Price2}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Typography component="h3" variant="h5">
                            {"Danh sách lx theo dõi"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="list_code_driver"
                                    label={"Danh sách lái xe theo dõi"}
                                    name="driverCodeList"
                                    autoComplete="driverCodeList"
                                    onChange={handleTextChange}
                                    value={data.driverCodeList}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <Text>
                                    {"Import Slogan\n"}
                                </Text>
                                <ModalSloganPopup/>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Typography component="h1" variant="h5">
                            {language.app_info}
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="CompanyName"
                            label={language.CompanyName}
                            name="CompanyName"
                            autoComplete="CompanyName"
                            onChange={handleTextChange}
                            value={data.CompanyName}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="CompanyWebsite"
                            label={language.CompanyWebsite}
                            name="CompanyWebsite"
                            autoComplete="CompanyWebsite"
                            onChange={handleTextChange}
                            value={data.CompanyWebsite}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="CompanyTerms"
                            label={language.terms}
                            name="CompanyTerms"
                            autoComplete="CompanyTerms"
                            onChange={handleTextChange}
                            value={data.CompanyTerms}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="driverTerms"
                            label={"Chính sách lái xe"}
                            name="driverTerms"
                            autoComplete="driverTerms"
                            onChange={handleTextChange}
                            value={data.driverTerms}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="riderTerms"
                            label={"Chính sách khách hàng"}
                            name="riderTerms"
                            autoComplete="riderTerms"
                            onChange={handleTextChange}
                            value={data.riderTerms}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="etoTerms"
                            label={"Chính sách sử dụng ETO"}
                            name="etoTerms"
                            autoComplete="etoTerms"
                            onChange={handleTextChange}
                            value={data.etoTerms}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="FacebookHandle"
                            label={language.FacebookHandle}
                            name="FacebookHandle"
                            autoComplete="FacebookHandle"
                            onChange={handleTextChange}
                            value={data.FacebookHandle}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="TwitterHandle"
                            label={language.TwitterHandle}
                            name="TwitterHandle"
                            autoComplete="TwitterHandle"
                            onChange={handleTextChange}
                            value={data.TwitterHandle}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="InstagramHandle"
                            label={language.InstagramHandle}
                            name="InstagramHandle"
                            autoComplete="InstagramHandle"
                            onChange={handleTextChange}
                            value={data.InstagramHandle}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="AppleStoreLink"
                            label={language.AppleStoreLink}
                            name="AppleStoreLink"
                            autoComplete="AppleStoreLink"
                            onChange={handleTextChange}
                            value={data.AppleStoreLink}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="PlayStoreLink"
                            label={language.PlayStoreLink}
                            name="PlayStoreLink"
                            autoComplete="PlayStoreLink"
                            onChange={handleTextChange}
                            value={data.PlayStoreLink}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="ApkLink"
                            label={language.ApkLink}
                            name="ApkLink"
                            autoComplete="ApkLink"
                            onChange={handleTextChange}
                            value={data.ApkLink}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="callback_url_pa"
                            label={"Chuyển cuộc gọi sang URL"}
                            name="callback_url_pa"
                            autoComplete="callback_url_pa"
                            onChange={handleTextChange}
                            value={data.callback_url_pa}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="callback_url_binhanh"
                            label={"Chuyển dữ liệu BA sang URL"}
                            name="callback_url_binhanh"
                            autoComplete="callback_url_binhanh"
                            onChange={handleTextChange}
                            value={data.callback_url_binhanh}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={data.maintenance}
                                    onChange={handleSwitchChange}
                                    name="maintenance"
                                    color="primary"
                                />
                            }
                            label={"Bảo trì hệ thống"}
                        />

                        {data.maintenance ?
                            <TextField
                                id="maintenance_date"
                                label={"Bảo trì đến ngày"}
                                type="datetime-local"
                                variant="filled"
                                fullWidth
                                name="maintenance_date"
                                value={data.maintenance_date}
                                onChange={handleTextChange}
                            /> : null
                        }
                        {data.maintenance ?
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="maintenance_msg"
                                label={"Thông báo quản trị"}
                                name="maintenance_msg"
                                autoComplete="maintenance_msg"
                                onChange={handleTextChange}
                                value={data.maintenance_msg}
                            /> : null
                        }

                        {data.maintenance ?
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="maintenance_url"
                                label={"Đường dẫn điều hướng"}
                                name="maintenance_url"
                                autoComplete="maintenance_url"
                                onChange={handleTextChange}
                                value={data.maintenance_url}
                            /> : null
                        }

                    </Grid>

                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {language.submit}
                        </Button>
                    </Grid>
                </Grid>
                <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{language.update_failed}</AlertDialog>
            </form>
    );

}

export default Settings;
