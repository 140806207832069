import {EDIT_NEWS, FETCH_NEWS, FETCH_NEWS_FAILED, FETCH_NEWS_SUCCESS, PUSH_NEWS} from "../store/types";

const INITIAL_STATE = {
    news: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const newsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWS:
            return {
                ...state,
                loading: true
            };
        case FETCH_NEWS_SUCCESS:
            return {
                ...state,
                news: action.payload,
                loading: false
            };
        case FETCH_NEWS_FAILED:
            return {
                ...state,
                news: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case PUSH_NEWS:
            return state;

        case EDIT_NEWS:
            return state;

        default:
            return state;
    }
};
