import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {api, FirebaseContext} from 'common';
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {checkDriverValid} from "common/src/other/ActionHelper";
import {generatePhoneVn} from "common/src/other/CommonFunctions";
import {onValue} from "firebase/database";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: 192,
        height: 192
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const TestLogic = (props) => {
    const {getVipByUid} = useContext(FirebaseContext);
    const {
        editSettings,
        clearSettingsViewError
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [countDriver, setCountDriver] = useState('4');
    const [clicked, setClicked] = useState(false);
    const [listDriver, setListDriver] = useState([]);
    const settings = useSelector(state => state.settingsdata.settings);
    const [contactLimitCustomer, setContactLimitCustomer] = useState('');
    const [driverV, setDriverV] = useState('');
    const [result, setResult] = useState('');
    const [phoneCustomer, setPhoneCustomer] = useState(generatePhoneVn(9));
    const [listVip, setListVip] = useState([]);
    const [codeDriverCheck, setCodeDriverCheck] = useState('');

    useEffect(() => {
        let drivers = [];
        if (countDriver && parseInt(countDriver)) {
            for (let i = 0; i < parseInt(countDriver); i++) {
                drivers.push(acceptObj(
                    listDriver && listDriver[i] && listDriver[i].timestamp ? listDriver[i].timestamp : GetCurrentMiniTimeStamp().toString(),
                    listDriver && listDriver[i] && listDriver[i].time_accept ? listDriver[i].time_accept : GetCurrentMiniTimeStamp().toString(),
                    listDriver && listDriver[i] && listDriver[i].code ? listDriver[i].code : '',
                    listDriver && listDriver[i] && listDriver[i].perUse ? listDriver[i].perUse : 0,
                    listDriver && listDriver[i] && listDriver[i].wallet ? listDriver[i].wallet : 0,
                    listDriver && listDriver[i] && listDriver[i].driverVipStatus ? listDriver[i].driverVipStatus : false,
                    listDriver && listDriver[i] && listDriver[i].driverType ? listDriver[i].driverType : 'noi_bo',
                    listDriver && listDriver[i] && listDriver[i].contactLimited ? listDriver[i].contactLimited : '',
                    listDriver && listDriver[i] && listDriver[i].inGroup ? listDriver[i].inGroup : 'trong_diem',
                    listDriver && listDriver[i] && listDriver[i].vip_limit ? listDriver[i].vip_limit : 2,
                    listDriver && listDriver[i] && listDriver[i].phone ? listDriver[i].phone : generatePhoneVn(9),
                    listDriver && listDriver[i] && listDriver[i].codeDriver ? listDriver[i].codeDriver : generatePhoneVn(2),
                    listDriver && listDriver[i] && listDriver[i].vipAt ? listDriver[i].vipAt : GetCurrentMiniTimeStamp().toString(),
                ));
            }
            setListDriver(drivers);
        } else {
            setListDriver([])
        }
    }, [countDriver]);

    const handleTextChange = (e) => {
        setCountDriver(e.target.value);
    };

    const handleTextChange2 = (e, idx) => {
        let newList = listDriver.map((driver, index) => {
            if (index === idx) {
                driver[e.target.name] = e.target.value;
            }
            return driver;
        });
        setListDriver(newList);
    };

    const handleSwitchChange = (e, idx) => {
        let newList = listDriver.map((driver, index) => {
            if (index === idx) {
                driver[e.target.name] = e.target.checked;
            }
            return driver;
        });
        setListDriver(newList);
    };

    const handleSwitchOnlyChange = (e, idx) => {
        let newList = listDriver.map((driver, index) => {
            if (e.target.checked == true) {
                if (index === idx) {
                    driver[e.target.name] = e.target.checked;
                } else {
                    driver[e.target.name] = false;
                }
            } else {
                if (index === idx) {
                    driver[e.target.name] = e.target.checked;
                }
            }
            return driver;
        });
        setListDriver(newList);
    };

    const handleSelectChange = (e, idx) => {
        let newList = listDriver.map((driver, index) => {
            if (index === idx) {
                driver[e.target.name] = e.target.value;
            }
            return driver;
        });
        setListDriver(newList);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setClicked(true);

        if (listDriver && listDriver.length > 0) {
            let listNew = listDriver.map((driver) => {
                driver.timestamp = parseInt(driver.timestamp);
                driver.time_accept = parseInt(driver.time_accept);
                driver.perUse = parseInt(driver.perUse);
                driver.wallet = parseInt(driver.wallet);
                driver.vip_limit = parseInt(driver.vip_limit);
                driver.contact_limited = driver.contactLimited ? driver.contactLimited.split(",") : [];
                if (driver.inGroup == 'trong_khu_vuc') {
                    driver.timestamp = 9999999999999999;
                }
                driver.vipAt = parseInt(driver.vipAt);
                return driver;
            });

            let result = checkDriverValid(phoneCustomer, listNew, settings, driverV, contactLimitCustomer ? contactLimitCustomer.split(",") : null, true);
            setResult(result);
        }
    }

    const handleClose = () => {
        setClicked(false);
        dispatch(clearSettingsViewError());
    };

    const acceptObj = (timestamp, time_accept, code, perUse = 0, wallet = 0, driverVipStatus = false, driverType = 'noi_bo', contactLimited = '', inGroup = 'trong_diem', vip_limit = 2, phone = '', codeDriver = '', vipAt = '') => {
        return {
            timestamp: timestamp,
            time_accept: time_accept,
            code: code,
            perUse: perUse,
            wallet: wallet,
            driverVipStatus: driverVipStatus,
            driverType: driverType,
            contactLimited: contactLimited,
            inGroup: inGroup,
            vip_limit: vip_limit,
            phone: phone,
            codeDriver: codeDriver,
            vipAt: vipAt
        }
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: 50}}>
                    <Typography component="h1" variant="h5">
                        {"Cài đặt test"}
                    </Typography>
                    <Grid container spacing={2}>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={2} md={2} lg={2}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="codeDriverCheck"
                                label={"Mã TX Check"}
                                name="codeDriverCheck"
                                autoComplete="codeDriverCheck"
                                onChange={(e) => {
                                    setCodeDriverCheck(e.target.value);
                                }
                                }
                                value={codeDriverCheck}
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={4} sm={2} md={2} lg={2}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="countDriver"
                                label={"Số lx"}
                                name="countDriver"
                                autoComplete="countDriver"
                                onChange={handleTextChange}
                                value={countDriver}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="driverV"
                                label={"SĐT tài xế Cài hộ"}
                                name="driverV"
                                autoComplete="driverV"
                                onChange={(e) => {
                                    setDriverV(e.target.value);
                                }
                                }
                                value={driverV}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="phoneCustomer"
                                label={"SĐT Khách"}
                                name="phoneCustomer"
                                autoComplete="phoneCustomer"
                                onChange={(e) => {
                                    setPhoneCustomer(e.target.value);
                                }
                                }
                                value={phoneCustomer}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={4} sm={6} md={6} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="contactLimitCustomer"
                                label={"Danh sách hạn chế của KH(Phân cách = dấu ,)"}
                                name="contactLimitCustomer"
                                autoComplete="contactLimitCustomer"
                                onChange={(e) => {
                                    setContactLimitCustomer(e.target.value);
                                }
                                }
                                value={contactLimitCustomer}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{padding: 10}}>
                        {listDriver && listDriver.length > 0 ? listDriver.map((driver, index) => {
                                return (
                                    <Grid container spacing={2} key={"driverList" + index.toString()} style={{borderWidth: 1, borderColor: "#612C58", margin: 5, padding: 5, borderRadiu: 10}}>
                                        Lái xe: {index.toString()}
                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"codeDriver" + index.toString()}
                                                key={"codeDriver" + index.toString()}
                                                label={"Mã LX"}
                                                name={"codeDriver"}
                                                autoComplete={"codeDriver" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.codeDriver}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"perUse" + index.toString()}
                                                label={"Điểm VIP"}
                                                key={"perUse" + index.toString()}
                                                name={"perUse"}
                                                autoComplete={"perUse" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.perUse}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"vipAt" + index.toString()}
                                                label={"Vip cũ nhất"}
                                                key={"vipAt" + index.toString()}
                                                name={"vipAt"}
                                                autoComplete={"vipAt" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.vipAt}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"vip_limit" + index.toString()}
                                                label={"Giới hạn VIP"}
                                                key={"vip_limit" + index.toString()}
                                                name={"vip_limit"}
                                                autoComplete={"vip_limit" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.vip_limit}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"timestamp" + index.toString()}
                                                key={"timestamp" + index.toString()}
                                                label={"Thời gian trong điểm"}
                                                name={"timestamp"}
                                                autoComplete={"timestamp" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.timestamp}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"time_accept" + index.toString()}
                                                key={"time_accept" + index.toString()}
                                                label={"Thời gian nhận"}
                                                name={"time_accept"}
                                                autoComplete={"time_accept" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.time_accept}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"wallet" + index.toString()}
                                                key={"wallet" + index.toString()}
                                                label={"Số dư ví"}
                                                name={"wallet"}
                                                autoComplete={"wallet" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.wallet}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={driver.driverVipStatus}
                                                        key={"driverVipStatus" + index.toString()}
                                                        onChange={(e) => {
                                                            handleSwitchChange(e, index);
                                                        }
                                                        }
                                                        name={"driverVipStatus"}
                                                        color="primary"
                                                    />
                                                }
                                                label={"Trạng thái VIP"}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"phone" + index.toString()}
                                                key={"phone" + index.toString()}
                                                label={"Số điện thoại"}
                                                name={"phone"}
                                                autoComplete={"phone" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.phone}
                                            />
                                        </Grid>

                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId={"driverType" + index}
                                                    id={"driverType" + index}
                                                    value={driver.driverType}
                                                    label="Kiểu Tài xế"
                                                    name={"driverType"}
                                                    key={"driverType" + index.toString()}
                                                    onChange={(event) => {
                                                        //(event.target.value)
                                                        handleSelectChange(event, index);
                                                    }
                                                    }
                                                >
                                                    <MenuItem value={'noi_bo'}>Nội bộ</MenuItem>
                                                    <MenuItem value={'dong_dam'}>Đóng đàm</MenuItem>
                                                    <MenuItem value={'cong_nghe'}>Công nghệ</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId={"inGroup" + index}
                                                    id={"inGroup" + index}
                                                    value={driver.inGroup}
                                                    name={"inGroup"}
                                                    label="Điểm KD"
                                                    key={"inGroup" + index.toString()}
                                                    onChange={(event) => {
                                                        //(event.target.value)
                                                        handleSelectChange(event, index);
                                                    }
                                                    }
                                                >
                                                    <MenuItem value={'trong_diem'}>Trong điểm</MenuItem>
                                                    <MenuItem value={'trong_khu_vuc'}>Trong khu vực</MenuItem>
                                                    <MenuItem disabled={true} value={'ngoai_khu_vuc'}>Ngoài khu vực</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id={"contactLimited" + index.toString()}
                                                key={"contactLimited" + index.toString()}
                                                label={"SĐT hạn chế (phân cách = dấy ,)"}
                                                name={"contactLimited"}
                                                autoComplete={"contactLimited" + index}
                                                onChange={(e) => {
                                                    handleTextChange2(e, index);
                                                }
                                                }
                                                value={driver.contactLimited}
                                            />
                                        </Grid>
                                    </Grid>

                                )
                            })
                            : null
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {"Kiểm tra Tài xế nhận"}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={async () => {
                            if (codeDriverCheck) {
                                var vip = null;
                                let listVipSnapshot = await onValue(getVipByUid(codeDriverCheck), async listVipSnapshot => {
                                    if (listVipSnapshot) {
                                        let listVip = listVipSnapshot.val();
                                        if (listVip) {
                                            let vips = await Object.keys(listVip)
                                                .filter(key => listVip[key].valueStatus === 'tang')
                                                .map((i) => {
                                                    listVip[i].id = i;
                                                    return listVip[i];
                                                });

                                            if (vips && vips.length > 0) {
                                                vips = await vips.sort(function (a, b) {
                                                    if (!b.at) {
                                                        b.at = GetCurrentMiniTimeStamp();
                                                    }
                                                    if (!a.at) {
                                                        a.at = GetCurrentMiniTimeStamp();
                                                    }
                                                    return (parseFloat(a.at) - parseFloat(b.at));
                                                });
                                                vips = await vips.map((v) => {
                                                    v.at = ConvertTimeStampToDate(v.at);
                                                    return v;
                                                });
                                            }
                                            setListVip(vips);
                                            console.log("vips", vips);
                                        }
                                    }
                                });

                            } else {
                                alert('Vui lòng nhập mã Tài xế để KT')
                            }
                        }
                        }
                    >
                        {"Kiểm tra list VIP KD"}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            setResult('');
                            setListVip([]);
                        }
                        }
                    >
                        {"Xoá KQ"}
                    </Button>
                </Grid>

            </Grid>
            <div>
                {listVip && listVip.length > 0 ? "Danh Sách ETO: \n " + JSON.stringify(listVip) + "\n" : 'Không có VIP\n'}

                {result && result.result && result.result[0] ? "TÀI XẾ NHẬN: " + result.result[0].codeDriver + "\n" : '\n'}
                {result ? "\n" + JSON.stringify(result.msg) : ''}
                {result ? "\n" + JSON.stringify(result.tracking) : ''}
            </div>
        </form>
    );

}

export default TestLogic;
