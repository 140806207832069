import {FETCH_BA_BOOKING_TRACKING, FETCH_BA_BOOKING_TRACKING_FAILED, FETCH_BA_BOOKING_TRACKING_SUCCESS, STOP_BA_TRACKING_FETCH} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue} from "firebase/database";

export const fetchBookingTracking = () => (dispatch) => {
    const {
        bookingTrackingRef
    } = firebase;
    dispatch({
        type: FETCH_BA_BOOKING_TRACKING,
        payload: null,
    });
    onValue(bookingTrackingRef(), (snapshot) => {
        if (snapshot.val()) {
            let dataCar = snapshot.val();
            dispatch({
                type: FETCH_BA_BOOKING_TRACKING_SUCCESS,
                payload: dataCar
            });
        } else {
            dispatch({
                type: FETCH_BA_BOOKING_TRACKING_FAILED,
                payload: "Lỗi nạp thông tin xe",
            });
        }
    }, {onlyOnce: true});
};

export const stopFetchBookingTracking = () => (dispatch) => {

    const {
        bookingTrackingRef
    } = firebase;

    dispatch({
        type: STOP_BA_TRACKING_FETCH,
        payload: null,
    });
    off(bookingTrackingRef());

}

