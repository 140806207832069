import {FETCH_POINT, FETCH_POINT_FAILED, FETCH_POINT_SUCCESS, INIT_POINT, INIT_POINT_SUCCESS, PUSH_POINT, PUSH_POINT_FAILED, PUSH_POINT_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    rootPoint: null,
    point: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const pointsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_POINT:
            return {
                ...state,
                loading: true
            };
        case FETCH_POINT_SUCCESS:
            return {
                ...state,
                rootPoint: action.payload.point,
                loading: false
            };
        case FETCH_POINT_FAILED:
            return {
                ...state,
                rootPoint: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case INIT_POINT:
            return {
                ...state,
                loading: true
            };
        case INIT_POINT_SUCCESS:
            return {
                ...state,
                point: action.payload.point,
                loading: false
            };


        case PUSH_POINT:
            return {
                ...state,
                loading: true
            };
        case PUSH_POINT_SUCCESS:
            return {
                ...state,
                point: action.payload.point,
                loading: false
            };
        case PUSH_POINT_FAILED:
            return {
                ...state,
                point: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
