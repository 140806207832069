import {FETCH_NOTIFY_IMPORTANCE, FETCH_NOTIFY_IMPORTANCE_FAILED, FETCH_NOTIFY_IMPORTANCE_SUCCESS, PUSH_NOTIFY_IMPORTANCE, PUSH_NOTIFY_IMPORTANCE_FAILED, PUSH_NOTIFY_IMPORTANCE_SUCCESS, UPDATE_NOTIFY_IMPORTANCE, UPDATE_NOTIFY_IMPORTANCE_FAILED, UPDATE_NOTIFY_IMPORTANCE_SUCCESS} from "../store/types";
import {RequestPushMsg} from "../other/NotificationFunctions";
import {firebase} from '../config/configureFirebase';
import {onValue, push, update} from "firebase/database";

export const fetchNotifyImportance = (uid = null) => (dispatch) => {
    const {
        notifyImportanceToRef,
        auth
    } = firebase;

    dispatch({
        type: FETCH_NOTIFY_IMPORTANCE,
        payload: null,
    });
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    onValue(notifyImportanceToRef(uidCheck), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            let notifyPending = [];
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            for (let i = 0; i < arr.length; i++) {
                if (data[arr[i]] && data[arr[i]].status && data[arr[i]].status == 'pending') {
                    notifyPending.push(data[arr[i]]);
                }
            }
            dispatch({
                type: FETCH_NOTIFY_IMPORTANCE_SUCCESS,
                payload: {
                    notifyImportance: arr,
                    notifyPending: notifyPending
                }
            });
        } else {
            dispatch({
                type: FETCH_NOTIFY_IMPORTANCE_FAILED,
                payload: "Chưa có thông báo quan trọng",
            });
        }
    });
};

export const pushNotifyImportance = (data) => (dispatch) => {
    const {
        notifyImportanceRef,
        singleUserRef
    } = firebase;

    dispatch({
        type: PUSH_NOTIFY_IMPORTANCE,
        payload: null,
    });
    push(notifyImportanceRef(), data).then((res) => {
        var notifyKey = res.key;
        data.id = notifyKey;
        onValue(singleUserRef(data.to), snapshot => {
            if (snapshot.val()) {
                let user = snapshot.val();
                if (data.type != "warning_area_empty") {
                    if (user.pushToken) {
                        /*RequestPushMsg(user.pushToken, language.notification_title, data.title);*/
                    }
                }

            }
        }, {onlyOnce: true});
        dispatch({
            type: PUSH_NOTIFY_IMPORTANCE_SUCCESS,
            payload: {
                notify: data
            }
        });
    }).catch(error => {
        dispatch({
            type: PUSH_NOTIFY_IMPORTANCE_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
}
export const updateNotifyImportance = (id, data) => (dispatch) => {
    const {
        notifyImportanceIdRef,
        singleUserRef,
        auth
    } = firebase;

    dispatch({
        type: UPDATE_NOTIFY_IMPORTANCE,
        payload: null,
    });
    onValue(notifyImportanceIdRef(id), (snapshot) => {
        if (snapshot.val()) {
            let dataNotify = snapshot.val();
            update(notifyImportanceIdRef(id), data).then((res) => {
                var notifyUID = null;
                if (dataNotify.to != auth.currentUser.uid) {
                    notifyUID = dataNotify.to;
                } else {
                    notifyUID = dataNotify.from;
                }
                onValue(singleUserRef(notifyUID), snapshot => {
                    if (snapshot.val()) {
                        let user = snapshot.val();
                        if (user.pushToken) {
                            RequestPushMsg(user.pushToken, "Thông báo quan trọng đã thay đổi", data.title);
                        }
                    }
                }, {onlyOnce: true});
                dispatch({
                    type: UPDATE_NOTIFY_IMPORTANCE_SUCCESS,
                    payload: {
                        notify: data
                    }
                });
            }).catch(error => {
                dispatch({
                    type: UPDATE_NOTIFY_IMPORTANCE_FAILED,
                    payload: error.code + ": " + error.message,
                });
            });
        } else {
            dispatch({
                type: UPDATE_NOTIFY_IMPORTANCE_FAILED,
                payload: "Chưa có thông báo quan trọng",
            });
        }
    }, {onlyOnce: true});

}
