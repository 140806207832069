import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Price from "./CarType/Price";
import Popup from "reactjs-popup";
import {makeStyles} from "@material-ui/core/styles";
import {View} from "react-native-web";

const useStyles = makeStyles(theme => ({
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }

}));
export default function PricePopupModal(props) {
    const {dataGroup} = props;
    const classes = useStyles();
    return (
        <Popup
            trigger={
                <View>
                    <Button variant="outlined" color="primary">
                        Cập nhật
                    </Button>
                </View>

            }
            modal
            nested
            position="top center"
        >
            {close => (
                <div className={classes.modal}>
                    <button className={classes.modalClose} onClick={close}>
                        X
                    </button>
                    <div className={classes.modalHeader}> Bảng giá điểm kinh doanh #{dataGroup.label}</div>
                    <div className={classes.modalContent}>
                        {' '}
                        <Price dataGroup={dataGroup}/>
                    </div>
                    <div className={classes.modalActions}>
                        <button
                            className='button'
                            onClick={() => {
                                console.log('modal closed ');
                                close();
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
}
