import {EDIT_FORCE_NOTIFY, FETCH_FORCE_NOTIFY, FETCH_FORCE_NOTIFY_FAILED, FETCH_FORCE_NOTIFY_SUCCESS, PUSH_FORCE_NOTIFY, STOP_FETCH_FORCE_NOTIFY} from "../store/types";

const INITIAL_STATE = {
    force_notify: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const forcenotifyreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_FORCE_NOTIFY:
            return {
                ...state,
                loading: true
            };
        case FETCH_FORCE_NOTIFY_SUCCESS:
            return {
                ...state,
                force_notify: action.payload,
                loading: false
            };
        case FETCH_FORCE_NOTIFY_FAILED:
            return {
                ...state,
                force_notify: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case STOP_FETCH_FORCE_NOTIFY:
            return {
                ...state,
                force_notify: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case PUSH_FORCE_NOTIFY:
            return state;

        case EDIT_FORCE_NOTIFY:
            return state;

        default:
            return state;
    }
};
