import {EDIT_CATRUCDEM, FETCH_CATRUCDEM, FETCH_CATRUCDEM_DRIVER, FETCH_CATRUCDEM_DRIVER_FAILED, FETCH_CATRUCDEM_DRIVER_SUCCESS, FETCH_CATRUCDEM_FAILED, FETCH_CATRUCDEM_SUCCESS, SET_CATRUCDEM, UPDATE_CATRUCDEM_HISTORY} from "../store/types";

const INITIAL_STATE = {
    catrucdems: null,
    catrucdem: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const catrucdemreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CATRUCDEM:
            return {
                ...state,
                loading: true
            };
        case FETCH_CATRUCDEM_SUCCESS:
            return {
                ...state,
                catrucdems: action.payload,
                loading: false
            };
        case FETCH_CATRUCDEM_FAILED:
            return {
                ...state,
                catrucdems: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case FETCH_CATRUCDEM_DRIVER:
            return {
                ...state,
                loading: true
            };
        case FETCH_CATRUCDEM_DRIVER_SUCCESS:
            return {
                ...state,
                catrucdem: action.payload,
                loading: false
            };
        case FETCH_CATRUCDEM_DRIVER_FAILED:
            return {
                ...state,
                catrucdem: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case EDIT_CATRUCDEM:
            return state;
        case UPDATE_CATRUCDEM_HISTORY:
            return state;
        case SET_CATRUCDEM:
            return state;
        default:
            return state;
    }
};