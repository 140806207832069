import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {features, language} from 'config';
import {api, FirebaseContext} from 'common';
import {View} from "react-native-web";
import Button from "../components/CustomButtons/Button";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import PricePopupModal from "../components/PricePopupModal";
import {FETCH_GROUP_AREA_FAILED} from "common/src/store/types";

const useStyles = makeStyles(theme => ({
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }

}));
export default function GroupDrivers() {
    let navigate = useNavigate();
    const {refApi} = useContext(FirebaseContext);
    const {removeAreaByIdApi, removeMemberAreaApi} = refApi;
    const {
        editGroupDriver,
        regAreaLeader,
        regAreaMember,
        fetchAreas,
        stopFetchAreas,
        fetchGroupArea,
        fetchOnceDrivers
    } = api;
    const [data, setData] = useState([]);
    const auth = useSelector(state => state.auth);
    const [dataArea, setDataArea] = useState([]);
    const classes = useStyles();
    const groupdriverdata = useSelector(state => state.groupdriverdata);
    const [groups, setGroups] = useState(null);
    const dispatch = useDispatch();
    const drivers = useSelector(state => state.usersdata.once_drivers);
    const areas = useSelector(state => state.areasdata.areas);

    useEffect(() => {
        dispatch(fetchOnceDrivers());
        dispatch(fetchAreas());
        dispatch(fetchGroupArea());
        return () => {
            dispatch(stopFetchAreas());
            dispatch({
                type: FETCH_GROUP_AREA_FAILED,
                payload: language.group_driver_not_found,
            });
        };

    }, []);

    const columns = [
        {title: "Mã Nhóm", field: 'value', render: rowData => <span>{rowData.value}</span>},
        {title: "Quản Trị", field: 'role', render: rowData => <span>{rowData.role}</span>},
        {title: "Tên Nhóm", field: 'label', render: rowData => <span>{rowData.label}</span>},
        {title: "Số Đàm", field: 'codes', render: rowData => <span>{rowData.codes}</span>},
        {title: "Tọa độ tâm", field: 'coord', render: rowData => <span>{rowData.coord}</span>},
        {title: "Giới Hạn Nhóm", field: 'limit', render: rowData => <span>{rowData.limit}</span>},
        {title: "Từ", field: 'time_limit_from', render: rowData => <span>{rowData.time_limit_from}</span>},
        {title: "Đến", field: 'time_limit_to', render: rowData => <span>{rowData.time_limit_to}</span>},
        {title: "Xóa Khu Vực", field: 'reset_area', type: 'boolean', initialEditValue: false},
        {title: "Trạng thái khóa", field: 'lock_status', type: 'boolean', initialEditValue: false},
        {
            title: language.promo_locations, field: 'promo_location', render: rowData => (

                <View>
                    <Button variant="outlined" color="primary" onClick={(e) => {
                        e.preventDefault();
                        navigate('/groupcoord', {
                            search: `?coord_id=${rowData.value}`,
                            state: {rowData: rowData}
                        })
                    }}>
                        Cập nhật
                    </Button>
                </View>
            )
        },
        {
            title: "Bảng giá", field: 'promo_location', render: rowData => (
                <PricePopupModal dataGroup={rowData}/>
            )
        },
    ];

    useEffect(() => {
        if (groups == null && groupdriverdata.complex && groupdriverdata.complex.length > 0 && data.length == 0) {
            let groupKD = {};
            groupdriverdata.complex.map((g) => {
                groupKD[g.value] = g.label;
            });
            setGroups(groupKD);
            setData(groupdriverdata.complex);
        } else {
            //setData([]);
        }
    }, [groupdriverdata.complex]);

    useEffect(() => {
        if (dataArea.length == 0 && groupdriverdata.data && groupdriverdata.data.length > 0) {
            let listArea = []
            Object.keys(groupdriverdata.data).map((key) => {
                let item = groupdriverdata.data[key];
                item.display_area = item.properties ? JSON.stringify(Object.values(item.properties)) : '';
                item.display_area_value = '';
                item.id = key;
                listArea.push(item);
            });

            /*setDataArea(Object.values(groupdriverdata.data).map((item) => {
                item.display_area = item.properties ? JSON.stringify(Object.values(item.properties)) : '';
                item.display_area_value = '';
                return item;
            }));*/

            setDataArea(listArea);
        } else {
            //setDataArea([]);
        }
    }, [groupdriverdata.data]);

    const ModalArea = (props) => {
        return (
            <Popup
                trigger={<button className='button'> Dữ liệu khu vực </button>}
                modal
                nested
                position="top center"
            >
                {close => (
                    <div className={classes.modal}>
                        <button className={classes.modalClose} onClick={close}>
                            X
                        </button>
                        <div className={classes.modalHeader}> Danh sách khu vực</div>
                        <div className={classes.modalContent}>
                            {dataArea && dataArea.length > 0 ?
                                <MaterialTable
                                    title={""}
                                    options={{
                                        exportButton: true,
                                        pageSize: 5,
                                        pageSizeOptions: [5]
                                    }}
                                    columns={[
                                        {title: "Tên vị trí", field: 'display_area', render: rowData => <span>{rowData.display_area}</span>, editable: 'never'},
                                        {
                                            title: "Cài đặt nhóm", field: 'display_area_value', lookup: groups, initialEditValue: ""
                                        }
                                    ]}
                                    data={dataArea}
                                    editable={features.AllowCriticalEditsAdmin ? {
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                let groupCode = newData.display_area_value;
                                                if (groupCode && groupCode != '') {
                                                    let group = data.filter((g) => g.value == groupCode)[0];
                                                    const tblData = data;
                                                    let groupUpdate = tblData[tblData.indexOf(group)];
                                                    let oldArea = groupUpdate.areas ? groupUpdate.areas : [];
                                                    let oldAreaId = groupUpdate.area_ids ? groupUpdate.area_ids : [];
                                                    let newArea = newData.geometry && newData.geometry.coordinates && newData.geometry.coordinates[0] && newData.geometry.coordinates[0][0] ? newData.geometry.coordinates[0][0] : [];
                                                    if (newArea.length > 0) {
                                                        oldArea.push(newArea);
                                                        oldAreaId.push(newData.id);
                                                    }
                                                    groupUpdate.areas = oldArea;
                                                    groupUpdate.area_ids = oldAreaId;
                                                    let listAddress = Object.values(JSON.parse(newData.display_area));
                                                    let displayName = listAddress[listAddress.length - 1] + ", " + listAddress[listAddress.length - 2] + ", " + listAddress[listAddress.length - 3];
                                                    tblData[tblData.indexOf(group)] = groupUpdate;
                                                    dispatch(regAreaLeader(auth.info.uid, newData.id, {
                                                        name: displayName,
                                                        status: 'active',
                                                        type: auth.info.profile.usertype,
                                                        group_coords: groupUpdate.group_coords ? groupUpdate.group_coords : null
                                                    }));
                                                    dispatch(editGroupDriver(tblData, "Update"));
                                                }
                                            }),
                                    } : null}
                                /> : <b>Đợi load dữ liệu</b>}
                        </div>
                        <div className={classes.modalActions}>
                            <button
                                className='button'
                                onClick={() => {
                                    console.log('modal closed ');
                                    close();
                                }}
                            >
                                Đóng
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        )
    };


    return (
        groupdriverdata.loading ? <CircularLoading/> :
            <View>
                <ModalArea/>
                <MaterialTable
                    title={language.group_driver_title}
                    columns={columns}
                    data={data}
                    options={{
                        exportButton: true,
                        pageSize: 10
                    }}
                    editable={features.AllowCriticalEditsAdmin ? {
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    newData.totalDriver = 0;
                                    tblData.push(newData);
                                    dispatch(editGroupDriver(tblData, "Add"));
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData[tblData.indexOf(oldData)] = newData;
                                    dispatch(editGroupDriver(tblData, "Update"));
                                    if (newData.reset_area && newData.reset_area == true) {
                                        if (newData.area_ids && newData.area_ids.length > 0) {
                                            Object.values(newData.area_ids).map((areaId) => {
                                                removeAreaByIdApi(areaId);
                                            });
                                        }
                                        newData.areas = null;
                                        newData.reset_area = null;
                                        newData.area_ids = null;
                                    } else {
                                        if (newData.codes && newData.area_ids && areas && drivers && drivers.length > 0) {
                                            Object.values(newData.area_ids).map((areaId) => {
                                                let area = areas.filter((a) => a.id === areaId)[0];
                                                newData.codes.split(',').map((driverCode) => {
                                                    let driver = drivers.filter((d) => d.codeDriver.trim() === driverCode.trim())[0];
                                                    console.log(area.members);
                                                    if (driver && area) {
                                                        if (area.members) {
                                                            var isAddMember = true;
                                                            Object.values(area.members).map((member) => {
                                                                if (member.codeDriver.trim() == driverCode.trim()) {
                                                                    isAddMember = false;
                                                                }
                                                            });
                                                            if (isAddMember) {
                                                                dispatch(regAreaMember(driver.id, area.id, {
                                                                    codeDriver: driver.codeDriver ? driver.codeDriver.trim() : null,
                                                                    from_uid: driver.id,
                                                                    type: driver.usertype ? driver.usertype : '',
                                                                    fromDriverCode: "Trung Tâm",
                                                                    free: 0
                                                                }));
                                                            }
                                                        } else {
                                                            dispatch(regAreaMember(driver.id, area.id, {
                                                                codeDriver: driver.codeDriver ? driver.codeDriver.trim() : null,
                                                                from_uid: driver.id,
                                                                type: driver.usertype ? driver.usertype : '',
                                                                fromDriverCode: "Trung Tâm",
                                                                free: 0
                                                            }));
                                                        }
                                                    }
                                                });

                                                Object.values(area.members).map((member) => {
                                                    var isRemoveMember = true;
                                                    newData.codes.split(',').map((driverCode) => {
                                                        if (member.codeDriver.trim() == driverCode.trim()) {
                                                            isRemoveMember = false;
                                                        }
                                                    });
                                                    if (isRemoveMember) {
                                                        removeMemberAreaApi(area.id, member.uid);
                                                    }
                                                });
                                            });
                                        }
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    dispatch(editGroupDriver(tblData, "Delete"));
                                }, 600);
                            }),
                    } : null}
                />
            </View>

    );
}
