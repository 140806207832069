import {ConvertTimeStampToDate, generateTripCode, GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "./DateFunctions";
import {RequestPushMsg} from "./NotificationFunctions";
import {language} from "config";
import {onValue, set, update} from "firebase/database";


export function checkDriverValid(phoneCustomer, driver_accepted_array, settings = null, customer_signup_via_referral = null, customer_contact_limited = null, isDev = false) {
    let msg = '';
    let tracking = '';
    if (driver_accepted_array && driver_accepted_array.length > 0) {
        var sorted = driver_accepted_array;
        // VIP
        if (phoneCustomer) {
            if (isDev === true) {
                sorted = sorted.filter(ctl => {
                    let listContactLimited = ctl.contact_limited;
                    if (listContactLimited && Array.isArray(listContactLimited) && listContactLimited.length > 0) {
                        let hasInLimited = listContactLimited.filter(ltcd => ltcd != phoneCustomer);
                        if (hasInLimited && hasInLimited.length > 0) {
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }
                });
            } else {
                sorted = sorted.filter(ctl => {
                    let listContactLimited = ctl.contact_limited;
                    if (listContactLimited && Array.isArray(listContactLimited) && listContactLimited.length > 0) {
                        let hasInLimited = listContactLimited.filter(ltcd => ltcd.phone && ltcd.phone != phoneCustomer);
                        if (hasInLimited && hasInLimited.length > 0) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
            }

            tracking = tracking + '0. Loại danh sách hạn chế ' + JSON.stringify(sorted);
        }

        if (customer_contact_limited) {
            let listBlack = Object.values(customer_contact_limited);
            for (let i = 0; i < listBlack.length; i++) {
                if (listBlack[i].phone) {
                    sorted = sorted.filter(b => b.phone != listBlack[i].phone);
                } else {
                    sorted = sorted.filter(b => b.phone != listBlack[i]);
                }

            }
            tracking = tracking + '\n1. Loại danh sách hạn chế ' + JSON.stringify(sorted);
        }

        if (customer_signup_via_referral) {
            let driverV = sorted.filter(d => d.phone == '+84' + customer_signup_via_referral || d.phone == customer_signup_via_referral);
            if (driverV) {
                tracking = tracking + '\n2. Nhận số đt tài xế ưu tiên ' + JSON.stringify(driverV);
                if (isDev === true) {
                    return {
                        result: driverV,
                        tracking: tracking,
                        msg: msg
                    }
                } else {
                    return driverV;
                }
            }
        }

        if (settings && settings.approve_by_vip) {
            sorted = sorted.sort(function (a, b) {
                if (!b.perUse) {
                    b.perUse = 0;
                }
                if (!a.perUse) {
                    a.perUse = 0;
                }
                return (parseFloat(b.perUse) - parseFloat(a.perUse));
            });
            tracking = tracking + '\n3. Áp dụng VIP ' + JSON.stringify(sorted);
            let sortedL = sorted.filter(v => v.vip_limit && v.vip_limit > 0);
            // Thông báo xe công nghệ khi hết giới hạn
            // - Bạn đã sử dụng hết quyền ưu tiên hôm nay
            if (sortedL && sortedL.length > 0) {
                sorted = sortedL.sort(function (a, b) {
                    return (parseFloat(b.vip_limit) - parseFloat(a.vip_limit));
                });

                sorted = sorted.filter(v => v.vip_limit == sorted[0].vip_limit);

            }
            tracking = tracking + '\n4. Áp dụng Hạn chế theo ngày ' + JSON.stringify(sorted);

            if (sorted && sorted.length > 0) {
                let sortedV = sorted.filter(vv => vv.driverVipStatus == true);
                tracking = tracking + '\n6. Loại các tài xế ko bật VIP ' + JSON.stringify(sortedV);
                if (sortedV && sortedV.length > 0) {
                    sorted = sortedV;
                    let vipValue = sorted[0].perUse;
                    if (vipValue <= 0) {
                        sorted = sorted.filter(x => x.driverType != 'cong_nghe');
                        // Thông báo xe công nghệ khi ETO = 0
                        // - Bạn cần có điểm ETO để ưu tiên nhận cuốc khách


                        tracking = tracking + '\n5. Vip = 0 loại hết tài xế công nghệ ' + JSON.stringify(sorted);
                    } else {
                        sorted = sorted.filter(v => v.perUse == vipValue);
                        tracking = tracking + '\n5.1. Check  VIP Khả dụng ' + JSON.stringify(sorted);
                        if (sorted && sorted.length > 1) {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.vipAt) {
                                    b.vipAt = GetCurrentMiniTimeStamp();
                                }
                                if (!a.vipAt) {
                                    a.vipAt = GetCurrentMiniTimeStamp();
                                }
                                return (parseFloat(a.vipAt) - parseFloat(b.vipAt));
                            });
                            tracking = tracking + '\n5.2. Lấy Vip cũ nhất ' + JSON.stringify(sorted);
                            if (sorted[0] && sorted[1] && sorted[0].vipAt != sorted[1].vipAt) {
                                sorted = [sorted[0]]
                            }
                        }
                    }
                }

            }

            if (sorted && sorted.length > 1) {
                // Wallet
                if (settings && settings.approve_by_wallet) {
                    let minW = settings.min_wallet ? parseFloat(settings.min_wallet) : 100000;

                    let listMinWallet = sorted.filter(lv => !lv.wallet || (lv.wallet && parseFloat(lv.wallet) < minW) || lv.wallet == 0);
                    if (listMinWallet && listMinWallet.length > 0) {
                        for (let i = 0; i < listMinWallet.length; i++) {
                            if (isDev === true) {
                                msg = msg + listMinWallet[i].codeDriver + " Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW + '\n';
                            } else {
                                RequestPushMsg(listMinWallet[i].pushToken, language.notification_title, "Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW);
                            }
                        }
                    }
                    sorted = sorted.filter(lv => lv.wallet && parseFloat(lv.wallet) >= minW);
                    tracking = tracking + '\n7. Loại các tài xế số dư ví ko đủ ' + JSON.stringify(sorted);
                    if (sorted && sorted.length > 1) {
                        //Time
                        if (settings && settings.approve_by_time) {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.timestamp) {
                                    b.timestamp = GetCurrentTimeStamp();
                                }
                                if (!a.timestamp) {
                                    a.timestamp = GetCurrentTimeStamp();
                                }
                                return (a.timestamp - b.timestamp);
                            });
                            let timeStampValue = sorted[0].timestamp;
                            sorted = sorted.filter(v => v.timestamp == timeStampValue);
                            tracking = tracking + '\n8. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
                            // Speed
                            if (sorted && sorted.length > 1) {
                                sorted = sorted.sort(function (a, b) {
                                    if (!b.time_accept) {
                                        b.time_accept = 0;
                                    }
                                    if (!a.time_accept) {
                                        a.time_accept = 0;
                                    }
                                    return (a.time_accept - b.time_accept);
                                });
                                tracking = tracking + '\n9. Áp dụng Tốc độ ấn ' + JSON.stringify(sorted);
                                if (isDev === true) {
                                    notificationToVipZeroDev(driver_accepted_array, msg);
                                } else {
                                    notificationToVipZero(driver_accepted_array, sorted[0].uid)
                                }

                                if (isDev === true) {
                                    return {
                                        result: sorted,
                                        tracking: tracking,
                                        msg: msg
                                    }
                                } else {
                                    return sorted;
                                }

                            } else {
                                if (isDev === true) {
                                    notificationToVipZeroDev(driver_accepted_array, msg);
                                } else {
                                    if (sorted[0] && sorted[0].uid) {
                                        notificationToVipZero(driver_accepted_array, sorted[0].uid)
                                    }
                                }
                                tracking = tracking + '\n8. Áp dụng Thời gian trong điểm có 1 lx ' + JSON.stringify(sorted);
                                if (isDev === true) {
                                    return {
                                        result: sorted,
                                        tracking: tracking,
                                        msg: msg
                                    }
                                } else {
                                    return sorted;
                                }
                            }
                        } else {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.time_accept) {
                                    b.time_accept = 0;
                                }
                                if (!a.time_accept) {
                                    a.time_accept = 0;
                                }
                                return (a.time_accept - b.time_accept);
                            });
                            tracking = tracking + '\n8. Áp dụng Thời gian trong điểm có 1 lx ' + JSON.stringify(sorted);
                            if (isDev === true) {
                                notificationToVipZeroDev(driver_accepted_array, msg);
                            } else {
                                if (sorted[0] && sorted[0].uid) {
                                    notificationToVipZero(driver_accepted_array, sorted[0].uid)
                                }
                            }
                            if (isDev === true) {
                                return {
                                    result: sorted,
                                    tracking: tracking,
                                    msg: msg
                                }
                            } else {
                                return sorted;
                            }
                        }
                    } else {
                        if (isDev === true) {
                            notificationToVipZeroDev(driver_accepted_array, msg);
                        } else {
                            if (sorted[0] && sorted[0].uid) {
                                notificationToVipZero(driver_accepted_array, sorted[0].uid)
                            }
                        }
                        tracking = tracking + '\n7. Áp dụng số dư ví có 1 lái xe ' + JSON.stringify(sorted);
                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    }
                }
            } else {
                if (sorted && sorted.length == 1) {
                    if (sorted[0].perUse == 0) {
                        sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                        if (isDev === true) {
                            notificationToVipZeroDev(driver_accepted_array, msg);
                        } else {
                            if (sorted[0] && sorted[0].uid) {
                                notificationToVipZero(driver_accepted_array, sorted[0].uid)
                            }
                        }
                    }
                }
                tracking = tracking + '\n6. Áp dụng điểm VIP có 1 lái xe ' + JSON.stringify(sorted);
                if (isDev === true) {
                    return {
                        result: sorted,
                        tracking: tracking,
                        msg: msg
                    }
                } else {
                    return sorted;
                }
            }
        } else {
            // Wallet
            if (settings && settings.approve_by_wallet) {
                let minW = settings.min_wallet ? parseFloat(settings.min_wallet) : 100000;
                let listMinWallet = driver_accepted_array.filter(lv => !lv.wallet || (lv.wallet && parseFloat(lv.wallet) < minW) || lv.wallet == 0);
                if (listMinWallet && listMinWallet.length > 0) {
                    for (let i = 0; i < listMinWallet.length; i++) {
                        if (isDev === true) {
                            msg = msg + listMinWallet[i].codeDriver + " Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW + '\n';
                        } else {
                            RequestPushMsg(listMinWallet[i].pushToken, language.notification_title, "Để nhận được cuốc khách bạn cần số dư ví trách nhiệm tối thiểu " + minW);
                        }
                    }
                }
                sorted = driver_accepted_array.filter(lv => lv.wallet && parseFloat(lv.wallet) >= minW);
                tracking = tracking + '\n5. Áp dụng số dư ' + JSON.stringify(sorted);
                sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                tracking = tracking + '\n6. Vip = 0 loại hết tài xế công nghệ ' + JSON.stringify(sorted);
                if (sorted && sorted.length > 1) {
                    //Time
                    if (settings && settings.approve_by_time) {
                        sorted = sorted.sort(function (a, b) {
                            if (!b.timestamp) {
                                b.timestamp = GetCurrentTimeStamp();
                            }
                            if (!a.timestamp) {
                                a.timestamp = GetCurrentTimeStamp();
                            }
                            return (a.timestamp - b.timestamp);
                        });
                        let timeStampValue = sorted[0].timestamp;
                        sorted = sorted.filter(v => v.timestamp == timeStampValue);
                        tracking = tracking + '\n7. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
                        // Speed
                        if (sorted && sorted.length > 1) {
                            sorted = sorted.sort(function (a, b) {
                                if (!b.time_accept) {
                                    b.time_accept = 0;
                                }
                                if (!a.time_accept) {
                                    a.time_accept = 0;
                                }
                                return (a.time_accept - b.time_accept);
                            });
                            tracking = tracking + '\n8. Áp dụng tốc độ ấn ' + JSON.stringify(sorted);
                            if (isDev === true) {
                                return {
                                    result: sorted,
                                    tracking: tracking,
                                    msg: msg
                                }
                            } else {
                                return sorted;
                            }
                        } else {
                            tracking = tracking + '\n7. Áp dụng thời gian trong điểm có 1 lx ' + JSON.stringify(sorted);
                            if (isDev === true) {
                                return {
                                    result: sorted,
                                    tracking: tracking,
                                    msg: msg
                                }
                            } else {
                                return sorted;
                            }
                        }
                    } else {
                        sorted = sorted.sort(function (a, b) {
                            if (!b.time_accept) {
                                b.time_accept = 0;
                            }
                            if (!a.time_accept) {
                                a.time_accept = 0;
                            }
                            return (a.time_accept - b.time_accept);
                        });
                        tracking = tracking + '\n7. Áp dụng tốc độ ấn có 1 lx ' + JSON.stringify(sorted);
                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    }
                } else {
                    tracking = tracking + '\n6. Áp số dư ví có 1 lx ' + JSON.stringify(sorted);
                    if (isDev === true) {
                        return {
                            result: sorted,
                            tracking: tracking,
                            msg: msg
                        }
                    } else {
                        return sorted;
                    }
                }
            } else {
                // TIME
                if (settings && settings.approve_by_time) {
                    sorted = driver_accepted_array.sort(function (a, b) {
                        if (!b.timestamp) {
                            b.timestamp = GetCurrentTimeStamp();
                        }
                        if (!a.timestamp) {
                            a.timestamp = GetCurrentTimeStamp();
                        }
                        return (a.timestamp - b.timestamp);
                    });
                    tracking = tracking + '\n7. Áp dụng thời gian trong điểm ' + JSON.stringify(sorted);
                    sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                    tracking = tracking + '\n7.1. Loại lx công nghệ ' + JSON.stringify(sorted);
                    let timeStampValue = sorted[0].timestamp;
                    sorted = sorted.filter(v => v.timestamp == timeStampValue);

                    // Speed
                    if (sorted && sorted.length > 1) {
                        sorted = sorted.sort(function (a, b) {
                            if (!b.time_accept) {
                                b.time_accept = 0;
                            }
                            if (!a.time_accept) {
                                a.time_accept = 0;
                            }
                            return (a.time_accept - b.time_accept);
                        });

                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    } else {
                        if (isDev === true) {
                            return {
                                result: sorted,
                                tracking: tracking,
                                msg: msg
                            }
                        } else {
                            return sorted;
                        }
                    }
                } else {
                    sorted = driver_accepted_array.sort(function (a, b) {
                        if (!b.time_accept) {
                            b.time_accept = GetCurrentTimeStamp();
                        }
                        if (!a.time_accept) {
                            a.time_accept = GetCurrentTimeStamp();
                        }
                        tracking = tracking + '\n7. Áp dụng tốc độ ấn có 1 lx ' + JSON.stringify(sorted);
                        return (a.time_accept - b.time_accept);
                    });
                    sorted = sorted.filter(v => v.driverType != 'cong_nghe');
                    tracking = tracking + '\n6. Loại lx công nghệ có 1 lx ' + JSON.stringify(sorted);
                    if (isDev === true) {
                        return {
                            result: sorted,
                            tracking: tracking,
                            msg: msg
                        }
                    } else {
                        return sorted;
                    }
                }
            }
        }
    } else {
        if (isDev === true) {
            return {
                result: [],
                tracking: tracking,
                msg: msg
            }
        } else {
            return [];
        }
    }
}

export function notificationToVipZero(driver_accepted_array, uid) {
    let listDriver = driver_accepted_array.filter(lv => lv.driverVipStatus == true && lv.perUse == 0 && lv.uid != uid);
    if (listDriver && listDriver.length > 0) {
        for (let i = 0; i < listDriver.length; i++) {
            RequestPushMsg(listDriver[i].pushToken, language.notification_title, "Bạn cần có điểm VIP để nhận cuốc khách");
        }
    }
}

export function notificationToVipZeroDev(driver_accepted_array, msg) {
    let listDriver = driver_accepted_array.filter(lv => lv.driverVipStatus == true && lv.perUse == 0);
    if (listDriver && listDriver.length > 0) {
        for (let i = 0; i < listDriver.length; i++) {
            msg = msg + listDriver[i].codeDriver + " ạn cần có điểm VIP để nhận cuốc khách " + '\n';
        }
    }
}

export function prepareDataBeforAddBookingV2(
    bookingData,
    carSelect = null,
    pickUp = null,
    drop = null,
    estimateTemp = null,
    groupKd3Km = null,
    estimatedata = null,
    bookingPushOutList = null,
    bookingPushInList = null,
    bookingPushInAreaList = null,
    userByPhone = null
) {
    if (estimateTemp) {
        if (pickUp) {
            estimateTemp.pickup = pickUp;
        }
        if (drop) {
            estimateTemp.drop = drop;
        }
    }
    var data = {
        carType: bookingData.carType ? bookingData.carType : bookingData.carDetails.name,
        carTypeCode: bookingData.carTypeCode ? bookingData.carTypeCode : (carSelect && carSelect.code ? carSelect.code : ''),
        carImage: bookingData.carImage ? bookingData.carImage : (carSelect && carSelect.image ? carSelect.image : ''),
        pickup: pickUp ? pickUp : null,
        pickupAddress: pickUp && pickUp.add ? pickUp.add : null,
        drop: drop ? drop : null,
        dropAddress: drop && drop.add ? drop.add : null,
        status: bookingData.bookLater ? "BOOKING_LATER" : "NEWV2",
        bookLater: bookingData.bookLater ? bookingData.bookLater : false,
        otp: false,
        estimateTemp: estimateTemp ? estimateTemp : null,
        tripdate: bookingData.bookingDate ? bookingData.bookingDate : GetCurrentMiniTimeStamp(),
        bookingDate: bookingData.bookingDate ? bookingData.bookingDate : GetCurrentMiniTimeStamp(),
        coords: (pickUp) ? [{
            latitude: pickUp.lat,
            longitude: pickUp.lng
        }] : null,
        driver_group: bookingData.driver_group ? bookingData.driver_group : bookingData.groupDetails ? bookingData.groupDetails : groupKd3Km,
        customer: bookingData.customer ? bookingData.customer : bookingData.userDetails.uid,
        customer_signup_via_referral: userByPhone && userByPhone.signupViaReferral ? userByPhone.signupViaReferral : null,
        customer_name: bookingData.userDetails.profile.firstName + ' ' + bookingData.userDetails.profile.lastName,
        customer_contact_limited: userByPhone.contact_limited && userByPhone.contact_limited ? userByPhone.contact_limited : null,
        signupViaReferral: bookingData.userDetails.profile.signupViaReferral ? bookingData.userDetails.profile.signupViaReferral : '',
        customer_contact: bookingData.customer_contact ? bookingData.customer_contact : bookingData.userDetails.profile.mobile ? bookingData.userDetails.profile.mobile : '',
        customer_note: bookingData.customer_note ? bookingData.customer_note : bookingData.userDetails.profile.note ? bookingData.userDetails.profile.note : '',
        customer_token: bookingData.customer_token ? bookingData.customer_token : bookingData.userDetails.profile.customer_token ? bookingData.userDetails.profile.customer_token : '',
        customer_image: bookingData.customer_image ? bookingData.customer_image : bookingData.userDetails.profile.profile_image ? bookingData.userDetails.profile.profile_image : '',
        driver: null,
        driverRating: null,
        driver_name: null,
        is_auto: 'YES',
        driver_contact: null,
        CodeDriver: null,
        driver_token: null,
        teamId: bookingData.teamId ? bookingData.teamId : null,
        driver_image: null,
        trip_code_status: 'inactive',
        trip_code: generateTripCode(6),
        type: bookingData.type ? bookingData.type : "TT",
        source: bookingData.source ? bookingData.source : "TT",
        callId: bookingData.callId,
        driverBook: bookingData.driverBook ? bookingData.driverBook : null,
        driverBooked: bookingData.driverBook && bookingData.driverBook.code ? bookingData.driverBook.code : null,
        customer_wish: false,
        totalRequest: bookingData.totalRequest ? bookingData.totalRequest : null,
        driverInGroup: bookingData.driverInGroup ? bookingData.driverInGroup : null,
        driverOutGroup: bookingData.driverOutGroup ? bookingData.driverOutGroup : null,
        perCustomer: bookingData.per ? bookingData.per : 0,
        perUseCustomer: bookingData.perUse ? bookingData.perUse : 0,
        driverInArea: bookingData.driverInArea ? bookingData.driverInArea : null,
        bookingPushOutList: bookingPushOutList ? bookingPushOutList : null,
        bookingPushInList: bookingPushInList ? bookingPushInList : null,
        bookingPushInAreaList: bookingPushInAreaList ? bookingPushInAreaList : null,
        is_two_way: bookingData.is_two_way && bookingData.is_two_way === true ? true : false,
        estimate: estimatedata ? estimatedata[0] : null,
        estimatePrice: bookingData.priceEstimate ? bookingData.priceEstimate : 0,
        tripEstimate: bookingData.tripEstimate ? bookingData.tripEstimate : null,
        metterTime: 0,
        metterDistance: 0,
        metterPrice: 0,
        estimateDistance: estimatedata ? estimatedata[2] : null,
        estimateTime: estimatedata ? estimatedata[3] : null,
        areaId: bookingData.areaId ? bookingData.areaId : null,
        prices: bookingData.prices ? bookingData.prices : null
    }

    if (data.driver_group && data.driver_group.prices && data.carTypeCode) {
        data.prices = data.driver_group.prices[data.carTypeCode];
    }

    return data;
}

export function affterAddBookingV2(ref, dataInput, dispatch) {
    let data = dataInput.data;
    let bookingKey = dataInput.bookingKey;
    let settings = dataInput.settings;
    let localString = ConvertTimeStampToDate(GetCurrentMiniTimeStamp());
    let api = ref.api;

    if (data.bookingPushInList) {
        set(ref.countTimeRef(bookingKey), "BAO_TIN_HIEU_LAN_1");
        set(ref.requestedDriversRef(bookingKey), data.bookingPushInList);
        var totalTimes = 1;
        let countDownBooking = setInterval(() => {
            if (totalTimes >= 10) {
                //countTimeStampRef(bookingKey).set(GetCurrentTimeStamp());
                clearInterval(countDownBooking);
            } else {
                set(ref.countTimeRef(bookingKey), totalTimes);
                totalTimes = totalTimes + 1;
            }
        }, 1000);
        setTimeout(() => {
            onValue(ref.singleBookingRef(bookingKey), snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    data.id = bookingKey;
                    if (data.driver) {
                        set(ref.countTimeRef(bookingKey), "CO_LX_1_3KM_NHAN");
                    } else {
                        let bookingNew = data;
                        if (bookingNew) {
                            var taskUpdate = {};
                            var driver_accepted_array = bookingNew.driver_accepted ? Object.values(bookingNew.driver_accepted) : [];
                            var sorted = driver_accepted_array ? checkDriverValid(bookingNew.customer_contact, driver_accepted_array, settings, bookingNew.customer_signup_via_referral, bookingNew.customer_contact_limited, false) : [];
                            if (sorted[0] && sorted[0].uid) {
                                let driverId = sorted[0].uid;
                                onValue(ref.singleUserRef(driverId), res => {
                                    let driverObj = res.val();
                                    if (driverObj) {
                                        /*var newData = {};
                                        newData.content = localString + " [" + booking.customer_note + "] Tài xế #" + profile.codeDriver + " đã nhận #" + bookingKey;
                                        reportdriverRef.push(newData);*/
                                        taskUpdate.driver = driverId;
                                        taskUpdate.driver_image = driverObj.avatar ? driverObj.avatar : "";
                                        taskUpdate.driver_name = driverObj.firstName + ' ' + driverObj.lastName;
                                        taskUpdate.driver_contact = driverObj.mobile;
                                        taskUpdate.driver_token = driverObj.pushToken;
                                        taskUpdate.driver_bankCode = driverObj.bankCode;
                                        taskUpdate.driver_bankAccount = driverObj.bankAccount;
                                        taskUpdate.vehicle_number = driverObj.vehicleNumber;
                                        taskUpdate.codeDriver = driverObj.codeDriver;
                                        taskUpdate.driverRating = driverObj.ratings ? driverObj.ratings.userrating : "0";
                                        taskUpdate.fleetadmin = driverObj.fleetadmin ? driverObj.fleetadmin : '';
                                        taskUpdate.accept_time = GetCurrentMiniTimeStamp();
                                        taskUpdate.notify_to_center = localString + " Tài xế #" + driverObj.codeDriver + " Đã nhận #" + bookingKey;
                                        taskUpdate.status = "ACCEPTED";
                                        taskUpdate.count_time = "LAI_XE_DA_NHAN";
                                        taskUpdate.driverType = driverObj.driverType;
                                        //taskUpdate.driver_accepted = null;
                                        taskUpdate.driverCurrentAddress = driverObj.location;
                                        taskUpdate.driver_location = {
                                            at: GetCurrentMiniTimeStamp(),
                                            lat: driverObj.location.lat,
                                            lng: driverObj.location.lng,
                                        };
                                        taskUpdate.accept_time_stone = driverObj.location;
                                        taskUpdate.isDriverNotMistake = false;
                                        taskUpdate.prices = bookingNew.prices ? bookingNew.prices : (sorted[0].prices ? sorted[0].prices : null);
                                        taskUpdate.customer_wish = false;

                                        if (settings && settings.approve_by_vip && driverObj.driverVipStatus) {
                                            taskUpdate.vipStatus = true;
                                        }
                                        taskUpdate.perUseDriver = driverObj.perUse ? driverObj.perUse : 0;
                                        taskUpdate.perDriver = driverObj.per ? driverObj.per : 0;
                                        taskUpdate.customer_wish = false;

                                        update(ref.singleBookingRef(bookingKey), taskUpdate);

                                        if (data.type == 'LX' && data.customer) {
                                            let dataNotify = {
                                                date_add: GetCurrentTimeStamp(),
                                                status: 'pending',
                                                from: data.customer,
                                                type: 'booking_request',
                                                booking_id: bookingKey,
                                                title: "Lái xe " + driverObj.codeDriver + " đã nhận",
                                                content: "Lái xe " + driverObj.codeDriver + " đã nhận",
                                                to: data.customer,
                                                params: [data]
                                            };
                                            dispatch(api.pushNotifyImportance(dataNotify));
                                        }

                                    }
                                }, {onlyOnce: true});
                            } else {
                                set(ref.countTimeRef(bookingKey), "BAO_TIN_HIEU_LAN_2_REQUEST");
                                update(ref.singleBookingRef(bookingKey), {
                                    driver_accepted: null,
                                    driver_mark_ignore_in: data.bookingPushInList ? data.bookingPushInList : null,
                                    reason: 'Cuốc xe đã tự động hủy do không có lái xe phù hợp',
                                    cancel_time: GetCurrentMiniTimeStamp(),
                                    notify_to_center: "Cuốc xe đã tự động hủy do không có lái xe phù hợp"
                                });
                                if (data.callId) {
                                    update(ref.paCallByIdRef(data.callId), {status: "BAO_TIN_HIEU_LAN_2_REQUEST"});
                                }
                                if ((data.reason_type && data.reason_type == 'rider') || data.status == "CANCELLED") {
                                } else {
                                    if (data.type == 'LX' && data.customer) {
                                        let dataNotify = {
                                            date_add: GetCurrentTimeStamp(),
                                            status: 'pending',
                                            from: data.customer,
                                            type: 'booking_request',
                                            booking_id: bookingKey,
                                            title: "Cuốc khách không tìm thấy lái xe",
                                            content: "Có vẻ các tài xế đang bận, vui lòng đặt lại sau ít phút",
                                            to: data.customer,
                                            params: [data]
                                        };
                                        dispatch(api.pushNotifyImportance(dataNotify));
                                    }
                                }
                            }

                            let pushed_in_drivers = bookingNew.pushed_in_drivers.split(',');
                            let drivers_accepted = bookingNew.driver_accepted ? Object.values(bookingNew.driver_accepted).map((d) => {
                                let driverCode = d.code.toString();
                                return driverCode;
                            }) : [];
                            for (let i = 0; i < pushed_in_drivers.length; i++) {
                                if (!drivers_accepted.includes(pushed_in_drivers[i])) {
                                    let driverCode = pushed_in_drivers[i];
                                    onValue(ref.userByCodeRef(driverCode), (snapshot) => {
                                        if (snapshot.val()) {
                                            const data = snapshot.val();
                                            const arr = Object.keys(data);
                                            RequestPushMsg(data[arr[0]].pushToken, "Thông báo", "Tự động chuyển sang nhóm tự do vì không nhận khách!");
                                            let userData = {
                                                groupDriver: 11,
                                                queue: false,
                                                group_info: {
                                                    group: {
                                                        id: 11,
                                                        label: "Kinh doanh tự do",
                                                        limit: "9999999",
                                                        lock_status: false,
                                                        totalDriver: 999,
                                                        value: "11",
                                                        at: GetCurrentTimeStamp()
                                                    }
                                                },
                                                coordData: {
                                                    coordId: 999,
                                                    groupId: 999
                                                }
                                            }
                                            update(ref.singleUserRef(arr[0]), userData);
                                        }
                                    }, {onlyOnce: true});
                                }
                            }
                        }
                    }
                }
            }, {onlyOnce: true});
        }, 20000);
    } else {
        set(ref.countTimeRef(bookingKey), "BAO_TIN_HIEU_LAN_2_REQUEST");
        update(ref.singleBookingRef(bookingKey), {
            driver_accepted: null,
        });
        if (data.callId) {
            update(ref.paCallByIdRef(data.callId), {status: "BAO_TIN_HIEU_LAN_2_REQUEST"});
        }
        data.id = bookingKey;
        if ((data.reason_type && data.reason_type == 'rider') || data.status == "CANCELLED") {
        } else {
            if (data.type == 'LX' && data.customer) {
                let dataNotify = {
                    date_add: GetCurrentTimeStamp(),
                    status: 'pending',
                    from: data.customer,
                    type: 'booking_request',
                    booking_id: bookingKey,
                    title: "Cuốc khách không tìm thấy lái xe",
                    content: "Có vẻ các tài xế đang bận, vui lòng đặt lại sau ít phút",
                    to: data.customer,
                    params: [data]
                };
                dispatch(api.pushNotifyImportance(dataNotify));
            }
        }
    }
}
