import React from "react";
import {useSelector} from "react-redux";
import {Marker} from "react-google-maps";
import * as geolib from "geolib";

export default function AvailableCarQueue(props) {
    const drivers = useSelector(state => state.usersdata.drivers);
    return (
        drivers && drivers.map(marker => {
            if(marker && marker.location && marker.location.lat && !marker.queue && marker.driverActiveStatus && !marker.lock_driver_status){
                let resultPickup = geolib.isPointInPolygon({
                    latitude: marker.location.lat,
                    longitude: marker.location.lng
                }, props.groupApply.areas[0]);
                if (resultPickup) {
                    return (
                        <Marker
                            position={{lat: marker.location.lat, lng: marker.location.lng}}
                            key={marker.id}
                            options={
                                {
                                    icon: require(`../../assets/img/display_` + marker.carStatus).default,
                                    size: {width: 32, height: 32},
                                }
                            }
                            onClick={() => {
                                //props.loadCarInfo(marker);
                            }}
                            label={
                                {
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    text: marker.codeDriver,
                                    padding: 10,
                                }
                            }
                        >
                        </Marker>
                    )
                }

            }

        })
    );
}
