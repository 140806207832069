import React, {useState, useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";
import {formatPriceRound} from "common/src/other/CommonFunctions";
import {formatNumber} from "react-native-currency-input";
import {features, language} from "config";
import {RequestPushMsg} from "common/src/other/NotificationFunctions";
import {api, FirebaseContext} from 'common';
import {fetchOnceDrivers} from "common/src/actions/usersactions";
import {fetchEtoWallet} from "common/src/actions/walletsactions";

export default function WalletManage() {
    const {refApi} = useContext(FirebaseContext);
    const {
        updateWalletBalance,
        fetchOnceDrivers,
        fetchEtoWallet,
        stopFetchEtoWallet
    } = api;
    const {setStatusWalletTransactionsApi, setBalanceWalletApi} = refApi;
    const dispatch = useDispatch();
    const areaWalletData = useSelector(state => state.walletsdata);
    const userData = useSelector(state => state.usersdata.once_drivers);
    const [wallet, setWallet] = useState(null);

    useEffect(() => {
        dispatch(fetchOnceDrivers());
        dispatch(fetchEtoWallet());
        return () => {
            dispatch(stopFetchEtoWallet());
        }
    }, []);

    useEffect(() => {
        if (areaWalletData.etoWallet) {
            setWallet(areaWalletData.etoWallet);
        } else {
            setWallet(null);
        }
    }, [areaWalletData]);

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }

    const columns = [
        /* {title: language.year, field: 'year'},
         {title: language.months, field: 'monthsName'},
         {title: "Ngày", field: 'dated'},*/
        {title: "Ngày Giờ", field: 'date_add', editable: 'never', render: rowData => <span>{ConvertTimeStampToDate(rowData.date_add * 1000)}</span>},
        {title: "SĐT", field: 'params', editable: 'never', render: rowData => <span>{rowData.params && rowData.params.mobile ? rowData.params.mobile : ''}</span>},
        {title: "MS", field: 'params', editable: 'never', render: rowData => <span>{rowData.params && rowData.params.driverCode ? rowData.params.driverCode : 'Khách hàng'}</span>},
        {title: 'Số tiền', field: 'amount', editable: 'never', render: rowData => <span>{formatPrice(parseFloat(rowData.amount))}</span>},
        {title: 'Ghi chú', field: 'note', editable: 'never'},
        {title: 'Kiểu gd', field: 'type', editable: 'never', render: rowData => <span>{rowData.type}</span>},
        {title: 'Hành động', field: 'status', lookup: {pending: "Đợi duyệt", approved: "Đồng ý", reject: "Từ chối"}, initialEditValue: "pending"},
    ];


    return (
        areaWalletData.loading ? <CircularLoading/> :
            <div>
                <MaterialTable
                    title={<h2 style={{textAlign: 'right', width: 400}}> {"Quản lý ví : Số dư hiện tại:"} {wallet ? formatPrice(wallet.balance) : '0'}</h2>}
                    columns={columns}
                    data={wallet && wallet.transactions ? Object.values(wallet.transactions) : []}
                    options={{
                        exportButton: true,
                        grouping: true,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                if (features.AllowCriticalEditsAdmin) {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData.status && oldData.status === 'pending') {
                                            if (newData.status === 'approved') {
                                                setStatusWalletTransactionsApi('eto', newData.id, 'approved');
                                                var newBalance = parseFloat(wallet.balance);
                                                if (newData.type == 'Deposit') {
                                                    newBalance = parseFloat(wallet.balance) - parseFloat(newData.amount);
                                                }
                                                if (newData.type == 'Withdraw') {
                                                    newBalance = parseFloat(wallet.balance) + parseFloat(newData.amount);
                                                }
                                                setBalanceWalletApi('eto', newBalance);
                                                let driver = userData.filter((d) => d.id === newData.params.uid)[0];
                                                if (driver) {
                                                    var walletBalance = parseFloat(driver.walletBalance);

                                                    if (newData.type == 'Deposit') {
                                                        walletBalance = parseFloat(driver.walletBalance) + parseFloat(newData.amount);
                                                    }
                                                    if (newData.type == 'Withdraw') {
                                                        walletBalance = parseFloat(driver.walletBalance) - parseFloat(newData.amount);
                                                    }

                                                    let details = {
                                                        type: newData.type,
                                                        amount: parseFloat(newData.amount),
                                                        date: GetCurrentMiniTimeStamp(),
                                                        txRef: newData.ref,
                                                        transaction_id: GetCurrentMiniTimeStamp()
                                                    }
                                                    dispatch(updateWalletBalance(walletBalance, details, newData.params.uid));
                                                }
                                                if (newData.params.pushToken) {
                                                    RequestPushMsg(newData.params.pushToken, "Lệnh giao dịch lúc " + ConvertTimeStampToDate(newData.date_add * 1000) + ' đã đã được xác nhận. Số dư ví sẽ được cập nhật!');
                                                }
                                            }
                                            if (newData.status === 'reject') {
                                                setStatusWalletTransactionsApi('eto', newData.id, 'reject');
                                                if (newData.params.pushToken) {
                                                    RequestPushMsg(newData.params.pushToken, "Lệnh giao dịch lúc " + ConvertTimeStampToDate(newData.date_add * 1000) + ' đã bị từ chối, Vui lòng liên hệ hỗ trợ!');
                                                }
                                            }
                                        } else {
                                            alert("Bạn đã xác nhận giao dịch! Không thể hoàn tác!");
                                        }

                                    }, 600);
                                } else {
                                    alert(language.demo_mode);
                                    reject();
                                }
                            })
                    }}

                />
            </div>
    );
}
