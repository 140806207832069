import React, {useContext, useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Text, View} from "react-native-web";
import {api, FirebaseContext} from "common";
import CancelCallCenter from "./CancelCallCenter";
import {ConvertTimeStampToDate} from "common/src/other/DateFunctions";
import CallNowCenter from "./CallNowCenter";
import {colors} from "../../config/theme";
import {useNavigate} from "react-router-dom";

export default function ButtonCallCenter(props) {
    const {
        bookingDetail,
        getUserDetail
    } = api;
    const dispatch = useDispatch();
    const [callDetail, setCallDetail] = useState(null);
    const settings = useSelector(state => state.settingsdata.settings);
    const usersdata = useSelector(state => state.usersdata.user_detail);
    const bookingdata = useSelector(state => state.bookingdata.bookingDetail);
    const [showCancel, setShowCancel] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [showCallNow, setShowCallNow] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [bookingDetailObj, setBookingDetailObj] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (usersdata && userDetail === null) {
            setUserDetail(usersdata);
        }
    }, [usersdata]);

    useEffect(() => {
        if (bookingdata && bookingDetailObj === null) {
            setBookingDetailObj(bookingdata);
        }
    }, [bookingdata]);

    useEffect(() => {
        if (bookingDetailObj && bookingDetailObj.driver && userDetail === null) {
            dispatch(getUserDetail(bookingDetailObj.driver))
        }
    }, [bookingDetailObj, userDetail]);

    useEffect(() => {
        if (userDetail && userDetail.mobile && showCallNow === null && callDetail) {
            setShowCallNow({phone: userDetail.mobile, uniqueid: callDetail.uniqueid, firebase_id: callDetail.firebase_id});
        }
    }, [userDetail, showCallNow, callDetail]);

    const listLyDo = [
        'Số bị trượt máy',
        'Lái xe gọi',
        'Khách hỏi thông tin',
        'Không có phương tiện hỗ trợ',
        'Vấn đề khác'
    ];
    const lineOptions = [
        {value: "101", label: "Line 101"},
        {value: "102", label: "Line 102"},
        {value: "103", label: "Line 103"},
        {value: "104", label: "Line 104"}
    ];
    useEffect(() => {
        if (props.callDetail) {
            setCallDetail(props.callDetail);
        }
    }, [props]);

    const closeCallback = useCallback(
        () => {
            dispatch({
                type: 'SELECT_USER_SUCCESS',
                payload: null
            });
            dispatch({
                type: 'SELECT_BOOKING_DETAIL_SUCCESS',
                payload: null
            });
            setShowCallNow(null);
            setUserDetail(null);
            setBookingDetailObj(null);
            setShowCancel(false);
            setModalVisible(false);
        },
        [],
    );

    return (
        <View style={{
            flex: 0.8,
            justifyContent: 'center',
            marginHorizontal: 10,
        }}>
            {callDetail && callDetail.requestHelper && callDetail.requestHelper === 'pickup' && callDetail.status !== 'booked_cancel' ?
                <Button
                    onPress={() => {
                        navigate('/dashboard', {
                            state: {callDetail: callDetail}
                        });
                    }}
                    title={"Chỉ đường"}
                    titleStyle={{color: "#FFF"}}
                    color={colors.BACKGROUND_DARK}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14,
                        marginBottom: 5
                    }}
                /> : null
            }

            {callDetail && callDetail.requestHelper && callDetail.requestHelper === 'pickup_added' && callDetail.status !== 'booked_cancel' ?
                <Button
                    onPress={() => {
                        alert("Đã cập nhật điểm đón");
                    }}
                    title={"Đã chỉ đường"}
                    titleStyle={{color: "#FFF"}}
                    color={colors.BACKGROUND_DARK}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14,
                        marginBottom: 5
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'paid' && callDetail.driver_code ?
                <Button
                    onPress={() => {
                        if (!callDetail.waring_accept && !callDetail.waring_pickup) {
                            alert("# " + callDetail.driver_code + " đã hoàn thành");
                        } else {
                            let msg = "# " + callDetail.driver_code;
                            if (callDetail.waring_accept) {
                                msg = msg + " điểm nhận cuốc khách không nằm trong vùng tín hiệu. \n";
                            }
                            if (callDetail.waring_accept) {
                                msg = msg + " Điểm khách lên xe không nằm trong vùng tín hiệu.";
                            }
                            alert(msg);

                        }
                    }}
                    title={"#" + callDetail.driver_code + " hoàn thành"}
                    titleStyle={{color: "#FFF", fontSize: 13}}
                    color={callDetail.waring_accept || callDetail.waring_pickup ? colors.YELLOW.primary : colors.GREEN.prime}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked' ?
                <Button
                    onPress={() => {
                        alert("Đã đặt cuốc khách mã");
                    }}
                    title={"Cuốc khách đã được tạo"}
                    titleStyle={{color: "#FFF"}}
                    color={colors.BACKGROUND_DARK}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'accept' && callDetail.driver_code && props.callDetail.booking_id ?
                <Button
                    onPress={() => {
                        setShowCallNow(null);
                        if (props.callDetail && props.callDetail.booking_id) {
                            dispatch(bookingDetail(props.callDetail.booking_id));
                            setModalVisible(true);
                        }
                    }}
                    color={colors.BACKGROUND_DARK}
                    title={"#" + callDetail.driver_code + " đã nhận"}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_1_cancel' ?
                <Button
                    onPress={() => {
                        setShowCancel(true);
                        setModalVisible(true);
                    }}
                    title={"Cuốc khách bị hủy lần 1"}
                    titleStyle={{color: "#FFF"}}
                    color={colors.BACKGROUND_DARK}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_2_cancel' ?
                <Button
                    onPress={() => {
                        setShowCancel(true);
                        setModalVisible(true);
                    }}
                    title={"Cuốc khách bị hủy lần 2"}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_3km_cancel' ?
                <Button
                    onPress={() => {
                        setShowCancel(true);
                        setModalVisible(true);
                    }}
                    title={"Cuốc khách bị hủy"}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_cancel' && props.rebook && callDetail.driver_code ?
                <Button
                    onPress={props.rebook(callDetail)}
                    color={colors.BACKGROUND_DARK}
                    title={"#" + callDetail.driver_code.trim() + " đặt lại"}
                    style={{padding: 20}}
                /> : null
            }

            {callDetail && callDetail.type != 'LX' && callDetail.status === 'booked_cancel' && !callDetail.cancel_by ?
                <Button
                    onPress={() => {
                        setShowCancel(true);
                        setModalVisible(true);
                    }}
                    title={"Nhập lý do bị hủy"}
                    color={colors.RED}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_cancel' && callDetail.cancel_by && callDetail.cancel_by == 'system' && callDetail.reason ?
                <Text style={{color: colors.RED, fontWeight: 'bold'}}>{callDetail.reason}</Text> : null
            }


            {callDetail && callDetail.status === 'cancel' && callDetail.driver_code ?
                <Button
                    onPress={() => {
                        alert("#" + callDetail.driver_code + " đã hủy");
                    }}
                    title={"#" + callDetail.driver_code + " đã hủy"}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'cancel' && !callDetail.driver_code && callDetail.reason ?
                <Button
                    onPress={() => {
                        alert(callDetail.reason);
                    }}
                    title={callDetail.reason}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_pickup' && callDetail.driver_code ?
                <Button
                    onPress={() => {
                        alert("#" + callDetail.driver_code + ' cần chỉ điểm đón');
                    }}
                    title={"#" + callDetail.driver_code + ' cần chỉ điểm đón'}
                    titleStyle={{color: "#FFF"}}
                    color={colors.BACKGROUND_DARK}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_mistake_2' ?
                <Button
                    onPress={() => {
                        setShowCancel(true);
                        setModalVisible(true);
                    }}
                    title={"Vi phạm 2 lần cần xử lý"}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'booked_mistake_1' ?
                <Button
                    onPress={() => {
                        setShowCancel(true);
                        setModalVisible(true);
                    }}
                    title={"Vi phạm 1 lần đang xử lý"}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }

            {callDetail && callDetail.status === 'BAO_TIN_HIEU_LAN_2_REQUEST' ?
                <Button
                    onPress={() => {
                        alert("Lái xe trong nhóm ko nhận");
                    }}
                    title={"Ko có lx nhận"}
                    color={colors.BACKGROUND_DARK}
                    titleStyle={{color: "#FFF"}}
                    containerStyle={{
                        flex: 1,
                        alignSelf: 'flex-end',
                        paddingRight: 14
                    }}
                /> : null
            }


            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 22
            }}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onPress={() => {
                        setModalVisible(false);
                    }}
                    titlle
                >
                    <View style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 22
                    }}>
                        <View style={{
                            margin: 20,
                            backgroundColor: "white",
                            borderRadius: 10,
                            borderWidth: 1,
                            padding: 10,
                            alignItems: "center",
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 4,
                            elevation: 5
                        }}>


                            {callDetail && showCancel === true && callDetail.booking_id ?
                                <CancelCallCenter
                                    destination={callDetail.destination} time={ConvertTimeStampToDate(callDetail.time * 1000)}
                                    phone={callDetail.source} uniqueid={callDetail.uniqueid} booking_id={callDetail}
                                    listLyDo={listLyDo} firebase_id={callDetail.firebase_id} closeCallback={closeCallback}/> : null
                            }
                            {callDetail && showCallNow && showCancel === false && userDetail ?
                                <CallNowCenter lineOptions={lineOptions}
                                               phone={showCallNow.phone} uniqueid={showCallNow.uniqueid}
                                               firebase_id={showCallNow.firebase_id} closeCallback={closeCallback}/> : null
                            }
                        </View>
                    </View>
                </Modal>
            </View>
        </View>
    );
}
