import React, {useState, useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
    features,
    language
} from 'config';
import {api, FirebaseContext} from 'common';
import { useLocation} from "react-router-dom";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import {Grid} from "@material-ui/core";

export default function Promos() {
    const {
        editPromoLocation,
        fetchPromoLocations
    } = api;
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const promodata = location.state.rowData;
    let promo_id = promodata.id;
    if (!promo_id) {
        promo_id = location.search;
    }
    const [address, setAddress] = useState(null);
    const columns = [
            {
                title: language.address,
                field: 'address',
                render: rowData => rowData.address,
                editComponent: (rowData) => {
                    return (
                        <Grid item xs={12}>
                            <GoogleMapsAutoComplete
                                apiOptions={{language: 'vi', region: 'vn'}}
                                style={{width: 400}}
                                variant={"outlined"}
                                placeholder={language.promo_locations}
                                value={address}
                                onChange={
                                    (value) => {
                                        if ("administrative_area_level_3" === value.types[0] || "sublocality_level_1" === value.types[0]) {
                                            setAddress(value);
                                        } else {
                                            alert(language.promo_valid_wards);
                                        }
                                    }
                                }
                            />
                        </Grid>
                    );
                }
            }
        ]
    ;

    useEffect(() => {
        dispatch(fetchPromoLocations(promo_id));
    }, [fetchPromoLocations]);

    const promolocationdata = useSelector(state => state.promolocationdata);

    useEffect(() => {
        if (promolocationdata.locations) {
            setData(promolocationdata.locations);
        } else {
            setData([]);
        }
    }, [promolocationdata.locations]);
    return (
        promolocationdata.loading ? <CircularLoading/> :
            <MaterialTable
                title={language.promo_locations + " Chiến dich: " + promodata.promo_name}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    newData.promo_id = promo_id;
                                    if (address && address.placeDetails && address.placeDetails.formatted_address.split(",").length === 4) {
                                        newData.address = address.placeDetails.formatted_address;
                                        newData.term = address.terms;
                                        tblData.push(newData);
                                        dispatch(editPromoLocation(tblData), "Add");
                                    } else {
                                        alert(language.promo_valid_wards);
                                        reject();
                                    }

                                }, 600)
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    newData.promo_id = promo_id;
                                    newData.address = address.formatted_address;
                                    newData.term = address.terms;
                                    tblData[tblData.indexOf(oldData)] = newData;
                                    dispatch(editPromoLocation(tblData), "Update");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    oldData.promo_id = promo_id;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    dispatch(editPromoLocation(tblData), "Delete");
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        }),
                }}
            />
    );
}
