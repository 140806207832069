import React, {useContext, useState} from 'react';
import {useDispatch} from "react-redux";
import {api, FirebaseContext} from "common";
import * as XLSX from 'xlsx';
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";
import {Text, View} from "react-native-web";
import {set} from "firebase/database";

export default function ImportSlogan(props) {
    const {sloganRef} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const {
        setCaTrucDem
    } = api;
    const [loading, setLoading] = useState(false);

    const processData = dataString => {
        let list = [];
        const dataStringLines = dataString.split(/\r\n|\n/);
        set(sloganRef(), dataStringLines);
        /*for (let i = 0; i < dataStringLines.length; i++) {
            const row = dataStringLines[i];
            if (!row || row == '') {
                continue;
            }
            list.push(row);
        }*/
    }

    // handle file upload
    const handleFileUpload = e => {
        setLoading(true);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, {header: 1});

            processData(data);
        };
        reader.readAsBinaryString(file);
        alert("Nạp dữ liệu thành công.");
        setTimeout(() => {
            setLoading(false);
            props.close();
        }, 3000);

    }
    return (
        loading ? <View style={{height: 100, width: 300}}><Text>Vui lòng đợi dữ liệu mới cập nhật</Text></View> :
            <input
                type="file"
                accept=".csv,.xlsx,.xls"
                placeholder={"Import Lịch Ca Đêm"}
                onChange={handleFileUpload}
                required={true}
            />

    );
}
