import React, {useState, useEffect, useContext} from 'react';
import {
    Select,
    MenuItem,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    TextField
} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import {useSelector, useDispatch} from "react-redux";
import AlertDialog from '../components/AlertDialog';
import {language} from 'config';
import {makeStyles} from '@material-ui/core/styles';
import {api, FirebaseContext} from 'common';
import {formatNumber} from 'react-native-currency-input';
import {formatPriceRound} from "common/src/other/CommonFunctions";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    title: {
        color: "#000",
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: '100%'
    }
}));

export default function AddBookings(props) {
    const {
        getEstimateV2,
        clearEstimate,
        addBooking,
        addBookingV2,
        clearBooking
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const cartypes = useSelector(state => state.cartypes.cars);
    const groupDrivers = useSelector(state => state.groupdriverdata.complex);
    const estimatedata = useSelector(state => state.estimatedata);
    const bookingdata = useSelector(state => state.bookingdata);
    const settings = useSelector(state => state.settingsdata.settings);
    const [carType, setCarType] = useState(language.select_car);
    const [groupDriver, setGroupDriver] = useState(language.select_group_driver);
    const [phoneRider, setPhoneRider] = useState('');
    const [noteRider, setNoteRider] = useState('Không có ghi chú');
    const [pickupAddress, setPickupAddress] = useState(null);
    const [dropAddress, setDropAddress] = useState(null);
    const [estimateModalStatus, setEstimateModalStatus] = useState(false);
    const [selectedCarDetails, setSelectedCarDetails] = useState(null);
    const [selectedGroupDrivers, setSelectedGroupDrivers] = useState(null);
    const [commonAlert, setCommonAlert] = useState({open: false, msg: ''});
    const [userCombo, setUserCombo] = useState(null);
    const [driverCombo, setDriverCombo] = useState(null);
    const [estimateRequested, setEstimateRequested] = useState(false);
    const [bookingType, setBookingType] = useState('Book Now');
    const auth = useSelector(state => state.auth);
    const getDateString = (date) => {
        let d = null;
        d = date ? new Date(date) : new Date();
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            mins = d.getMinutes();
        if (month >= 1 & month <= 9)
            month = '0' + month.toString();
        if (day >= 0 & day <= 9)
            day = '0' + day.toString();
        if (hours >= 0 & hours <= 9)
            hours = '0' + hours.toString();
        if (mins >= 0 & mins <= 9)
            mins = '0' + mins.toString();
        return [year, month, day].join('-') + 'T' + [hours, mins].join(':');
    }

    const [selectedDate, setSelectedDate] = useState(getDateString());

    const handleCarSelect = (event) => {
        setCarType(event.target.value);
        var carDetails = null;
        for (let i = 0; i < cartypes.length; i++) {
            if (cartypes[i].name === event.target.value) {
                carDetails = cartypes[i];
            }
        }
        setSelectedCarDetails(carDetails);
    };

    const handleGroupDriver = (event) => {
        setGroupDriver(event.target.value);
        var groupDetails = null;
        for (let i = 0; i < groupDrivers.length; i++) {
            if (groupDrivers[i].label === event.target.value) {
                groupDetails = groupDrivers[i];
            }
        }
        setSelectedGroupDrivers(groupDetails);
    };

    const handlePhoneRider = (event) => {
        setPhoneRider(event.target.value);
    };
    const handleNoteRider = (event) => {
        setNoteRider(event.target.value);
    };


    const handleBookTypeSelect = (event) => {
        setBookingType(event.target.value);
        if (bookingType === 'Book Later') {
            setSelectedDate(getDateString());
        }
    };

    const formatPrice = (value) => {
        return formatPriceRound(value, formatNumber) + "(đ)";
    }

    const onDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    useEffect(() => {
        if (estimatedata.estimate && estimateRequested) {
            setEstimateRequested(false);
            setEstimateModalStatus(true);
        }
    }, [estimatedata.estimate, estimateRequested]);

    const handleGetEstimate = (e) => {
        e.preventDefault();
        setEstimateRequested(true);
        if (selectedGroupDrivers && selectedCarDetails) {
            dispatch(getEstimateV2({
                pickup: pickupAddress == null && props.address ? props.address : pickupAddress,
                group_driver: dropAddress,
                carDetails: selectedCarDetails
            }));
        } else {
            setCommonAlert({open: true, msg: language.select_proper})
        }
    };

    const confirmBooking = (e) => {
        e.preventDefault();
        setEstimateModalStatus(false);
        let bookingObject = {
            pickup: pickupAddress,
            drop: dropAddress,
            carDetails: selectedCarDetails,
            userDetails: {
                uid: userCombo.uid,
                profile: {
                    firstName: userCombo.firstName,
                    lastName: userCombo.lastName,
                    mobile: userCombo.mobile,
                    pushToken: userCombo.pushToken
                }
            },
            estimate: estimatedata.estimate,
            tripdate: new Date(selectedDate).toString(),
            bookLater: bookingType === 'Book Later' ? true : false,
            settings: settings,
            booking_type_web: true,
            driverDetails: driverCombo ? {
                uid: driverCombo.uid,
                profile: {
                    firstName: driverCombo.firstName,
                    lastName: driverCombo.lastName,
                    mobile: driverCombo.mobile,
                    pushToken: driverCombo.pushToken
                }
            } : null
        };
        dispatch(addBooking(bookingObject));
    };

    const confirmBookingV2 = (e) => {
        e.preventDefault();
        setEstimateModalStatus(false);
        if (props.address) {
            props.address.add = props.address.description;
        }
        if (pickupAddress) {
            pickupAddress.add = pickupAddress.description;
        }
        let bookingObject = {
            pickup: pickupAddress == null && props.address ? props.address : pickupAddress,
            carDetails: selectedCarDetails,
            userDetails: {
                uid: auth.info.uid,
                profile: {
                    firstName: "Trung Tâm",
                    lastName: "",
                    mobile: phoneRider ? phoneRider : settings.panic,
                    note: noteRider ? noteRider : 'Không có ghi chú',
                    pushToken: auth.info.pushToken
                }
            },
            groupDetails: selectedGroupDrivers,
            settings: settings,
            tripdate: new Date(selectedDate).toString(),
            bookLater: false,
            booking_type_web: true
        };
        dispatch(addBookingV2(bookingObject));
    };

    const handleEstimateModalClose = (e) => {
        e.preventDefault();
        setEstimateModalStatus(false);
        dispatch(clearEstimate());
        setEstimateRequested(false);
    };

    const handleEstimateErrorClose = (e) => {
        e.preventDefault();
        dispatch(clearEstimate());
        setEstimateRequested(false);
    };

    const handleBookingAlertClose = (e) => {
        e.preventDefault();
        dispatch(clearBooking());
        dispatch(clearEstimate());
        clearForm();
    };

    const clearForm = () => {
        setUserCombo(null);
        setDriverCombo(null);
        setPickupAddress(null);
        setDropAddress(null);
        setSelectedCarDetails(null);
        setSelectedGroupDrivers(null);
        setCarType(language.select_car);
        setGroupDriver(language.select_group_driver);
        setBookingType(language.book_now);
        setEstimateRequested(false);
    }

    const handleBookingErrorClose = (e) => {
        e.preventDefault();
        dispatch(clearBooking());
        setEstimateRequested(false);
    };

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({open: false, msg: ''})
    };

    return (
        <div className={classes.container}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" className={classes.title}>
                            {language.addbookinglable}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleMapsAutoComplete
                            apiOptions={{language: 'vi', region: 'vn'}}
                            variant={"outlined"}
                            placeholder={language.pickup_location}
                            value={props.address ? props.address : pickupAddress}
                            className={classes.items}
                            onChange={
                                (value) => {
                                    setPickupAddress(value);
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {cartypes ?
                            <Select
                                id="car-type-native"
                                value={carType}
                                onChange={handleCarSelect}
                                variant="outlined"
                                fullWidth
                                className={carType === language.select_car ? classes.inputdimmed : classes.input}
                            >
                                <MenuItem value={language.select_car} key={language.select_car}>
                                    {language.select_car}
                                </MenuItem>
                                {
                                    cartypes.map((car) =>
                                        <MenuItem key={car.name} value={car.name}>
                                            <img src={car.image} className={classes.carphoto}
                                                 alt="car types"/>{car.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {cartypes ?
                            <Select
                                id="group-driver-native"
                                value={groupDriver}
                                onChange={handleGroupDriver}
                                variant="outlined"
                                fullWidth
                                className={groupDriver === language.select_group_driver ? classes.inputdimmed : classes.input}
                            >
                                <MenuItem value={language.select_group_driver} key={language.select_group_driver}>
                                    {language.select_group_driver}
                                </MenuItem>
                                {
                                    groupDrivers.map((group) =>
                                        <MenuItem key={group.label} value={group.label}>
                                            {group.label}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="phone-river-native"
                            label={"SĐT khách / Trung tâm"}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                className: classes.input
                            }}
                            value={phoneRider}
                            onChange={handlePhoneRider}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="note-river-native"
                            label={"Ghi chú cuốc xe"}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                className: classes.input
                            }}
                            value={noteRider}
                            onChange={handleNoteRider}
                        />
                    </Grid>
                    {bookingType === 'Book Later' ?
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="datetime-local"
                                label={language.booking_date_time}
                                type="datetime-local"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    className: classes.input
                                }}
                                value={selectedDate}
                                onChange={onDateChange}
                            />
                        </Grid>
                        : null}
                    <Grid item xs={12} sm={6}>
                        <Button
                            size="large"
                            onClick={handleGetEstimate}
                            variant="contained"
                            color="primary"
                            className={classes.buttonStyle}
                        >
                            <i className="fas fa-car"/>
                            {language.book}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={estimateModalStatus}
                onClose={handleEstimateModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{language.add_booking_by_group_title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {language.add_booking_by_group_description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEstimateModalClose} color="primary">
                        {language.cancel}
                    </Button>
                    <Button onClick={confirmBookingV2} color="primary" autoFocus>
                        {language.book_now}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog open={bookingdata.booking ? true : false}
                         onClose={handleBookingAlertClose}>{bookingdata.booking ? language.booking_success + bookingdata.booking.booking_id : null}</AlertDialog>
            <AlertDialog open={bookingdata.error.flag}
                         onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
            <AlertDialog open={estimatedata.error.flag}
                         onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        </div>
    );
}
