import {FETCH_AREA_WALLET, FETCH_AREA_WALLET_FAILED, FETCH_AREA_WALLET_SUCCESS, FETCH_ETO_WALLET, FETCH_ETO_WALLET_FAILED, FETCH_ETO_WALLET_SUCCESS, INIT_AREA_WALLET, INIT_AREA_WALLET_FAILED, INIT_AREA_WALLET_SUCCESS, PUSH_AREA_WALLET, PUSH_AREA_WALLET_FAILED, PUSH_AREA_WALLET_SUCCESS, PUSH_ETO_WALLET, PUSH_ETO_WALLET_FAILED, PUSH_ETO_WALLET_SUCCESS, STOP_FETCH_ETO_WALLET} from "../store/types";
import {GetCurrentTimeStamp} from "../other/DateFunctions";
import {off, onValue, push, set} from "firebase/database";
import {firebase} from '../config/configureFirebase';

export const fetchEtoWallet = () => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: FETCH_ETO_WALLET,
        payload: null,
    });
    onValue(areaWalletRef("eto"), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            if (data.transactions) {
                Object.keys(data.transactions)
                    .map((key) => {
                        data.transactions[key].id = key;
                    });
            }
            dispatch({
                type: FETCH_ETO_WALLET_SUCCESS,
                payload: {
                    etoWallet: data
                }
            });
        } else {
            dispatch({
                type: FETCH_ETO_WALLET_FAILED,
                payload: "Bạn chưa đăng ký ví thanh toán kinh doanh",
            });
        }
    });
};

export const stopFetchEtoWallet = () => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_ETO_WALLET,
        payload: null,
    });
    off(areaWalletRef("eto"));
};


export const fetchAreaWallet = (areaId) => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: FETCH_AREA_WALLET,
        payload: null,
    });
    onValue(areaWalletRef("area_" + areaId), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            if (data.transactions) {
                Object.keys(data.transactions)
                    .map((key) => {
                        data.transactions[key].id = key;
                    });
            }
            dispatch({
                type: FETCH_AREA_WALLET_SUCCESS,
                payload: {
                    areaWallet: data
                }
            });
        } else {
            dispatch({
                type: FETCH_AREA_WALLET_FAILED,
                payload: "Bạn chưa đăng ký ví thanh toán kinh doanh",
            });
        }
    });
};

export const initEtoWallet = () => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: INIT_AREA_WALLET,
        payload: null,
    });
    let data = {
        balance: 0,
        ref: "eto",
        status: 'active'
    }
    set(areaWalletRef(data.ref), data)
        .then(() => {
            dispatch({
                type: INIT_AREA_WALLET_SUCCESS,
                payload: {
                    etoWallet: data
                },
            });
        })
        .catch(err => {
            dispatch({
                type: INIT_AREA_WALLET_FAILED,
                payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
            });
        });
};

export const initAreaWallet = (areaId) => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: INIT_AREA_WALLET,
        payload: null,
    });
    let data = {
        balance: 0,
        ref: "area_" + areaId,
        status: 'active'
    }
    set(areaWalletRef(data.ref), data)
        .then(() => {
            dispatch({
                type: INIT_AREA_WALLET_SUCCESS,
                payload: {
                    areaWallet: data
                },
            });
        })
        .catch(err => {
            dispatch({
                type: INIT_AREA_WALLET_FAILED,
                payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
            });
        });
};

export const pushAreaWallet = (data) => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: PUSH_AREA_WALLET,
        payload: null,
    });
    let dataPush = {
        amount: data.amount,
        ref: data.ref,
        date_add: GetCurrentTimeStamp(),
        status: data.status ? data.status : 'pending',
        type: data.type,
        note: data.note,
        params: data.params ? data.params : null
    }
    onValue(areaWalletRef('area_' + data.areaId), (snapshot) => {
        if (snapshot.val()) {
            let dataAreaWallet = snapshot.val();
            let balanceOld = parseFloat(dataAreaWallet.balance);

            push(areaWalletRef('area_' + data.areaId + "/transactions"), dataPush)
                .then(() => {
                    var balanceNew = balanceOld;
                    if (dataPush.type == 'memberjoin' || dataPush.type == "holdbooking") {
                        balanceNew = balanceOld + parseFloat(dataPush.amount);
                    } else {
                        balanceNew = balanceOld - parseFloat(dataPush.amount);
                    }
                    set(areaWalletRef('area_' + data.areaId + "/balance"), balanceNew);

                    dispatch(pushEtoWallet(data));

                    dispatch({
                        type: PUSH_AREA_WALLET_SUCCESS,
                        payload: {
                            transaction: data
                        },
                    });
                })
                .catch(err => {
                    dispatch({
                        type: PUSH_AREA_WALLET_FAILED,
                        payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
                    });
                });
        } else {
            let dataInit = {
                balance: 0,
                ref: "area_" + data.areaId,
                status: 'active'
            }
            set(areaWalletRef(data.ref), dataInit)
                .then(() => {
                    let balanceOld = 0;
                    push(areaWalletRef('area_' + data.areaId + "/transactions"), dataPush)
                        .then(() => {
                            var balanceNew = balanceOld;
                            if (dataPush.type == 'memberjoin' || dataPush.type == "holdbooking") {
                                balanceNew = balanceOld + parseFloat(dataPush.amount);
                            } else {
                                balanceNew = balanceOld - parseFloat(dataPush.amount);
                            }
                            set(areaWalletRef('area_' + data.areaId + "/balance"), balanceNew);

                            dispatch(pushEtoWallet(data));

                            dispatch({
                                type: PUSH_AREA_WALLET_SUCCESS,
                                payload: {
                                    transaction: data
                                },
                            });
                        })
                        .catch(err => {
                            dispatch({
                                type: PUSH_AREA_WALLET_FAILED,
                                payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
                            });
                        });
                })
                .catch(err => {
                    dispatch({
                        type: PUSH_AREA_WALLET_FAILED,
                        payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
                    });
                });
        }
    }, {onlyOnce: true});
};

export const pushEtoWallet = (data) => (dispatch) => {
    const {
        areaWalletRef
    } = firebase;

    dispatch({
        type: PUSH_ETO_WALLET,
        payload: null,
    });
    let dataPush = {
        amount: data.amount,
        ref: data.ref,
        date_add: GetCurrentTimeStamp(),
        status: data.status ? data.status : 'pending',
        type: data.type,
        note: data.note,
        params: data.params ? data.params : null
    }
    if (data.type === "Credit" || data.type === "Debit" || data.type == 'Withdraw' || data.type == 'Deposit') {
        onValue(areaWalletRef('eto'), (snapshot) => {
            if (snapshot.val()) {
                let dataAreaWallet = snapshot.val();
                let balanceOld = parseFloat(dataAreaWallet.balance);
                push(areaWalletRef("eto/transactions"), dataPush)
                    .then(() => {
                        var balanceNew = balanceOld;
                        if (dataPush.type == "Deposit") {
                            if (dataPush.status == 'approved') {
                                balanceNew = balanceOld - parseFloat(dataPush.amount);
                                set(areaWalletRef("eto/balance"), balanceNew);
                            }
                        }
                        dispatch({
                            type: PUSH_ETO_WALLET_SUCCESS,
                            payload: {
                                transaction: data
                            },
                        });
                    })
                    .catch(err => {
                        dispatch({
                            type: PUSH_ETO_WALLET_FAILED,
                            payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
                        });
                    });
            } else {
                let dataInit = {
                    balance: 0,
                    ref: 'eto',
                    status: 'active'
                }
                set(areaWalletRef(data.ref), dataInit)
                    .then(() => {
                        let balanceOld = 0;
                        push(areaWalletRef("eto/transactions"), dataPush)
                            .then(() => {
                                var balanceNew = balanceOld;
                                if (dataPush.type == 'memberjoin' || dataPush.type == "deposit") {
                                    balanceNew = balanceOld + parseFloat(dataPush.amount);
                                } else {
                                    balanceNew = balanceOld - parseFloat(dataPush.amount);
                                }
                                set(areaWalletRef("eto/balance"), balanceNew);
                                dispatch({
                                    type: PUSH_ETO_WALLET_SUCCESS,
                                    payload: {
                                        transaction: data
                                    },
                                });
                            })
                            .catch(err => {
                                dispatch({
                                    type: PUSH_ETO_WALLET_FAILED,
                                    payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
                                });
                            });
                    })
                    .catch(err => {
                        dispatch({
                            type: PUSH_ETO_WALLET_FAILED,
                            payload: "Có lỗi xảy ra trong quá trình khởi tạo ví. Vui lòng liên hệ quản trị!",
                        });
                    });
            }
        }, {onlyOnce: true});
    }
};
