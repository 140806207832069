import {cloud_function_server_url, PA_API_CALLNOW} from "config";

let api_key_call = "a3a799635add5feb5ad2f1cb1b1680e3";
let api_key = "a3a799635add5feb5ad2f1cb1b1680e3";
export const callNow = async (phone, extension) => {
    var axios = require('axios');

    var config = {
        method: 'post',
        url: PA_API_CALLNOW.url + "?api_key=" + PA_API_CALLNOW.api_key + "&extension=" + extension + "&phone=" + phone
    };

    await axios(config)
        .then(function (response) {
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            return false;
        });
}

export const createNoteCall = (phone, note) => {
    var axios = require('axios');
    let PA_API_URL_CREATE_NOTE = 'https://crm.pavietnam.vn/api/note-call/takeNote.php';
    var config = {
        method: 'post',
        url: PA_API_URL_CREATE_NOTE + "?api_key=" + api_key + "&content=" + note + "&phone=" + phone
    };

    axios(config)
        .then(function (response) {
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            return false;
        });
}


export const getNoteCallByPhone = (phone) => {
    var axios = require('axios');
    let PA_API_URL_RECIVE_NOTE = 'https://crm.pavietnam.vn/api/note-call/getNote.php';
    var config = {
        method: 'post',
        url: PA_API_URL_RECIVE_NOTE + "?api_key=" + api_key + "&phone=" + phone
    };

    axios(config)
        .then(function (response) {
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            return false;
        });
}


export const downloadFileCall = (recording_file) => {
    var axios = require('axios');
    let PA_API_URL_RECIVE_NOTE = 'https://crm.pavietnam.vn/api/playRecording.php';
    var config = {
        method: 'post',
        url: PA_API_URL_RECIVE_NOTE + "?api_key=" + api_key + "&recording_file=" + recording_file
    };

    axios(config)
        .then(function (response) {
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            return false;
        });
}


export const getRecordAudio = async (source, from_date, to_date) => {
    let response = await fetch(`${cloud_function_server_url}/get_audio_pa`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "api_key": api_key,
            "source": source,
            "from_date": from_date,
            "to_date": to_date
        })
    });

    let json = await response.json();
    if (json) {
        return json;
    } else {
        console.log(json.error);
    }
    return null;
}

export const paCallNow = async (extension, phone) => {
    const axios = require('axios');
    const qs = require('qs');
    let data = qs.stringify({
        'api_key': '4c45e7655ec7fcb3389ec2a9cb9fbe92',
        'extension': phone + '',
        'phone': extension + ''
    });


    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${cloud_function_server_url}/pa_call_now`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
    };

    let json = await axios.request(config);
    return json;

}
