import {
    EDIT_TEAMS_TAXI, EDIT_TEAMS_TAXI_SUCCESS,
    FETCH_TEAMS_TAXI,
    FETCH_TEAMS_TAXI_BY_ID, FETCH_TEAMS_TAXI_BY_ID_FAILED,
    FETCH_TEAMS_TAXI_BY_ID_SUCCESS,
    FETCH_TEAMS_TAXI_FAILED,
    FETCH_TEAMS_TAXI_SUCCESS,
    INVITE_MEMBER_TAXI_TEAM,
    INVITE_MEMBER_TAXI_TEAM_FAILED,
    INVITE_MEMBER_TAXI_TEAM_SUCCESS,
    JOIN_TAXI_TEAM,
    JOIN_TAXI_TEAM_FAILED,
    JOIN_TAXI_TEAM_SUCCESS,
} from "../store/types";

export const INITIAL_STATE = {
    taxi_teams: null,
    team: null,
    loading: false,
    invite_result: null,
    join_result: null,
    error: {
        flag: false,
        msg: null
    }
}

export const taxiteamsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TEAMS_TAXI:
            return {
                ...state,
                loading: true
            };
        case FETCH_TEAMS_TAXI_SUCCESS:
            return {
                ...state,
                taxi_teams: action.payload,
                loading: false
            };

        case EDIT_TEAMS_TAXI:
            return {
                ...state,
                loading: true
            };
        case EDIT_TEAMS_TAXI_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case FETCH_TEAMS_TAXI_FAILED:
            return {
                ...state,
                taxi_teams: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_TEAMS_TAXI_BY_ID:
            return {
                ...state,
                loading: true
            };
        case FETCH_TEAMS_TAXI_BY_ID_SUCCESS:
            return {
                ...state,
                team: action.payload,
                loading: false
            };
        case FETCH_TEAMS_TAXI_BY_ID_FAILED:
            return {
                ...state,
                team: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case INVITE_MEMBER_TAXI_TEAM:
            return {
                ...state,
                loading: true
            };
        case INVITE_MEMBER_TAXI_TEAM_SUCCESS:
            return {
                ...state,
                invite_result: action.payload,
                loading: false
            };
        case INVITE_MEMBER_TAXI_TEAM_FAILED:
            return {
                ...state,
                invite_result: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case JOIN_TAXI_TEAM:
            return {
                ...state,
                loading: true
            };
        case JOIN_TAXI_TEAM_SUCCESS:
            return {
                ...state,
                join_result: action.payload,
                loading: false
            };
        case JOIN_TAXI_TEAM_FAILED:
            return {
                ...state,
                join_result: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
