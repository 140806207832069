import React from "react";
import {useSelector} from "react-redux";
import {Marker} from "react-google-maps";


export default function MarkerQueue(props) {
    const driversqueue = useSelector(state => state.usersdata.driversqueue);

    return (
        driversqueue && driversqueue.map(marker => (
            <Marker
                position={{lat: marker.location.lat, lng: marker.location.lng}}
                key={marker.id}
                options={
                    {
                        icon: require(`../../assets/img/display_` + marker.carStatus).default,
                        size: {width: 32, height: 32},
                    }
                }
                onClick={() => {
                    //props.loadCarInfo(marker);
                }}
                label={
                    {
                        color: '#ffffff',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        text: marker.codeDriver,
                        padding: 10,
                    }
                }
            >
            </Marker>
        ))
    );
}
