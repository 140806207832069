import {FETCH_BA_BOOKING_TRACKING, FETCH_BA_BOOKING_TRACKING_FAILED, FETCH_BA_BOOKING_TRACKING_SUCCESS, STOP_BA_TRACKING_FETCH} from "../store/types";

const INITIAL_STATE = {
    tracking: null,
    loadingTracking: false,
    error: {
        flag: false,
        msg: null
    }
}

export const bookingtrackingreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_BA_BOOKING_TRACKING:
            return {
                ...state,
                loadingTracking: true
            };
        case FETCH_BA_BOOKING_TRACKING_SUCCESS:
            return {
                ...state,
                tracking: action.payload,
                loadingTracking: false
            };
        case FETCH_BA_BOOKING_TRACKING_FAILED:
            return {
                ...state,
                loadingTracking: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case STOP_BA_TRACKING_FETCH:
            return INITIAL_STATE

        default:
            return state;
    }
};