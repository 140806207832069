import {
    UPDATE_GPS_FRIEND,
    UPDATE_GPS_LOCATION, UPDATE_GPS_LOCATION_SECOND,
} from "../store/types";

const INITIAL_STATE = {
    location: null,
    locationSecond: null,
    friend: null
}
export const gpsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_GPS_LOCATION:
            return {
                ...state,
                location: action.payload,
            };
        case UPDATE_GPS_LOCATION_SECOND:
            return {
                ...state,
                locationSecond: action.payload,
            };
        case UPDATE_GPS_FRIEND:
            return {
                ...state,
                friend: action.payload,
            };
        default:
            return state;
    }
};