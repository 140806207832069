import React, {useContext, useEffect, useState} from 'react';
import {api, FirebaseContext} from "common";
import {colors} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function CustomerCareCallCenterComponent(props) {
    const { paCallByIdRef} = useContext(FirebaseContext);
    const [rowData, setRowData] = useState(props.rowData);
    const isBooking = props.isBooking;

    useEffect(() => {
        if (isBooking && rowData && rowData.callId) {
            paCallByIdRef(rowData.callId).once("value", (snapshot) => {
                if (snapshot.val()) {
                    let callObj = snapshot.val();
                    callObj.id = rowData.callId;
                    setRowData(callObj);
                } else {
                }
            });
        }
    }, [isBooking]);

    return (
        <>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                id={"dialog_customer_care"}
                className={{
                    backgroundColor: colors.orange.A200
                }}
                onClick={
                    () => {
                        if (props.showModalCustomerCareCallback) {
                            props.showModalCustomerCareCallback(rowData, props.callObj);
                        }
                    }
                }
            >
                {props.rowData && props.rowData.hasUpdateReport ? "Đã gửi báo cáo" : "Chăm sóc"}
            </Button>
        </>
    )
}
