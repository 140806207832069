import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {api, FirebaseContext} from 'common';
import {View} from "react-native-web";

export default function ForceNotify() {
    const {
        editForceNotify,
        pushForceNotify,
        stopFetchForceNotify,
        fetchForceNotify
    } = api;
    const dispatch = useDispatch();
    const forcenotifydata = useSelector(state => state.forcenotifydata.force_notify);
    const [data, setData] = useState([]);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(fetchForceNotify());
        return () => {
            dispatch(stopFetchForceNotify());
        }
    }, []);

    useEffect(() => {
        if (forcenotifydata) {
            setData(forcenotifydata.filter(notify => notify.date_add));
        } else {
            setData([]);
        }
    }, [forcenotifydata]);

    useEffect(() => {
        if (forcenotifydata) {
            setData(forcenotifydata)
        }
    }, [forcenotifydata])

    const columns = [
        {title: "Ngày Tháng", field: 'date_add'},
        {title: "Mã LX", field: 'driverCode', initialEditValue: ""},
        {title: "Tiêu đề", field: 'title', initialEditValue: ""},
        {title: "Nội dung", field: 'content', initialEditValue: ""},
        {title: "Loại thông báo", field: 'type', lookup: {maintenance: "Bảo trì", warning: "Cảnh báo", reject_booking: "Từ chối cuốc khách"}, initialEditValue: "warning"},
        {title: "Người thông báo", field: 'add_by'},
        {title: "Trạng thái", field: 'status', lookup: {pending: "Đợi gửi", success: "Đã gửi", cancel: "Hủy bỏ", done: "Đã thực hiện"}, initialEditValue: "pending"}
    ];

    return (
        <View>
            <MaterialTable
                title={"Danh sách cảnh báo"}
                columns={columns}
                data={data}
                options={{
                    exportButton: false,
                    pageSize: 25,
                    pageSizeOptions: [10, 25, 50, 100]
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(pushForceNotify(newData));
                            }, 600);
                        }),

                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(editForceNotify(newData.id, newData));
                            }, 600);
                        })
                }}
            />
        </View>

    );
}
