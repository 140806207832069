import {
    FirebaseConfig
} from 'config';

export function RequestPushMsg(token, title, msg) {
    if (token && token != undefined) {
        fetch(`https://${FirebaseConfig.authDomain}/send_notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "token": token,
                "title": title,
                "msg": msg
            })
        })
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            });
    }
}