/*eslint-disable*/
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import {AccountBox, DirectionsCar, House, Info} from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {useSelector} from "react-redux";
import {language} from "config";
import {useNavigate} from 'react-router-dom';
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const auth = useSelector(state => state.auth);
    const settings = useSelector(state => state.settingsdata.settings);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.info && auth.info.profile) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [auth.info]);

    return (
        <List className={classes.list} style={{backgroundColor: '#596e73'}}>
            <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/')
                    }}
                >
                    {/*<House className={classes.icons}/>{language.home}*/}
                    <Hidden smDown implementation="css">
                        <img src={require("../../assets/img/home.gif").default} alt="Eto" style={{width: 30}}/>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <img src={require("../../assets/img/home-black.gif").default} alt="Eto" style={{width: 30, marginRight: 10}}/>
                        {language.home}
                    </Hidden>
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>


                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={(e) => {
                        e.preventDefault();
                        if (auth && auth.info && auth.info.profile && (auth.info.profile.usertype == 'rider' || auth.info.profile.usertype == 'driver')) {
                            navigate('/bookings')
                        } else {
                            navigate('/callcenter')
                        }
                    }}
                >
                    <Hidden smDown implementation="css">
                        <img src={require("../../assets/img/account.gif").default} alt="Eto" style={{width: 30}}/>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <img src={require("../../assets/img/account-black.gif").default} alt="Eto" style={{width: 30, marginRight: 10}}/>
                        {loggedIn ?
                            language.myaccount
                            :
                            language.login_signup
                        }
                    </Hidden>
                    {/*{loggedIn ?
                        <AccountBox className={classes.icons}/>
                        :
                        <AccountBox className={classes.icons}/>
                    }

                    {loggedIn ?
                        language.myaccount
                        :
                        language.login_signup
                    }*/}
                </Button>
            </ListItem>
            {/*<ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={(e) => {
                        e.preventDefault();
                        history.push('/booking_web')
                    }}
                >
                    <DirectionsCar className={classes.icons}/>
                    Đặt xe
                </Button>
            </ListItem>*/}
            <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/about-us')
                    }}
                >
                    <Hidden smDown implementation="css">
                        <img src={require("../../assets/img/info.gif").default} alt="Eto" style={{width: 30}}/>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <img src={require("../../assets/img/info-black.gif").default} alt="Eto" style={{width: 30, marginRight: 10}}/> {language.about_us}
                    </Hidden>
                    {/*<Info className={classes.icons}/>{language.about_us}*/}
                </Button>
            </ListItem>
            {settings && settings.FacebookHandle ?
                <ListItem className={classes.listItem}>
                    <Tooltip
                        id="instagram-facebook"
                        title={language.follow_facebook}
                        placement={window.innerWidth > 959 ? "top" : "left"}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <Button
                            color="transparent"
                            href={settings.FacebookHandle}
                            target="_blank"
                            className={classes.navLink}
                        >
                            <img src={require("../../assets/img/fb-ico.png").default} alt="Eto" style={{width: 30}}/>
                            {/*<i className={classes.socialIcons + " fab fa-facebook"}/>*/}
                        </Button>
                    </Tooltip>
                </ListItem>
                : null}
            {settings && settings.TwitterHandle ?
                <ListItem className={classes.listItem}>
                    <Tooltip
                        id="instagram-twitter"
                        title={language.follow_twitter}
                        placement={window.innerWidth > 959 ? "top" : "left"}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <Button
                            href={settings.TwitterHandle}
                            target="_blank"
                            color="transparent"
                            className={classes.navLink}
                        >
                            <img src={require("../../assets/img/zalo-ico.png").default} alt="Eto" style={{width: 30}}/>
                            {/*<i className={classes.socialIcons + " fab fa-twitter"}/>*/}
                        </Button>
                    </Tooltip>
                </ListItem>
                : null}
            {settings && settings.InstagramHandle ?
                <ListItem className={classes.listItem}>
                    <Tooltip
                        id="instagram-twitter"
                        title={language.follow_instagram}
                        placement={window.innerWidth > 959 ? "top" : "left"}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <Button
                            href={settings.InstagramHandle}
                            target="_blank"
                            color="transparent"
                            className={classes.navLink}
                        >
                            <i className={classes.socialIcons + " fab fa-instagram"}/>
                        </Button>
                    </Tooltip>
                </ListItem>
                : null}
        </List>
    );
}
