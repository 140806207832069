import {ADD_FRIENDS, ADD_FRIENDS_ACCEPTED, ADD_FRIENDS_ACCEPTED_FAILED, ADD_FRIENDS_ACCEPTED_SUCCESS, ADD_FRIENDS_FAILED, ADD_FRIENDS_SUCCESS, FETCH_FRIENDS, FETCH_FRIENDS_FAILED, FETCH_FRIENDS_SUCCESS, REMOVE_FRIENDS, REMOVE_FRIENDS_FAILED, REMOVE_FRIENDS_SUCCESS} from "../store/types";
import {GetCurrentTimeStamp} from "../other/DateFunctions";
import store from "../store/store";
import {off, onValue, remove, set} from "firebase/database";
import {firebase} from '../config/configureFirebase';
import {pushNotifyImportance} from "./notifyimportanceaction";

export const fetchFriend = (uid) => (dispatch) => {
    const {
        friendRef,
    } = firebase;

    dispatch({
        type: FETCH_FRIENDS,
        payload: null,
    });

    onValue(friendRef(uid), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_FRIENDS_SUCCESS,
                payload: {
                    friends: arr
                }
            });
        } else {
            dispatch({
                type: FETCH_FRIENDS_FAILED,
                payload: "Có lỗi trọng trong khi load danh sách bạn bè, vui lòng liên hệ quản trị!",
            });
        }
    });
};

export const fetchFriendOff = (uid) => (dispatch) => {
    const {
        friendRef,
    } = firebase;
    off(friendRef(uid));
};


export const addFriend = (uid, uidFriend, data) => (dispatch) => {
    const {
        friendDetailRef
    } = firebase;

    dispatch({
        type: ADD_FRIENDS,
        payload: null,
    });
    let profile = store.getState().auth.info.profile;

    set(friendDetailRef(uid, uidFriend), data)
        .then((r) => {
            let dataNotify = {
                date_add: GetCurrentTimeStamp(),
                status: 'pending',
                from: uid,
                type: 'add_friend',
                title: profile.codeDriver + " gửi lời mời kết bạn! ",
                content: "Kết bạn cùng " + profile.codeDriver + " các bạn sẽ xem được vị trí...!",
                to: uidFriend,
                params: data
            };
            dispatch(pushNotifyImportance(dataNotify));
            dispatch({
                type: ADD_FRIENDS_SUCCESS,
                payload: {
                    friend: data
                }
            });
        }).catch((error) => {
        dispatch({
            type: ADD_FRIENDS_FAILED,
            payload: "Có lỗi trọng trong khi thêm bạn bè, vui lòng liên hệ quản trị!",
        });
    });
};

export const acceptAddFriend = (uidFrom, status) => (dispatch) => {
    const {
        friendDetailRef,
        friendDetailStatusRef
    } = firebase;

    dispatch({
        type: ADD_FRIENDS_ACCEPTED,
        payload: null,
    });
    let info = store.getState().auth.info;

    set(friendDetailStatusRef(uidFrom, info.uid), status)
        .then((r) => {
            if (status == 'accepted') {
                let dataNotify = {
                    date_add: GetCurrentTimeStamp(),
                    status: 'pending',
                    from: info.uid,
                    type: 'add_friend_accepted',
                    title: "Lái xe #" + info.profile.codeDriver + " đã đồng ý kết bạn! ",
                    content: "Bạn và lái xe #" + info.profile.codeDriver + " đã trở thành bạn bè.",
                    to: uidFrom
                };
                dispatch(pushNotifyImportance(dataNotify));
                let data = {
                    status: 'accepted',
                    date_add: GetCurrentTimeStamp(),

                }
                set(friendDetailRef(info.uid, uidFrom), data);
                dispatch({
                    type: ADD_FRIENDS_ACCEPTED_SUCCESS,
                    payload: {
                        friend: data
                    }
                });
            } else {
                let dataNotify = {
                    date_add: GetCurrentTimeStamp(),
                    status: 'pending',
                    from: info.uid,
                    type: 'add_friend_accepted',
                    title: "Lái xe #" + info.profile.codeDriver + " đã từ chối kết bạn! ",
                    content: "Lái xe #" + info.profile.codeDriver + " đã từ chối kết bạn của bạn, Hãy gọi cho bạn đó và thực hiện lại! ",
                    to: uidFrom
                };
                dispatch(pushNotifyImportance(dataNotify));
                dispatch({
                    type: ADD_FRIENDS_ACCEPTED_SUCCESS,
                    payload: {
                        friend: null
                    }
                });
            }

        }).catch((error) => {
        dispatch({
            type: ADD_FRIENDS_ACCEPTED_FAILED,
            payload: "Có lỗi trọng trong khi thêm bạn bè, vui lòng liên hệ quản trị!",
        });
    });
};

export const removeFriend = (uid, uidFriend) => (dispatch) => {
    const {
        friendDetailRef
    } = firebase;

    dispatch({
        type: REMOVE_FRIENDS,
        payload: null,
    });
    let profile = store.getState().auth.info.profile;

    remove(friendDetailRef(uid, uidFriend))
        .then((r) => {
            let dataNotify = {
                date_add: GetCurrentTimeStamp(),
                status: 'pending',
                from: uid,
                type: 'add_friend',
                title: "Lái xe #" + profile.codeDriver + " đã hủy kết bạn! ",
                content: "#" + profile.codeDriver + " đã hủy kết bạn với bạn!",
                to: uidFriend
            };
            dispatch(pushNotifyImportance(dataNotify));
            dispatch({
                type: REMOVE_FRIENDS_SUCCESS,
                payload: {
                    friend: dataNotify
                }
            });
        }).catch((error) => {
        dispatch({
            type: REMOVE_FRIENDS_FAILED,
            payload: "Có lỗi trọng trong khi xóa bạn bè, vui lòng liên hệ quản trị!",
        });
    });
};

