import {FETCH_WALLET_MANAGE, FETCH_WALLET_MANAGE_FAILED, FETCH_WALLET_MANAGE_SUCCESS,} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {onValue} from "firebase/database";

export const fetchWalletManage = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_WALLET_MANAGE,
        payload: null
    });
    onValue(usersRef, snapshot => {
        if (snapshot.val()) {
            const mainArr = snapshot.val();
            var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            var renderobj = {};
            Object.keys(mainArr).map(j => {
                if ((mainArr[j].walletHistory || mainArr[j].walletHistory !== undefined)) {
                    const walletHistory = mainArr[j].walletHistory;
                    Object.keys(walletHistory).map(w => {
                        let bdt = new Date(walletHistory[w].date);
                        let uniqueKey = bdt.getFullYear() + '_' + bdt.getMonth() + '_' + bdt.getDate() + '_' + bdt.getTime() + '_' + j;
                        if (walletHistory[w].amount > 0) {
                            renderobj[uniqueKey] = {};
                            renderobj[uniqueKey]['date'] = walletHistory[w].date;
                            renderobj[uniqueKey]['time'] = bdt.getTime();
                            renderobj[uniqueKey]['dated'] = bdt.getDate();
                            renderobj[uniqueKey]['year'] = bdt.getFullYear();
                            renderobj[uniqueKey]['month'] = bdt.getMonth();
                            renderobj[uniqueKey]['monthsName'] = monthsName[bdt.getMonth()];
                            renderobj[uniqueKey]['userType'] = mainArr[j].usertype;
                            renderobj[uniqueKey]['driverCode'] = mainArr[j].codeDriver ? mainArr[j].codeDriver : '';
                            renderobj[uniqueKey]['txRef'] = walletHistory[w].txRef ? walletHistory[w].txRef : '';
                            renderobj[uniqueKey]['type'] = walletHistory[w].type ? walletHistory[w].type : '';
                            renderobj[uniqueKey]['amount'] = walletHistory[w].amount ? walletHistory[w].amount : 0;
                            renderobj[uniqueKey]['uid'] = w;
                            renderobj[uniqueKey]['uniqueKey'] = uniqueKey;
                        }
                    });

                }
                return null;
            });
            if (renderobj) {
                const arr = Object.keys(renderobj).map(i => {
                    return renderobj[i]
                })
                dispatch({
                    type: FETCH_WALLET_MANAGE_SUCCESS,
                    payload: arr.sort((a, b) => (a.time > b.time) ? 1 : -1),
                });
            }

        } else {
            dispatch({
                type: FETCH_WALLET_MANAGE_FAILED,
                payload: "No data available."
            });
        }
    }, {onlyOnce: true});
};


