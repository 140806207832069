import {
    UPDATE_DATA_CAR,
} from "../store/types";

const INITIAL_STATE = {
    data: "Sảnh chờ"
}
export const datacarreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_DATA_CAR:
            return {
                data: action.payload,
            };
        default:
            return state;
    }
};