/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {List, ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import {useNavigate} from 'react-router-dom';
import {language} from "config";
import {useSelector} from 'react-redux';

const useStyles = makeStyles(styles);

export default function FooterAdmin(props) {
    const classes = useStyles();
    const settings = useSelector(state => state.settingsdata.settings);
    const {whiteFont} = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    const aClasses = classNames({
        [classes.a]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    let navigate = useNavigate();
    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a
                                style={{cursor: 'pointer'}}
                                className={classes.block}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/')
                                }}
                            >
                                {language.home}
                            </a>
                        </ListItem>
                    </List>
                </div>
                {settings && settings.CompanyWebsite ?
                    <div className={classes.right}>
                        &copy; {1900 + new Date().getYear() + " "}
                        <a
                            href={settings.CompanyWebsite}
                            className={aClasses}
                            target="_blank"
                        >
                            {settings.CompanyName + " Version WebAdmin: " + " 2.1.3"}
                        </a>
                    </div>
                    : null}
            </div>
        </footer>
    );
}

FooterAdmin.propTypes = {
    whiteFont: PropTypes.bool
};
