import {
    CANCEL_BOOKING,
    FETCH_BOOKINGS,
    FETCH_BOOKINGS_FAILED,
    FETCH_BOOKINGS_LATER,
    FETCH_BOOKINGS_LATER_FAILED,
    FETCH_BOOKINGS_LATER_SUCCESS,
    FETCH_BOOKINGS_SUCCESS,
    FETCH_BOOKINGS_TEAM,
    FETCH_BOOKINGS_TEAM_FAILED,
    FETCH_BOOKINGS_TEAM_SUCCESS,
    FETCH_NEWS_BOOKINGS,
    FETCH_NEWS_BOOKINGS_FAILED,
    FETCH_NEWS_BOOKINGS_SUCCESS,
    FETCH_NOTES,
    FETCH_NOTES_FAILED,
    FETCH_NOTES_SUCCESS,
    FETCH_ONCE_ALL_BOOKINGS,
    FETCH_ONCE_ALL_BOOKINGS_BY_CODE,
    FETCH_ONCE_ALL_BOOKINGS_BY_CODE_SUCCESS,
    FETCH_ONCE_ALL_BOOKINGS_BY_CUS,
    FETCH_ONCE_ALL_BOOKINGS_BY_CUS_SUCCESS,
    FETCH_ONCE_ALL_BOOKINGS_SUCCESS,
    FETCH_TOTAL_BOOKINGS_CANCEL,
    FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS,
    STOP_FETCH_BOOKINGS,
    UPDATE_BOOKING,
    UPDATE_BOOKING_STARTED,
    UPDATE_TRACKING
} from "../store/types";
import store from '../store/store';
import {RequestPushMsg} from '../other/NotificationFunctions';
import {GetTripDistance} from '../other/GeoFunctions';
import {dateStyle, language} from 'config';
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp} from "../other/DateFunctions";
import {equalTo, get, limitToLast, off, onValue, orderByChild, orderByKey, push, query, remove, set, update} from "firebase/database";
import {firebase} from '../config/configureFirebase';

export const fetchBookings = (uid = null, role) => (dispatch) => {
    const {
        bookingListRef,
        auth,
        refApi
    } = firebase;

    dispatch({
        type: FETCH_BOOKINGS,
        payload: null,
    });
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    off(bookingListRef(uidCheck, role));
    let limit = role === 'fleetadmin' || role === 'admin' ? 500 : 10;
    onValue(query(bookingListRef(uidCheck, role), limitToLast(200)), (snapshot) => {
        if (snapshot.val() && store.getState().auth) {
            const data = snapshot.val();
            const active = [];
            let tracked = null;
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });

            for (let i = 0; i < bookings.length; i++) {
                if (role === 'driver' && bookings[i].driver && ['ACCEPTED', 'ARRIVED', 'STARTED', 'REACHED', 'DA_DEN_CHO_DON_KHACH'].includes(bookings[i].status) && bookings[i].driver === uid) {
                    tracked = bookings[i];
                }

                if (role === 'rider' && bookings[i].customer && ['ACCEPTED', 'ARRIVED', 'STARTED', 'REACHED', 'DA_DEN_CHO_DON_KHACH', 'PAID'].includes(bookings[i].status) && bookings[i].customer === uid) {
                    active.push(bookings[i]);
                }
            }
            var listBookingFleet = [];
            if (uidCheck && store.getState().auth.info && store.getState().auth.info.profile) {
                let profile = store.getState().auth.info.profile;
                if (profile.usertype == 'fleetadmin') {
                    listBookingFleet = bookings.reverse().filter(item => item.customer == uidCheck);
                } else {
                    if (profile.usertype == 'admin') {
                        listBookingFleet = bookings.reverse();
                    }
                }
            }
            dispatch({
                type: FETCH_BOOKINGS_SUCCESS,
                payload: {
                    bookings: listBookingFleet,
                    tracked: tracked,
                    active: active
                },
            });
        } else {
            dispatch({
                type: FETCH_BOOKINGS_FAILED,
                payload: language.no_bookings,
            });
        }
    });
};

export const fetchNewsBookings = (uid = null) => (dispatch) => {
    const {
        auth,
        tasksNewAcceptedRef
    } = firebase;

    dispatch({
        type: FETCH_NEWS_BOOKINGS,
        payload: null,
    });
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    onValue(query(tasksNewAcceptedRef(), limitToLast(10)), (snapshot) => {
        if (snapshot.val()) {
            const bookingNews = [];
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });
            for (let i = 0; i < bookings.length; i++) {
                if (bookings[i].customer == uidCheck) {
                    bookingNews.push(bookings[i]);
                }
            }
            dispatch({
                type: FETCH_NEWS_BOOKINGS_SUCCESS,
                payload: {
                    booking_news: bookingNews
                },
            });
        } else {
            dispatch({
                type: FETCH_NEWS_BOOKINGS_FAILED,
                payload: language.no_bookings,
            });
        }
    });
}

export const stopFetchNewsBookings = () => (dispatch) => {
    const {
        tasksNewAcceptedRef,
    } = firebase;
    off(tasksNewAcceptedRef());
    dispatch({
        type: FETCH_NEWS_BOOKINGS_FAILED,
        payload: language.no_bookings,
    });
}

export const fetchOnceAllBookings = (codeDriver = null, uid) => (dispatch) => async () => {
    const {
        bookingAllListRef,
        listBookingPendingTypeRef
    } = firebase;

    dispatch({
        type: FETCH_ONCE_ALL_BOOKINGS,
        payload: null,
    });

    var listByDriverCode = [];
    var listByDriverBook = [];
    var listByDriver = [];
    var listByCustomer = [];
    var listByBookingLater = [];
    var listBookingIgnoreIn = [];
    var listBookingIgnoreOut = [];

    /*await bookingAllListRef().orderByChild('driver_mark_ignore_out/' + uid).equalTo(true).once("value", (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });
            listBookingIgnoreOut = bookings.reverse();
        }
    });*/

    /*await bookingAllListRef().orderByChild('driver_mark_ignore_in/' + uid).equalTo(true).once("value", (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });
            listBookingIgnoreIn = bookings.reverse();
        }
    });*/

    if (codeDriver) {
        onValue(query(bookingAllListRef(), orderByChild('driverBooked'), equalTo(codeDriver)), (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const bookings = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                        data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                        if (data[i].pickup) {
                            data[i].pickupAddress = data[i].pickup.add;
                        }
                        if (data[i].drop) {
                            data[i].dropAddress = data[i].drop.add;
                        }
                        data[i].discount = data[i].discount_amount
                            ? data[i].discount_amount
                            : 0;
                        data[i].cashPaymentAmount = data[i].cashPaymentAmount
                            ? data[i].cashPaymentAmount
                            : 0;
                        data[i].cardPaymentAmount = data[i].cardPaymentAmount
                            ? data[i].cardPaymentAmount
                            : 0;
                        return data[i];
                    });
                listByDriverBook = bookings.reverse();
            }
        }, {onlyOnce: true});
    } else {
        if (uid) {
            onValue(query(bookingAllListRef(), orderByChild('customer'), equalTo(uid)), (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const bookings = Object.keys(data)
                        .map((i) => {
                            data[i].id = i;
                            data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                            if (data[i].pickup) {
                                data[i].pickupAddress = data[i].pickup.add;
                            }
                            if (data[i].drop) {
                                data[i].dropAddress = data[i].drop.add;
                            }
                            data[i].discount = data[i].discount_amount
                                ? data[i].discount_amount
                                : 0;
                            data[i].cashPaymentAmount = data[i].cashPaymentAmount
                                ? data[i].cashPaymentAmount
                                : 0;
                            data[i].cardPaymentAmount = data[i].cardPaymentAmount
                                ? data[i].cardPaymentAmount
                                : 0;
                            return data[i];
                        });
                    listByDriverBook = bookings.reverse();
                }
            }, {onlyOnce: true});
        }
    }

    if (codeDriver) {
        onValue(query(bookingAllListRef(), orderByChild('driver'), equalTo(uid)), (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                const bookings = Object.keys(data)
                    .map((i) => {
                        data[i].id = i;
                        data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                        if (data[i].pickup) {
                            data[i].pickupAddress = data[i].pickup.add;
                        }
                        if (data[i].drop) {
                            data[i].dropAddress = data[i].drop.add;
                        }
                        data[i].discount = data[i].discount_amount
                            ? data[i].discount_amount
                            : 0;
                        data[i].cashPaymentAmount = data[i].cashPaymentAmount
                            ? data[i].cashPaymentAmount
                            : 0;
                        data[i].cardPaymentAmount = data[i].cardPaymentAmount
                            ? data[i].cardPaymentAmount
                            : 0;
                        return data[i];
                    });
                listByDriver = bookings.reverse();
            }
        }, {onlyOnce: true});
    }


    /*await bookingAllListRef().orderByChild('customer').equalTo(uid).once("value", (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });
            listByCustomer = bookings.reverse();
        }
    });*/

    /*await listBookingPendingTypeRef().once("value", (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });
            listByBookingLater = bookings.reverse().filter((b) => (b.bookingPushInList && b.bookingPushInList[uid] == true) || (b.bookingPushOutList && b.bookingPushOutList[uid] == true));
        }
    });*/

    dispatch({
        type: FETCH_ONCE_ALL_BOOKINGS_SUCCESS,
        payload: {
            listBookAliasDriver: listByDriverBook.concat(listByDriverCode).concat(listByCustomer).concat(listByDriver).concat(listByBookingLater).concat(listBookingIgnoreIn).concat(listBookingIgnoreOut)
        },
    });

};

export const fetchOnceAllBookingsByCus = (phone) => (dispatch) => async () => {
    const {
        bookingAllListRef,
    } = firebase;

    dispatch({
        type: FETCH_ONCE_ALL_BOOKINGS_BY_CUS,
        payload: null,
    });

    var listByCustomer = [];


    onValue(query(bookingAllListRef(), orderByChild('customer_contact'), equalTo(phone)), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;

                    return data[i];
                });
            listByCustomer = bookings.reverse();
        }
    }, {onlyOnce: true});

    dispatch({
        type: FETCH_ONCE_ALL_BOOKINGS_BY_CUS_SUCCESS,
        payload: {
            listBookingByCusPhone: listByCustomer
        },
    });

};

export const fetchOnceAllBookingsByCode = (code) => (dispatch) => async () => {
    const {
        bookingAllListRef,
    } = firebase;

    dispatch({
        type: FETCH_ONCE_ALL_BOOKINGS_BY_CODE,
        payload: null,
    });

    var listByCustomer = [];


    onValue(query(bookingAllListRef(), orderByChild('codeDriver'), equalTo(code)), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;

                    return data[i];
                });
            listByCustomer = bookings.reverse();
        }
    }, {onlyOnce: true});

    dispatch({
        type: FETCH_ONCE_ALL_BOOKINGS_BY_CODE_SUCCESS,
        payload: {
            listBookingByCode: listByCustomer
        },
    });

};


export const fetchBookingsLater = (uid = null) => (dispatch) => {
    const {
        listBookingPendingTypeRef,
        auth
    } = firebase;

    dispatch({
        type: FETCH_BOOKINGS_LATER,
        payload: null,
    });
    let uidCheck = uid ? uid : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null);
    onValue(listBookingPendingTypeRef(), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookingLaters = [];
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });

            for (let i = 0; i < bookings.length; i++) {
                if (bookings[i].customer == uidCheck && !bookings[i].has_accepted) {
                    bookingLaters.push(bookings[i]);
                }
            }
            dispatch({
                type: FETCH_BOOKINGS_LATER_SUCCESS,
                payload: {
                    booking_driver_later: bookingLaters,
                },
            });
        } else {
            dispatch({
                type: FETCH_BOOKINGS_LATER_FAILED,
                payload: language.no_bookings,
            });
        }
    });
};

export const fetchBookingsTeam = (uid, teamId) => (dispatch) => {
    const {
        bookingListTeamRef,
    } = firebase;

    dispatch({
        type: FETCH_BOOKINGS_TEAM,
        payload: null,
    });

    onValue(bookingListTeamRef(uid), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const active = [];
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount
                        ? data[i].discount_amount
                        : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount
                        ? data[i].cashPaymentAmount
                        : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount
                        ? data[i].cardPaymentAmount
                        : 0;
                    return data[i];
                });

            for (let i = 0; i < bookings.length; i++) {
                if (bookings[i].teamId && teamId === bookings[i].teamId) {
                    active.push(bookings[i]);
                }
            }

            dispatch({
                type: FETCH_BOOKINGS_TEAM_SUCCESS,
                payload: {
                    booking_teams: active
                },
            });
        } else {
            dispatch({
                type: FETCH_BOOKINGS_TEAM_FAILED,
                payload: language.no_bookings,
            });
        }
    });
};

export const stopFetchBookings = (uid, role) => (dispatch) => {
    const {
        bookingListRef,
    } = firebase;

    dispatch({
        type: STOP_FETCH_BOOKINGS,
        payload: null,
    });

    off(bookingListRef(uid, role));
};

export const fetchAllBookings = () => (dispatch) => {

    const {
        bookingAllRef,
        noteListPhoneRef
    } = firebase;

    dispatch({
        type: FETCH_NOTES,
        payload: null,
    });

    onValue(bookingAllRef(), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].customer_note && data[i].customer_note !== '' && data[i].type === 'TT' && data[i].carTypeCode === 'taxi' && data[i].status === 'PAID')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            let listResult = [];
            for (let i = 0; i < arr.length; i++) {
                const listData = arr.filter(booking => booking.customer_note === arr[i].customer_note);
                if (listData && listData.length > 0) {
                    let checkList = listResult.filter(item => item.title === arr[i].customer_note);
                    if (checkList.length === 0) {
                        listResult.push({title: arr[i].customer_note, count: listData.length, phone: arr[i].customer_contact});
                        //noteListPhoneRef(arr[i].customer_contact).push(arr[i].customer_note);
                    }
                }
            }

            const noteSorted = listResult.sort((a, b) => (a.count < b.count) ? 1 : -1)
            dispatch({
                type: FETCH_NOTES_SUCCESS,
                payload: {
                    notes: noteSorted
                },
            });
        } else {
            dispatch({
                type: FETCH_NOTES_FAILED,
                payload: language.no_bookings,
            });
        }
    }, {onlyOnce: true});
};

export const updateBookingStarted = (booking) => (dispatch) => {
    const {
        singleBookingRef,
        refApi
    } = firebase;
    dispatch({
        type: UPDATE_BOOKING_STARTED,
        payload: booking,
    });

    if (booking.status == 'STARTED') {
        update(singleBookingRef(booking.id), booking);
    }
}

export const updateBooking = (booking) => async (dispatch) => {
    const {
        auth, refApi,
        trackingRef,
        singleBookingRef,
        singleUserRef,
        walletBalRef,
        walletHistoryRef,
        reportdriverRef,
        requestedDriversRef,
        setDriverFavouriteRatingRef,
        notifyImportanceToRef
    } = firebase;
    let dt = new Date();
    let today = ConvertTimeStampToDate(dt.getTime());
    dispatch({
        type: UPDATE_BOOKING,
        payload: booking,
    });
    var newData = {};

    if (booking.id) {
        if (booking.status == 'ARRIVED') {
            booking.driver_arrive_time = today;
            newData.content = today + " : " + booking.notify_to_center ? booking.notify_to_center : language.driver_near + ' [' + booking.customer_note + '] #' + booking.id;
            if (newData.content) {
                push(reportdriverRef, newData);
            }
            await update(singleBookingRef(booking.id), booking);
            await update(singleUserRef(booking.driver), {queue: true});
            if (booking.requestedDrivers && booking.id) {
                await remove(requestedDriversRef(booking.id));
            }
            await RequestPushMsg(booking.customer_token, language.notification_title, language.driver_near);
        }

        if (booking.status == 'CANCELLED') {
            if (booking.notify_to_center == 'CleanData') {
                booking.notify_to_center = '';
                await update(singleBookingRef(booking.id), booking);
                if (booking.driver) {
                    await update(singleUserRef(booking.driver), {cr_bookingid: null});
                }
            }
            if (booking.driver) {
                onValue(notifyImportanceToRef(booking.driver), (snapshot) => {
                    if (snapshot.val()) {
                        let dataNotify = snapshot.val();
                        const arr = Object.keys(dataNotify)
                            .filter(i => dataNotify[i].booking_id && dataNotify[i].booking_id == booking.id)
                            .map(i => {
                                dataNotify[i].id = i;
                                return dataNotify[i];
                            });

                        if (arr && arr.length > 0) {
                            let notify = arr[0];
                            refApi.setNotifyImportanceStatusApi(notify.id, 'approved');
                        }
                    }
                }, {onlyOnce: true});
            }
            if (booking.requestedDrivers && booking.id) {
                await remove(requestedDriversRef(booking.id));
            }
        }

        if (booking.status == 'ACCEPTED' && booking.driver && booking.id) {
            if (booking.driver_arrive_time) {
            } else {
                let dt = new Date();
                booking.driver_arrive_time = dt.getTime().toString();
                push(reportdriverRef, newData);
                await update(singleUserRef(booking.driver), {queue: true});
                await update(singleBookingRef(booking.id), booking);
            }
        }

        if (booking.status == 'DA_DEN_CHO_DON_KHACH' && booking.driver) {
            await update(singleUserRef(booking.driver), {queue: true});
            let dt = new Date();
            let timeString = dt.getTime();
            let localString = ConvertTimeStampToDate(timeString);
            booking.trip_start_time = localString;
            booking.startTime = timeString;
            newData.content = today + " : " + (booking.notify_to_center ? booking.notify_to_center : language.driver_near) + ' #' + booking.id;
            push(reportdriverRef, newData);
            await update(singleBookingRef(booking.id), booking);
        }

        if (booking.status == 'STARTED' && booking.driver) {
            let dt = new Date();
            let timeString = dt.getTime();
            let localString = ConvertTimeStampToDate(timeString);
            if (!booking.trip_start_time) {
                booking.trip_start_time = localString;
            }
            if (!booking.startTime) {
                booking.startTime = timeString;
            }

            await update(singleBookingRef(booking.id), booking);
        }
        if (booking.status == 'REACHED') {
            let lastLocation = store.getState().trackingdata.tracking;
            let is_counter = false;
            if (booking.driver_group && booking.drop) {
                is_counter = true;
            }
            push(trackingRef(booking.id), {
                at: new Date().getTime(),
                status: 'REACHED',
                lat: lastLocation && lastLocation.tracking ? lastLocation.tracking.lat : null,
                lng: lastLocation && lastLocation.tracking ? lastLocation.tracking.lng : null,
                is_counter: is_counter
            });
            dispatch({
                type: UPDATE_TRACKING,
                payload: {
                    tracking: {
                        at: new Date().getTime(),
                        status: 'REACHED',
                        lat: lastLocation && lastLocation.tracking ? lastLocation.tracking.lat : null,
                        lng: lastLocation && lastLocation.tracking ? lastLocation.tracking.lng : null,
                        is_counter: is_counter
                    }
                }
            });
            if (booking.drop) {
                newData.content = today + " : " + (booking.notify_to_center ? booking.notify_to_center : "Chuyến đi đã hoàn thành, Tài xế có thể nhận cuốc mới") + ' #' + booking.id;
                push(reportdriverRef, newData);
                onValue(query(trackingRef(booking.id), orderByKey()), async (snapshot) => {
                    const data = snapshot.val();
                    let res = await GetTripDistance(data);
                    booking.coords = res.coords;
                    await update(singleBookingRef(booking.id), booking);
                }, {onlyOnce: true});
            } else {
                newData.content = today + " : " + (booking.notify_to_center ? booking.notify_to_center : "Chuyến đi đã hoàn thành, Tài xế có thể nhận cuốc mới");
                push(reportdriverRef, newData);
                await update(singleUserRef(booking.driver), {queue: false});
                RequestPushMsg(booking.driver_token, language.notification_title, "Chuyến đi đã hoàn thành, Tài xế có thể nhận cuốc mới");
            }
        }
        if (booking.status == 'PENDING' && booking.driver) {
            await update(singleBookingRef(booking.id), booking);
            await update(singleUserRef(booking.driver), {queue: false});
        }
        if (booking.status == 'PAID') {
            await update(singleBookingRef(booking.id), booking);
            onValue(notifyImportanceToRef(booking.driver), (snapshot) => {
                if (snapshot.val()) {
                    let dataNotify = snapshot.val();
                    const arr = Object.keys(dataNotify)
                        .filter(i => dataNotify[i].booking_id && dataNotify[i].booking_id == booking.id)
                        .map(i => {
                            dataNotify[i].id = i;
                            return dataNotify[i];
                        });

                    if (arr && arr.length > 0) {
                        let notify = arr[0];
                        refApi.setNotifyImportanceStatusApi(notify.id, 'approved');
                    }
                }
            }, {onlyOnce: true});

            onValue(notifyImportanceToRef(booking.customer), (snapshot) => {
                if (snapshot.val()) {
                    let dataNotify = snapshot.val();
                    const arr = Object.keys(dataNotify)
                        .filter(i => dataNotify[i].booking_id && dataNotify[i].booking_id == booking.id)
                        .map(i => {
                            dataNotify[i].id = i;
                            return dataNotify[i];
                        });

                    if (arr && arr.length > 0) {
                        let notify = arr[0];
                        refApi.setNotifyImportanceStatusApi(notify.id, 'approved');
                    }
                }
            }, {onlyOnce: true});

            onValue(singleUserRef(booking.driver), snapshot => {
                let walletBalance = snapshot.val() ? parseFloat(snapshot.val().walletBalance) : 0;
                if (booking.driver_share) {
                    walletBalance = walletBalance + parseFloat(booking.driver_share);
                }
                let balanceUpdate = walletBalance ? walletBalance : 0;
                set(walletBalRef(booking.driver), balanceUpdate);

                let details = {
                    type: 'Credit',
                    amount: booking.driver_share ? parseFloat(booking.driver_share) : 0,
                    date: GetCurrentMiniTimeStamp(),
                    txRef: booking.id
                }
                push(walletHistoryRef(booking.driver), details);

                if (parseFloat(booking.cashPaymentAmount) > 0) {
                    let details = {
                        type: 'Debit',
                        amount: booking.cashPaymentAmount ? parseFloat(booking.cashPaymentAmount) : 0,
                        date: GetCurrentMiniTimeStamp(),
                        txRef: booking.id
                    }
                    push(walletHistoryRef(booking.driver), details);
                }
            }, {onlyOnce: true});
        }
        if (booking.status === 'COMPLETE') {
            if (booking.rating) {
                newData.content = today + " : " + (booking.notify_to_center ? booking.notify_to_center : "Chuyến đi đã hoàn thành, Tài xế có thể nhận cuốc mới");
                push(reportdriverRef, newData);
                await update(singleBookingRef(booking.id), booking);
                RequestPushMsg(booking.driver_token, language.notification_title, language.received_rating.toString().replace("X", booking.rating.toString()));
                onValue(singleUserRef(booking.driver), snapshot => {
                    let profile = snapshot.val();
                    let ratings = {};
                    if (profile && profile.ratings) {
                        ratings = profile.ratings
                        let details = ratings.details;
                        let sum = 0;
                        for (let i = 0; i < details.length; i++) {
                            sum = sum + parseFloat(details[i].rate);
                        }
                        sum = sum + booking.rating;
                        ratings.userrating = parseFloat(sum / (details.length + 1)).toFixed(1);
                        ratings.details.push({
                            user: booking.customer,
                            rate: booking.rating
                        });
                    } else {
                        ratings.userrating = booking.rating;
                        ratings.details = [];
                        ratings.details.push({
                            user: booking.customer,
                            rate: booking.rating
                        });
                    }
                    if (ratings.userrating) {
                        set(setDriverFavouriteRatingRef(booking.customer, booking.driver), ratings.userrating);
                    }
                    update(singleUserRef(booking.driver), {ratings: ratings});
                }, {onlyOnce: true});
            } else {
                let dt = new Date();
                let localString = dt.toLocaleTimeString(dateStyle);
                let timeString = dt.getTime();
                booking.trip_start_time = localString;
                booking.startTime = timeString;
                booking.notify_to_center = "";
                await update(singleBookingRef(booking.id), booking);
                await update(singleUserRef(booking.driver), {queue: false});

                RequestPushMsg(booking.driver_token, language.notification_title, "Chuyến đi đã hoàn thành, Tài xế có thể nhận cuốc mới");
            }
            if (booking.requestedDrivers && booking.id) {
                await remove(requestedDriversRef(booking.id));
            }
        }
    }
};

export const getTotalBookingCancel = () => (dispatch) => {
    const {
        bookingAllListRef,
        auth
    } = firebase;

    dispatch({
        type: FETCH_TOTAL_BOOKINGS_CANCEL,
        payload: null,
    });
    var totalCancel = 0;
    var totalCancelOut = 0;
    var totalCancelIn = 0;
    //driver_mark_ignore_out: data.bookingPushOutList
    onValue(query(bookingAllListRef(), orderByChild('driver'), equalTo(auth.currentUser.uid)), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .filter(i => data[i].status === 'CANCELLED')
                .map((i) => {
                    data[i].id = i;
                    return data[i];
                });
            totalCancel = bookings.length;
        }
    }, {onlyOnce: true});

    onValue(query(bookingAllListRef(), orderByChild('driver_mark_ignore_out/' + auth.currentUser.uid), equalTo(true)), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
            totalCancelOut = bookings.length;
        }
    }, {onlyOnce: true});

    onValue(query(bookingAllListRef(), orderByChild('driver_mark_ignore_in/' + auth.currentUser.uid), equalTo(true)), (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
            totalCancelIn = bookings.length;
        }
    }, {onlyOnce: true});

    dispatch({
        type: FETCH_TOTAL_BOOKINGS_CANCEL_SUCCESS,
        payload: {
            totalBookingCancel: totalCancel,
            totalBookingIgnore: totalCancelIn + totalCancelOut
        },
    });
}

export const stopGetTotalBookingCancel = () => (dispatch) => {
    const {
        bookingAllListRef,
        auth
    } = firebase;

    off(query(bookingAllListRef(), orderByChild('driver'), equalTo(auth.currentUser.uid)));

    off(query(bookingAllListRef(), orderByChild('driver_mark_ignore_out/' + auth.currentUser.uid), equalTo(true)))

    off(query(bookingAllListRef(), orderByChild('driver_mark_ignore_in/' + auth.currentUser.uid), equalTo(true)))
}


export const cancelBooking = (data) => (dispatch) => {
    const {
        singleBookingRef,
        requestedDriversRef,
        reportdriverRef,
        refApi,
        notifyImportanceToRef,
        auth,
        notifyImportanceIdRef
    } = firebase;

    if (data.booking.id) {
        let dt = new Date();
        let today = ConvertTimeStampToDate(dt.getTime());
        dispatch({
            type: CANCEL_BOOKING,
            payload: data,
        });

        var newData = {};
        var codeDriver = " ";
        if (data.booking.codeDriver) {
            codeDriver = data.booking.codeDriver;
        }

        if (data.booking.status === 'NEW' || data.booking.status === 'NEWV2') {
            if (data.booking.status === 'NEWV2' && codeDriver) {
                newData.content = today + ' Tài xế ' + codeDriver + ' từ chối #' + data.booking.id;
            } else if (data.booking.status === 'NEWV2' && codeDriver == '') {
                newData.content = today + ' [' + data.booking.customer_note + '] Cuốc xe đã tự động hủy do không có tài xế nhận #' + data.booking.id;
            }
        } else {
            if (data.cancel_by && data.cancel_by == 'system') {
                newData.content = today + ' [' + data.booking.customer_note + ']  Tài xế #' + codeDriver + ' ' + language.booking_cancelled + ' #' + data.booking.id;
            } else {
                newData.content = today + ' [' + data.booking.customer_note + ']  Trung tâm đã hủy chuyến đi';
            }
        }
        if (newData.content) {
            push(reportdriverRef, newData);
        }

        if (data.booking.requestedDrivers && data.booking.id) {
            remove(requestedDriversRef(data.booking.id));
        }

        let hasPushNotify = (data.booking.customer_token && data.booking.driver && (data.booking.status === 'ACCEPTED' || data.booking.status === 'ARRIVED' || data.booking.status === 'DA_DEN_CHO_DON_KHACH'));

        /*if ((data.booking.status === 'NEW' || data.booking.status === 'NEWV2') && data.cancel_by === 'rider') {
            singleTaskDriverAcceptRef(data.booking.id).once("value", snapshot => {
                let driverAccepted = snapshot.val();
                if (driverAccepted) {
                    Object.values(driverAccepted).map((driver) => {
                        singleUserRef(driver.uid).once("value", snapshot => {
                            if (snapshot.val()) {
                                let profile = snapshot.val();
                                let currentBalance = parseFloat(profile.walletBalance);
                                walletBalRef(driver.uid).set(currentBalance + 100000).then(() => {
                                    let details = {
                                        type: 'Refund',
                                        amount: 100000,
                                        date: GetCurrentMiniTimeStamp(),
                                        txRef: data.booking.id ? data.booking.id : GetCurrentMiniTimeStamp(),
                                        transaction_id: generateTripCode(10)
                                    }
                                    walletHistoryRef(driver.uid).push(details).then(() => {
                                        RequestPushMsg(profile.pushToken, language.notification_title, "Tiền tạm giữ cuốc khách đã được hoàn trả");
                                    })
                                })
                            }
                        });
                    });
                }
            });
        }*/

        var regionType = '';
        if (auth.currentUser.uid == data.booking.driver) {
            regionType = 'driver';
        }

        if (auth.currentUser.uid == data.booking.customer) {
            regionType = 'rider';
        }

        if (regionType == '') {
            regionType = 'system';
        }

        if (data.cancel_by && data.cancel_by == 'system') {
            regionType = 'system';
        }

        let dataUpdate = {
            status: 'CANCELLED',
            count_time: "BAO_TIN_HIEU_LAN_2_REQUEST",
            reason: data.reason ? data.reason : newData.content,
            reason_type: regionType,
            notify_to_center: newData.content ? newData.content : 'Cuốc xe đã tự động hủy do không có tài xế nhận #' + data.booking.id
        }

        if (regionType == 'rider') {
            dataUpdate.driver_mark_ignore_out = '';
        }


        if (data && data.booking && data.booking.customer) {
            onValue(notifyImportanceToRef(data.booking.customer), (snapshot) => {
                if (snapshot.val()) {
                    let dataNotify = snapshot.val();
                    const arr = Object.keys(dataNotify)
                        .filter(i => dataNotify[i].status == 'pending' && dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == data.booking.id)
                        .map(i => {
                            dataNotify[i].id = i;
                            return dataNotify[i];
                        });
                    if (arr && arr.length > 0) {
                        let notify = arr[0];
                        let dataNotify = {
                            title: "Cuốc khách đã bị huỷ",
                            content: data.reason ? data.reason : '',
                            status: regionType == 'rider' ? 'reject' : 'pending'
                        };
                        update(notifyImportanceIdRef(notify.id), dataNotify);
                    }
                }
            }, {onlyOnce: true});
            if (data.booking.customer_token) {
                RequestPushMsg(data.booking.customer_token, "Cuốc khách đã bị huỷ", data.reason ? data.reason : 'Cuốc khách đã bị huỷ bởi tài xế');
            }
        }

        if (data && data.booking && data.booking.driver) {
            onValue(notifyImportanceToRef(data.booking.driver), (snapshot) => {
                if (snapshot.val()) {
                    let dataNotify = snapshot.val();
                    const arr = Object.keys(dataNotify)
                        .filter(i => dataNotify[i].status == 'pending' && dataNotify[i].type == "booking_driver_accepted" && dataNotify[i].booking_id && dataNotify[i].booking_id == data.booking.id)
                        .map(i => {
                            dataNotify[i].id = i;
                            return dataNotify[i];
                        });
                    if (arr && arr.length > 0) {
                        let notify = arr[0];
                        let dataNotify = {
                            title: "Cuốc khách đã bị huỷ",
                            content: data.reason ? data.reason : '',
                            status: 'pending'
                        };
                        update(notifyImportanceIdRef(notify.id), dataNotify);
                    }
                }
            }, {onlyOnce: true});


            if (data && data.booking && data.booking.customer) {
                /*notifyImportanceToRef(data.booking.customer).once("value", (snapshot) => {
                    if (snapshot.val()) {
                        let dataNotify = snapshot.val();
                        const arr = Object.keys(dataNotify)
                            .filter(i => dataNotify[i].status == 'pending' && dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == data.booking.id)
                            .map(i => {
                                dataNotify[i].id = i;
                                return dataNotify[i];
                            });
                        if (arr && arr.length > 0) {
                            let notify = arr[0];
                            let dataNotify = {
                                title: "Cuốc khách đã bị huỷ",
                                content: data.reason ? data.reason : '',
                                status: 'reject'
                            };
                            notifyImportanceIdRef(notify.id).update(dataNotify);
                        }
                    }
                });*/
                if (data.booking.driver_token && !data.has_recover_eto && !data.has_recover_limit) {
                    RequestPushMsg(data.booking.driver_token, "Cuốc khách đã bị huỷ", data.reason ? data.reason : 'Khách hàng đã huỷ');
                } else {
                    if (data.booking.driver_token && (data.has_recover_eto || data.has_recover_limit)) {
                        RequestPushMsg(data.booking.driver_token, "ETO Thông báo", "Trạng thái quyền ưu tiên đã được khôi phục");
                    }
                }
            }

        }

        update(singleBookingRef(data.booking.id), dataUpdate).then((res) => {
            /*if (regionType == 'driver') { // Khóa lái xe 5 phut
                dispatch(api.getTotalBookingCancel());
                dispatch(api.updateUserLockStatus(data.booking.driver, true));
                setTimeout(() => {
                    dispatch(api.updateUserLockStatus(data.booking.driver, false));
                }, 1000 * 60 * 5);
            }

            if (regionType == 'system') {
                if (hasPushNotify) {
                    RequestPushMsg(data.booking.driver_token, language.notification_title, "Cuốc khách đã bị huỷ");
                }
            }*/

            refApi.updateQueueDriver(data.booking.driver, false);
            // Thông báo cho người đặt

            // Thong bao cho quan ly
            /*if (codeDriver && data.booking.areaId) {
                const areasdata = store.getState().areasdata.areas;
                if (areasdata) {
                    let areaBooking = areasdata.filter((a) => a.id == data.booking.areaId)[0];
                    if (areaBooking && areaBooking.leaders) {
                        let leader = Object.values(areaBooking.leaders).filter((l) => l.role == 'leader' && l.status == 'active')[0];
                        if (leader && codeDriver) {
                            let dataNotify = {
                                date_add: GetCurrentTimeStamp(),
                                status: 'pending',
                                type: 'warning_driver_cancel',
                                title: "Tài xế #" + codeDriver + ' đã hủy cuốc khách.',
                                content: "Tài xế đã hủy cuốc khách của bạn!",
                                to: data.booking.customer,
                                params: {
                                    booking: data.booking,
                                    area: areaBooking,
                                    leader: leader
                                }
                            };
                            dispatch(api.pushNotifyImportance(dataNotify));
                            let dataNotify2 = {
                                date_add: GetCurrentTimeStamp(),
                                status: 'pending',
                                type: 'warning_area_empty',
                                title: 'Cảnh báo Hủy cuốc khách',
                                content: "Bạn đã hủy cuốc khách, hệ thống tự động khóa bạn 5 phút",
                                to: data.booking.driver,
                                params: {
                                    booking: data.booking,
                                    area: areaBooking,
                                    leader: leader
                                }
                            };
                            dispatch(api.pushNotifyImportance(dataNotify2));
                        }
                    }
                }
                // Cảnh báo cho LX hủy
            }*/
        }).catch((error) => {
            console.log("error", error);
        });
    }
};


