import {
    EDIT_TEAMS_TAXI,
    EDIT_TEAMS_TAXI_SUCCESS,
    FETCH_TEAMS_TAXI,
    FETCH_TEAMS_TAXI_BY_ID,
    FETCH_TEAMS_TAXI_BY_ID_FAILED,
    FETCH_TEAMS_TAXI_BY_ID_SUCCESS,
    FETCH_TEAMS_TAXI_FAILED,
    FETCH_TEAMS_TAXI_SUCCESS,
    INVITE_MEMBER_TAXI_TEAM,
    INVITE_MEMBER_TAXI_TEAM_FAILED,
    INVITE_MEMBER_TAXI_TEAM_SUCCESS,
    JOIN_TAXI_TEAM,
    JOIN_TAXI_TEAM_FAILED,
    JOIN_TAXI_TEAM_SUCCESS,
    REMOVE_MEMBER_TAXI_TEAM,
    REMOVE_MEMBER_TAXI_TEAM_FAILED,
    REMOVE_MEMBER_TAXI_TEAM_SUCCESS
} from "../store/types";
import {GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "../other/DateFunctions";
import {MainConfig} from "config/mainconfig";
import store from "../store/store";
import {validPhoneVN} from "../other/CommonFunctions";
import {RequestPushMsg} from "../other/NotificationFunctions";
import {firebase} from '../config/configureFirebase';
import {onValue, push, remove, set, update} from "firebase/database";
import {getDownloadURL, uploadBytesResumable} from "firebase/storage";

export const fetchTaxiTeams = () => (dispatch) => {

    const {
        taxiTeamsRef
    } = firebase;

    dispatch({
        type: FETCH_TEAMS_TAXI,
        payload: null
    });
    onValue(taxiTeamsRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();

            const arr = Object.keys(data).map(i => {
                data[i].id = i
                return data[i]
            });
            dispatch({
                type: FETCH_TEAMS_TAXI_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_TEAMS_TAXI_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const fetchTaxiTeamById = (teamId) => (dispatch) => {

    const {
        taxiTeamByIdRef
    } = firebase;

    dispatch({
        type: FETCH_TEAMS_TAXI_BY_ID,
        payload: null
    });
    onValue(taxiTeamByIdRef(teamId), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            data.id = teamId;
            dispatch({
                type: FETCH_TEAMS_TAXI_BY_ID_SUCCESS,
                payload: data
            });
        } else {
            dispatch({
                type: FETCH_TEAMS_TAXI_BY_ID_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const editTaxiTeams = (taxiTeams, method) => (dispatch) => {
    const {
        auth,
        taxiTeamsRef,
        taxiTeamsEditRef,
        taxiTeamsEditImageRef,
        inviteMemberDetailRef,
        inviteMembersRef
    } = firebase;
    let settings = store.getState().settingsdata.settings;
    dispatch({
        type: EDIT_TEAMS_TAXI,
        payload: {method, taxiTeams}
    });
    if (method === 'Add') {
        taxiTeams.admin = auth.currentUser.uid;
        taxiTeams.code = GetCurrentTimeStamp();
        taxiTeams.create_date = GetCurrentMiniTimeStamp();
        taxiTeams.status = 'accepted';
        taxiTeams.members = [{uid: auth.currentUser.uid, role: 'admin', status: 'accepted', date_add: GetCurrentMiniTimeStamp()}]
        taxiTeams.url_share = (settings && settings.CompanyWebsite ? settings.CompanyWebsite : MainConfig.domain) + "/invite/" + taxiTeams.code;
        push(taxiTeamsRef, taxiTeams);
        dispatch({
            type: EDIT_TEAMS_TAXI_SUCCESS,
            payload: {method, taxiTeams}
        });
    } else if (method === 'Delete') {
        remove(taxiTeamsEditRef(taxiTeams.id));
        dispatch({
            type: EDIT_TEAMS_TAXI_SUCCESS,
            payload: {method, taxiTeams}
        });
    } else if (method === 'Update') {
        if (taxiTeams.blob_data) {
            uploadBytesResumable(taxiTeamsEditImageRef(taxiTeams.id), taxiTeams.blob_data).then(() => {
                taxiTeams.blob_data.close()
                return getDownloadURL(taxiTeamsEditImageRef(taxiTeams.id));
            }).then((url) => {
                taxiTeams.image = url;
                taxiTeams.blob_data = null;
                update(taxiTeamsEditRef(taxiTeams.id), taxiTeams);
                dispatch({
                    type: EDIT_TEAMS_TAXI_SUCCESS,
                    payload: {method, taxiTeams}
                });
            })
        } else {
            if (taxiTeams.type && taxiTeams.type == 'changeAdmin') {
                onValue(inviteMembersRef(taxiTeams.id), snapshot => {
                    if (snapshot.val()) {
                        const data = snapshot.val();
                        data.map((mem, index) => {
                            if (mem.role == 'admin') {
                                remove(inviteMemberDetailRef(taxiTeams.id, index));
                            }
                            if (mem.uid == taxiTeams.adminChange) {
                                update(inviteMemberDetailRef(taxiTeams.id, index), {role: 'admin'});
                                dispatch({
                                    type: EDIT_TEAMS_TAXI_SUCCESS,
                                    payload: data
                                });
                            }
                        });
                    }
                }, {onlyOnce: true});
                let data = {
                    id: taxiTeams.id,
                    admin: taxiTeams.adminChange
                }
                update(taxiTeamsEditRef(taxiTeams.id), data);
                dispatch({
                    type: EDIT_TEAMS_TAXI_SUCCESS,
                    payload: {method, taxiTeams}
                });
            } else {
                update(taxiTeamsEditRef(taxiTeams.id), taxiTeams);
                dispatch({
                    type: EDIT_TEAMS_TAXI_SUCCESS,
                    payload: {method, taxiTeams}
                });
            }

        }
    } else {
        set(taxiTeamsEditRef(taxiTeams.id), taxiTeams);
        dispatch({
            type: EDIT_TEAMS_TAXI,
            payload: {method, taxiTeams}
        });
    }
}

export const joinTeam = (uid, teamId, dataUpdate) => (dispatch) => {
    const {
        inviteMembersRef,
        inviteMemberDetailRef
    } = firebase;
    let teamSelect = store.getState().sessiondata.teamSelected;
    dispatch({
        type: JOIN_TAXI_TEAM,
        payload: uid
    });
    if (!teamSelect || !teamSelect.id) {
        dispatch({
            type: JOIN_TAXI_TEAM_FAILED,
            payload: {message: "Không xác định được nhóm taxi!"}
        });
    } else {
        onValue(inviteMembersRef(teamId), snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                data.map((mem, index) => {
                    if (mem.uid == uid) {
                        update(inviteMemberDetailRef(teamId, index), dataUpdate);
                        dispatch({
                            type: JOIN_TAXI_TEAM_SUCCESS,
                            payload: data
                        });
                    }
                });
            } else {
                dispatch({
                    type: JOIN_TAXI_TEAM_FAILED,
                    payload: null
                });
            }
        }, {onlyOnce: true});
    }
}

export const removeMember = (uid, teamId) => (dispatch) => {
    const {
        inviteMembersRef,
        inviteMemberDetailRef
    } = firebase;
    let teamSelect = store.getState().sessiondata.teamSelected;
    dispatch({
        type: REMOVE_MEMBER_TAXI_TEAM,
        payload: uid
    });
    if (!teamSelect || !teamSelect.id) {
        dispatch({
            type: REMOVE_MEMBER_TAXI_TEAM_FAILED,
            payload: {message: "Không xác định được nhóm taxi!"}
        });
    } else {
        onValue(inviteMembersRef(teamId), snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val();
                data.map((mem, index) => {
                    if (mem.uid == uid) {
                        remove(inviteMemberDetailRef(teamId, index));
                        dispatch({
                            type: REMOVE_MEMBER_TAXI_TEAM_SUCCESS,
                            payload: data
                        });
                    }
                });
            } else {
                dispatch({
                    type: REMOVE_MEMBER_TAXI_TEAM_FAILED,
                    payload: null
                });
            }
        }, {onlyOnce: true});
    }
}

export const inviteMember = (phone, teamId) => (dispatch) => {
    const {
        inviteMembersRef,
        auth
    } = firebase;
    let driverActive = store.getState().usersdata.drivers_active;
    dispatch({
        type: INVITE_MEMBER_TAXI_TEAM,
        payload: phone
    });
    if (!teamId) {
        dispatch({
            type: INVITE_MEMBER_TAXI_TEAM_FAILED,
            payload: {message: "Không xác định được nhóm taxi!"}
        });
    } else {
        if (!phone) {
            dispatch({
                type: INVITE_MEMBER_TAXI_TEAM_FAILED,
                payload: {message: "Vui lòng nhập số điện thoai hoặc mã số lái xe"}
            });
        } else {
            var drivers = null;
            var driver = null;
            if (validPhoneVN(phone)) {
                drivers = driverActive.filter((d) => d.mobile == '+84' + phone);
            } else {
                drivers = driverActive.filter((d) => d.codeDriver == phone);
            }
            if (drivers) {
                driver = drivers[0];
                onValue(inviteMembersRef(teamId), snapshot => {
                    if (snapshot.val()) {
                        const data = snapshot.val();
                        const arr = Object.keys(data).map(i => {
                            data[i].id = i
                            return data[i]
                        });

                        var listMember = null;
                        if (driver) {
                            listMember = arr.filter((m) => m.uid == driver.id);
                        }

                        if (!listMember || listMember.length === 0 || !driver) {
                            let objInvite = {uid: driver ? driver.id : phone, role: 'member', status: 'pending', date_add: GetCurrentMiniTimeStamp(), invite_by: auth.currentUser.uid};
                            data.push(objInvite);
                            try {
                                if (driver && driver.pushToken) {
                                    RequestPushMsg(driver.pushToken, "Bạn có lời mời  tham gia nhóm, vào quản lý hội nhóm để đồng ý!");
                                }
                                set(inviteMembersRef(teamId), data);
                                dispatch({
                                    type: INVITE_MEMBER_TAXI_TEAM_SUCCESS,
                                    payload: objInvite
                                });
                            } catch (error) {
                                console.log(error);
                                dispatch({
                                    type: INVITE_MEMBER_TAXI_TEAM_FAILED,
                                    payload: {message: "Có lỗi phát sinh, vui lòng liên hệ admin!"}
                                });
                            }
                        } else {
                            dispatch({
                                type: INVITE_MEMBER_TAXI_TEAM_FAILED,
                                payload: {message: "Lái xe có trong nhóm"}
                            });
                        }
                    } else {
                        dispatch({
                            type: INVITE_MEMBER_TAXI_TEAM_FAILED,
                            payload: {message: "Lái xe có trong nhóm"}
                        });
                    }
                }, {onlyOnce: true});
            } else {
                dispatch({
                    type: INVITE_MEMBER_TAXI_TEAM_FAILED,
                    payload: {message: "Lái xe có trong nhóm"}
                });
            }
        }
    }
}
