import {
    FETCH_TASKS,
    FETCH_TASKS_SUCCESS,
    FETCH_TASKS_FAILED,
    ACCEPT_TASK,
    CANCEL_TASK, TRACKING_TASKS, TRACKING_TASKS_SUCCESS, TRACKING_TASKS_FAILED, ASSIGN_TASK, FETCH_ACCEPTED_TASKS, FETCH_ACCEPTED_TASKS_SUCCESS, FETCH_ACCEPTED_TASKS_FAILED,
} from "../store/types";

const INITIAL_STATE = {
    tasks: null,
    tracking_tasks: null,
    tasks_accepted: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const tasklistreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TASKS:
            return {
                ...state,
                loading: true
            };
        case FETCH_TASKS_SUCCESS:
            return {
                ...state,
                tasks: action.payload,
                loading: false
            };
        case FETCH_TASKS_FAILED:
            return {
                ...state,
                tasks: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_ACCEPTED_TASKS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ACCEPTED_TASKS_SUCCESS:
            return {
                ...state,
                tasks_accepted: action.payload,
                loading: false
            };
        case FETCH_ACCEPTED_TASKS_FAILED:
            return {
                ...state,
                tasks_accepted: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case TRACKING_TASKS:
            return {
                ...state,
                loading: true
            };
        case TRACKING_TASKS_SUCCESS:
            return {
                ...state,
                tracking_tasks: action.payload,
                loading: false
            };
        case TRACKING_TASKS_FAILED:
            return {
                ...state,
                tracking_tasks: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case ACCEPT_TASK:
            return {
                ...state
            };
        case ASSIGN_TASK:
            return {
                ...state
            };
        case CANCEL_TASK:
            return {
                ...state
            };
        default:
            return state;
    }
};