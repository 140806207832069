import React from "react";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import {useLocation} from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Invite(props) {
    const classes = useStyles();
    const {...rest} = props;
    const queryString = require('query-string');
    const location = useLocation();
    const parsed = queryString.parse(location.search);
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/header-back.png").default}/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <br/>
                    {parsed && parsed.phone ?
                        <h1>{parsed.phone}</h1> : <h1>Không hợp lệ</h1>
                    }

                    <br/>

                    <br/>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
