import {FETCH_NOTIFY_IMPORTANCE, FETCH_NOTIFY_IMPORTANCE_FAILED, FETCH_NOTIFY_IMPORTANCE_SUCCESS, PUSH_NOTIFY_IMPORTANCE, PUSH_NOTIFY_IMPORTANCE_FAILED, PUSH_NOTIFY_IMPORTANCE_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    notify: [],
    notifyPending: [],
    notifyImportance: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const notifyimportancereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NOTIFY_IMPORTANCE:
            return {
                ...state,
                loading: true
            };
        case FETCH_NOTIFY_IMPORTANCE_SUCCESS:
            return {
                ...state,
                notifyPending: action.payload.notifyPending,
                notifyImportance: action.payload.notifyImportance,
                loading: false
            };
        case FETCH_NOTIFY_IMPORTANCE_FAILED:
            return {
                ...state,
                notifyPending: [],
                notifyImportance: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case PUSH_NOTIFY_IMPORTANCE:
            return {
                ...state,
                loading: true
            };
        case PUSH_NOTIFY_IMPORTANCE_SUCCESS:
            return {
                ...state,
                notify: action.payload.notify,
                loading: false
            };
        case PUSH_NOTIFY_IMPORTANCE_FAILED:
            return {
                ...state,
                notify: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
