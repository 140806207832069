import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useEffect, useState} from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export default function CheckboxesTags(props) {
    const datas = props.datas ? props.datas : [];
    const placeholder = props.placeholder ? props.placeholder : '';
    const title = props.title ? props.title : 'title';
    const id = props.id ? props.id : 'checkboxes-tags-demo';
    const defaultValue = props.defaultValue ? props.defaultValue : [];
    const checkboxStyle = props.checkboxStyle ? props.checkboxStyle : {marginRight: 8};
    const selectStyle = props.selectStyle ? props.selectStyle : {width: '95%'};
    const inputStyle = props.inputStyle ? props.inputStyle : null;
    const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue);
        }
    }, [defaultValue]);
    const handleInputChange = (event, value) => {
        setInputValue(value);
    };
    return (
        <Autocomplete
            multiple
            id={id}
            onClose={() => {
                if (props.onClose) {
                    props.onClose(id, inputValue);
                }
            }}
            options={datas}
            disableCloseOnSelect
            getOptionLabel={(option) => option[title]}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={checkboxStyle}
                        checked={selected}
                    />
                    {option[title]}
                </li>
            )}
            style={selectStyle}
            renderInput={(params) => (
                <TextField {...params} label={placeholder} placeholder={placeholder} multiline={true}
                           numberOfLines={5}/>
            )}
        />
    );
}