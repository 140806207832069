import * as React from 'react';
import Box from '@mui/material/Box';
import {language} from "config/language";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {styled, alpha} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SegmentTwoToneIcon from '@mui/icons-material/SegmentTwoTone';
import Toolbar from '@mui/material/Toolbar';
import BgBlueLinear from "../assets/img/bg_blue_linear.png";
import CustomTreeView from "../components/TreeView/CustomTreeView";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api, FirebaseContext} from 'common';

import CheckboxesTags from "../components/Select/CheckboxesTags";
import SearchIcon from '@mui/icons-material/Search';
import {TreeView} from "@mui/x-tree-view";
const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const inputStyle = {
    color: 'white'
};


const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const divAdd = {
    display: 'flex',
    flexDirection: 'column',
    border: 2,
    borderStyle: 'solid',
    borderColor: '#2c8c00',
    borderRadius: 10,
    padding: 5
}
export default function DisabledTreeItems() {
    const {
        fetchRolePermission, editRolePermission, stopFetchRolePermission, fetchOnceUsers, editRolePermissionMember
    } = api;
    const dispatch = useDispatch();
    const rolepermissiondata = useSelector(state => state.rolepermissiondata);
    const [showAddPermission, setShowAddPermission] = useState(false);
    const [permissionName, setPermissionName] = useState('');
    const [permissionCode, setPermissionCode] = useState('');
    const [dataRoles, setDataRoles] = useState([]);
    const usersdata = useSelector(state => state.usersdata);
    const [userDatas, setUserDatas] = useState([]);
    const [showSelectDriver, setShowSelectDriver] = useState(false);

    useEffect(() => {
        dispatch(fetchRolePermission());
        dispatch(fetchOnceUsers());
        return () => {
            dispatch(stopFetchRolePermission());
        }
    }, []);

    useEffect(() => {
        if (rolepermissiondata.roles) {
            setDataRoles(rolepermissiondata.roles);
        }
    }, [rolepermissiondata]);

    useEffect(() => {
        if (usersdata.once_users) {
            let users = usersdata.once_users.map((user) => {
                user.title = user.codeDriver + " - " + user.firstName + " " + user.lastName;
                return user;
            });
            setUserDatas(users);
        } else {
            setUserDatas([]);
        }
    }, [usersdata.once_users]);

    const onCloseCallback = useCallback((parentId, value) => {
        var newValue = [];
        if (value && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
                newValue[value[i].id] = {
                    codeDriver: value[i].codeDriver,
                    nameDisplay: value[i].title,
                    id: value[i].id,
                    status: true
                }
            }
        }

        if (parentId) {
            dispatch(editRolePermissionMember(parentId, newValue, "Set"));
        }

        setShowSelectDriver(false);
    }, []);

    const onDeleteParent = useCallback((nodeId) => {
        if (nodeId) {
            dispatch(editRolePermission({
                id: nodeId
            }, "Delete"));
        }
    }, []);

    const onDeleteChild = useCallback((parenId, nodeId) => {
        if (parenId) {
            dispatch(editRolePermissionMember(parenId, {
                id: nodeId
            }, "Delete"));
        }
    }, []);

    const onChangeStatusParent = useCallback((nodeId, status) => {
        if (nodeId) {
            dispatch(editRolePermission({
                id: nodeId,
                status: status
            }, "Update"));
        }
    }, []);

    const onChangeStatusChild = useCallback((parenId, nodeId, status) => {
        if (parenId) {
            dispatch(editRolePermissionMember(parenId, {
                id: nodeId,
                status: status
            }, "Update"));
        }
    }, []);

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                maxWidth: 400,
                backgroundImage: "url(" + BgBlueLinear + ")",
                borderRadius: 15,
                paddingBottom: 20
            }}>
                <h1 style={{fontSize: 30, color: 'white'}}>{language.role_permission}</h1>
                <Box sx={{
                    minHeight: 50, flexGrow: 1, maxWidth: 400, overflowX: 'auto', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }} px={2}>
                    <Toolbar>
                        <IconButton
                            aria-label="Thêm"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexGrow: 1,
                                color: 'white'
                            }}
                            color="inherit"
                            size="medium"
                            onClick={() => {
                                setShowAddPermission(true);
                            }}
                        >
                            <AddIcon fontSize="inherit"/>
                        </IconButton>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon color={'info'}/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                style={inputStyle}
                            />
                        </Search>
                        <IconButton
                            aria-label="Thêm"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexGrow: 1,
                                color: 'white'
                            }}
                            color="inherit"
                            size="medium"
                            onClick={() => {

                            }}
                        >
                            <SegmentTwoToneIcon fontSize="inherit"/>
                        </IconButton>
                    </Toolbar>
                </Box>
                <Box sx={{minHeight: 270, flexGrow: 1, maxWidth: 400, overflowY: 'auto'}} px={2}>
                    {showAddPermission ?
                        <div style={divAdd}>
                            <h2 style={{
                                fontSize: 20, color: 'white', alignItems: 'center',
                                justifyContent: 'center', textAlign: 'center', padding: 0, margin: 0, marginBottom: 10
                            }}>Thêm quyền nhân viên</h2>
                            <StyledInputBase
                                placeholder="Tên nhóm …"
                                inputProps={{'aria-label': 'Tên nhóm'}}
                                style={{
                                    borderRadius: 5,
                                    color: 'white',
                                    backgroundColor: alpha("#ffffff", 0.15),
                                    '&:hover': {
                                        backgroundColor: alpha("#ffffff", 0.25),
                                    },
                                }}
                                onChange={(event) => {
                                    setPermissionName(event.target.value);
                                }
                                }
                            />
                            <StyledInputBase
                                placeholder="Mã nhóm …"
                                inputProps={{'aria-label': 'Mã nhóm'}}
                                style={{
                                    borderRadius: 5,
                                    color: 'white',
                                    backgroundColor: alpha("#ffffff", 0.15),
                                    '&:hover': {
                                        backgroundColor: alpha("#ffffff", 0.25),
                                    },
                                    marginTop: 10
                                }}
                                onChange={(event) => {
                                    setPermissionCode(event.target.value);
                                }
                                }
                            />

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}>
                                <IconButton
                                    aria-label="Huỷ"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        color: "#ff4612"
                                    }}
                                    color="inherit"
                                    size="medium"
                                    onClick={() => {
                                        setPermissionCode('');
                                        setPermissionName('');
                                        setShowAddPermission(false);
                                    }}
                                >
                                    <CancelIcon fontSize="inherit"/>
                                </IconButton>

                                <IconButton
                                    aria-label="Lưu"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        color: "#00a615"
                                    }}
                                    color="inherit"
                                    size="medium"
                                    onClick={() => {
                                        if (permissionName && permissionCode) {
                                            dispatch(editRolePermission({
                                                name: permissionName,
                                                code: permissionCode,
                                                status: true
                                            }, "Add"));
                                            alert("Thêm quyền " + permissionName + " thành công!");
                                            setPermissionCode('');
                                            setPermissionName('');
                                            setShowAddPermission(false);
                                        } else {
                                            if (permissionName == '') {
                                                alert("Vui lòng nhập tên quyền");
                                            }
                                            if (permissionCode == '') {
                                                alert("Vui lòng nhập mã");
                                            }
                                        }

                                    }}
                                >
                                    <SaveIcon fontSize="inherit"/>
                                </IconButton>
                            </div>

                        </div> : null}

                    {dataRoles ?
                        <TreeView
                            aria-label="disabled items"
                            style={{
                                color: 'white'
                            }}
                            defaultCollapseIcon={<HorizontalRuleIcon style={{marginTop: 7}} fontSize={"medium"}/>}
                            defaultExpandIcon={<AddIcon style={{marginTop: 7}} fontSize={"medium"}/>}
                            multiSelect
                        >
                            {dataRoles.map((role, index) => {
                                return (
                                    <CustomTreeView item={role} type={"parent"}
                                                    onDelete={onDeleteParent}
                                                    onChangeStatus={onChangeStatusParent}
                                                    nodeId={role.id} label={role.name + ":"} style={{
                                        border: 2,
                                        width: 350,
                                        borderStyle: 'solid',
                                        borderColor: '#3a3a3a',
                                        borderRadius: 10,
                                        padding: 5,
                                        marginTop: 10
                                    }}>
                                        {role.members && role.members ?
                                            Object.values(role.members).map((member, index) => {
                                                return (
                                                    <CustomTreeView type={"child"}
                                                                    onDelete={onDeleteChild}
                                                                    parentId={role.id}
                                                                    onChangeStatus={onChangeStatusChild}
                                                                    item={member} nodeId={member.id} label={member.nameDisplay}/>
                                                )
                                            })
                                            : null}
                                        {showSelectDriver ?
                                            <CheckboxesTags
                                                datas={userDatas}
                                                id={role.id}
                                                onClose={onCloseCallback}
                                                defaultValue={() => {
                                                    var seleteds = [];
                                                    if (role.members) {
                                                        Object.values(role.members).map((member) => {
                                                            seleteds.push(userDatas.filter(user => user.id === member.id)[0]);
                                                        });
                                                    }
                                                    return seleteds;
                                                }
                                                }
                                                selectStyle={{
                                                    color: '#ffffff'
                                                }}
                                            />
                                            :
                                            <IconButton
                                                aria-label="Thêm"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexGrow: 1, width: '80%'
                                                }}
                                                color="inherit"
                                                size="medium"
                                                onClick={() => {
                                                    setShowSelectDriver(true);
                                                }}
                                            >
                                                <AddIcon fontSize="inherit"/>
                                            </IconButton>}
                                    </CustomTreeView>

                                )
                            })
                            }
                        </TreeView> : null}
                </Box>
            </div>
        </div>
    );
}