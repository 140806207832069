import React, {useContext} from 'react';
import {Divider, ListItemIcon, MenuItem, MenuList, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ContactSupport from '@material-ui/icons/ContactSupport';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import StarsIcon from '@mui/icons-material/Stars';
import {features, language} from 'config';
import assets from 'assets';
import {api, FirebaseContext} from 'common';

function AppMenu() {
    const {
        signOutL
    } = api;
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const LogOut = () => {
        dispatch(signOutL());
    };

    let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
    let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
    return (
        <div style={{overflowY: 'scroll', paddingBottom: 100}}>
            <div style={{display: 'flex', justifyContent: 'center', backgroundColor: '#444444'}}>
                <img style={{marginTop: '20px', marginBottom: '20px', width: '120px', height: '120px'}}
                     src={assets.logo192x192} alt="Logo"/>
            </div>
            <Divider/>
            <MenuList>
                {features.WebsitePagesEnabled ?
                    <MenuItem component={Link} to="/">
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.home}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/dashboard">
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.dashboard_text}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/callcenter">
                        <ListItemIcon>
                            <BusinessCenter/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Trung Tâm"}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/reportdrivers">
                        <ListItemIcon>
                            <CarIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">Báo cáo lịch sử tài</Typography>
                    </MenuItem>
                    : null}


                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/customer_care">
                        <ListItemIcon>
                            <ContactSupport/>
                        </ListItemIcon>
                        <Typography variant="inherit">Chăm sóc khách hàng</Typography>
                    </MenuItem>
                    : null}


                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/catrucdems">
                        <ListItemIcon>
                            <CalendarIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.catrucdem}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/report_booking">
                        <ListItemIcon>
                            <SummarizeIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Báo cáo CK APP"}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/giaoxe">
                        <ListItemIcon>
                            <CalendarIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.giaoxe}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/force_notify">
                        <ListItemIcon>
                            <NotifyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Cảnh báo"}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/backend_tracking_car">
                        <ListItemIcon>
                            <CloudSyncIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Báo cáo CK Metter"}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/vip_report">
                        <ListItemIcon>
                            <StarsIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Báo cáo VIP"}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin ?
                    <MenuItem component={Link} to="/report_total_booking_day">
                        <ListItemIcon>
                            <NotifyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Cuốc khách theo ngày"}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin || isFleetAdmin ?
                    <MenuItem component={Link} to="/mistakes">
                        <ListItemIcon>
                            <NotifyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Danh sách vi phạm"}</Typography>
                    </MenuItem>
                    : null}
                <MenuItem component={Link} to="/bookings">
                    <ListItemIcon>
                        <ListIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">{language.booking_history}</Typography>
                </MenuItem>
                {/*{isAdmin ?
                    <MenuItem component={Link} to="/addbookings">
                        <ListItemIcon>
                            <ListIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.addbookinglable}</Typography>
                    </MenuItem>
                    : null}*/}
                {isAdmin ?
                    <MenuItem component={Link} to="/riders">
                        <ListItemIcon>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.riders}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/drivers">
                        <ListItemIcon>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.drivers}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/fleetadmins">
                        <ListItemIcon>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.fleetadmins}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/cartypes">
                        <ListItemIcon>
                            <CarIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.car_type}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/groupdrivers">
                        <ListItemIcon>
                            <CarIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.group_driver_title}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/walletmanage">
                        <ListItemIcon>
                            <WalletIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">Lịch sử giao dịch</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/cancelreasons">
                        <ListItemIcon>
                            <CarIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.cancellation_reasons}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/earningreports">
                        <ListItemIcon>
                            <MoneyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.earning_reports}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/driverearning">
                        <ListItemIcon>
                            <MoneyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.driver_earning}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/transaction">
                        <ListItemIcon>
                            <MoneyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.transaction_report}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/addtowallet">
                        <ListItemIcon>
                            <MoneyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.add_to_wallet}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/withdraws">
                        <ListItemIcon>
                            <MoneyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.withdraws}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/promos">
                        <ListItemIcon>
                            <OfferIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.promo}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/notifications">
                        <ListItemIcon>
                            <NotifyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.push_notification_title}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/mistake_config">
                        <ListItemIcon>
                            <NotifyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Cài đặt vi phạm"}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/catrucdem_config">
                        <ListItemIcon>
                            <NotifyIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Cấu hình ca đêm"}</Typography>
                    </MenuItem>
                    : null}
                {isAdmin ?
                    <MenuItem component={Link} to="/settings">
                        <ListItemIcon>
                            <LocalAtmIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.settings_title}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin ?
                    <MenuItem component={Link} to="/role_permission">
                        <ListItemIcon>
                            <PersonOutlineIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{language.role_permission}</Typography>
                    </MenuItem>
                    : null}

                {isAdmin ?
                    <MenuItem component={Link} to="/test_logic">
                        <ListItemIcon>
                            <PersonOutlineIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">{"Test Logic"}</Typography>
                    </MenuItem>
                    : null}

                <MenuItem component={Link} to="/profile">
                    <ListItemIcon>
                        <PersonOutlineIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">{language.profile}</Typography>
                </MenuItem>
                <MenuItem onClick={LogOut}>
                    <ListItemIcon>
                        <ExitIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">{language.logout}</Typography>
                </MenuItem>
            </MenuList>
        </div>
    );
}

export default AppMenu;
