import React, {useCallback, useContext, useEffect, useRef, useState,} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import Grid from "@material-ui/core/Grid";
import {Button, Modal, Text, View} from "react-native-web";
import AddBookingsNew from "../views/AddBookingsNew";
import {ConvertSecondToHm, ConvertTimeStampPaUnique, ConvertTimeStampTimeForPa, ConvertTimeStampToDate, GetDateString, GetDateString00,} from "common/src/other/DateFunctions";
import {formatDisplayDateSmart, HiddenPhone,} from "common/src/other/CommonFunctions";
import BookingCallCenter from "../components/CallCenter/BookingCallCenter";
import CancelCallCenter from "../components/CallCenter/CancelCallCenter";
import CallNowCenter from "../components/CallCenter/CallNowCenter";
import ButtonCallCenter from "../components/CallCenter/ButtonCallCenter";
import {api, FirebaseContext} from "common";
import FooterAdmin from "../components/Footer/FooterAdmin";
import ThemXeCallCenter from "../components/CallCenter/ThemXeCallCenter";
import ButtonCallCenterDetail from "../components/CallCenter/ButtonCallCenterDetail";
import ButtonCallCenterDetailDialog from "../components/CallCenter/ButtonCallCenterDetailDialog";
import {language} from "config";
import {colors} from "../config/theme";
import Chat from "@material-ui/icons/Chat";
import IconButton from "@material-ui/core/IconButton";
import ShowNotifyDialog from "../components/Notify/ShowNotifyDialog";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer";
import {TouchableOpacity} from "react-native";
import {List, Paper, TextField} from "@material-ui/core";
import AddCallCenter from "../components/CallCenter/AddCallCenter";
import MaterialTable from "material-table";
import NoteCallCenterComponent from "../components/CallCenter/NoteCallCenterComponent";
import ReactAudioPlayer from "react-audio-player";
import {getRecordAudio} from "common/src/other/PAApiFunctions.js";
import {PlayCircleFilledOutlined, Refresh} from "@material-ui/icons";
import CustomerCareCallCenterComponent from "../components/CallCenter/CustomerCareCallCenterComponent";
import ShowCustomerCareDialog from "../components/CallCenter/ShowCustomerCareDialog";
import {update} from "firebase/database";

const dashboardRoutes = [];
const API_PA_PHONE = "a3a799635add5feb5ad2f1cb1b1680e3";
const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: "center",
    },
    gridcontainer: {
        alignContent: "center",
    },
    items: {
        margin: 0,
        width: "100%",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: 192,
        height: 192,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    marginTop50: {
        marginTop: 50,
    },
    borderLeftCallCenter: {
        borderRightColor: "#0078ff",
        borderRightWidth: 2,
        backgroundColor: "rgba(255,255,255,0.2)",
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: "column",
    },
    marginBottom50: {
        marginBottom: 50,
    },
    titleList: {
        fontSize: 14,
        marginTop: 10,
    },
    input: {
        height: 25,
        borderRadius: 5,
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
}));

const CallCenter = (props) => {
    const {paCallByIdRef, refApi} = useContext(FirebaseContext);
    const {
        bookingDetailOn,
        fetchReportDrivers,
        stopFetchReportDrivers,
        addReport,
        fetchCallsPending,
        stopFetchCallsPending,
        fetchCallDetailAll,
        stopFetchCallDetailAll,
        fetchAllBookings,
        fetchOnceDrivers,
        fetchNoteList,
        stopFetchNoteList,
        reportCallCenter
    } = api;
    const dispatch = useDispatch();
    const {setAudioCallCenterApi, setNoteCallCenterApi} = refApi;
    const settingsdata = useSelector((state) => state.settingsdata);
    const classes = useStyles();
    const [dataSetting, setDataSetting] = useState({
        note: "",
        reasons: "",
    });
    const [dataRp, setDataRp] = useState([]);

    const tableRef = useRef();
    const auth = useSelector((state) => state.auth);
    const [dataRpImportance, setDataRpImportance] = useState([]);
    const callDetailData = useSelector(
        (state) => state.pacallcenterdata.callDetail
    );
    const notelistdata = useSelector((state) => state.notelistdata.notes);
    const reportdriverdata = useSelector((state) => state.reportdriverdata);
    const [modalShow, setModalShow] = useState(false);
    const notesdata = useSelector((state) => state.bookinglistdata.notes);
    const groupDriverData = useSelector(
        (state) => state.groupdriverdata.group_kd
    );
    const pacallcenterdatas = useSelector((state) => state.pacallcenterdata);
    const [listCall, setListCall] = useState([]);
    const [listCallApp, setListCallApp] = useState([]);
    const [listGroup, setListGroup] = useState(null);
    const cartypes = useSelector((state) => state.cartypes.cars);
    const [notes, setNotes] = useState([]);
    const [popupData, setPopupData] = useState(null);
    const [callObj, setCallObj] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showThemXe, setShowThemXe] = useState(false);
    const [showCallNow, setShowCallNow] = useState(null);
    const [searchInit, setSearchInit] = useState(null);
    const [soundAlert, setSoundAlert] = useState(null);
    const [soundAlert2, setSoundAlert2] = useState(null);
    const [soundAlertViPham, setSoundAlertViPham] = useState(null);
    const [soundAlertHuy, setSoundAlertHuy] = useState(null);
    const [soundAlertDoiCa, setSoundAlertDoiCa] = useState(null);
    const [soundAlertHoTro, setSoundAlertHoTro] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showCustomerCareDialog, setShowCustomerCareDialog] = useState(false);
    const [callDetail, setCallDetail] = useState(null);
    const [showChatModal, setShowChatModal] = useState(null);
    const [driverCode, setDriverCode] = useState(null);
    const [noteBooking, setNoteBooking] = useState(null);
    const [bookingDate, setBookingDate] = useState(null);
    const [addBookingDialog, setAddBookingDialog] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState(null);
    const [greet, setGreet] = useState(null);
    const [startDateValue, setStartDateValue] = useState(GetDateString00());
    const [endDateValue, setEndDateValue] = useState(GetDateString());
    const [startDate, setStartDate] = useState(new Date(GetDateString00()).getTime() / 1000);
    const [endDate, setEndDate] = useState(new Date(GetDateString()).getTime() / 1000);
    const [reportData, setReportData] = useState(null);
    const [isApp, setIsApp] = useState(false);

    const {...rest} = props;

    useEffect(() => {
        dispatch(fetchOnceDrivers());
        dispatch(fetchReportDrivers());
        dispatch(fetchCallsPending());
        dispatch(fetchCallDetailAll());
        //dispatch(fetchAllBookings());
        dispatch(fetchNoteList());
        return () => {
            dispatch(stopFetchReportDrivers());
            dispatch(stopFetchCallsPending());
            dispatch(stopFetchCallDetailAll());
            dispatch(stopFetchNoteList());
        };
    }, []);

    const loadRecordFile = async (
        source,
        from_date,
        to_date,
        id,
        time_call,
        time
    ) => {
        setLoadingAudio({[time]: true});
        let listAudio = [];

        if (source && from_date && to_date) {
            await getRecordAudio(source, from_date, to_date)
                .then(async (dataResult) => {
                    if (dataResult && dataResult.data) {
                        let listAudioFiltered = JSON.parse(dataResult.data)
                            .filter((a) => a.inbound == "")
                            .map((am) => {
                                listAudio.push(am.recording.split("/").at(-1));
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            await getRecordAudio("102", from_date, to_date)
                .then((dataResult) => {
                    if (dataResult && dataResult.data) {
                        let listAudioFiltered = JSON.parse(dataResult.data)
                            .filter((a) => a.inbound == "" && a.to == source)
                            .map((am) => {
                                listAudio.push(am.recording.split("/").at(-1));
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            await getRecordAudio("101", from_date, to_date)
                .then(async (dataResult) => {
                    if (dataResult && dataResult.data) {
                        let listAudioFiltered = JSON.parse(dataResult.data)
                            .filter((a) => a.inbound == "" && a.to == source)
                            .map((am) => {
                                listAudio.push(am.recording.split("/").at(-1));
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            if (time_call) {
                time_call.map(async (time) => {
                    let from_date1 = ConvertTimeStampTimeForPa((time - 600) * 1000);
                    let to_date1 = ConvertTimeStampTimeForPa((time + 1800) * 1000);
                    await getRecordAudio("101", from_date1, to_date1)
                        .then((dataResult) => {
                            if (dataResult && dataResult.data) {
                                let listAudioFiltered = JSON.parse(dataResult.data)
                                    .filter((a) => a.inbound == "" && a.to == props.phone)
                                    .map((am) => {
                                        listAudio.push(am.recording.split("/").at(-1));
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    await getRecordAudio("102", from_date1, to_date1)
                        .then((dataResult) => {
                            if (dataResult && dataResult.data) {
                                let listAudioFiltered = JSON.parse(dataResult.data)
                                    .filter((a) => a.inbound == "" && a.to == props.phone)
                                    .map((am) => {
                                        listAudio.push(am.recording.split("/").at(-1));
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }

            if (listAudio) {
                setAudioCallCenterApi(id, listAudio);
            }
            setLoadingAudio(null);
        } else {
            setLoadingAudio(null);
            alert("Thiếu thông tin");
            return null;
        }
    };

    const loadSound = async () => {
        const sound = new Audio(
            require("../assets/sounds/cokhachhangmoi.wav").default
        );
        sound.load();
        setSoundAlert(sound);
    };

    const playSound = async () => {
        if (soundAlert) {
            const audioPromise = soundAlert.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const loadSound2 = async () => {
        const sound = new Audio(
            require("../assets/sounds/cuockhachhuydatlai.wav").default
        );
        sound.load();
        setSoundAlert2(sound);
    };

    const playSound2 = async () => {
        if (soundAlert2) {
            const audioPromise = soundAlert2.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    const loadSoundHuy = async () => {
        const sound = new Audio(
            require("../assets/sounds/cuokhachhuy.wav").default
        );
        sound.load();
        setSoundAlertHuy(sound);
    };

    const playSoundHuy = async () => {
        if (soundAlertHuy) {
            const audioPromise = soundAlertHuy.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (!soundAlertHuy) {
            loadSoundHuy();
        }
    }, [soundAlertHuy, setSoundAlertHuy]);

    const loadSoundDoiCa = async () => {
        const sound = new Audio(
            require("../assets/sounds/xetduyetcadem.wav").default
        );
        sound.load();
        setSoundAlertDoiCa(sound);
    };

    const playSoundDoiCa = async () => {
        if (soundAlertDoiCa) {
            const audioPromise = soundAlertDoiCa.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (!soundAlertDoiCa) {
            loadSoundDoiCa();
        }
    }, [soundAlertDoiCa, setSoundAlertDoiCa]);

    const loadSoundHoTro = async () => {
        const sound = new Audio(
            require("../assets/sounds/hotrochiduong.wav").default
        );
        sound.load();
        setSoundAlertHoTro(sound);
    };

    const playSoundHoTro = async () => {
        if (soundAlertHoTro) {
            const audioPromise = soundAlertHoTro.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (!soundAlertHoTro) {
            loadSoundHoTro();
        }
    }, [soundAlertHoTro, setSoundAlertHoTro]);

    const loadSoundViPham = async () => {
        const sound = new Audio(require("../assets/sounds/loivipham.wav").default);
        sound.load();
        setSoundAlertViPham(sound);
    };

    const playSoundViPham = async () => {
        if (soundAlertViPham) {
            const audioPromise = soundAlertViPham.play();
            if (audioPromise !== undefined) {
                audioPromise
                    .then((_) => {
                        // autoplay started
                    })
                    .catch((err) => {
                        // catch dom exception
                        console.info(err);
                    });
            }
        }
    };

    useEffect(() => {
        if (!soundAlertViPham) {
            loadSoundViPham();
        }
    }, [soundAlertViPham, setSoundAlertViPham]);

    useEffect(() => {
        if (!soundAlert) {
            loadSound();
        }
    }, [soundAlert, setSoundAlert]);

    useEffect(() => {
        if (!soundAlert2) {
            loadSound2();
        }
    }, [soundAlert2, setSoundAlert2]);

    useEffect(() => {
        if (notesdata) {
            setNotes(notesdata);
        }
    }, [notesdata]);

    useEffect(() => {
        if (groupDriverData && groupDriverData.length > 0) {
            setListGroup(
                groupDriverData.filter((g) => g.value != 11 && g.value != 10)
            );
        }
    }, [groupDriverData]);

    useEffect(() => {
        var myDate = new Date();
        var hrs = myDate.getHours();

        if (hrs < 12)
            setGreet(
                " Chào buổi sáng Tâm " +
                auth.info.profile.lastName +
                ", chúc bạn ngày mới vui vẻ!"
            );
        else if (hrs >= 12 && hrs <= 18)
            setGreet(" Xin chào Tâm " + auth.info.profile.lastName + " chúc Tâm buổi chiều nhiều năng lượng.");
        else if (hrs >= 18 && hrs <= 24)
            setGreet(" Xin chào Tâm " + auth.info.profile.lastName + " chúc Tâm buổi tối nhiều niềm vui!");

        if (pacallcenterdatas.calls && pacallcenterdatas.calls.length > 0) {
            let resultCall = [];
            for (let i = 0; i < pacallcenterdatas.calls.length; i++) {
                if (
                    i > 0 &&
                    pacallcenterdatas.calls[i].source ===
                    pacallcenterdatas.calls[i - 1].source &&
                    ConvertTimeStampToDate(pacallcenterdatas.calls[i].time * 1000) ===
                    ConvertTimeStampToDate(
                        pacallcenterdatas.calls[i - 1].time * 1000
                    ) &&
                    pacallcenterdatas.calls[i].status ===
                    pacallcenterdatas.calls[i - 1].status
                ) {
                    continue;
                } else {
                    pacallcenterdatas.calls[i].date_formated = ConvertTimeStampToDate(
                        pacallcenterdatas.calls[i].time * 1000
                    );
                    pacallcenterdatas.calls[i].date_format_pa = ConvertTimeStampPaUnique(
                        pacallcenterdatas.calls[i].time * 1000
                    );
                    resultCall.push(pacallcenterdatas.calls[i]);
                }
            }

            let checkListCall1 = resultCall.filter(
                (item) =>
                    (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                    (!item.type || (item.type && item.type != "Taxi Metter"))
            );
            let checkListCall2 = listCall.filter(
                (item) =>
                    (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                    (!item.type || (item.type && item.type != "Taxi Metter"))
            );
            if (listCall) {
                if (checkListCall1.length > checkListCall2.length) {
                    playSound();
                }
            }

            setListCall(checkListCall1);
        } else {
            setListCall([]);
        }
        setEndDateValue(GetDateString());
        let uid = auth.info.uid;
        if (uid && startDate && new Date(GetDateString()).getTime() / 1000) {
            dispatch(reportCallCenter(uid, startDate, new Date(GetDateString()).getTime() / 1000));
        }
    }, [pacallcenterdatas.calls, searchInit]);

    useEffect(() => {
        if (pacallcenterdatas.apps && pacallcenterdatas.apps.length > 0) {
            let resultCall = [];
            for (let i = 0; i < pacallcenterdatas.apps.length; i++) {
                if (
                    i > 0 &&
                    pacallcenterdatas.apps[i].source ===
                    pacallcenterdatas.apps[i - 1].source &&
                    ConvertTimeStampToDate(pacallcenterdatas.apps[i].time * 1000) ===
                    ConvertTimeStampToDate(
                        pacallcenterdatas.apps[i - 1].time * 1000
                    ) &&
                    pacallcenterdatas.apps[i].status ===
                    pacallcenterdatas.apps[i - 1].status
                ) {
                    continue;
                } else {
                    pacallcenterdatas.apps[i].date_formated = ConvertTimeStampToDate(
                        pacallcenterdatas.apps[i].time * 1000
                    );
                    pacallcenterdatas.apps[i].date_format_pa = ConvertTimeStampPaUnique(
                        pacallcenterdatas.apps[i].time * 1000
                    );
                    resultCall.push(pacallcenterdatas.apps[i]);
                }
            }

            let checkListCall1 = resultCall.filter(
                (item) =>
                    (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                    (!item.type || (item.type && item.type != "Taxi Metter"))
            );

            setListCallApp(checkListCall1);
        } else {
            setListCallApp([]);
        }
        setEndDateValue(GetDateString());
        let uid = auth.info.uid;
        if (uid && startDate && new Date(GetDateString()).getTime() / 1000) {
            dispatch(reportCallCenter(uid, startDate, new Date(GetDateString()).getTime() / 1000));
        }
    }, [pacallcenterdatas.apps, searchInit]);

    useEffect(() => {
        if (pacallcenterdatas.report_fleet) {
            setReportData(pacallcenterdatas.report_fleet);
        }
    }, [pacallcenterdatas.report_fleet]);

    useEffect(() => {
        if (reportdriverdata.reports) {
            setDataRp(
                reportdriverdata.reports.filter((report) => report.content !== "")
            );
            let listAlert = reportdriverdata.reports.filter(
                (report) =>
                    report.content.includes("hủy 1") ||
                    report.content.includes("hủy 0") ||
                    report.content.includes("hủy 2") ||
                    report.content.includes("hủy 3") ||
                    report.content.includes("hỗ trợ") ||
                    report.content.includes("vi phạm") ||
                    report.content.includes("nhận xe") ||
                    report.content.includes("đã đồng ý thay") ||
                    report.content.includes("hủy đặt xe") ||
                    report.content.includes("điểm đón cuốc khách") ||
                    report.content.includes("tự động hủy")
            );

            if (dataRpImportance) {
                if (listAlert && listAlert.length > dataRpImportance.length) {
                    if (
                        listAlert[0] &&
                        (listAlert[0].content.includes("hủy 0") ||
                            listAlert[0].content.includes("hủy 0") ||
                            listAlert[0].content.includes("hủy 2") ||
                            listAlert[0].content.includes("hủy 3") ||
                            listAlert[0].content.includes("tự động hủy"))
                    ) {
                        playSound2();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("vi phạm")) {
                        playSoundViPham();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("hủy đặt xe")) {
                        playSoundHuy();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("đã đồng ý thay")) {
                        playSoundDoiCa();
                    }

                    if (listAlert[0] && listAlert[0].content.includes("hỗ trợ")) {
                        playSoundHoTro();
                    }
                }
            } else {
                playSound2();
            }
            setDataRpImportance(listAlert);
        } else {
            setDataRp([]);
            setDataRpImportance([]);
        }
    }, [reportdriverdata.reports, searchInit]);

    useEffect(() => {
        if (settingsdata.settings) {
            setDataSetting(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    const showModalCallback = useCallback((popupData, callObj) => {
        if (popupData) {
            setPopupData(popupData);
        }
        if (callObj) {
            setCallObj(callObj);
        }
        setShowModal(true);
    }, []);

    const showModalCustomerCareCallback = useCallback((rowDataInput, callObjInput) => {
        setTimeout(() => {
            if (rowDataInput) {
                setPopupData(rowDataInput);
            }
            if (callObjInput) {
                setCallObj(callObjInput);
            }
            setShowCustomerCareDialog(true);
        }, 1000);
    }, []);

    const hideModalCallback = useCallback(() => {
        setPopupData(null);
        setCallObj(null);
        setShowModal(false);
        setShowCustomerCareDialog(false);
        //setNoteTemp(null);
    }, []);

    const hideChatModalCallback = useCallback(() => {
        setCallObj(null);
        setPopupData(null);
        setShowChatModal(false);
        setDriverCode(null);
        setNoteBooking(null);
        dispatch({
            type: 'SELECT_BOOKING_DETAIL_SUCCESS', payload: null
        });
    }, []);

    const closeCallback = useCallback(() => {
        setModalVisible(false);
        setPopupData(null);
        setShowCancel(false);
        setShowThemXe(false);
        setShowCallNow(null);
        setAddBookingDialog(false);
    }, []);

    const searchCallback = useCallback((searchText) => {
        setSearchInit(null);
        setListCall(null);
        setDataRp(null);
        if (searchText === searchInit) {
            searchText = "";
        }
        if (searchText) {
            //tableRef.current.options.searchText(searchText);
            setSearchInit(searchText);
        }
    }, []);

    /* useEffect(()=>{
         if(isApp == true){
             if(callcolumns){
             }
         }
     },[isApp, callcolumns]);*/

    const MyVerticallyCenteredModal = (props) => {
        return (
            <Modal
                animationType="slide"
                visible={modalShow}
                titlle
                onRequestClose={() => {
                    setModalShow(false);
                }}
            >
                <AddBookingsNew phone={""}/>
            </Modal>
        );
    };

    const listLyDo = [
        "Số bị trượt máy",
        "Lái xe gọi",
        "Khách hỏi thông tin",
        "Không có phương tiện hỗ trợ",
        "Vấn đề khác",
    ];

    const InfoReport = (props) => {
        let content = props.content;
        var bookingKey = null;
        let contentArray = content.split("#");
        if (contentArray.length > 0) {
            bookingKey = contentArray[contentArray.length - 1];
            if (bookingKey) {
                bookingKey = bookingKey.trim();
            }
        }
        var colorButton = "rgb(0, 147, 227)";
        if (content.includes("hủy")) {
            colorButton = "red";
        }
        if (content.includes("vi phạm")) {
            colorButton = "rgb(208, 188, 0)";
        }
        if (content.includes("hỗ trợ")) {
            colorButton = "rgb(208, 188, 0)";
        }
        return (
            <View
                style={{
                    flex: 0.5,
                    justifyContent: "center",
                    marginHorizontal: 10,
                }}
            >
                <Text style={{fontSize: 13}}>{content}</Text>
                {props.hasButton && props.search ? (
                    <Button
                        onPress={() => {
                            props.search(bookingKey);
                        }}
                        title={bookingKey}
                        titleStyle={{color: "#FFF"}}
                        color={colorButton}
                        containerStyle={{
                            flex: 1,
                            alignSelf: "flex-end",
                            paddingRight: 14,
                        }}
                    />
                ) : null}
            </View>
        );
    };


    const columns = [
        {
            title: language.notification_title,
            field: "content",
            render: (rowData) => (
                <InfoReport
                    content={rowData.content}
                    hasButton={true}
                    search={searchCallback}
                />
            ),
        },
    ];
    const columns_rp = [
        {
            title: "Nhật ký cuốc khách",
            field: "content",
            render: (rowData) => (
                <InfoReport content={rowData.content} hasButton={false}/>
            ),
        },
    ];
    const callcolumns = [
        /*{title: "ID", field: 'firebase_id', render: rowData => rowData.firebase_id},*/
        {
            title: "Thời gian",
            field: "date_formated",
            render: (rowData) => formatDisplayDateSmart(rowData.date_formated),
            width: "1%",
        },
        {
            title: "ID",
            field: "id",
            render: (rowData) => rowData.id,
            width: "1%",
        },
        {
            title: "Cuộc gọi",
            field: "time",
            render: (rowData) =>
                isApp ? null :
                    rowData.audios ? (
                        <List
                            sx={{
                                width: "100%",
                                maxWidth: 100,
                                bgcolor: "background.paper",
                                position: "relative",
                                overflow: "auto",
                                maxHeight: 300,
                                "& ul": {padding: 0},
                            }}
                        >
                            {rowData.audios.map((a, index) => {
                                return (
                                    <li key={`section-${index}`}>
                                        <ReactAudioPlayer
                                            src={
                                                "https://crm.pavietnam.vn/api/playRecording.php?api_key=" +
                                                API_PA_PHONE +
                                                "&recording_file=" +
                                                a
                                            }
                                            style={{
                                                backgroundColor: a.includes("out")
                                                    ? colors.YELLOW.primary
                                                    : colors.GREEN.prime,
                                            }}
                                            title={
                                                "" + a.split("-").at(-3) + " : " + a.split("-").at(-2)
                                            }
                                            controls
                                        />
                                    </li>
                                );
                            })}

                            <li key={`section-${rowData.audios.length}`}>
                                <IconButton
                                    aria-label="Nghe ghi âm"
                                    color="secondary"
                                    size="medium"
                                    disabled={loadingAudio && loadingAudio[rowData.time]}
                                    onClick={async () => {
                                        await loadRecordFile(
                                            rowData.source,
                                            ConvertTimeStampTimeForPa(
                                                (parseInt(rowData.time) - 600) * 1000
                                            ),
                                            ConvertTimeStampTimeForPa(
                                                (parseInt(rowData.time) + 1800) * 1000
                                            ),
                                            rowData.id,
                                            rowData.time_call_out ? rowData.time_call_out : [],
                                            rowData.time
                                        );
                                    }}
                                >
                                    {loadingAudio && loadingAudio[rowData.time] ? (
                                        <View>{"Đang tải"}</View>
                                    ) : (
                                        <Refresh fontSize="inherit" color={"action"}/>
                                    )}
                                </IconButton>
                            </li>
                        </List>
                    ) : (
                        <IconButton
                            aria-label="Nghe ghi âm"
                            color="secondary"
                            size="medium"
                            disabled={loadingAudio && loadingAudio[rowData.time]}
                            onClick={async () => {
                                await loadRecordFile(
                                    rowData.source,
                                    ConvertTimeStampTimeForPa(
                                        (parseInt(rowData.time) - 600) * 1000
                                    ),
                                    ConvertTimeStampTimeForPa(
                                        (parseInt(rowData.time) + 1800) * 1000
                                    ),
                                    rowData.id,
                                    rowData.time_call_out ? rowData.time_call_out : [],
                                    rowData.time
                                );
                            }}
                        >
                            {loadingAudio && loadingAudio[rowData.time] ? (
                                <View>{"Đang tải"}</View>
                            ) : (
                                <PlayCircleFilledOutlined fontSize="inherit" color={"action"}/>
                            )}
                        </IconButton>
                    ),
        },
        {
            title: "SĐT Lái Xe",
            field: "booking_id",
            render: (rowData) =>
                rowData.driver_phone ? (
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: 150,
                        }}
                    >
                        <Button
                            onPress={() => {
                                setShowCallNow({
                                    time_call: rowData.time_call_out ? rowData.time_call_out : [],
                                    phone: rowData.driver_phone,
                                    uniqueid: rowData.uniqueid,
                                    firebase_id: rowData.firebase_id,
                                    time: rowData.time,
                                    audios: rowData.audios ? rowData.audios : [],
                                    source: rowData.source,
                                });
                                setModalVisible(true);
                            }}
                            title={rowData.driver_phone}
                            titleStyle={{color: "#FFF"}}
                            color={colors.BACKGROUND_DARK}
                            containerStyle={{
                                flex: 1,
                                alignSelf: "flex-end",
                                paddingRight: 14,
                            }}
                        />
                        {rowData.driver_code ? (
                            <IconButton
                                aria-label="Gửi tin nhắn"
                                color="secondary"
                                size="medium"
                                onClick={() => {
                                    setDriverCode(rowData.driver_code);
                                    setNoteBooking(rowData.note);
                                    if (rowData.bookingDate) {
                                        setBookingDate(ConvertTimeStampToDate(rowData.bookingDate));
                                    }
                                    setShowChatModal(true);
                                }}
                            >
                                <Chat fontSize="inherit" color={"action"}/>
                            </IconButton>
                        ) : null}
                    </View>
                ) : (
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: 150,
                        }}
                    >
                        <Text>{"Chưa có"}</Text>
                    </View>
                ),
        },
        {
            title: "Ghi chú CK",
            field: "source",
            render: (rowData) => {
                let arrayOptions =
                    notelistdata && notelistdata[rowData.source]
                        ? Object.keys(
                            Object.values(notelistdata[rowData.source]).reduce(
                                (a, c) => ((a[c] = (a[c] || 0) + 1), a),
                                Object.create(null)
                            )
                        )
                        : [];
                if (arrayOptions) {
                    return (
                        <View
                            style={{
                                justifyContent: "center",
                                minWidth: 200,
                            }}
                        >
                            <NoteCallCenterComponent
                                rowData={rowData}
                                arrayOptions={arrayOptions}
                                isApp={isApp}
                            />
                        </View>
                    );
                }

            },
            //rowData.booking_note ? rowData.booking_note : (rowData.note ? rowData.note : "Chưa có")
        },
        {
            title: "SĐT Khách",
            field: "source",
            render: (rowData) => (
                <View
                    style={{
                        justifyContent: "center",
                        marginHorizontal: 10,
                    }}
                >
                    <Button
                        onPress={() => {
                            setShowCallNow({
                                time_call: rowData.time_call_out ? rowData.time_call_out : [],
                                phone: rowData.source,
                                uniqueid: rowData.uniqueid,
                                firebase_id: rowData.firebase_id,
                                time: rowData.time,
                                audios: rowData.audios ? rowData.audios : [],
                                source: rowData.source,
                            });
                            setModalVisible(true);
                        }}
                        title={
                            "[" + rowData.destination + "] " + (auth && auth.user && auth.user.usertype && auth.user.usertype == "admin" ? rowData.source : HiddenPhone(rowData.source))
                        }
                        color={colors.BACKGROUND_DARK}
                        titleStyle={{color: "#FFF"}}
                        containerStyle={{
                            flex: 1,
                            alignSelf: "flex-end",
                            paddingRight: 14,
                        }}
                    />
                </View>
            ),
        },
        {
            title: "Đặt xe",
            field: "source",
            render: (rowData) => {
                let source = rowData.source;
                var callObj = {};
                if (callDetailData && callDetailData[source]) {
                    callObj = callDetailData[source];
                }

                //props.callObj.alias

                if (rowData.booking_id) {
                    if (
                        rowData.driver_code &&
                        dataSetting &&
                        dataSetting.driverCodeList
                    ) {
                        let listDriverCodeArray = dataSetting.driverCodeList.split(",");
                        if (listDriverCodeArray.includes(rowData.driver_code)) {
                            return (
                                <View
                                    style={{
                                        justifyContent: "center",
                                        minWidth: 200,
                                        flexDirection: "row",
                                    }}
                                >
                                    <CustomerCareCallCenterComponent rowData={rowData} callObj={callObj}
                                                                     showModalCustomerCareCallback={showModalCustomerCareCallback}
                                                                     hideModal={hideModalCallback}/>
                                </View>
                            );
                        } else {
                            return callObj && callObj.alias
                                ? callObj.alias
                                : "Cập nhật bí danh";
                        }
                    } else {
                        return callObj && callObj.alias
                            ? callObj.alias
                            : "Cập nhật bí danh";
                    }
                    //return callObj && callObj.alias ? callObj.alias : "Cập nhật bí danh";
                } else {
                    return (
                        <View
                            style={{
                                justifyContent: "center",
                                marginHorizontal: 10,
                            }}
                        >
                            <ButtonCallCenterDetail
                                callDetail={rowData}
                                callObj={callObj}
                                showModal={showModalCallback}
                                popupData={rowData}
                                hideModal={hideModalCallback}
                                listGroup={listGroup}
                                cartypes={cartypes}
                            />
                        </View>
                    );
                }
            },
        },
        {
            title: "Thêm xe",
            field: "source",
            initialEditValue: false,
            render: (rowData) => (
                isApp ? null :
                    <View
                        style={{
                            justifyContent: "center",
                            marginHorizontal: 10,
                        }}
                    >
                        <Button
                            onPress={() => {
                                setPopupData(rowData);
                                setShowThemXe(true);
                                setModalVisible(true);
                            }}
                            color={colors.BACKGROUND_DARK}
                            title={"Thêm xe"}
                            titleStyle={{color: "#FFF"}}
                            containerStyle={{
                                flex: 1,
                                alignSelf: "flex-end",
                                paddingRight: 14,
                            }}
                        />
                    </View>
            ),
        },
        {
            title: "Trạng thái",
            field: "status",
            render: (rowData) => {
                if (rowData.status === "pending") {
                    return (
                        <View style={{flex: 1, flexDirection: "row"}}>
                            <View style={{height: 30, width: 5}}/>
                            <View
                                style={{
                                    justifyContent: "center",
                                    marginHorizontal: 10,
                                }}
                            >
                                <Button
                                    onPress={() => {
                                        setPopupData(rowData);
                                        setTimeout(() => {
                                            setModalVisible(true);
                                            setShowCancel(true);
                                        }, 200);
                                    }}
                                    title={"Hủy"}
                                    style={{padding: 20}}
                                    color={colors.BACKGROUND_DARK}
                                />
                            </View>
                        </View>
                    );
                }
                if (rowData.status !== "pending") {
                    return (
                        <View style={{flex: 1, flexDirection: "row"}}>
                            {rowData.status === "cancel" &&
                            !rowData.driver_code &&
                            rowData.reason ? (
                                <Text>{rowData.reason}</Text>
                            ) : (
                                <View
                                    style={{
                                        flex: 0.5,
                                        justifyContent: "center",
                                        marginHorizontal: 10,
                                    }}
                                >
                                    {rowData &&
                                    rowData.status === "accept" &&
                                    rowData.driver_code &&
                                    rowData.booking_id ? (
                                        <Button
                                            onPress={() => {
                                                setShowCancel(true);
                                                setModalVisible(true);
                                                setCallDetail(rowData);
                                                // Rollback VIP
                                            }}
                                            color={colors.BACKGROUND_DARK}
                                            title={"Khách hủy"}
                                            titleStyle={{color: "#FFF"}}
                                            containerStyle={{
                                                flex: 1,
                                                alignSelf: "flex-end",
                                                paddingRight: 14,
                                            }}
                                        />
                                    ) : null}

                                    {rowData.status === "booked_cancel" &&
                                    !rowData.old_booking_id &&
                                    rowData.type != "khachquen" &&
                                    !rowData.cancel_by ? (
                                        <View
                                            style={{
                                                justifyContent: "center",
                                                marginHorizontal: 10,
                                            }}
                                        >
                                            {rowData.type != "KH" && rowData.type != "LX" ? (
                                                <ButtonCallCenterDetail
                                                    titleButton={"Đặt lại"}
                                                    callDetail={rowData}
                                                    callObj={
                                                        rowData.source &&
                                                        callDetailData &&
                                                        callDetailData[rowData.source]
                                                            ? callDetailData[rowData.source]
                                                            : null
                                                    }
                                                    showModal={showModalCallback}
                                                    popupData={rowData}
                                                    hideModal={hideModalCallback}
                                                    listGroup={listGroup}
                                                    cartypes={cartypes}
                                                />
                                            ) : (
                                                <Text>
                                                    {rowData.type == "KH"
                                                        ? "Khách đặt qua APP"
                                                        : "Tài xế đặt qua APP"}
                                                </Text>
                                            )}
                                        </View>
                                    ) : null}

                                    {rowData.status === "booked_cancel" &&
                                    !rowData.old_booking_id &&
                                    rowData.type == "khachquen" ? (
                                        <Button
                                            onPress={() => {
                                                alert(
                                                    "Taxi Metter " +
                                                    (rowData.codeDriver
                                                        ? "#" + rowData.codeDriver.trim() + ""
                                                        : "")
                                                );
                                            }}
                                            color={colors.BACKGROUND_DARK}
                                            title={
                                                rowData.codeDriver
                                                    ? "#" + rowData.codeDriver.trim() + " Khách quen"
                                                    : "Khách quen"
                                            }
                                            style={{padding: 20}}
                                        />
                                    ) : null}
                                    {rowData.status === "booked_cancel" &&
                                    rowData.old_booking_id ? (
                                        <Button
                                            onPress={() => {
                                                setPopupData(rowData);
                                                setTimeout(() => {
                                                    setModalVisible(true);
                                                    setShowCancel(false);
                                                }, 200);
                                            }}
                                            color={colors.BACKGROUND_DARK}
                                            title={
                                                rowData.codeDriver
                                                    ? "#" + rowData.codeDriver.trim() + " đã đặt lại"
                                                    : "Đã đặt lại"
                                            }
                                            style={{padding: 20}}
                                        />
                                    ) : null}

                                    {rowData.status === "BAO_TIN_HIEU_LAN_2_REQUEST" && rowData.type != 'LX' &&
                                    rowData.booking_id ? (
                                        // Đặt lại 3km
                                        <Button
                                            onPress={() => {
                                                if (rowData.booking_id) {
                                                    dispatch(
                                                        addReport({
                                                            content:
                                                                " #" + rowData.booking_id + " chuyển tín hiệu ",
                                                        })
                                                    );
                                                    if (rowData.id) {
                                                        update(paCallByIdRef(rowData.id), {
                                                            status: "BAO_TIN_HIEU_LAN_2_REQUEST_DONE",
                                                        });
                                                    }
                                                    setTimeout(() => {
                                                        dispatch(bookingDetailOn(rowData.booking_id));
                                                    }, 300);
                                                    alert("Cuốc khách đã được chuyển tín hiệu");
                                                }
                                            }}
                                            title={"Đặt lại"}
                                            color={colors.BACKGROUND_DARK}
                                            titleStyle={{color: "#FFF"}}
                                            containerStyle={{
                                                flex: 1,
                                                alignSelf: "flex-end",
                                                paddingRight: 14,
                                            }}
                                        />
                                    ) : null}

                                    {(rowData.status === "booked_mistake_1" ||
                                        rowData.status === "booked_mistake_2") &&
                                    rowData.booking_id &&
                                    rowData.type != "KH" &&
                                    rowData.type != "LX" ? (
                                        <Button
                                            onPress={() => {
                                                if (rowData.booking_id) {
                                                    dispatch(
                                                        addReport({
                                                            content:
                                                                " #" +
                                                                rowData.booking_id +
                                                                " vi phạm -> chuyển tín hiệu ",
                                                        })
                                                    );
                                                    dispatch(fetchOnceDrivers());
                                                    setTimeout(() => {
                                                        dispatch(
                                                            bookingDetailOn(
                                                                rowData.booking_id,
                                                                settingsdata && settingsdata.bankinh
                                                                    ? settingsdata.bankinh
                                                                    : 3
                                                            )
                                                        );
                                                    }, 300);
                                                }
                                            }}
                                            title={"Đặt lại"}
                                            color={colors.BACKGROUND_DARK}
                                            titleStyle={{color: "#FFF"}}
                                            containerStyle={{
                                                flex: 1,
                                                alignSelf: "flex-end",
                                                paddingRight: 14,
                                            }}
                                        />
                                    ) : null}

                                    {rowData &&
                                    rowData.status === "BAO_TIN_HIEU_LAN_2_REQUEST_DONE" ? (
                                        <Button
                                            onPress={() => {
                                                alert("Đợt LX nhận");
                                            }}
                                            title={"Đợi LX nhận"}
                                            color={colors.BACKGROUND_DARK}
                                            titleStyle={{color: "#FFF"}}
                                            containerStyle={{
                                                flex: 1,
                                                alignSelf: "flex-end",
                                                paddingRight: 14,
                                            }}
                                        />
                                    ) : null}
                                </View>
                            )}
                            <View style={{height: 30, width: 5}}/>
                            {rowData.status &&
                            rowData.status === "cancel" &&
                            !rowData.driver_code &&
                            rowData.reason ? null : (
                                <ButtonCallCenter {...props} callDetail={rowData}/>
                            )}
                        </View>
                    );
                }
            },
        },
        {
            title: "Mã CK",
            field: "booking_id",
            render: (rowData) =>
                rowData.booking_id ? rowData.booking_id : "Chưa có",
            hidden: true,
        }
    ];

    const lineOptions = [
        {value: "101", label: "Line 101"},
        {value: "102", label: "Line 102"},
        {value: "103", label: "Line 103"},
        {value: "104", label: "Line 104"},
    ];

    return pacallcenterdatas.loading ? (
        <CircularLoading/>
    ) : (
        <View className={classes.form}>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <GridContainer spacing={2}>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <Typography
                                component="h3"
                                variant="h5"
                                align="center"
                                className={classes.marginBottom50}
                            >
                                {"Điều hành: " + greet}
                            </Typography>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2}>
                            <Paper style={{margin: 10}}>
                                <TouchableOpacity
                                    style={{
                                        backgroundColor: colors.GREEN.prime,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 10,
                                        elevation: 2,
                                        borderRadius: 2,
                                        shadowOffset: {
                                            height: 1,
                                            width: 0,
                                        },
                                        shadowColor: colors.BLACK,
                                        shadowOpacity: 0.35,
                                        shadowRadius: 5,
                                    }}
                                    onPress={() => {
                                        setAddBookingDialog(true);
                                        setModalVisible(true);
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: colors.WHITE,
                                            fontSize: 16,
                                        }}
                                    >
                                        {"Tạo cuộc gọi"}
                                    </Text>
                                </TouchableOpacity>
                            </Paper>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2}>
                            <Paper style={{margin: 10}}>
                                <TouchableOpacity
                                    style={{
                                        backgroundColor: colors.MAIN3,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 10,
                                        elevation: 2,
                                        borderRadius: 2,
                                        shadowOffset: {
                                            height: 1,
                                            width: 0,
                                        },
                                        shadowColor: colors.BLACK,
                                        shadowOpacity: 0.35,
                                        shadowRadius: 5,
                                    }}
                                    onPress={() => {
                                        setIsApp(false);
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: colors.WHITE,
                                            fontSize: 16,
                                        }}
                                    >
                                        {"Khách tổng đài"}
                                    </Text>
                                </TouchableOpacity>
                            </Paper>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2}>
                            <Paper style={{margin: 10}}>
                                <TouchableOpacity
                                    style={{
                                        backgroundColor: colors.MAIN5,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 10,
                                        elevation: 2,
                                        borderRadius: 2,
                                        shadowOffset: {
                                            height: 1,
                                            width: 0,
                                        },
                                        shadowColor: colors.BLACK,
                                        shadowOpacity: 0.35,
                                        shadowRadius: 5,
                                    }}
                                    onPress={() => {
                                        /*let response = await fetch(`https://eto-viet.firebaseapp.com/send_notification`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                token: "ExponentPushToken[ktGwgCP-0vakNX9Ko-O7Ui]",
                                                title: "ETO Thông báo",
                                                msg: "Nội dung thông báo 123123"
                                            })
                                        });*/
                                        setIsApp(true);
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: colors.WHITE,
                                            fontSize: 16,
                                        }}
                                    >
                                        {"Khách APP"}
                                    </Text>
                                </TouchableOpacity>
                            </Paper>
                        </GridItem>


                        {auth.info && auth.info.profile && auth.info.profile.usertype == 'fleetadmin' ?
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                                <View
                                    style={{flexDirection: 'row', width: '100%'}}
                                >
                                    <TextField
                                        id="datetime-local-start"
                                        label={"Thời gian bắt đầu"}
                                        type="datetime-local"
                                        variant="outlined"
                                        value={startDateValue ? startDateValue : GetDateString00()}
                                        onChange={(event) => {
                                            setStartDateValue(event.target.value);
                                            setStartDate(new Date(event.target.value).getTime() / 1000);
                                        }
                                        }
                                    />
                                    <TextField
                                        id="datetime-local-end"
                                        label={"Thời gian kết thúc"}
                                        type="datetime-local"
                                        variant="outlined"
                                        value={endDateValue ? endDateValue : GetDateString()}
                                        onChange={(event) => {
                                            setEndDateValue(event.target.value);
                                            setEndDate(new Date(event.target.value).getTime() / 1000);
                                        }
                                        }
                                    />
                                    <Button
                                        onPress={() => {
                                            let uid = auth.info.uid;
                                            if (uid && startDate && endDate) {
                                                dispatch(reportCallCenter(uid, startDate, endDate));
                                            }
                                        }}
                                        color={colors.BACKGROUND_DARK}
                                        title={"Thống kê"}
                                        style={{padding: 20, alignCenter: true}}
                                    />
                                    {!pacallcenterdatas.loading_report && reportData ?
                                        <Text>{"Thời gian hoạt động: " + ConvertSecondToHm(reportData.time_active) + "\n" + "Cuộc gọi đến: " + reportData.total_call + "\nCuốc khách đã xử lý: " + reportData.total_success}</Text>
                                        : null
                                    }
                                    {pacallcenterdatas.loading_report ?
                                        <Text>{"Đang tải thông tin"}</Text>
                                        : null
                                    }

                                </View>

                            </GridItem> : null}

                    </GridContainer>

                    {isApp ?
                        listCallApp ? (
                            searchInit ? (
                                <MaterialTable
                                    tableRef={tableRef}
                                    data={listCallApp}
                                    title={"Quản lý cuộc gọi"}
                                    columns={callcolumns}
                                    options={{
                                        columnsButton: true,
                                        exportButton: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                        searchText: searchInit ? searchInit : null,
                                        /*sorting: true,
                                        filtering: true,*/
                                    }}
                                    style={{
                                        flexGrow: 0,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                />
                            ) : null
                        ) : null
                        :
                        listCall ? (
                            searchInit ? (
                                <MaterialTable
                                    tableRef={tableRef}
                                    data={listCall}
                                    title={"Quản lý cuộc gọi"}
                                    columns={callcolumns}
                                    options={{
                                        columnsButton: true,
                                        exportButton: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                        searchText: searchInit ? searchInit : null,
                                        /*sorting: true,
                                        filtering: true,*/
                                    }}
                                    style={{
                                        flexGrow: 0,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                />
                            ) : null
                        ) : null
                    }

                    {isApp ?
                        listCallApp && !searchInit ? (
                            <MaterialTable
                                tableRef={tableRef}
                                data={listCallApp}
                                title={"Quản lý cuộc gọi"}
                                columns={callcolumns}
                                options={{
                                    columnsButton: true,
                                    exportButton: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                    /*sorting: true,
                                    filtering: true,*/
                                }}
                                style={{
                                    flexGrow: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            />
                        ) : null
                        :
                        listCall && !searchInit ? (
                            <MaterialTable
                                tableRef={tableRef}
                                data={listCall}
                                title={"Quản lý cuộc gọi"}
                                columns={callcolumns}
                                options={{
                                    columnsButton: true,
                                    exportButton: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                    /*sorting: true,
                                    filtering: true,*/
                                }}
                                style={{
                                    flexGrow: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            />
                        ) : null
                    }
                </Grid>
                {/*<Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: 50}}>
                        {dataRpImportance ?
                            <MaterialTable
                                title={""}
                                columns={columns}
                                data={dataRpImportance}
                                options={{
                                    exportButton: false,
                                    columnsButton: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100, 200, 500]
                                }}
                                style={{
                                    flexGrow: 0
                                }}
                            /> : null}
                    </Grid>*/}
                {/*<Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: 50}}>

                        {dataRp ?
                            searchInit ?
                                <MaterialTable
                                    tableRef={tableRpRef}
                                    title={""}
                                    columns={columns_rp}
                                    data={dataRp}
                                    options={{
                                        exportButton: false,
                                        columnsButton: true,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50, 100, 200, 500],
                                        searchText: searchInit ? searchInit : null
                                        //filtering: true
                                    }}
                                    style={{
                                        flexGrow: 0
                                    }}
                                /> : null
                            : null}


                        {dataRp && !searchInit ?
                            <MaterialTable
                                tableRef={tableRpRef}
                                title={""}
                                columns={columns_rp}
                                data={dataRp}
                                options={{
                                    exportButton: false,
                                    columnsButton: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100, 200, 500]
                                }}
                                style={{
                                    flexGrow: 0
                                }}
                            /> : null}


                    </Grid>*/}
            </Grid>
            <FooterAdmin
                color="transparent"
                routes={dashboardRoutes}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white",
                }}
                {...rest}
            />
            {popupData ? (
                <ButtonCallCenterDetailDialog
                    notes={
                        notelistdata && notelistdata[popupData.source]
                            ? Object.keys(
                                Object.values(notelistdata[popupData.source]).reduce(
                                    (a, c) => ((a[c] = (a[c] || 0) + 1), a),
                                    Object.create(null)
                                )
                            )
                            : []
                    }
                    showModal={showModal}
                    hideModal={hideModalCallback}
                    callDetail={popupData}
                    callObj={callObj}
                    listGroup={listGroup}
                    cartypes={cartypes}
                />
            ) : null}
            {
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onPress={() => {
                        setModalVisible(false);
                        setPopupData(null);
                    }}
                    titlle
                >
                    <View
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 22,
                        }}
                    >
                        <View
                            style={{
                                margin: 20,
                                backgroundColor: "white",
                                borderRadius: 10,
                                borderWidth: 1,
                                padding: 10,
                                alignItems: "center",
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 4,
                                elevation: 5,
                            }}
                        >
                            {popupData &&
                            showCancel === false &&
                            !popupData.codeDriver &&
                            showThemXe === false ? (
                                <BookingCallCenter
                                    {...props}
                                    time={ConvertTimeStampToDate(popupData.time * 1000)}
                                    callDetail={popupData}
                                    notes={notes}
                                    listGroup={listGroup}
                                    listLyDo={listLyDo}
                                    cartypes={cartypes}
                                    closeCallback={closeCallback}
                                />
                            ) : null}

                            {addBookingDialog ? (
                                <AddCallCenter {...props} closeCallback={closeCallback}/>
                            ) : null}

                            {popupData && showThemXe === true ? (
                                <ThemXeCallCenter
                                    {...props}
                                    callDetail={popupData}
                                    closeCallback={closeCallback}
                                />
                            ) : null}

                            {popupData &&
                            showCancel === false &&
                            popupData.codeDriver &&
                            showThemXe === false ? (
                                <BookingCallCenter
                                    {...props}
                                    time={ConvertTimeStampToDate(popupData.time * 1000)}
                                    notes={notes}
                                    callDetail={popupData}
                                    listGroup={listGroup}
                                    listLyDo={listLyDo}
                                    cartypes={cartypes}
                                    closeCallback={closeCallback}
                                    driverCode={popupData.codeDriver.trim()}
                                />
                            ) : null}
                            {popupData && showCancel === true && showThemXe === false ? (
                                <CancelCallCenter
                                    destination={popupData.destination}
                                    time={ConvertTimeStampToDate(popupData.time * 1000)}
                                    phone={popupData.source}
                                    uniqueid={popupData.uniqueid}
                                    listLyDo={listLyDo}
                                    firebase_id={popupData.firebase_id}
                                    closeCallback={closeCallback}
                                />
                            ) : null}
                            {popupData === null && showCallNow && showThemXe === false ? (
                                <CallNowCenter
                                    lineOptions={lineOptions}
                                    time={showCallNow.time}
                                    source={showCallNow.source}
                                    time_call={showCallNow.time_call ? showCallNow.time_call : []}
                                    phone={showCallNow.phone}
                                    uniqueid={showCallNow.uniqueid}
                                    listAudio={showCallNow.audios ? showCallNow.audios : []}
                                    firebase_id={showCallNow.firebase_id}
                                    closeCallback={closeCallback}
                                />
                            ) : null}

                            {callDetail && showCancel === true && callDetail.booking_id ? (
                                <CancelCallCenter
                                    destination={callDetail.destination}
                                    time={ConvertTimeStampToDate(callDetail.time * 1000)}
                                    phone={callDetail.source}
                                    uniqueid={callDetail.uniqueid}
                                    booking_id={callDetail}
                                    listLyDo={listLyDo}
                                    firebase_id={callDetail.firebase_id}
                                    closeCallback={closeCallback}
                                    fromTT={true}
                                />
                            ) : null}
                        </View>
                    </View>
                </Modal>
            }
            {showChatModal && driverCode ? (
                <ShowNotifyDialog
                    showModal={showChatModal}
                    bookingDate={bookingDate}
                    hideModal={hideChatModalCallback}
                    isChatOnce={true}
                    driverCode={driverCode}
                    noteBooking={noteBooking}
                />
            ) : null}

            {showCustomerCareDialog && popupData ?
                <ShowCustomerCareDialog showModal={showCustomerCareDialog} hideModal={hideModalCallback}
                                        rowData={popupData} callObj={callObj ? callObj : null}/> : null}
        </View>
    );
};

export default CallCenter;
