/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import {language} from "config";
import {useSelector} from 'react-redux';
import {View, Text} from "react-native-web";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
    const settings = useSelector(state => state.settingsdata.settings);
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer className={classes.textCenter} justify="center">
                    <View style={{
                        width: '100%',
                        flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        borderBottomWidth: 1,
                        paddingBottom: 20,
                        borderBottomColor: '#484848'
                    }}>
                        <View style={{
                            width: '70%',
                            flexDirection: 'column', justifyContent: "center", alignItems: "center"
                        }}>
                            <Text style={{color: '#484848', fontSize: 16}}>
                                {"CÔNG TY DỊCH VỤ VẬN TẢI MY SƠN"}
                            </Text>
                            <Text style={{color: '#484848', fontSize: 16}}>
                                {"Mã số thuế: 0201903957 do Sở Kế Hoạch Đầu Tư Và Đầu Tư TP. Hải Phòng cấp ngày 24/09/2018"}
                            </Text>
                            <Text style={{color: '#484848'}}>
                                {"Địa chỉ: My Sơn - Ngũ Lão - Thuỷ Nguyên - Hải Phòng"}
                            </Text>
                        </View>

                        <img className={classes.triobanner} src={require("../../assets/img/logomyson.png").default} alt="Eto" style={{width: 100}}/>
                    </View>
                    <View style={{
                        width: '100%',
                        flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        borderBottomWidth: 1,
                        paddingBottom: 20,
                        paddingTop: 20,
                        borderBottomColor: '#484848'
                    }}>
                        <Text style={{color: '#484848', textAlign: 'left'}}>
                            {"Chuyên dịch vụ"}
                        </Text>
                        <Text style={{color: '#484848', textAlign: 'center'}}>
                            {"XE TAXI - XE HỢP ĐỒNG"}
                        </Text>
                    </View>

                    <View style={{
                        width: '100%',
                        flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        paddingBottom: 20,
                        paddingTop: 20
                    }}>
                        <Text style={{color: '#484848', textAlign: 'left'}}>
                            {"Tải ứng dụng"}
                        </Text>
                        <View style={{
                            flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center",
                        }}>
                            {settings && settings.AppleStoreLink ?
                                <a href={settings.AppleStoreLink}><img src={require("../../assets/img/down-apple.png").default} alt="Apple Store Link" style={{width: 100}}/></a>
                                : null}
                            <span style={{marginRight: '5px'}}></span>
                            {settings && settings.PlayStoreLink ?
                                <a href={settings.PlayStoreLink}><img src={require("../../assets/img/down-android.png").default} alt="Playstore Link" style={{width: 100}}/></a>
                                : null}
                            <span style={{marginRight: '5px'}}></span>
                            {settings && settings.ApkLink ?
                                <a href={settings.ApkLink}><img src={require("../../assets/img/apkdownload.png").default} alt="APK Download Link" style={{width: 100}}/></a>
                                : null}
                        </View>
                    </View>


                </GridContainer>

            </div>
        </div>
    );
}
