import {EDIT_PROMO_LOCATION, FETCH_PROMO_LOCATIONS, FETCH_PROMO_LOCATIONS_FAILED, FETCH_PROMO_LOCATIONS_SUCCESS} from "../store/types";

import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {onValue, set} from "firebase/database";

export const fetchPromoLocations = (promo_id) => (dispatch) => {
    const {
        promoLocationRef
    } = firebase;

    dispatch({
        type: FETCH_PROMO_LOCATIONS,
        payload: null,
    });
    onValue(promoLocationRef(promo_id), snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_PROMO_LOCATIONS_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_PROMO_LOCATIONS_FAILED,
                payload: language.promo_location_not_found,
            });
        }
    });
};

export const editPromoLocation = (promos, method) => (dispatch) => {
    let promo_id = promos[0].promo_id;
    if (promo_id) {
        const {
            promoLocationRef
        } = firebase;

        dispatch({
            type: EDIT_PROMO_LOCATION,
            payload: method
        });
        set(promoLocationRef(promo_id),promos);
    } else {
        dispatch({
            type: FETCH_PROMO_LOCATIONS_FAILED,
            payload: language.promo_location_not_found,
        });
    }

}


