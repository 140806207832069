import {EDIT_GIAOXE, FETCH_GIAOXE, FETCH_GIAOXE_BIENSO, FETCH_GIAOXE_BIENSO_FAILED, FETCH_GIAOXE_BIENSO_SUCCESS, FETCH_GIAOXE_FAILED, FETCH_GIAOXE_SUCCESS, PUSH_GIAOXE, STOP_FETCH_GIAOXE} from "../store/types";

const INITIAL_STATE = {
    giaoxe: null,
    giaoxeByBienSo: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const giaoxereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_GIAOXE:
            return {
                ...state,
                loading: true
            };
        case FETCH_GIAOXE_SUCCESS:
            return {
                ...state,
                giaoxe: action.payload,
                loading: false
            };
        case FETCH_GIAOXE_FAILED:
            return {
                ...state,
                giaoxe: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case STOP_FETCH_GIAOXE:
            return {
                ...state,
                giaoxe: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_GIAOXE_BIENSO:
            return {
                ...state,
                loading: true
            };
        case FETCH_GIAOXE_BIENSO_SUCCESS:
            return {
                ...state,
                giaoxeByBienSo: action.payload,
                loading: false
            };
        case FETCH_GIAOXE_BIENSO_FAILED:
            return {
                ...state,
                giaoxeByBienSo: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case PUSH_GIAOXE:
            return state;

        case EDIT_GIAOXE:
            return state;

        default:
            return state;
    }
};
