import {
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    EDIT_USER,
    EDIT_USER_FAILED,
    EDIT_USER_SUCCESS,
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_FAILED,
    FETCH_ALL_DRIVERS_QUEUE,
    FETCH_ALL_DRIVERS_QUEUE_FAILED,
    FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
    FETCH_ALL_DRIVERS_SUCCESS,
    FETCH_ALL_FLEETADMIN,
    FETCH_ALL_FLEETADMIN_FAILED,
    FETCH_ALL_FLEETADMIN_SUCCESS,
    FETCH_ALL_ONCE_DRIVER,
    FETCH_ALL_ONCE_DRIVER_FAILED,
    FETCH_ALL_ONCE_DRIVER_SUCCESS,
    FETCH_ALL_ONCE_USERS,
    FETCH_ALL_ONCE_USERS_FAILED,
    FETCH_ALL_ONCE_USERS_SUCCESS,
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_FAILED,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_DRIVER_ACTIVE,
    FETCH_DRIVER_ACTIVE_FAILED,
    FETCH_DRIVER_ACTIVE_SUCCESS,
    FETCH_DRIVER_OUT_IN_GROUP,
    FETCH_DRIVER_OUT_IN_GROUP_FAILED,
    FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
    SELECT_USER,
    SELECT_USER_FAILED,
    SELECT_USER_SUCCESS,
    STOP_FETCH_ALL_DRIVERS_SUCCESS,
    UPDATE_USER_GROUP,
    UPDATE_USER_LOCK_STATUS,
    UPDATE_USER_LOCK_STATUS_FAILED,
    UPDATE_USER_LOCK_STATUS_SUCCESS
} from "../store/types";
import store from "../store/store";
import {getUserValidSign} from "../other/CommonFunctions";
import {GetCurrentTimeStamp} from "../other/DateFunctions";
import {firebase} from '../config/configureFirebase';
import {equalTo, get, off, onValue, orderByChild, push, query, remove, set, update} from "firebase/database";
import {FirebaseConfig} from "config";

export const GetDistance = (lat1, lon1, lat2, lon2) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist;
    }
};

export const fetchUsers = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_USERS,
        payload: null
    });
    onValue(query(usersRef, orderByChild('order_number')), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_USERS_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const stopFetchUsers = () => {

    const {
        usersRef
    } = firebase;
    off(query(usersRef, orderByChild('order_number')));
};

export const fetchFleetUsersOnline = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_FLEETADMIN,
        payload: null
    });
    onValue(query(usersRef, orderByChild('usertype'), equalTo('fleetadmin')), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_FLEETADMIN_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_FLEETADMIN_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const fetchDriversOn = () => (dispatch) => {

    const {
        usersRef,
        caTrucDemsRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_ACTIVE,
        payload: null
    });

    onValue(query(usersRef, orderByChild("usertype"), equalTo("driver")), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved && data[i].approved === true)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_DRIVER_ACTIVE_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_DRIVER_ACTIVE_FAILED,
                payload: "No users available."
            });
        }
    });

};


export const stopFetchDriversOn = () => (dispatch) => {

    const {
        usersRef,
        caTrucDemsRef
    } = firebase;
    dispatch({
        type: FETCH_DRIVER_ACTIVE_FAILED,
        payload: "No users available."
    });
    off(query(usersRef, orderByChild('vehicleNumberLeader')));
};

export const fetchOnceUsers = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_ONCE_USERS,
        payload: null
    });
    onValue(query(usersRef, orderByChild('order_number')), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_ONCE_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_ONCE_USERS_FAILED,
                payload: "No users available."
            });
        }
    }, {onlyOnce: true});
};

export const fetchOnceDrivers = () => (dispatch) => {

    const {
        usersRef,
        groupdriverRef,
        groupdriverTotalRef,
        groupdriverCoordTotalRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_ONCE_DRIVER,
        payload: null
    });

    get(query(usersRef, orderByChild("usertype"), equalTo('driver'))).then((snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].approved == true && data[i].location)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });

            onValue(groupdriverRef, (snapshot) => {
                if (snapshot.val()) {
                    let data = snapshot.val();
                    for (let i = 0; i < data.length; i++) {
                        if (data[i] && data[i].value) {
                            if (data[i].value != '0' && data[i].value != '1' && data[i].value != '9' && data[i].value != '12' && data[i].lock_status == false) {
                                let drivers = arr.filter((d) => d.groupDriver == data[i].value);
                                if (data[i].group_coords) {
                                    let listKey = Object.keys(data[i].group_coords);
                                    if (listKey) {
                                        listKey.map((keyId) => {
                                            if (data[i].group_coords[keyId]) {
                                                let coord = data[i].group_coords[keyId];
                                                let driverCoords = arr.filter((d) => d.coordData && parseInt(d.coordData.coordId) === parseInt(keyId) && parseInt(d.coordData.groupId) === parseInt(data[i].value) && parseInt(d.coordData.groupId) === parseInt(d.groupDriver));
                                                if (driverCoords && coord) {
                                                    set(groupdriverCoordTotalRef(data[i].value, keyId), driverCoords.length);
                                                }
                                            }
                                        });
                                    }
                                }
                                if (drivers) {
                                    set(groupdriverTotalRef(data[i].value), drivers.length);
                                }
                            }
                        }
                    }
                }
            }, {onlyOnce: true});

            dispatch({
                type: FETCH_ALL_ONCE_DRIVER_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_ONCE_DRIVER_FAILED,
                payload: "No users available."
            });
        }
    });
};

export const fetchDriversOutInGroup = (bankinh, carType, pickup, group = null, currentAreaId = null) => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_OUT_IN_GROUP,
        payload: null
    });
    var resultOut = [];
    var resultIn = [];
    var resultInArea = [];
    let cars = store.getState().cartypes.cars;
    const groups = store.getState().groupdriverdata.group_kd;
    const profile = store.getState().auth.info.profile;
    let settings = store.getState().settingsdata.settings;
    var group_info = null;
    var driver_favourite = null;
    var currentPhone = null;
    if (profile && profile.driver_favourite) {
        driver_favourite = store.getState().auth.info.profile.driver_favourite;
    }
    if (profile && profile.mobile) {
        currentPhone = profile.mobile.replace("+840", "0");
    }
    if (store.getState().groupdriverdata && store.getState().groupdriverdata.group_kd && group) {
        group_info = store.getState().groupdriverdata.group_kd.filter((g) => g.value == group)[0];
    }

    if (cars && cars.length > 0) {
        let carSelect = cars.filter((car) => car.code === carType);

        if (carSelect[0]) {
            let carSelected = carSelect[0];
            var listDrivers = [];
            if (carSelected.driverCodes) {
                listDrivers = carSelected.driverCodes.split(",");
            }

            var groupValid = null;
            if (group && groups) {
                groupValid = groups.filter((g) => {
                    if (g.value && g.value == group) {
                        return true;
                    }
                })[0];
            }
            const promises = [];
            get(query(usersRef, orderByChild("usertype"), equalTo('driver'))).then((snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();

                    var users = Object.keys(data)
                        .filter(i =>
                            data[i].pushToken && data[i].codeDriver && data[i].groupDriver
                            && data[i].queue === false && data[i].driverActiveStatus === true && data[i].approved === true
                            && data[i].usertype && !data[i].lock_driver_status
                            && data[i].location && data[i].carTypeCode == carType
                            && data[i].approvedDriver == 'approved'
                        )
                        .map(i => {
                            data[i].id = i;
                            return data[i];
                        });
                    if (profile && profile.codeDriver) {
                        users = users.filter(u2 => u2.codeDriver.trim() != profile.codeDriver.trim());
                    }

                    /*if (driver_favourite !== null) {
                        users = users.filter(u2 => driver_favourite[u2.id] && driver_favourite[u2.id].status !== false);
                    }

                    if (currentPhone) {
                        users = users.filter(u2 => !u2.river_favourite || !u2.river_favourite[currentPhone]);
                    }*/
                    for (let i = 0; i < users.length; i++) {
                        let usr = users[i];
                        promises.push(getUserValidSign(usr, bankinh, pickup, listDrivers, carType, resultOut, null, groupValid, currentAreaId, resultInArea));
                    }
                }

                Promise.all(promises)
                    .then((results) => {
                        if (group_info !== null && group_info.codes) {
                            let listDriverCode = group_info.codes.split(',');
                            let newResultIn = [];
                            if (resultIn.length > 0) {
                                resultIn.map((driver) => {
                                    if (driver.codeDriver && listDriverCode.includes(driver.codeDriver.toString().trim())) {
                                        newResultIn.push(driver);
                                    }
                                });
                            }
                            //{id: usr.id, codeDriver: usr.codeDriver.toString().trim(), pushToken: usr.pushToken, location: usr.location}
                            if (settings && settings.approve_by_px) {
                                dispatch({
                                    type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                                    payload: {driverInGroup: newResultIn, driverOutGroup: resultOut, driverInArea: resultInArea}
                                });
                            } else {
                                dispatch({
                                    type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                                    payload: {driverInGroup: newResultIn, driverOutGroup: resultOut, driverInArea: []}
                                });
                            }

                        } else {
                            if (settings && settings.approve_by_px) {
                                dispatch({
                                    type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                                    payload: {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: resultInArea}
                                });
                            } else {
                                dispatch({
                                    type: FETCH_DRIVER_OUT_IN_GROUP_SUCCESS,
                                    payload: {driverInGroup: resultIn, driverOutGroup: resultOut, driverInArea: []}
                                });
                            }

                        }

                    })
                    .catch((e) => {
                        console.log("error");
                        console.log(e);
                        dispatch({
                            type: FETCH_DRIVER_OUT_IN_GROUP_FAILED,
                            payload: null
                        });
                    });
            }).catch(error => console.log("error 12", error));
        }
    }
};

export const fetchAllDrivers = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_DRIVERS,
        drivers: null
    });

    onValue(query(usersRef, orderByChild("usertype"), equalTo("driver")), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();

            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].groupDriver !== '' && data[i].approved === true)
                .map(i => {
                    data[i].id = i;
                    if (data[i].queue) {
                        data[i].carStatus = 'xe341.png';
                    } else {
                        if (data[i].driverActiveStatus === false) {
                            data[i].carStatus = 'xe040.png';
                        } else {
                            data[i].carStatus = 'xe042.png';
                        }
                    }
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_DRIVERS_SUCCESS,
                drivers: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_FAILED,
                drivers: "No users available."
            });
        }
    });
};

export const fetchAllDriversOff = () => (dispatch) => {
    const {
        usersRef
    } = firebase;
    dispatch({
        type: STOP_FETCH_ALL_DRIVERS_SUCCESS,
        drivers: null,
    });
    off(query(usersRef, orderByChild("usertype"), equalTo("driver")));
}


export const fetchAllDriversQueue = () => (dispatch) => {

    const {
        usersRef
    } = firebase;

    dispatch({
        type: FETCH_ALL_DRIVERS_QUEUE,
        driversqueue: null
    });

    onValue(query(usersRef, orderByChild("queue"), equalTo(true)), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].groupDriver !== '' && data[i].approved === true)
                .map(i => {
                    data[i].id = i;
                    if (data[i].queue) {
                        data[i].carStatus = 'xe341.png';
                    } else {
                        if (data[i].driverActiveStatus === false) {
                            data[i].carStatus = 'xe040.png';
                        } else {
                            data[i].carStatus = 'xe042.png';
                        }
                    }
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_DRIVERS_QUEUE_SUCCESS,
                driversqueue: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_QUEUE_FAILED,
                driversqueue: "No users available."
            });
        }
    });
};


export const fetchAllDriversQueueOff = () => (dispatch) => {

    const {
        usersRef
    } = firebase;
    off(query(usersRef, orderByChild("queue"), equalTo(true)));
};


export const addUser = (userdata) => (dispatch) => {
    const {
        usersRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: userdata
    });
    let countUser = 0;
    onValue(usersRef, snapshot => {
        countUser = Object.keys(snapshot.val()).length;
    });

    userdata.order_number = countUser + 1;
    push(usersRef, userdata).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
}

export const editUser = (id, user) => (dispatch) => {

    const {
        singleUserRef,
        usersRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: user
    });
    let editedUser = user;
    delete editedUser.id;

    if (!editedUser.order_number) {
        let countUser = 0;
        onValue(usersRef, snapshot => {
            countUser = Object.keys(snapshot.val()).length;
        });
        editedUser.order_number = countUser + 1;
    }

    set(singleUserRef(id), editedUser).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
}


export const updateUserGroup = (uid, groupDriver) => (dispatch) => {
    const {
        singleUserRef,
    } = firebase;

    dispatch({
        type: UPDATE_USER_GROUP,
        payload: null
    });

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            var userDetail = snapshot.val();
            userDetail.groupDriver = groupDriver;
            set(singleUserRef(uid), userDetail).then(() => {
                dispatch({
                    type: EDIT_USER_SUCCESS,
                    payload: "Cập nhật nhóm thành công"
                });
            }).catch(error => {
                dispatch({
                    type: EDIT_USER_FAILED,
                    payload: "Có lỗi xảy ra trong khi cập nhật nhóm"
                });
            });
        }
    }, {onlyOnce: true});


};

export const updateUserLockStatus = (uid, status) => (dispatch) => {
    const {
        singleUserRef,
    } = firebase;

    dispatch({
        type: UPDATE_USER_LOCK_STATUS,
        payload: null
    });

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            var userDetail = snapshot.val();
            userDetail.lock_driver_status = status ? status : false;
            if (status) {
                userDetail.lock_driver_time = GetCurrentTimeStamp();
            } else {
                userDetail.lock_driver_time = 0;
            }
            set(singleUserRef(uid), userDetail).then(() => {
                dispatch({
                    type: UPDATE_USER_LOCK_STATUS_SUCCESS,
                    payload: "Cập nhật Trạng thái thành công"
                });

            }).catch(error => {
                dispatch({
                    type: UPDATE_USER_LOCK_STATUS_FAILED,
                    payload: "Có lỗi xảy ra trong khi cập nhật Trạng thái"
                });
            });
        }
    }, {onlyOnce: true});
};

export const getUserDetail = (id) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;

    dispatch({
        type: SELECT_USER,
        user_detail: null
    });

    onValue(singleUserRef(id), function (snapshot) {
        if (snapshot.val()) {
            const data = snapshot.val();
            data.id = id;
            dispatch({
                type: SELECT_USER_SUCCESS,
                user_detail: data
            });
        } else {
            dispatch({
                type: SELECT_USER_FAILED,
                user_detail: "No users available."
            });
        }
    });
}

export const removeSignupViaReferralCloud = async (uid) => {
    let result = await fetch(`https://asia-east1-eto-viet.cloudfunctions.net/remove_signup_via_referral`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "uid": uid
        })
    });
    return result;
}


export const getSignupViaReferral = async (mobileS) => {
    const {
        usersRef
    } = firebase;


    return await get(query(usersRef, orderByChild("mobile"), equalTo(mobileS))).then((snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });

            return arr[0];
        } else {
            return [];
        }
    });
}

export const getSignupViaReferralReg = async (mobileS) => {
    const {
        usersRef
    } = firebase;
    return await get(query(usersRef, orderByChild("signupViaReferral"), equalTo(mobileS))).then((snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            return arr
        } else {
            return [];
        }
    });
}


export const updatePer = (uid, totalBook) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;
    if (uid && uid != 'undefined') {
        update(singleUserRef(uid), {per: totalBook ? totalBook : 0});
    }
};


