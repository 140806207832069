import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch} from "react-redux";
import {api, FirebaseContext} from 'common';
import {formatDisplayDate} from "common/src/other/CommonFunctions";
import {ConvertTimeStampToDate} from "common/src/other/DateFunctions";
import {View, Text} from "react-native-web";
import {onValue} from "firebase/database";

export default function VipReport() {
    const { vipReportRef} = useContext(FirebaseContext);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [totalCurrentVip, setTotalCurrentVip] = useState(0);
    const [totalCurrentVipUp, setTotalCurrentVipUp] = useState(0);
    const [totalCurrentVipDown, setTotalCurrentVipDown] = useState(0);
    const [totalVip, setTotalVip] = useState(0);
    const [totalVipUp, setTotalVipUp] = useState(0);
    const [totalVipDown, setTotalVipDown] = useState(0);

    useEffect(() => {
        onValue(vipReportRef(), res => {
            if (res.val()) {
                let prepareData = Object.values(res.val()).map(d => {
                    if (d.valueStatus == 'giam' && d.value > 0) {
                        d.value = -1;
                        d.valueDown = 1;
                        d.valueUp = 0;
                    } else {
                        if (d.valueStatus == 'tang' && d.value > 0) {
                            d.valueUp = 1;
                            d.valueDown = 0;
                        } else {
                            d.valueUp = 0;
                            d.valueDown = 0;
                        }
                    }
                    return d;
                })
                setData(prepareData);

            } else {
                setData([]);
                setTotalCurrentVip(0);
            }
        }, {onlyOnce: true});
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            setTotalCurrentVip(data.reduce((a, v) => a = a + v.value, 0));
            setTotalCurrentVipUp(data.reduce((a, v) => a = a + v.valueUp, 0));
            setTotalCurrentVipDown(data.reduce((a, v) => a = a + v.valueDown, 0));

            setTotalVip(data.reduce((a, v) => a = a + v.value, 0));
            setTotalVipUp(data.reduce((a, v) => a = a + v.valueUp, 0));
            setTotalVipDown(data.reduce((a, v) => a = a + v.valueDown, 0));

        } else {
            setTotalCurrentVip(0);
            setTotalCurrentVipUp(0);
            setTotalCurrentVipDown(0);

            setTotalVip(0);
            setTotalVipUp(0);
            setTotalVipDown(0);
        }
    }, [data]);


    const columns = [
        {
            title: "Thời gian",
            field: 'at',
            editable: 'never',
            defaultSort: 'desc',
            render: rowData => formatDisplayDate(ConvertTimeStampToDate(rowData.at))
        },
        {title: "Mã số", field: 'codeDriver', initialEditValue: ''},
        {title: "Kiểu", field: 'type', lookup: {ck: "Từ cuốc khách", gd: "Từ giao dịch"}},
        {
            title: "Giá trị", field: 'value', initialEditValue: ''
        },
        {title: "Trạng thái", field: 'valueStatus', lookup: {tang: "Tăng", giam: "Giảm"}},
        {title: "Đối tác", field: 'widthCodeDriver', initialEditValue: ''}
    ];

    return (
        <>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 50}}>
                <Text>{"Tổng hiện tại vip: " + totalCurrentVip}</Text>
                <Text style={{marginLeft: 40}}>{"Tăng: " + totalCurrentVipUp}</Text>
                <Text style={{marginLeft: 40}}>{"Giảm: " + totalCurrentVipDown}</Text>
            </View>
            <MaterialTable
                title={"Báo cáo VIP"}
                columns={columns}
                data={data}
                onFilterChange={(filters) => {
                    var dataFilter = data;
                    for (let i = 0; i < filters.length; i++) {
                        let f = filters[i];
                        if (Array.isArray(f.value)) {
                            f.value.map(vf => {
                                dataFilter = dataFilter.filter(v => v[f.column.field] == vf);
                            });
                        } else {
                            dataFilter = dataFilter.filter(v => v[f.column.field] == f.value);
                        }
                    }
                    if (dataFilter && dataFilter.length > 0) {
                        setTotalVip(dataFilter.reduce((a, v) => a = a + v.value, 0));
                        setTotalVipUp(dataFilter.reduce((a, v) => a = a + v.valueUp, 0));
                        setTotalVipDown(dataFilter.reduce((a, v) => a = a + v.valueDown, 0));
                    } else {
                        setTotalVip(0);
                        setTotalVipUp(0);
                        setTotalVipDown(0);
                    }
                    /* let dataFilted = data.filter();*/
                }}
                options={{
                    exportButton: true,
                    sorting: true,
                    search: false,
                    filtering: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100]
                }}
            />
            <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: 30}}>
                <Text>{"Tổng vip: " + totalVip}</Text>
                <Text style={{marginLeft: 40}}>{"Tăng: " + totalVipUp}</Text>
                <Text style={{marginLeft: 40}}>{"Giảm: " + totalVipDown}</Text>
            </View>
        </>
    );
}
