import {
    FETCH_GROUP_COORDS,
    FETCH_GROUP_COORDS_SUCCESS,
    FETCH_GROUP_COORDS_FAILED,
    EDIT_GROUP_COORD,
} from "../store/types";

const INITIAL_STATE = {
    coords: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const groupcoordreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_GROUP_COORDS:
            return {
                ...state,
                loading: true
            };
        case FETCH_GROUP_COORDS_SUCCESS:
            return {
                ...state,
                coords: action.payload,
                loading: false
            };
        case FETCH_GROUP_COORDS_FAILED:
            return {
                ...state,
                coords: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case EDIT_GROUP_COORD:
            return state;
        default:
            return state;
    }
};