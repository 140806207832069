import {FETCH_MARKETS, FETCH_MARKETS_FAILED, FETCH_MARKETS_SUCCESS, FETCH_POINT, FETCH_POINT_FAILED, FETCH_POINT_SUCCESS, INIT_POINT, INIT_POINT_SUCCESS, PUSH_MARKET, PUSH_MARKET_FAILED, PUSH_MARKET_SUCCESS, PUSH_MARKET_TRANSACTION, PUSH_MARKET_TRANSACTION_FAILED, PUSH_MARKET_TRANSACTION_SUCCESS, PUSH_POINT, PUSH_POINT_FAILED, PUSH_POINT_SUCCESS} from "../store/types";

const INITIAL_STATE = {
    markets: [],
    market: null,
    transaction: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const marketsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MARKETS:
            return {
                ...state,
                loading: true
            };
        case FETCH_MARKETS_SUCCESS:
            return {
                ...state,
                markets: action.payload.markets,
                loading: false
            };
        case FETCH_MARKETS_FAILED:
            return {
                ...state,
                markets: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case PUSH_MARKET:
            return {
                ...state,
                loading: true
            };
        case PUSH_MARKET_SUCCESS:
            return {
                ...state,
                market: action.payload,
                loading: false
            };
        case PUSH_MARKET_FAILED:
            return {
                ...state,
                market: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case PUSH_MARKET_TRANSACTION:
            return {
                ...state,
                loading: true
            };
        case PUSH_MARKET_TRANSACTION_SUCCESS:
            return {
                ...state,
                transaction: action.payload,
                loading: false
            };
        case PUSH_MARKET_TRANSACTION_FAILED:
            return {
                ...state,
                transaction: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        default:
            return state;
    }
};
