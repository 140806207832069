import {EDIT_CANCELLATION_REASON, FETCH_CANCEL_REASONS, FETCH_CANCEL_REASONS_FAILED, FETCH_CANCEL_REASONS_SUCCESS, STOP_FETCH_CANCEL_REASONS} from "../store/types";

import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {off, onValue, set} from "firebase/database";

export const fetchCancelReasons = () => (dispatch) => {

    const {
        cancelreasonRef
    } = firebase;

    dispatch({
        type: FETCH_CANCEL_REASONS,
        payload: null,
    });
    onValue(cancelreasonRef, (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            let arr = [];
            for (let i = 0; i < data.length; i++) {
                arr.push(data[i].label);
            }
            dispatch({
                type: FETCH_CANCEL_REASONS_SUCCESS,
                payload: {
                    simple: arr,
                    complex: snapshot.val()
                }
            });
        } else {
            dispatch({
                type: FETCH_CANCEL_REASONS_FAILED,
                payload: language.no_cancel_reason,
            });
        }
    });
};

export const stopFetchCancelReasons = () => (dispatch) => {
    const {
        cancelreasonRef
    } = firebase;

    dispatch({
        type: STOP_FETCH_CANCEL_REASONS,
        payload: null,
    });
    off(cancelreasonRef);
};

export const editCancellationReason = (reasons, method) => (dispatch) => {
    const {
        cancelreasonRef
    } = firebase;

    dispatch({
        type: EDIT_CANCELLATION_REASON,
        payload: method
    });
    set(cancelreasonRef, reasons);
}



