import {FETCH_TRADE, FETCH_TRADE_FAILED, FETCH_TRADE_SUCCESS, SET_TRADE, SET_TRADE_FAILED, SET_TRADE_SUCCESS, UPDATE_TRADE, UPDATE_TRADE_FAILED, UPDATE_TRADE_SUCCESS} from "../store/types";
import {onValue, set, update} from "firebase/database";
import {firebase} from '../config/configureFirebase';
import {pushNotifyImportance} from "./notifyimportanceaction";

export const fetchTrade = (key) => (dispatch) => {
    const {
        tradeRef
    } = firebase;

    dispatch({
        type: FETCH_TRADE,
        payload: null,
    });
    onValue(tradeRef(key), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            if (data && !data.lock_status) {
                dispatch({
                    type: FETCH_TRADE_SUCCESS,
                    payload: {
                        trade: data
                    }
                });
            } else {
                dispatch({
                    type: FETCH_TRADE_FAILED,
                    payload: "Không tìm thấy dữ liệu giao dịch",
                });
            }
        } else {
            dispatch({
                type: FETCH_TRADE_FAILED,
                payload: "Không tìm thấy dữ liệu giao dịch",
            });
        }
    });
};

export const stopFetchTrade = (key) => (dispatch) => {
    const {
        refApi
    } = firebase;
    refApi.stopFetchTradeApi(key);
    dispatch({
        type: FETCH_TRADE_FAILED,
        payload: "Không tìm thấy dữ liệu giao dịch",
    });
}

export const setTrade = (data) => (dispatch) => {
    const {
        tradeRef
    } = firebase;

    dispatch({
        type: SET_TRADE,
        payload: null,
    });
    if (data.key) {
        set(tradeRef(data.key), data)
            .then(() => {
                if (data.members && data.members.length > 0) {
                    for (let i = 0; i < data.members.length; i++) {
                        let dataNotify = {
                            date_add: data.date_add,
                            status: 'pending',
                            from: data.from_uid,
                            type: 'trade',
                            title: "Yêu cầu giao dịch từ #" + data.key.split("_")[0],
                            content: "Yêu cầu giao dịch điểm ETO",
                            screen: 'TradesScreen',
                            to: data.members[i],
                            params: {
                                tradeId: data.key,
                                datas: data.datas ? data.datas : null
                            }
                        };
                        dispatch(pushNotifyImportance(dataNotify));
                    }
                }
                dispatch({
                    type: SET_TRADE_SUCCESS,
                    payload: {
                        trade: data
                    }
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_TRADE_FAILED,
                    payload: "Có lỗi khởi tạo giao dịch, vui lòng liên hệ quản trị",
                });
            });
    } else {
        dispatch({
            type: SET_TRADE_FAILED,
            payload: "Không tồn tại khóa giao dịch",
        });
    }
};

export const updateTrade = (data) => (dispatch) => {
    const {
        tradeRef
    } = firebase;

    dispatch({
        type: UPDATE_TRADE,
        payload: null,
    });
    if (data.key) {
        update(tradeRef(data.key), data)
            .then(() => {
                dispatch({
                    type: UPDATE_TRADE_SUCCESS,
                    payload: {
                        trade: data
                    }
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_TRADE_FAILED,
                    payload: "Có lỗi khi thay đổi thông tin giao dịch, vui lòng liên hệ quản trị",
                });
            });
    } else {
        dispatch({
            type: UPDATE_TRADE_FAILED,
            payload: "Không tồn tại khóa giao dịch",
        });
    }
};

