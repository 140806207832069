import React, {useEffect, useContext} from 'react';
import CircularLoading from "../components/CircularLoading";
import {useSelector, useDispatch} from "react-redux";
import {language} from 'config';
import {api, FirebaseContext} from 'common';
import {GetCurrentDateCaTrucDem, GetCurrentTimeStamp} from "common/src/other/DateFunctions";

function AuthLoading(props) {
    const {refApi} = useContext(FirebaseContext);
    const {setUserLoginHistoryApi, updateProfileDoRef} = refApi;
    const {
        fetchUser,
        fetchCarTypes,
        fetchGroupDrivers,
        fetchReportDrivers,
        fetchSettings,
        fetchBookings,
        fetchCancelReasons,
        fetchPromos,
        fetchUsers,
        fetchAllDrivers,
        fetchAllDriversQueue,
        fetchOnceUsers,
        fetchNotifications,
        fetchMistakes,
        fetchEarningsReport,
        signOutL,
        fetchWithdraws,
        fetchAllBookings,
        fetchCallsPending,
        fetchForceNotify,
        fetchGroupArea,
        fetchCallDetailAll,
        fetchFleetUsersOnline,
        fetchEtoWallet,
        fetchDriverCaTrucDem
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const settingsdata = useSelector((state) => state.settingsdata);

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch, fetchSettings]);

    useEffect(() => {
        dispatch(fetchCarTypes());
        dispatch(fetchGroupDrivers());
        dispatch(fetchDriverCaTrucDem(GetCurrentDateCaTrucDem()));
        dispatch(fetchFleetUsersOnline());
        dispatch(fetchUser());
    }, [settingsdata.settings, dispatch, fetchCarTypes, fetchGroupDrivers, fetchFleetUsersOnline, fetchDriverCaTrucDem, fetchUser]);

    useEffect(() => {
        if (auth.user) {
            let role = auth.user.usertype;
            if (role === 'rider') {
                dispatch(fetchBookings(auth.user.uid, role));
                dispatch(fetchCancelReasons());
            } else if (role === 'driver') {
                dispatch(fetchBookings(auth.user.uid, role));
                dispatch(fetchGroupDrivers());
                dispatch(fetchNotifications());
                dispatch(fetchCancelReasons());
            } else if (role === 'admin') {
                //dispatch(fetchForceNotify());
                //dispatch(fetchUsers());
                //dispatch(fetchOnceUsers());
                //dispatch(fetchBookings(auth.info.uid, role));
                //dispatch(fetchAllBookings());
                //dispatch(fetchPromos());
                //dispatch(fetchDriverEarnings(auth.info.uid, role));
                //dispatch(fetchEtoWallet());
                //dispatch(fetchNotifications());
                //dispatch(fetchEarningsReport());
                //dispatch(fetchCancelReasons());
                dispatch(fetchGroupDrivers());
                dispatch(fetchCancelReasons());
                //dispatch(fetchGroupArea());
                //dispatch(fetchReportDrivers());
                //dispatch(fetchWithdraws());
                //dispatch(fetchAllDrivers());
                //dispatch(fetchCallsPending());
                //dispatch(fetchAllDriversQueue());
                //dispatch(fetchMistakes());
                //dispatch(fetchCallDetailAll());
                //dispatch(fetchAreas());
            } else if (role === 'fleetadmin') {
                console.log('auth.info.uid', auth.user.uid);
                if (!auth.user.isOnline) {
                    if (setUserLoginHistoryApi) {
                        setUserLoginHistoryApi(auth.user.uid, GetCurrentTimeStamp(), {
                            type: 'login', at: GetCurrentTimeStamp(), lat: "temp",
                            lng: "temp"
                        });
                    }
                    updateProfileDoRef(auth.user.uid, {isOnline: true});
                }
                dispatch(fetchGroupDrivers());
                dispatch(fetchCancelReasons());
                /*dispatch(fetchCancelReasons());
                dispatch(fetchForceNotify());
                //dispatch(fetchUsers());
                dispatch(fetchOnceUsers());
                dispatch(fetchAllBookings());
                /!*dispatch(fetchBookings(auth.info.uid, role));
                dispatch(fetchDriverEarnings(auth.info.uid, role));*!/
                dispatch(fetchGroupDrivers());
                dispatch(fetchReportDrivers());
                dispatch(fetchAllDrivers());
                //dispatch(fetchAllDriversQueue());
                dispatch(fetchCallsPending());
                dispatch(fetchMistakes());
                dispatch(fetchCallDetailAll());*/
            } else {
                alert(language.not_valid_user_type);
                dispatch(signOutL());
            }
        }
    }, [auth.user, dispatch, fetchBookings, fetchCallDetailAll, fetchForceNotify, fetchGroupArea, fetchAllBookings, fetchMistakes, fetchCallsPending, fetchCancelReasons, fetchGroupDrivers, fetchReportDrivers, fetchAllDrivers, fetchAllDriversQueue, fetchEarningsReport, fetchNotifications, fetchPromos, fetchUsers, fetchOnceUsers, fetchWithdraws, signOutL, fetchEtoWallet]);


    return settingsdata.loading ? (
        <CircularLoading/>
    ) : settingsdata.settings ? (
        auth.loading ? (
            <CircularLoading/>
        ) : (
            props.children
        )
    ) : (
        <div>
            <span>No Database Settings found</span>
        </div>
    );
}

export default AuthLoading;
