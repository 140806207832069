import {FETCH_BA_BOOKING_LOCATION, FETCH_BA_BOOKING_LOCATION_FAILED, FETCH_BA_BOOKING_LOCATION_SUCCESS, FETCH_BOOKING_LOCATION, FETCH_BOOKING_LOCATION_FAILED, FETCH_BOOKING_LOCATION_SUCCESS, STOP_BA_LOCATION_FETCH, STOP_LOCATION_FETCH} from "../store/types";
import {language} from 'config';
import {firebase} from '../config/configureFirebase';
import {limitToLast, off, onValue, query} from "firebase/database";

export const fetchBookingLocations = (bookingId) => (dispatch) => {
    const {
        trackingRef
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_LOCATION,
        payload: bookingId,
    });
    onValue(query(trackingRef(bookingId), limitToLast(1)), (snapshot) => {
            if (snapshot.val()) {
                let data = snapshot.val();
                const locations = Object.keys(data)
                    .map((i) => {
                        return data[i]
                    });
                if (locations.length == 1) {
                    dispatch({
                        type: FETCH_BOOKING_LOCATION_SUCCESS,
                        payload: locations[0]
                    });
                } else {
                    dispatch({
                        type: FETCH_BOOKING_LOCATION_FAILED,
                        payload: language.location_fetch_error,
                    });
                }
            } else {
                dispatch({
                    type: FETCH_BOOKING_LOCATION_FAILED,
                    payload: language.location_fetch_error,
                });
            }
        }
    );
};

export const fetchLastBaLocations = (biensoxe = null) => (dispatch) => {
    const {
        binhanhTrackingCarApiRef,
        binhanhTrackingDataApiRef
    } = firebase;
    dispatch({
        type: FETCH_BA_BOOKING_LOCATION,
        payload: biensoxe ? biensoxe : '',
    });
    if (biensoxe) {
        onValue(binhanhTrackingCarApiRef(biensoxe), (snapshot) => {
            if (snapshot.val()) {
                let dataCar = snapshot.val();
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_SUCCESS,
                    payload: dataCar
                });
            } else {
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_FAILED,
                    payload: "Lỗi nạp thông tin xe",
                });
            }
        });
    } else {
        onValue(binhanhTrackingDataApiRef(), (snapshot) => {
            if (snapshot.val()) {
                let dataCar = snapshot.val();
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_SUCCESS,
                    payload: dataCar
                });
            } else {
                dispatch({
                    type: FETCH_BA_BOOKING_LOCATION_FAILED,
                    payload: "Lỗi nạp thông tin xe",
                });
            }
        });
    }
};

export const stopLocationFetch = (bookingId) => (dispatch) => {
    const {
        trackingRef
    } = firebase;

    dispatch({
        type: STOP_LOCATION_FETCH,
        payload: bookingId,
    });
    off(trackingRef(bookingId));
}

export const stopLastBaLocationsFetch = (biensoxe = null) => (dispatch) => {

    const {
        binhanhTrackingCarApiRef,
        binhanhTrackingDataApiRef
    } = firebase;

    dispatch({
        type: STOP_BA_LOCATION_FETCH,
        payload: biensoxe ? biensoxe : '',
    });
    if (biensoxe) {
        off(binhanhTrackingCarApiRef(biensoxe));
    } else {
        off(binhanhTrackingDataApiRef());
    }

}
