import {EDIT_ROLE_PERMISSION, EDIT_ROLE_PERMISSION_FAILED, EDIT_ROLE_PERMISSION_MEMBER, EDIT_ROLE_PERMISSION_MEMBER_FAILED, EDIT_ROLE_PERMISSION_MEMBER_SUCCESS, EDIT_ROLE_PERMISSION_SUCCESS, FETCH_ROLE_PERMISIONS, FETCH_ROLE_PERMISIONS_FAILED, FETCH_ROLE_PERMISIONS_SUCCESS, STOP_FETCH_ROLE_PERMISIONS,} from "../store/types";

export const INITIAL_STATE = {
    roles: null,
    role: null,
    loading: false,
    member:null,
    error: {
        flag: false,
        msg: null
    }
}

export const rolepermissionreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ROLE_PERMISIONS:
            return {
                ...state,
                loading: true
            };
        case FETCH_ROLE_PERMISIONS_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                loading: false
            };
        case FETCH_ROLE_PERMISIONS_FAILED:
            return {
                ...state,
                roles: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case STOP_FETCH_ROLE_PERMISIONS:
            return {
                ...state,
                roles: null,
                loading: false,
                error: {
                    flag: false,
                    msg: action.payload
                }
            };

        case EDIT_ROLE_PERMISSION:
            return {
                ...state,
                loading: true
            };
        case EDIT_ROLE_PERMISSION_SUCCESS:
            return {
                ...state,
                role: action.payload,
                loading: false
            };
        case EDIT_ROLE_PERMISSION_FAILED:
            return {
                ...state,
                role: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case EDIT_ROLE_PERMISSION_MEMBER:
            return {
                ...state,
                loading: true
            };
        case EDIT_ROLE_PERMISSION_MEMBER_SUCCESS:
            return {
                ...state,
                member: action.payload,
                loading: false
            };
        case EDIT_ROLE_PERMISSION_MEMBER_FAILED:
            return {
                ...state,
                member: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        default:
            return state;
    }
};
