import {FETCH_NOTE_LIST, FETCH_NOTE_LIST_FAILED, FETCH_NOTE_LIST_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {off, onValue} from "firebase/database";

export const fetchNoteList = () => (dispatch) => {
    const {
        noteListRef
    } = firebase;

    dispatch({
        type: FETCH_NOTE_LIST,
        payload: null,
    });
    onValue(noteListRef(), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            dispatch({
                type: FETCH_NOTE_LIST_SUCCESS,
                payload: data,
            });
        } else {
            dispatch({
                type: FETCH_NOTE_LIST_FAILED,
                payload: "Không có cuộc gọi đến",
            });
        }
    })
};

export const stopFetchNoteList = () => (dispatch) => {
    const {
        noteListRef
    } = firebase;
    off(noteListRef());
}
