import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {features, language} from 'config';
import {api, FirebaseContext} from 'common';
import {View} from "react-native-web";
import Popup from "reactjs-popup";
import {makeStyles} from "@material-ui/core/styles";
import CircularLoading from "../components/CircularLoading";
import ImportLichTrucDem from "../components/CaTrucDem/ImportLichTrucDem";
import {ConvertTimeStampToDate} from "common/src/other/DateFunctions";
import {RequestPushMsg} from "common/src/other/NotificationFunctions";


const useStyles = makeStyles(theme => ({
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }

}));

export default function CaTrucDem() {
    const classes = useStyles();
    const {
        editCaTrucDem,
        fetchCaTrucDem,
        stopFetchCaTrucDem,
        updateStatusHistory
    } = api;
    const columns = [
        {title: "Ngày Tháng", field: 'date_add', editable: 'never'},
        {title: "Điều hành", field: 'admin', editable: 'never'},
        {title: "Số Hiệu Đàm", field: 'prime_driver', editable: 'never'},
        {title: "Số Hiệu Đàm Thực Tế", field: 'second_driver', render: rowData => rowData.second_driver ? rowData.second_driver : rowData.prime_driver, editable: 'never'},
        {title: "Lịch sử đổi ca", field: 'histories', render: rowData => <ModalLich parentId={rowData.id} secondDriver={rowData.second_driver} dhistories={rowData.histories ? rowData.histories : []} date={rowData.date_add}/>, editable: 'never'},
        {title: "Ngày import", field: 'date_import', render: rowData => ConvertTimeStampToDate(rowData.date_import), editable: 'never'},
    ];
    const [data, setData] = useState([]);
    const catrucdems = useSelector(state => state.catrucdems);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCaTrucDem());
        return () => {
            dispatch(stopFetchCaTrucDem());
        }
    }, []);


    useEffect(() => {
        if (catrucdems.catrucdems) {
            setData(Object.values(catrucdems.catrucdems));
            // Thông kê đổi ca
            /*let listCd = Object.values(catrucdems.catrucdems);
            let listCd2 = listCd.filter((c) => c.id && c.id.includes('2_2023'));
            let listDriverChanged = {};
            for (let i = 0; i < listCd2.length; i++) {
                let list1 = listCd2[i].prime_driver.split(',');
                let list2 = listCd2[i].second_driver.split(',');
                for (let j = 0; j < list1.length; j++) {
                    let driverCode1 = list1[j];
                    if (listDriverChanged[driverCode1]) {
                        listDriverChanged[driverCode1].total_work = listDriverChanged[driverCode1].total_work + 1;
                        if (!list2.includes(driverCode1)) {
                            if (listDriverChanged[driverCode1]) {
                                listDriverChanged[driverCode1].total_change = listDriverChanged[driverCode1].total_change + 1;
                                if (listCd2[i].id) {
                                    listDriverChanged[driverCode1].date_change = listDriverChanged[driverCode1].date_change + ', ' + listCd2[i].id;
                                }
                            }
                        }

                    } else {
                        listDriverChanged[driverCode1] = {total_work: 1, total_change: 0, date_change: ''};
                        if (!list2.includes(driverCode1)) {
                            if (listDriverChanged[driverCode1]) {
                                listDriverChanged[driverCode1].total_change = listDriverChanged[driverCode1].total_change + 1;
                                if (listCd2[i].id) {
                                    listDriverChanged[driverCode1].date_change = listDriverChanged[driverCode1].date_change + ', ' + listCd2[i].id;
                                }
                            }
                        }
                    }
                }
            }
            console.log(JSON.stringify(listDriverChanged));*/
        } else {
            setData([]);
        }
    }, [catrucdems.catrucdems]);

    const ModalLich = (props) => {
        var histories = null;
        if (props.dhistories) {
            histories = Object.keys(props.dhistories)
                .map((i) => {
                    props.dhistories[i].id = i;
                    return props.dhistories[i];
                });
            histories = histories.sort((a, b) => (parseInt(a.date_add) < parseInt(b.date_add)) ? 1 : -1);
        }
        return (
            <Popup
                trigger={<button className='button'> Thông tin đổi ca</button>}
                modal
                nested
                position="top center"
            >
                {close => (
                    <div className={classes.modal}>
                        <button className={classes.modalClose} onClick={close}>
                            X
                        </button>
                        <div className={classes.modalHeader}> Thông tin đổi ca trực ngày {props.date}</div>
                        <div className={classes.modalContent}>
                            {histories ?
                                <MaterialTable
                                    title={""}
                                    columns={[
                                        {title: "Ngày giờ", field: 'date_add', render: rowData => ConvertTimeStampToDate(rowData.date_add), editable: 'never'},
                                        {title: "Lái xe nhường", field: 'from_driver', editable: 'never'},
                                        {title: "Lái xe nhận", field: 'to_driver', editable: 'never'},
                                        {title: "LX xác nhận ?", field: 'lx_status', lookup: {pending: "Đợi xác nhận", approved: "Đồng ý", reject: "Từ chối"}, initialEditValue: "pending", editable: 'never'},
                                        {title: "Tâm duyệt", field: 'status', lookup: {pending: "Đợi duyệt", approved: "Đồng ý", reject: "Từ chối"}, initialEditValue: "pending"}
                                    ]}
                                    data={histories}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                if (features.AllowCriticalEditsAdmin) {
                                                    setTimeout(() => {
                                                        resolve();
                                                        const tblData = data;
                                                        tblData[tblData.indexOf(oldData)] = newData;
                                                        if (newData.lx_status && newData.lx_status === 'approved') {
                                                            alert(newData.status);
                                                            if (oldData.status === 'pending') {
                                                                if (newData.status === 'approved') {
                                                                    dispatch(updateStatusHistory(props.parentId, newData.id, {status: newData.status}));
                                                                    var listSecond = props.secondDriver.split(',');
                                                                    var indexUpdate = listSecond.indexOf(newData.from_driver.toString().trim());
                                                                    if (indexUpdate !== -1) {
                                                                        listSecond[indexUpdate] = newData.to_driver.toString().trim();
                                                                    }
                                                                    let data_update = {
                                                                        id: props.parentId,
                                                                        second_driver: listSecond.join(",")
                                                                    }
                                                                    dispatch(editCaTrucDem(data_update, "Update"));
                                                                    alert("Bạn đã duyệt đổi ca. Điều này không thể thay đổi");
                                                                    if (newData.from_driver_token) {
                                                                        RequestPushMsg(newData.from_driver_token, "Xác nhận đổi ca", "Trung tâm đã duyệt yêu cầu đổi ca của bạn!");
                                                                    }
                                                                    if (newData.to_driver_token) {
                                                                        RequestPushMsg(newData.to_driver_token, "Xác nhận đổi ca", "Trung tâm đã duyệt yêu cầu nhận ca của bạn!");
                                                                    }
                                                                }
                                                                if (newData.status === 'reject') {
                                                                    dispatch(updateStatusHistory(props.parentId, newData.id, {status: newData.status}));
                                                                    alert("Bạn đã từ chối đổi ca. Điều này không thể thay đổi");
                                                                    if (newData.from_driver_token) {
                                                                        RequestPushMsg(newData.from_driver_token, "Xác nhận đổi ca", "Trung tâm đã từ chối yêu cầu đổi ca của bạn!");
                                                                    }
                                                                    if (newData.to_driver_token) {
                                                                        RequestPushMsg(newData.to_driver_token, "Xác nhận đổi ca", "Trung tâm đã từ chối yêu cầu nhận ca của bạn!");
                                                                    }
                                                                }
                                                            } else {
                                                                alert("Bạn không thể duyệt 2 lần");
                                                            }
                                                        } else {
                                                            alert("Bạn chỉ được duyệt khi lái xe #" + newData.to_driver + " đồng ý");
                                                        }

                                                    }, 600);
                                                } else {
                                                    alert(language.demo_mode);
                                                    reject();
                                                }
                                            })
                                    }}

                                /> : null}
                        </div>
                        <div className={classes.modalActions}>
                            <button
                                className='button'
                                onClick={() => {
                                    console.log('modal closed ');
                                    close();
                                }}
                            >
                                Đóng
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        )
    };

    const ModalImportLich = (props) => (
        <Popup
            trigger={<button className='button'> Import Lịch</button>}
            modal
            nested
            position="top center"
        >
            {close => (
                <div className={classes.modal}>
                    <button className={classes.modalClose} onClick={close}>
                        X
                    </button>
                    <div className={classes.modalHeader}> Import Lịch Trực</div>
                    <div className={classes.modalContent}>
                        {' '}
                        <ImportLichTrucDem close={close}/>
                    </div>
                    <div className={classes.modalActions}>
                        <button
                            className='button'
                            onClick={() => {
                                console.log('modal closed ');
                                close();
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );


    return (
        <View>
            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: 'center'
            }}>
                <ModalImportLich/>
            </View>
            {catrucdems.loading ? <CircularLoading/> : <MaterialTable
                title={"Ca Trực Đêm"}
                columns={columns}
                data={data}
                options={{
                    exportButton: true,
                    pageSize: 25,
                    filtering: true,
                    pageSizeOptions: [10, 25, 50, 100]
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if (features.AllowCriticalEditsAdmin) {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    //dispatch(editMistakes(newData, "Add"));
                                }, 600);
                            } else {
                                alert(language.demo_mode);
                                reject();
                            }
                        })
                }}
            />}
        </View>

    );
}
