import {FETCH_DRIVER_FAVIOURITE, FETCH_DRIVER_FAVIOURITE_FAILED, FETCH_DRIVER_FAVIOURITE_SUCCESS, FETCH_ONE_DRIVER_FAVIOURITE, FETCH_ONE_DRIVER_FAVIOURITE_FAILED, FETCH_ONE_DRIVER_FAVIOURITE_SUCCESS} from "../store/types";
import {firebase} from '../config/configureFirebase';
import {onValue, push, remove, set, update} from "firebase/database";

export const fetchDriverFaviourite = (uid) => (dispatch) => () => {
    const {
        driverFavouriteRef
    } = firebase;

    dispatch({
        type: FETCH_DRIVER_FAVIOURITE,
        payload: null
    });
    onValue(driverFavouriteRef(uid), snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_DRIVER_FAVIOURITE_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_DRIVER_FAVIOURITE_FAILED,
                payload: "Chưa có tài xế yêu thích"
            });
        }
    });
};

export const fetchOneDriverFaviourite = (uid, driverId) => (dispatch) => () => {
    const {
        driverFavouriteDriverIdRef
    } = firebase;

    dispatch({
        type: FETCH_ONE_DRIVER_FAVIOURITE,
        payload: null
    });
    onValue(driverFavouriteDriverIdRef(uid, driverId), snapshot => {
        if (snapshot.val()) {
            dispatch({
                type: FETCH_ONE_DRIVER_FAVIOURITE_SUCCESS,
                payload: snapshot.val()
            });
        } else {
            dispatch({
                type: FETCH_ONE_DRIVER_FAVIOURITE_FAILED,
                payload: "Chưa có tài xế yêu thích"
            });
        }
    });
};


export const pushDriverFaviourite = (uid, driverFaviouriteObj) => () => {
    const {
        driverFavouriteRef
    } = firebase;
    push(driverFavouriteRef(uid), driverFaviouriteObj);
};

export const setDriverFaviourite = (uid, driverFaviouriteObj) => () => {
    const {
        driverFavouriteDriverIdRef
    } = firebase;
    set(driverFavouriteDriverIdRef(uid, driverFaviouriteObj.uid), driverFaviouriteObj);
};

export const delDriverFaviourite = (uid, driverId) => () => {
    const {
        driverFavouriteDriverIdRef
    } = firebase;
    remove(driverFavouriteDriverIdRef(uid, driverId));
};

export const editDriverFaviourite = (uid, driverFaviouriteObj) => () => {
    const {
        driverFavouriteDriverIdRef
    } = firebase;
    update(driverFavouriteDriverIdRef(uid, driverFaviouriteObj.uid), driverFaviouriteObj);
};
