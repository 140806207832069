import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGridPremium, GridToolbar, useGridApiRef, useKeepGroupedColumnsHidden,} from '@mui/x-data-grid-premium';
import {groupByArray} from "common/src/other/CommonFunctions";
import {ConvertSecondToHm, GetEndDatePicker, GetStartDatePicker} from "common/src/other/DateFunctions";
import {useDispatch} from "react-redux";
import {FirebaseContext} from 'common';
import {Text, View} from "react-native-web";
import {onValue} from "firebase/database";


export default function ReportBooking() {
    const {bookingAllListByPickupTimeRef} = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startDate, setStartDate] = useState(GetStartDatePicker('X'));
    const [endDate, setEndDate] = useState(GetEndDatePicker('X'));
    const [rows, setRows] = useState([]);
    const [defaultSValue, setDefaultSValue] = useState(GetStartDatePicker("YYYY-MM-DD HH:mm").toString());
    const [defaultEValue, setDefaultEValue] = useState(GetEndDatePicker('YYYY-MM-DD HH:mm').toString());
    const [loading, setLoading] = useState(false);
    const [listCode, setListCode] = useState([]);
    const [listBs, setListBs] = useState([]);
    const [codes, setCodes] = useState([]);
    const [bksFilter, setBksFilter] = useState([]);

    const [queryOptions, setQueryOptions] = useState({});

    useEffect(() => {
        if (startDate && endDate && rowsPerPage) {
            if (startDate && endDate && rowsPerPage) {
                setLoading(true);
                let prepareData = [];
                onValue(bookingAllListByPickupTimeRef("bookingDate", 100000, endDate * 1000, 1000000), snapshot => {
                    if (snapshot.val()) {
                        const data = snapshot.val();
                        const arr = Object.keys(data).map((i, index) => {
                            data[i].id = i;
                            data[i].stt = index + 1;
                            data[i].paidTime = data[i].paid_time ? new Date(data[i].paid_time.at) : '';
                            data[i].waitingTime = data[i].waitingTime ? ConvertSecondToHm(data[i].waitingTime) : '0';
                            data[i].estimateDistance = data[i].estimateDistance ? parseFloat(data[i].estimateDistance).toFixed(2) : '0';
                            data[i].estimate = data[i].estimate ? parseFloat(data[i].estimate) : '';
                            data[i].customer_paid = data[i].customer_paid ? parseFloat(data[i].customer_paid).toFixed(2) : '0';
                            data[i].accept_time = data[i].accept_time ? new Date(data[i].accept_time) : '';
                            data[i].pickupTime = data[i].pickupTime ? new Date(data[i].pickupTime) : '';
                            data[i].is_two_way = data[i].is_two_way && data[i].is_two_way == true ? '2 chiều' : '1 chiều';
                            data[i].accept_time_add = data[i].accept_time_stone && data[i].accept_time_stone.add ? data[i].accept_time_stone.add : 'Không xác định';
                            data[i].customer_paid = data[i].customer_paid ? parseFloat(data[i].customer_paid) : '';
                            return data[i];
                        });
                        let rowsFilter = arr;
                        if (codes && codes.length > 0) {
                            var listDatasFilted = [];
                            for (let i = 0; i < codes.length; i++) {
                                let phoneFilter = codes[i];
                                if (phoneFilter && phoneFilter.value) {
                                    listDatasFilted = listDatasFilted.concat(prepareData.filter((d) => d.codeDriver.toString() == phoneFilter.value.toString()));
                                }
                            }
                            rowsFilter = listDatasFilted
                        }

                        if (bksFilter && bksFilter.length > 0) {
                            var listDatasFilted = [];
                            for (let i = 0; i < bksFilter.length; i++) {
                                let bksF = bksFilter[i];
                                if (bksF && bksF.value) {
                                    listDatasFilted = listDatasFilted.concat(rowsFilter.filter((d) => d.bks.toString() == bksF.value.toString()));
                                }
                            }
                            rowsFilter = listDatasFilted
                        }
                        setRows(rowsFilter);

                        /*let sorted = prepareData.filter(item => item.status !== 'NEWV2');*/
                        //sorted = sorted.sort((a, b) => (parseInt(a[orderBy]) < parseInt(b[orderBy])) ? 1 : -1);
                        /*sorted = sorted.filter(item => !item.customer_note || (item.customer_note && item.customer_note != 'Khách quen lái xe'));*/
                        let listCodeDriverUnique = Object.keys(groupByArray(Object.values(data), 'codeDriver'));
                        let listBsUnique = Object.keys(groupByArray(Object.values(data), 'vehicle_number'));
                        let listC = [];
                        let listBsx = [];
                        for (let i = 0; i < listCodeDriverUnique.length; i++) {
                            listC.push({
                                title: listCodeDriverUnique[i], value: listCodeDriverUnique[i]
                            });
                        }
                        for (let i = 0; i < listBsUnique.length; i++) {
                            listBsx.push({
                                title: listBsUnique[i], value: listBsUnique[i]
                            });
                        }
                        setListCode(listC);
                        setListBs(listBsx)
                        setLoading(false);
                    } else {
                        setRows(prepareData);
                        setLoading(false);
                    }
                }, {onlyOnce: true})
            }
        }
    }, [startDate, endDate, rowsPerPage, codes, bksFilter]);

    const headCells = [
        {
            field: 'stt',
            type: 'number',
            headerName: 'STT',
        },
        {
            field: 'vehicle_number',
            headerName: 'Biển số',
        },
        {
            field: 'codeDriver',
            headerName: 'Đàm',
        },
        {
            field: 'accept_time',
            type: 'dateTime',
            headerName: 'Thời gian nhận',
            resizable: true,
            width: 200
        },
        {
            field: 'pickupTime',
            headerName: 'Thời gian đón',
            type: 'dateTime',
            resizable: true,
            width: 200

        },
        {
            field: 'paidTime',
            type: 'dateTime',
            headerName: 'Thời gian trả',
            resizable: true,
            width: 200
        },
        {
            field: 'accept_time_add',
            headerName: 'Vị trí nhận',
            resizable: true,
            width: 300
        },
        {
            field: 'pickupAddress',
            headerName: 'Vị trí đón',
            resizable: true,
            width: 300
        },
        {
            field: 'dropAddress',
            headerName: 'Vị trí trả',
            resizable: true,
            width: 300
        },
        {
            field: 'waitingTime',
            headerName: 'Thời gian chờ',
        },
        {
            field: 'estimateDistance',
            type: 'number',
            headerName: 'KM',
        },
        {
            field: 'is_two_way',
            headerName: 'Thuê bao',
        },
        {
            field: 'estimate',
            type: 'number',
            headerName: 'Tiền đồng hồ',
        },
        {
            field: 'customer_paid',
            type: 'number',
            headerName: 'Thực thu',
        }
    ];

    let data2 = {};
    data2.columns = headCells;
    data2.experimentalFeatures = {ariaV7: true};
    data2.initialState = {
        columnVisibilityModel: {
            id: true
        }
    }
    data2.rows = rows;

    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            ...data2.initialState,
            rowGrouping: {
                ...data2.initialState?.rowGrouping,
                model: ['vehicle_number']
            },
            aggregation: {
                model: {
                    estimateDistance: 'sum',
                    estimate: 'sum',
                    customer_paid: 'sum'
                },
            },
        },
    });

    const onFilterChange = useCallback((filterModel) => {
        // Here you save the data you need from the filter model
        setQueryOptions({filterModel: {...filterModel}});
    }, []);

    return (
        <Box sx={{height: 520, width: '100%', marginTop: 10}}>
            <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: 17, padding: 10}}>{"Báo cáo cuốc khách APP"}</Text>
            </View>
            <DataGridPremium
                {...data2}
                apiRef={apiRef}
                loading={loading}
                disableRowSelectionOnClick
                initialState={initialState}
                hideFooterPagination={false}
                pagination={true}
                slots={{toolbar: GridToolbar}}
                groupingColDef={{leafField: 'vehicle_number'}}
            />
        </Box>
    );
}