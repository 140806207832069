import {FETCH_VOTE, FETCH_VOTE_FAILED, FETCH_VOTE_SUCCESS, PUSH_VOTE, PUSH_VOTE_FAILED, PUSH_VOTE_SUCCESS} from "../store/types";
import {onValue, push, set} from "firebase/database";
import {firebase} from '../config/configureFirebase';
import {pushNotifyImportance} from "./notifyimportanceaction";
import {pushTaskRunner} from "./tasksrunneraction";

export const fetchVotes = (uid) => (dispatch) => {
    const {
        votesByIdRef
    } = firebase;

    dispatch({
        type: FETCH_VOTE,
        payload: null,
    });
    onValue(votesByIdRef(uid), (snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const arr = Object.keys(data)
                .filter((id) => data[id].status == 'pending')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            dispatch({
                type: FETCH_VOTE_SUCCESS,
                payload: {
                    votes: arr,
                }
            });
        } else {
            dispatch({
                type: FETCH_VOTE_FAILED,
                payload: "Chưa có thông báo quan trọng",
            });
        }
    });
};

export const pushVote = (data) => (dispatch) => {
    const {
        votesRef,
        refApi
    } = firebase;

    dispatch({
        type: PUSH_VOTE,
        payload: null,
    });
    let memberListAdd = {};
    if (data.members && data.members.length > 0) {
        for (let i = 0; i < data.members.length; i++) {
            data[data.members[i]] = true;
            let memberAdd = {
                status: 'pending',
                date_add: data.date_add
            }
            memberListAdd[data.members[i]] = memberAdd;
        }
    }
    data.result = memberListAdd;
    push(votesRef(), data).then((res) => {
        var voteKey = res.key;
        data.id = voteKey;
        if (data.members && data.members.length > 0) {
            for (let i = 0; i < data.members.length; i++) {
                let dataNotify = {
                    date_add: data.date_add,
                    status: 'pending',
                    from: data.from,
                    type: 'vote',
                    title: data.title,
                    content: data.content,
                    screen: 'VoteManageScreen',
                    to: data.members[i],
                    params: {
                        voteId: voteKey,
                        area: data.area ? data.area : null
                    }
                };
                dispatch(pushNotifyImportance(dataNotify));
            }
        }
        if (data.area && data.area.id) {
            dispatch(refApi.setVotingAreaApi(data.area.id, data.type, 'processing'));
            if (data.date_to) {
                dispatch(pushTaskRunner({
                    worker: 'vote',
                    status: 'scheduled',
                    performAt: data.date_to,
                    data: {
                        voteId: voteKey,
                        area: data.area ? data.area : null,
                        approve_action: data.approve_action ? data.approve_action : null,
                        reject_action: data.reject_action ? data.reject_action : null,
                        cancel_action: data.cancel_action ? data.cancel_action : null
                    }
                }));
            }
        }

        dispatch({
            type: PUSH_VOTE_SUCCESS,
            payload: {
                vote: data
            }
        });
    }).catch(error => {
        dispatch({
            type: PUSH_VOTE_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
}


export const pushVoteDynamic = (data) => (dispatch) => {
    const {
        votesRef,
        getDynamicByPatchRef
    } = firebase;

    dispatch({
        type: PUSH_VOTE,
        payload: null,
    });
    let memberListAdd = {};
    if (data.members && data.members.length > 0) {
        for (let i = 0; i < data.members.length; i++) {
            data[data.members[i]] = true;
            let memberAdd = {
                status: 'pending',
                date_add: data.date_add
            }
            memberListAdd[data.members[i]] = memberAdd;
        }
    }
    data.result = memberListAdd;
    push(votesRef(), data).then((res) => {
        var voteKey = res.key;
        data.id = voteKey;
        if (data.members && data.members.length > 0) {
            for (let i = 0; i < data.members.length; i++) {
                let dataNotify = {
                    date_add: data.date_add,
                    status: 'pending',
                    from: data.from,
                    type: 'vote',
                    title: data.title,
                    content: data.content,
                    screen: 'VoteManageScreen',
                    to: data.members[i],
                    params: {
                        voteId: voteKey,
                        datas: data.datas ? data.datas : null
                    }
                };
                dispatch(pushNotifyImportance(dataNotify));
            }
        }
        if (data.ref) {
            set(getDynamicByPatchRef(data.ref), 'processing');

        }
        if (data.date_to) {
            dispatch(pushTaskRunner({
                worker: 'vote',
                status: 'scheduled',
                performAt: data.date_to,
                data: {
                    voteId: voteKey,
                    params: data.datas ? data.datas : null,
                    approve_action: data.approve_action ? data.approve_action : null,
                    reject_action: data.reject_action ? data.reject_action : null,
                    cancel_action: data.cancel_action ? data.cancel_action : null
                }
            }));
        }
        dispatch({
            type: PUSH_VOTE_SUCCESS,
            payload: {
                vote: data
            }
        });
    }).catch(error => {
        dispatch({
            type: PUSH_VOTE_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
}
