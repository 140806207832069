import React, {useState, useEffect, useContext} from 'react';
import MaterialTable from 'material-table';
import {useSelector, useDispatch} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {features, language} from 'config';
import {api, FirebaseContext} from 'common';
import Popup from "reactjs-popup";
import {makeStyles} from "@material-ui/core/styles";
import {ConvertTimeStampToDate} from "common/src/other/DateFunctions";

const useStyles = makeStyles(theme => ({
    modal: {
        fontSize: 12,
        border: 1,
        borderStyle: 'solid',
        borderColor: '#cfcece',
        background: '#f6fbe4'
    },
    modalHeader: {
        width: '100%',
        borderBottom: '1',
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        fontSize: 18,
        textAlign: 'center',
        padding: 5,
        fontWeight: 'bold'
    },
    modalContent: {
        width: '100%',
        padding: '10px 5px',
    },
    modalActions: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center'
    },

    modalClose: {
        cursor: 'pointer',
        position: 'absolute',
        display: 'block',
        padding: '2px 5px',
        width: 40,
        height: 40,
        right: -10,
        top: -10,
        background: '#ffffff',
        color: 'black',
        border: 1,
        borderRadius: 18,
        borderStyle: 'solid',
        borderColor: '#cfcece'
    }

}));


export default function Mistakes() {
    const {
        sendMistake,
        editMistakes,
        fetchMistakes,
        stopFetchMistakes
    } = api;
    const classes = useStyles();
    const mistakeconfigdata = useSelector(state => state.mistakeconfigdata);
    const [data, setData] = useState([]);
    const [mistakeConfig, setMistakeConfig] = useState({});
    const mistakedata = useSelector(state => state.mistakedata);
    const dispatch = useDispatch();
    let statusObj = {open: "Ghi nhận vi phạm", reclaim: "Kháng nghị", no_mistake: "Bỏ qua vi phạm", mistake: "Xác nhận vi phạm"};

    useEffect(() => {
        dispatch(fetchMistakes());
        return () => {
            dispatch(stopFetchMistakes());
        }
    }, []);

    useEffect(() => {
        if (mistakeconfigdata.mistake_config) {
            let obj = {};
            for (let i = 0; i < mistakeconfigdata.mistake_config.length; i++) {
                let name = mistakeconfigdata.mistake_config[i].mistake_name;
                let code = mistakeconfigdata.mistake_config[i].mistake_code;
                obj[code] = name;
            }
            setMistakeConfig(obj);
        } else {
            setMistakeConfig({});
        }
    }, [mistakeconfigdata.mistake_config]);

    useEffect(() => {
        if (mistakedata.mistakes) {
            setData(mistakedata.mistakes);
        }
    }, [mistakedata.mistakes]);


    const columns = [
        {title: "Ngày giờ", field: 'date_add', render: rowData => ConvertTimeStampToDate(rowData.date_add), editable: 'never'},
        {
            title: "Loại Vi Pham",
            field: 'type',
            lookup: mistakeConfig,
        },
        {title: "LX Vi Phạm", field: 'driver_code', editable: 'never'},
        {title: "Tiêu đề", field: 'title', editable: 'never'},
        {title: "Nội dung", field: 'content', editable: 'never'},
        {title: "Lịch sử xử lý", field: 'histories', render: rowData => <ModalHistory histories={rowData.histories ? rowData.histories : []}/>, editable: 'never'},
        {
            title: "Trạng thái", field: 'status',
            lookup: statusObj
        }
    ];

    const ModalHistory = (props) => (
        <Popup
            trigger={<button className='button'> Lịch sử vi phạm</button>}
            modal
            nested
            position="top center"
        >
            {close => (
                <div className={classes.modal}>
                    <button className={classes.modalClose} onClick={close}>
                        X
                    </button>
                    <div className={classes.modalHeader}> Lịch sử xử lý vi phạm</div>
                    <div className={classes.modalContent}>
                        {props.histories ?
                            <MaterialTable
                                title={""}
                                columns={[{title: "Ngày giờ", field: 'date_add', render: rowData => ConvertTimeStampToDate(rowData.date_add)},
                                    {title: "Nội dung", field: 'content'}]}
                                data={Object.values(props.histories)}
                            /> : null}
                    </div>
                    <div className={classes.modalActions}>
                        <button
                            className='button'
                            onClick={() => {
                                console.log('modal closed ');
                                close();
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
    return (
        mistakedata.loading ? <CircularLoading/> :
            <MaterialTable
                title={"Danh sách vi phạm"}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const tblData = data;
                                tblData.push(newData);
                                features.AllowCriticalEditsAdmin ?
                                    dispatch(sendMistake(newData))
                                    :
                                    alert(language.demo_mode);
                                dispatch(editMistakes(newData, "Add"));
                            }, 600);
                        }),

                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const tblData = data;
                                tblData[tblData.indexOf(oldData)] = newData;
                                if (newData.status != oldData.status) {
                                    newData.history = {date_add: new Date().getTime(), content: " Trung tâm đã " + statusObj[newData.status]};
                                }
                                dispatch(editMistakes(newData, "Update"));
                            }, 600);
                        }),
                    onRowDelete: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(editMistakes(newData, "Delete"));
                            }, 600);
                        }),
                }}
            />
    );
}
