import React from "react";
import {BrowserRouter, Route, Routes,} from 'react-router-dom';
import {HelmetProvider} from "react-helmet-async";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import 'firebase/app-check';
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import DriverPrivacyPolicy from "./views/DriverPrivacyPolicy.js";
import RiderPrivacyPolicy from "./views/RiderPrivacyPolicy.js";
import BookingWeb from "./views/BookingWeb.js";
import AboutUs from "./views/AboutUs";
import AuthLoading from './views/AuthLoading';
import {Provider} from "react-redux";
import ProtectedRoute from './views/ProtectedRoute';
import MyProfile from './views/MyProfile';
import BookingHistory from './views/BookingHistory';
import Dashboard from './views/Dashboard';
import CarTypes from './views/CarTypes';
import CaTrucDem from './views/CaTrucDem';
import CaTrucDemConfig from './views/CaTrucDemConfig';
import GiaoXe from './views/GiaoXe';
import ForceNotify from './views/ForceNotify';
import GroupDrivers from './views/GroupDrivers';
import Promos from './views/Promos';
import PromoLocations from './views/PromoLocations';
import GroupCoords from './views/GroupCoords';
import Riders from './views/Riders';
import Drivers from './views/Drivers';
import FleetAdmins from './views/FleetAdmins';
import Notifications from './views/Notifications';
import DriverEarning from './views/DriverEarning';
import Earningreports from './views/Earningreports';
import Settings from './views/Settings';
import Withdraws from './views/Withdraws';
import CancellationReasons from './views/CancellationReasons';
import RegisterPage from './views/RegisterPage';
import AddMoney from "./views/AddMoney";
import {FirebaseProvider, store} from 'common';
import {features} from 'config';
import ReportDrivers from "./views/ReportDrivers";
import CustomerCare from "./views/CustomerCare";
import CallCenter from "./views/CallCenter";
import Mistakes from "./views/Mistakes";
import MistakeConfig from "./views/MistakeConfig";
import Invite from "./views/Invite";
import ReportTotalCKDrivers from "./views/ReportTotalCKDrivers";
import WalletManage from './views/WalletManage';
import {ThemeProvider} from '@mui/styles';
import RolePermission from './views/RolePermission';
import ReportBooking from "./views/ReportBooking";
import BackendTrackingCar from "./views/BackendTrackingCar";
import VipReport from "./views/VipReport"
import EtoPolicy from "./views/EtoPolicy";
import TestLogic from "./views/TestLogic";
import {FirebaseConfig} from './config/FirebaseConfig';
import {createTheme} from '@mui/material';

function App() {
    const theme = createTheme()
    return (
        <HelmetProvider>
            <Provider store={store}>
                <FirebaseProvider config={FirebaseConfig}>
                    <ThemeProvider theme={theme}>
                        <AuthLoading>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/callcenter" element={<ProtectedRoute permit={"admin,fleetadmin"}><CallCenter/></ProtectedRoute>}/>
                                    <Route path="/dashboard" element={<ProtectedRoute permit={"admin,fleetadmin"}><Dashboard/></ProtectedRoute>}/>
                                    <Route path="/bookings" element={<ProtectedRoute permit={"rider,admin,driver,fleetadmin"}><BookingHistory/></ProtectedRoute>}/>
                                    <Route path="/profile" element={<ProtectedRoute permit={"rider,admin,driver,fleetadmin"}><MyProfile/></ProtectedRoute>}/>
                                    <Route path="/report_booking" element={<ProtectedRoute permit={"admin,fleetadmin"}><ReportBooking/></ProtectedRoute>}/>
                                    <Route path="/catrucdems" element={<ProtectedRoute permit={"admin,fleetadmin"}><CaTrucDem/></ProtectedRoute>}/>
                                    <Route path="/giaoxe" element={<ProtectedRoute permit={"admin,fleetadmin"}><GiaoXe/></ProtectedRoute>}/>
                                    <Route path="/force_notify" element={<ProtectedRoute permit={"admin,fleetadmin"}><ForceNotify/></ProtectedRoute>}/>
                                    <Route path="/mistakes" element={<ProtectedRoute permit={"admin,fleetadmin"}><Mistakes/></ProtectedRoute>}/>
                                    <Route path="/walletmanage" element={<ProtectedRoute permit={"admin"}><WalletManage/></ProtectedRoute>}/>
                                    <Route path="/promolocation" element={<ProtectedRoute permit={"rider,admin,driver,fleetadmin"}><PromoLocations/></ProtectedRoute>}/>
                                    <Route path="/groupcoord" element={<ProtectedRoute permit={"rider,admin,driver"}><GroupCoords/></ProtectedRoute>}/>
                                    <Route path="/cartypes" element={<ProtectedRoute permit={"admin"}><CarTypes/></ProtectedRoute>}/>
                                    <Route path="/groupdrivers" element={<ProtectedRoute permit={"admin"}><GroupDrivers/></ProtectedRoute>}/>
                                    <Route path="/reportdrivers" element={<ProtectedRoute permit={"admin,fleetadmin"}><ReportDrivers/></ProtectedRoute>}/>
                                    <Route path="/customer_care" element={<ProtectedRoute permit={"admin,fleetadmin"}><CustomerCare/></ProtectedRoute>}/>
                                    <Route path="/cancelreasons" element={<ProtectedRoute permit={"admin,fleetadmin"}><CancellationReasons/></ProtectedRoute>}/>
                                    <Route path="/promos" element={<ProtectedRoute permit={"admin"}><Promos/></ProtectedRoute>}/>
                                    <Route path="/riders" element={<ProtectedRoute permit={"admin"}><Riders/></ProtectedRoute>}/>
                                    <Route path="/drivers" element={<ProtectedRoute permit={"admin,fleetadmin"}><Drivers/></ProtectedRoute>}/>
                                    <Route path="/fleetadmins" element={<ProtectedRoute permit={"admin"}><FleetAdmins/></ProtectedRoute>}/>
                                    <Route path="/driverearning" element={<ProtectedRoute permit={"admin,fleetadmin"}><DriverEarning/></ProtectedRoute>}/>
                                    <Route path="/notifications" element={<ProtectedRoute permit={"admin"}><Notifications/></ProtectedRoute>}/>
                                    <Route path="/earningreports" element={<ProtectedRoute permit={"admin"}><Earningreports/></ProtectedRoute>}/>
                                    <Route path="/report_total_booking_day" element={<ProtectedRoute permit={"admin"}><ReportTotalCKDrivers/></ProtectedRoute>}/>
                                    <Route path="/addtowallet" element={<ProtectedRoute permit={"admin"}><AddMoney/></ProtectedRoute>}/>
                                    <Route path="/withdraws" element={<ProtectedRoute permit={"admin"}><Withdraws/></ProtectedRoute>}/>
                                    <Route path="/mistake_config" element={<ProtectedRoute permit={"admin"}><MistakeConfig/></ProtectedRoute>}/>
                                    <Route path="/catrucdem_config" element={<ProtectedRoute permit={"admin"}><CaTrucDemConfig/></ProtectedRoute>}/>
                                    <Route path="/settings" element={<ProtectedRoute permit={"admin"}><Settings/></ProtectedRoute>}/>
                                    <Route path="/role_permission" element={<ProtectedRoute permit={"admin"}><RolePermission/></ProtectedRoute>}/>
                                    <Route path="/backend_tracking_car" element={<ProtectedRoute permit={"admin,fleetadmin"}><BackendTrackingCar/></ProtectedRoute>}/>
                                    <Route path="/test_logic" element={<ProtectedRoute permit={"admin"}><TestLogic/></ProtectedRoute>}/>
                                    <Route path="/vip_report" element={<ProtectedRoute permit={"admin,fleetadmin"}><VipReport/></ProtectedRoute>}/>

                                    <Route path="/about-us" element={<AboutUs/>}/>
                                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                                    <Route path="/driver-privacy-policy" element={<DriverPrivacyPolicy/>}/>
                                    <Route path="/rider-privacy-policy" element={<RiderPrivacyPolicy/>}/>
                                    <Route path="/eto-term-policy" element={<EtoPolicy/>}/>
                                    <Route path="/booking_web" element={<BookingWeb/>}/>
                                    <Route path="/invite" element={<Invite/>}/>
                                    {features.WebsitePagesEnabled ?
                                        <Route path="/register" element={<RegisterPage/>}/>
                                        : null}
                                    {features.WebsitePagesEnabled ?
                                        <Route path="/login" element={<LoginPage/>}/>
                                        : null}
                                    {features.WebsitePagesEnabled ?
                                        <Route path="/" element={<LandingPage/>}/>
                                        :
                                        <Route path="/" element={<LoginPage/>}/>
                                    }
                                </Routes>
                            </BrowserRouter>
                        </AuthLoading>
                    </ThemeProvider>
                </FirebaseProvider>
            </Provider>
        </HelmetProvider>
    );
}

export default App;
