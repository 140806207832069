import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import {Text} from "react-native-web";
import {useDispatch, useSelector} from "react-redux";
import {api, FirebaseContext} from "common";
import CircularLoading from "../CircularLoading";


const useStyles = makeStyles(theme => ({
    titleList: {
        fontSize: 14,
        marginTop: 10
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        height: 39,
    },
    backgroundRed: {
        backgroundColor: "rgba(255,179,179,0.74)",
        flexDirection: 'column'
    },
    row: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgba(255,0,0,0.74)"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
}));

export default function ThemXeCallCenter(props) {
    const styles = useStyles();
    const [buttonDisable] = useState(false);
    const {
        addCallCancel
    } = api;
    const dispatch = useDispatch();

    return (
        <FormControl>
            <Grid container spacing={1} style={{minHeight: 63}}>
                <Grid item sm={12} md={12} lg={12} style={{paddingTop: 28}}>
                    <Text>{"Thêm xe cho khách đi "}{props.callDetail.note ? props.callDetail.note : props.callDetail.booking_note ? props.callDetail.booking_note : ""}</Text>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={buttonDisable}
                        size="small"
                        id={"themxe_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                        className={styles.submit}
                        onClick={
                            () => {
                                let dataPush = {
                                    destination: props.callDetail.destination,
                                    note: props.callDetail.note ? props.callDetail.note : props.callDetail.booking_note ? props.callDetail.booking_note : "",
                                    phone: props.callDetail.source,
                                    type: props.callDetail.type ? props.callDetail.type : "TT"
                                };
                                dispatch(addCallCancel(dataPush));
                                alert("Thêm xe thành công");
                                props.closeCallback();
                            }
                        }
                    >
                        {"Thêm xe"}
                    </Button>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="red"
                        size="small"
                        id={"cancelbutton_" + (props.callDetail && props.callDetail.uniqueid ? props.callDetail.uniqueid : "")}
                        className={styles.submit}
                        onClick={props.closeCallback}
                    >
                        {"Bỏ qua"}
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    )
}
