import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useDispatch, useSelector} from "react-redux";
import {dateStyle, features, language, timeZone} from 'config';
import {api, FirebaseContext} from 'common';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from "@mui/material/IconButton";
import {GetCurrentMiniTimeStamp} from "common/src/other/DateFunctions";
import {set} from "firebase/database";

export default function Users() {
    const {refApi} = useContext(FirebaseContext);
    const {
        addUser,
        editUser,
        deleteUser,
        checkUserExists,
        fetchOnceUsers,
        fetchAreasOnce
    } = api;
    const [cars, setCars] = useState({});
    const [groups, setGroups] = useState({});
    const usersdata = useSelector(state => state.usersdata.once_users);
    const cartypes = useSelector(state => state.cartypes);
    const groupdriverdata = useSelector(state => state.groupdriverdata);
    const areasdata = useSelector(state => state.areasdata.areas);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [approveLookup, setApproveLookup] = useState({pending: "Đợi duyệt", reject: "Từ chối", approved: "Đã duyệt", new: "Chưa gửi"});
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        dispatch(fetchOnceUsers());
        dispatch(fetchAreasOnce());
    }, []);

    useEffect(() => {
        if (areasdata && areasdata.areas_once) {
            setAreas(Object.values(areasdata.areas_once));
        }
    }, [areasdata]);

    const handleClick = (name, uid, idSelect, buttonId) => {
        let valueSelect = document.getElementById(idSelect);
        let textElement = document.getElementById(name);
        let buttonElement = document.getElementById(buttonId);
        if (valueSelect && uid) {
            let content = valueSelect.textContent;
            if (approveLookup) {
                let keySelect = Object.keys(approveLookup).filter(key => approveLookup[key] == content)[0];
                if (textElement) {
                    let value = textElement.value;
                    let obj = {
                        at: GetCurrentMiniTimeStamp(),
                        by: auth.info && auth.info.uid ? auth.info.uid : '',
                        key: keySelect,
                        reason: keySelect == 'reject' ? value : ''
                    }
                    refApi.approveDriverReasonRef(uid, obj).then(() => {
                        refApi.approveDriverRef(uid, keySelect).then(() => {
                            dispatch(fetchOnceUsers());
                            buttonElement.style.display = 'none';
                        }).catch(error => console.log(error));
                    }).catch(error => console.log(error));
                }
            }
        }
        /**/
    };

    const handleSelect = (idText, valueSelect, idButton, oldValue, textValue) => {
        let textElement = document.getElementById(idText);
        let buttonElement = document.getElementById(idButton);
        if (valueSelect !== oldValue) {
            buttonElement.style.display = 'block';
        } else {
            buttonElement.style.display = 'none';
        }

        if (valueSelect == 'reject') {
            if (textElement) {
                textElement.parentNode.parentNode.style.display = 'block';
                textElement.disabled = false;
                textElement.value = '';
            }
        } else {
            if (textElement) {
                textElement.parentNode.parentNode.style.display = 'none';
                textElement.disabled = true;
                textElement.value = textValue;
            }
        }
        /**/
    };
    useEffect(() => {
        if (usersdata) {
            usersdata.map((driver) => {
                if (driver && driver.codeDriver && driver.codeDriver.length > 1 && driver.usertype == 'driver') {
                    if (driver.id) {
                        //app.database().ref("users/" + uid),
                        console.log("update");
                        /*refApi.updateProfileDoRef(driver.id, {
                            driverVipStatus: false,
                            historyLockGroup: null,
                            historyOutGroup: null,
                            walletHistory: null,
                            loginHistory: null,
                            per: 0,
                            perUse: 0
                        });*/
                    }
                }
            });
            //api.setCodeDriverApi(stringCode);
        }
    }, [usersdata]);

    useEffect(() => {
        if (cartypes.cars) {
            let obj = {};
            cartypes.cars.map((car) => obj[car.name] = car.name)
            setCars(obj);
        }
    }, [cartypes.cars]);

    useEffect(() => {
        if (groupdriverdata.complex) {
            let obj = {};
            groupdriverdata.complex.map((group) => obj[group.value] = group.label)
            setGroups(obj);
        } else {
            setGroups([]);
        }
    }, [groupdriverdata.complex]);

    const columns = [
        {
            title: language.createdAt,
            field: 'createdAt',
            editable: 'never',
            defaultSort: 'desc',
            render: rowData => rowData.createdAt ? new Date(rowData.createdAt).toLocaleString(dateStyle, {timeZone: timeZone}) : null
        },
        {title: language.first_name, field: 'firstName', initialEditValue: ''},
        {title: language.last_name, field: 'lastName', initialEditValue: ''},
        {title: language.code_driver, field: 'codeDriver', initialEditValue: ''},
        {title: "Kiểu LX", field: 'driverType', lookup: {noi_bo: "Nội bộ", dong_dam: "Đóng đàm", cong_nghe: "Công nghệ"}},
        {title: language.mobile, field: 'mobile'}, // , editable: 'onAdd'
        {
            title: language.profile_image,
            field: 'profile_image',
            render: rowData => rowData.profile_image ?
                <a href={rowData.profile_image} target={'_blank'}><img alt='Profile' src={rowData.profile_image} style={{width: 50, borderRadius: '50%'}}/></a> : null,
            editable: 'never'
        },
        {
            title: "Ảnh hồ sơ",
            field: 'avatar',
            render: rowData => rowData.avatar ?
                <a href={rowData.avatar} target={'_blank'}><img alt='Ảnh hồ sơ' src={rowData.avatar} style={{width: 50, borderRadius: '50%'}}/></a> : null,
            editable: 'never'
        },
        {
            title: "Ảnh xe",
            field: 'biensoxe',
            render: rowData => rowData.biensoxe ?
                <a href={rowData.biensoxe} target={'_blank'}><img alt='Ảnh xe' src={rowData.biensoxe} style={{width: 50, borderRadius: '50%'}}/></a> : null,
            editable: 'never'
        },
        {
            title: "Ảnh CC trước",
            field: 'cmnd',
            render: rowData => rowData.cmnd ?
                <a href={rowData.cmnd} target={'_blank'}><img alt='Ảnh CC trước' src={rowData.cmnd} style={{width: 50, borderRadius: '50%'}}/></a> : null,
            editable: 'never'
        },
        {
            title: "Ảnh CC Sau",
            field: 'cmnds',
            render: rowData => rowData.cmnds ?
                <a href={rowData.cmnds} target={'_blank'}><img alt='Ảnh CC trước' src={rowData.cmnds} style={{width: 50, borderRadius: '50%'}}/></a> : null,
            editable: 'never'
        },
        /* {title: language.vehicle_model_name, field: 'vehicleMake', initialEditValue: ''},
         {title: language.vehicle_model_no, field: 'vehicleModel', initialEditValue: ''},*/
        {title: language.vehicle_reg_no, field: 'vehicleNumber', initialEditValue: ''},
        /*{
            title: language.cmnd,
            field: 'cmnd',
            render: rowData => rowData.cmnd ? <img alt='License' src={rowData.cmnd} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.cmnd_s,
            field: 'cmndS',
            render: rowData => rowData.cmndS ? <img alt='License' src={rowData.cmndS} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.lisence_image,
            field: 'licenseImage',
            render: rowData => rowData.licenseImage ?
                <img alt='License' src={rowData.licenseImage} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.lisence_image_s,
            field: 'licenseSImage',
            render: rowData => rowData.licenseSImage ?
                <img alt='License' src={rowData.licenseSImage} style={{width: 100}}/> : null,
            editable: 'never'
        },
        {
            title: language.anh_bien_so_xe,
            field: 'anhBienSoXe',
            render: rowData => rowData.anhBienSoXe ?
                <img alt='License' src={rowData.anhBienSoXe} style={{width: 100}}/> : null,
            editable: 'never'
        },*/
        {title: language.car_type, field: 'carType', lookup: cars},
        {title: language.group_driver_title, field: 'groupDriver', lookup: groups},
        {title: "GPS ĐT?", field: 'gpsdt', type: 'boolean', initialEditValue: false},
        {title: "Contermet?", field: 'contermet', type: 'boolean', initialEditValue: false},
        {title: language.account_approve, field: 'approved', type: 'boolean', initialEditValue: true},
        {
            title: "Phê duyệt Tài xế", field: 'approvedDriver', lookup: approveLookup, initialEditValue: 'new',
            render: rowData => <Box
                component="form"
                sx={{
                    width: 150
                }}
                noValidate
                autoComplete="off"
            >

                <FormControl fullWidth sx={{flexDirection: 'row'}}>
                    <Select
                        labelId={"demo-simple-select-label" + rowData.id}
                        id={"demo-simple-select" + rowData.id}
                        defaultValue={rowData.approvedDriver ? rowData.approvedDriver : 'new'}
                        onChange={(event) => {
                            let value = event.target.value;
                            if (value == 'pending' || value == 'new') {
                                alert("Không thể chuyển trạng thái này");
                            } else {
                                handleSelect("driver_status_" + rowData.id, value, 'icon_button' + rowData.uid, rowData.approvedDriver ? rowData.approvedDriver : 'new', rowData.approvedDriverReason ? Object.values(rowData.approvedDriverReason)[Object.values(rowData.approvedDriverReason).length - 1].reason : '');
                            }
                        }
                        }
                    >
                        {approveLookup ?
                            Object.keys(approveLookup).map((key, index) => {
                                return (
                                    <MenuItem id={"menu_" + rowData.id + "_" + index.toString()} value={key} disabled={key == 'pending' || key == 'new'}>{approveLookup[key]}</MenuItem>
                                )
                            }) : null
                        }
                    </Select>
                    <IconButton
                        aria-label="Lưu"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                            color: 'white',
                            display: 'none'
                        }}
                        color="primary"
                        size="medium"
                        id={'icon_button' + rowData.uid}
                        onClick={() => {
                            if (rowData.uid) {
                                handleClick("driver_status_" + rowData.id, rowData.uid, "demo-simple-select" + rowData.id, 'icon_button' + rowData.uid);
                            }
                        }
                        }
                    >
                        <SaveIcon fontSize="inherit" color={"primary"}/>
                    </IconButton>
                </FormControl>
                <TextField
                    id={"driver_status_" + rowData.id}
                    label={"do"}
                    variant="outlined"
                    disabled={rowData.approvedDriver && rowData.approvedDriver == 'pending' ? true : false}
                    sx={{display: rowData.approvedDriver && (rowData.approvedDriver == 'reject' || rowData.approvedDriver == 'pending') ? 'block' : 'none'}}
                    fullWidth
                    defaultValue={rowData.approvedDriverReason ? Object.values(rowData.approvedDriverReason)[Object.values(rowData.approvedDriverReason).length - 1].reason : ''}
                    onChange={() => {

                    }
                    }

                />
            </Box>,
        },
        {title: language.driver_active, field: 'driverActiveStatus', type: 'boolean', initialEditValue: true},
        {
            title: language.wallet_balance,
            field: 'walletBalance',
            type: 'numeric',
            editable: 'never',
            initialEditValue: 0
        },
        {title: language.signup_via_referral, field: 'signupViaReferral', editable: 'never'},
        {title: language.referralId, field: 'referralId', editable: 'never', initialEditValue: ''},
        {title: language.bankName, field: 'bankName', initialEditValue: ''},
        {title: language.bankCode, field: 'bankCode', initialEditValue: ''},
        {title: language.bankAccount, field: 'bankAccount', initialEditValue: ''},
        {title: "Leader", field: 'vehicleNumberLeader', initialEditValue: ''},
        {title: language.queue, field: 'queue', type: 'boolean', initialEditValue: false},
    ];

    return (
        <MaterialTable
            title={language.drivers}
            columns={columns}
            data={usersdata ? usersdata.filter((u) => u.usertype == 'driver') : []}
            options={{
                exportButton: true,
                sorting: true,
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100]
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            checkUserExists(newData).then((res) => {
                                if (res.users && res.users.length > 0) {
                                    alert(language.user_exists);
                                    reject();
                                } else if (res.error) {
                                    alert(language.email_or_mobile_issue);
                                    reject();
                                } else {
                                    newData['createdByAdmin'] = true;
                                    newData['lock_driver_status'] = false;
                                    newData['usertype'] = 'driver';
                                    newData['createdAt'] = new Date().toISOString();
                                    newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                                    let role = auth.info.profile.usertype;
                                    if (role === 'fleetadmin') {
                                        newData['fleetadmin'] = auth.info.uid;
                                    }
                                    dispatch(addUser(newData));
                                    resolve();
                                }
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();

                            let car = cartypes.cars.filter((car) => car.name == newData.carType && car.type == "taxi")[0];
                            if (car) {
                                newData["carTypeCode"] = car.code;
                            }
                            if (newData.codeDriver) {
                                newData.codeDriver = newData.codeDriver.toString().trim();
                            }

                            let infoMember = {
                                codeDriver: "789",
                                date_add: 1670383817,
                                info: {
                                    codeDriver: "789",
                                    free: 0,
                                    fromDriverCode: "Trung Tâm",
                                    from_uid: oldData.id,
                                    type: "driver"
                                },
                                role: "member",
                                status: "active",
                                type: "driver",
                                uid: oldData.id
                            }


                            //addMemberAreaApi(areaId, uid, data);

                            dispatch(editUser(oldData.id, newData));
                            dispatch(fetchOnceUsers());
                        }, 600);
                    }),
                onRowDelete: oldData =>
                    features.AllowCriticalEditsAdmin ?
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(deleteUser(oldData.id));
                            }, 600);
                        })
                        :
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                alert(language.demo_mode);
                            }, 600);
                        })
                ,
            }}
        />
    );
}
